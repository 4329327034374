export var ETableFilterValueType;
(function (ETableFilterValueType) {
    ETableFilterValueType["TEXT"] = "TEXT";
    ETableFilterValueType["BOOLEAN"] = "BOOLEAN";
    ETableFilterValueType["NUMBER"] = "NUMBER";
    ETableFilterValueType["DATE"] = "DATE";
    ETableFilterValueType["OPTION"] = "OPTION";
    ETableFilterValueType["OPTIONS"] = "OPTIONS";
})(ETableFilterValueType || (ETableFilterValueType = {}));
export var ETableFilterComparison;
(function (ETableFilterComparison) {
    ETableFilterComparison["EQUAL"] = "EQUAL";
    ETableFilterComparison["CONTAINS"] = "CONTAINS";
    ETableFilterComparison["BIGGER"] = "BIGGER";
    ETableFilterComparison["LESS"] = "LESS";
    ETableFilterComparison["EQUAL_BIGGER"] = "EQUAL_BIGGER";
    ETableFilterComparison["EQUAL_LESS"] = "EQUAL_LESS";
})(ETableFilterComparison || (ETableFilterComparison = {}));
