import { convertDictionaryToViewLabelArray } from "utils-library/dist/utils";
export var EAlertsManagementRights;
(function (EAlertsManagementRights) {
    EAlertsManagementRights["ALERT_CONFIG_VIEW"] = "AM_CV";
    EAlertsManagementRights["ALERT_CONFIG_EDIT"] = "AM_CE";
    EAlertsManagementRights["ALERT_HANDLING_VIEW"] = "AM_HV";
    EAlertsManagementRights["ALERT_HANDLING_EDIT"] = "AM_HE";
    EAlertsManagementRights["ALERT_HANDLING_RESOLVE"] = "AM_HR";
    EAlertsManagementRights["ALERT_HANDLING_UNRESOLVE"] = "AM_HU";
    EAlertsManagementRights["ALERTS_TEST_PAGE"] = "AM_TP";
})(EAlertsManagementRights || (EAlertsManagementRights = {}));
const labelsDic = {
    [EAlertsManagementRights.ALERT_CONFIG_VIEW]: 'Alert Mgtm / Config view',
    [EAlertsManagementRights.ALERT_CONFIG_EDIT]: 'Alert Mgtm / Config edit',
    [EAlertsManagementRights.ALERT_HANDLING_VIEW]: 'Alert Mgtm / Handling view',
    [EAlertsManagementRights.ALERT_HANDLING_EDIT]: 'Alert Mgtm / Handling edit',
    [EAlertsManagementRights.ALERT_HANDLING_RESOLVE]: 'Alert Mgtm / Handling resolve',
    [EAlertsManagementRights.ALERT_HANDLING_UNRESOLVE]: 'Alert Mgtm / Handling unresolve',
    [EAlertsManagementRights.ALERTS_TEST_PAGE]: 'Alert Mgtm / Test page',
};
export const EAlertsManagementRightsArray = convertDictionaryToViewLabelArray(labelsDic);
