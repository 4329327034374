import { EDeviceBrokerType } from "./IDevice";
import { EDeviceType } from "./EDeviceType";
export var EDeviceCycleStatus;
(function (EDeviceCycleStatus) {
    EDeviceCycleStatus["ONLINE"] = "ONLINE";
    EDeviceCycleStatus["STARTING"] = "STARTING";
    EDeviceCycleStatus["RESTARTING"] = "RESTARTING";
    EDeviceCycleStatus["TIMEOUT"] = "TIMEOUT";
    EDeviceCycleStatus["SHUTDOWN"] = "SHUTDOWN";
    EDeviceCycleStatus["UNKNOWN"] = "UNKNOWN";
})(EDeviceCycleStatus || (EDeviceCycleStatus = {}));
export var EDeviceSummaryStatus;
(function (EDeviceSummaryStatus) {
    EDeviceSummaryStatus["OK"] = "OK";
    EDeviceSummaryStatus["WARN"] = "WARN";
    EDeviceSummaryStatus["ERROR"] = "ERROR";
    EDeviceSummaryStatus["UNKNOWN"] = "UNKNOWN";
})(EDeviceSummaryStatus || (EDeviceSummaryStatus = {}));
export const getDefaultDeviceStatus = (partial) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    return ({
        schema: 'https://www.mhcorbin.com/schemas/device-status/1.0',
        deviceBrokerId: (_a = partial === null || partial === void 0 ? void 0 : partial.deviceBrokerId) !== null && _a !== void 0 ? _a : '',
        deviceBrokerType: (_b = partial === null || partial === void 0 ? void 0 : partial.deviceBrokerType) !== null && _b !== void 0 ? _b : EDeviceBrokerType.UNKNOWN,
        companyId: (_c = partial === null || partial === void 0 ? void 0 : partial.companyId) !== null && _c !== void 0 ? _c : '',
        regionId: (_d = partial === null || partial === void 0 ? void 0 : partial.regionId) !== null && _d !== void 0 ? _d : '',
        locationId: (_e = partial === null || partial === void 0 ? void 0 : partial.locationId) !== null && _e !== void 0 ? _e : '',
        deviceId: (_f = partial === null || partial === void 0 ? void 0 : partial.deviceId) !== null && _f !== void 0 ? _f : '',
        activationId: (_g = partial === null || partial === void 0 ? void 0 : partial.activationId) !== null && _g !== void 0 ? _g : '',
        manufacturerName: (_h = partial === null || partial === void 0 ? void 0 : partial.manufacturerName) !== null && _h !== void 0 ? _h : '',
        deviceType: (_j = partial === null || partial === void 0 ? void 0 : partial.deviceType) !== null && _j !== void 0 ? _j : EDeviceType.UNKNOWN,
        cycleStatus: (_k = partial === null || partial === void 0 ? void 0 : partial.cycleStatus) !== null && _k !== void 0 ? _k : EDeviceCycleStatus.UNKNOWN,
        summaryStatus: (_l = partial === null || partial === void 0 ? void 0 : partial.summaryStatus) !== null && _l !== void 0 ? _l : EDeviceSummaryStatus.UNKNOWN,
        status: (_m = partial === null || partial === void 0 ? void 0 : partial.status) !== null && _m !== void 0 ? _m : null,
        firmwareVersion: (_o = partial === null || partial === void 0 ? void 0 : partial.firmwareVersion) !== null && _o !== void 0 ? _o : '',
        updatedAt: (_p = partial === null || partial === void 0 ? void 0 : partial.updatedAt) !== null && _p !== void 0 ? _p : 0,
        loadStatusError: (_q = partial === null || partial === void 0 ? void 0 : partial.loadStatusError) !== null && _q !== void 0 ? _q : null,
    });
};
