import {IAppRoute} from "../../application/config/IAppRoute";

import {routeSignInPage} from "./routeSignInPage";
import {routeOAuthSignResolutionInPage} from "./routeOAuthSignResolutionInPage";
import {routeSignInWithInvitationPage} from "./routeSignInWithInvitationPage";
import {routeUserProfilePage} from "./routeUserProfilePage";
import {routeCreateCompanyPage} from "./routeCreateCompanyPage";

export const routesUserAuthentication: IAppRoute<any, any>[] = [
  routeSignInPage,
  routeSignInWithInvitationPage,
  routeOAuthSignResolutionInPage,
  routeUserProfilePage,
  routeCreateCompanyPage,
];
