export var EDeviceType;
(function (EDeviceType) {
    EDeviceType["UNKNOWN"] = "UNKNOWN";
    EDeviceType["CONNECT_ITS_LOCATION_HUB"] = "CONNECT:ITS_v1";
    EDeviceType["MHC_TRAFFIC_VEHICLES_COUNTER"] = "MHC_TRAFFIC_VEHICLES_COUNTER";
    EDeviceType["DEMO_CONNECT_ITS_LOCATION_HUB"] = "CONNECT:ITS";
    EDeviceType["DEMO_SPEED_CAMERA"] = "DEMO_SPEED_CAMERA";
    EDeviceType["DEMO_SNOW_SENSOR"] = "DEMO_SNOW_SENSOR";
})(EDeviceType || (EDeviceType = {}));
export var EDeviceFamily;
(function (EDeviceFamily) {
    EDeviceFamily["HUBS"] = "HUBS";
    EDeviceFamily["TRAFFIC_COUNTER"] = "TRAFFIC_COUNTER";
    EDeviceFamily["DEMO"] = "DEMO";
    EDeviceFamily["WEATHER"] = "WEATHER";
})(EDeviceFamily || (EDeviceFamily = {}));
