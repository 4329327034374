import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isMac } from "utils-library/dist/web";
import { Button, EButtonVariant, EButtonColor, EButtonSize, } from "../Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "../Box";
import { useTheme, useMediaQuery, } from "../ThemeProvider";
import { createIcon } from "../IconComponent";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
export const Confirm = (props) => {
    const { show, title, message = "", children, helperMessage = "", forceUserConfirmation = false, labelConfirmButton = "Confirm", labelConfirmIcon = createIcon.byMuiIcon(CheckIcon), labelCancelButton = "Cancel", labelCancelIcon = createIcon.byMuiIcon(CancelIcon), onOk, onCancel, onClose, } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const handleOkClick = () => {
        onOk && onOk();
        onClose && onClose(true);
    };
    const handleCancelClick = () => {
        onCancel && onCancel();
        onClose && onClose(false);
    };
    const primaryButton = (_jsx(Button, { Icon: labelConfirmIcon, variant: EButtonVariant.CONTAINED, color: EButtonColor.PRIMARY, size: EButtonSize.MEDIUM, onClick: handleOkClick, children: labelConfirmButton }));
    return (_jsxs(Dialog, { sx: { zIndex: 3200 }, open: show, onClose: forceUserConfirmation ? handleCancelClick : undefined, "aria-labelledby": `confirmation-dialog-${title}`, fullScreen: fullScreen, children: [_jsx(DialogTitle, { children: title }), _jsxs(DialogContent, { children: [_jsx(DialogContentText, { id: "confirmation-dialog-description", children: _jsx("strong", { style: { fontSize: theme.typography.fontSize + 2 }, children: message }) }), children, _jsx("br", {}), _jsx("br", {}), _jsx(Box, { component: "span", sx: {
                            fontStyle: 'italic',
                            fontWeight: 'bold',
                            whiteSpace: 'pre',
                            marginTop: theme => theme.spacing(1),
                        }, children: helperMessage })] }), _jsxs(DialogActions, { children: [!isMac && primaryButton, _jsx(Button, { Icon: labelCancelIcon, show: labelCancelButton !== null, variant: EButtonVariant.CONTAINED, color: EButtonColor.ERROR, size: EButtonSize.MEDIUM, onClick: handleCancelClick, children: labelCancelButton }), isMac && primaryButton] })] }));
};
