import { validateDataMethods } from "utils-library/dist/validation-engine";
import { EDeviceType } from "../interfaces/EDeviceType";
export const TrafficVehiclesCounterMHCDriver = {
    deviceType: EDeviceType.MHC_TRAFFIC_VEHICLES_COUNTER,
    label: 'NC400 Traffic Counter',
    validateConfig: { activeStudy: validateDataMethods.isBoolean },
    validateStatus: {
        acPower: validateDataMethods.isBoolean,
        dcVoltage: validateDataMethods.isNumber,
        studyStatus: validateDataMethods.isBoolean,
        batteryChargePercentage: value => validateDataMethods.isNumberInRange(value, 0, 100),
    },
};
export const getDefaultTrafficVehiclesCounterConfig = () => ({ activeStudy: false });
