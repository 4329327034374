import { validateDataMethods } from "utils-library/dist/validation-engine";
import { EDeviceType } from "../interfaces/EDeviceType";
export const DemoSpeedCamera = {
    deviceType: EDeviceType.DEMO_SPEED_CAMERA,
    label: '(demo) Speed camera',
    validateConfig: {
        scanDistanceInMeters: value => validateDataMethods.isNumberInRange(value, 0, 1000),
        speedLimitInKm: value => validateDataMethods.isNumberInRange(value, 0, 1000),
    },
    validateStatus: {
        incidentsInLast30Mins: value => validateDataMethods.isNumberInRange(value, 0, 10000),
        voltage: value => validateDataMethods.isNumberInRange(value, 0, 1000),
    },
};
export const getDefaultDemoSpeedCameraConfig = () => ({
    scanDistanceInMeters: 1,
    speedLimitInKm: 0,
});
