import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { dynaSwitch } from "dyna-switch";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "../../Box";
import { FlexContainerVertical } from "../../FlexContainer";
import { ButtonBar, EButtonBarAlign, } from "../../ButtonBar";
import { Button, EButtonColor, EButtonSize, } from "../../Button";
import { useTheme, } from "../../ThemeProvider";
import { alpha } from "@mui/material/styles";
import SaveIcon from "@mui/icons-material/Save";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import CancelIcon from "@mui/icons-material/Cancel";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import FailedIcon from "@mui/icons-material/Error";
import RetryIcon from "@mui/icons-material/Replay";
import DownloadedIcon from "@mui/icons-material/CloudDownload";
import SavedIcon from "@mui/icons-material/SaveAlt";
export const SaveOfflineMapsDialog = (props) => {
    const { sx = {}, dataComponentName, saveMapsArgs: { savedMapsLabel, savedMapsCount, saveMapsLabel, saveMapsCount, saveMaps, }, onClose, } = props;
    const theme = useTheme();
    const [step, setStep] = useState(navigator.onLine
        ? 'CONFIRM_SAVE'
        : 'NO_INTERNET');
    const [progress, setProgress] = useState(0);
    const [loadFailed, setLoadFailed] = useState(0);
    const [saveFailed, setSaveFailed] = useState(0);
    const [saveHandler, setSaveHandler] = useState();
    const handleNoInternetRetry = () => {
        setStep(navigator.onLine
            ? "CONFIRM_SAVE"
            : "NO_INTERNET");
    };
    const handleSaveClick = () => {
        const saveHandler = saveMaps({
            onProgress: ({ progress, failedLoadCount, failedSaveCount, }) => {
                setProgress(progress);
                setLoadFailed(failedLoadCount);
                setSaveFailed(failedSaveCount);
            },
            onComplete: () => {
                setStep('COMPLETED');
            },
        });
        setSaveHandler(saveHandler);
        setStep('SAVING');
    };
    const handleCancelSaving = () => {
        if (saveHandler)
            saveHandler.cancel();
        onClose();
    };
    const handleRetryClick = () => {
        setLoadFailed(0);
        setSaveHandler(undefined);
        setProgress(0);
        handleSaveClick();
    };
    const sxSuccessIcon = {
        color: theme.palette.success.main,
        width: 128,
        height: 128,
    };
    const sxFailedIcon = {
        color: theme.palette.error.main,
        width: 128,
        height: 128,
    };
    const sxProgressSmallIcon = {
        width: 16,
        height: 16,
        marginRight: 6,
        position: 'relative',
        top: 2,
    };
    const content = dynaSwitch(step, _jsx("span", { children: "Internal error" }), // 4TS this is not possible
    {
        NO_INTERNET: (_jsxs(_Fragment, { children: [_jsx(FailedIcon, { sx: sxFailedIcon }), _jsx("h2", { children: "No internet connection" }), _jsxs(ButtonBar, { align: EButtonBarAlign.CENTER, children: [_jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(RetryIcon, {}), color: EButtonColor.PRIMARY, onClick: handleNoInternetRetry, children: "Retry" }), _jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(CancelIcon, {}), color: EButtonColor.SECONDARY, onClick: onClose, children: "Cancel" })] })] })),
        CONFIRM_SAVE: (_jsxs(_Fragment, { children: [_jsx(SaveIcon, { sx: {
                        width: 128,
                        height: 128,
                    } }), _jsxs("h3", { children: ["Save ", saveMapsLabel, " ?"] }), savedMapsCount
                    ? _jsxs("h5", { children: ["You have already saved ", savedMapsLabel] })
                    : _jsx("h5", { children: "Nothing has been saved so far" }), _jsxs(ButtonBar, { align: EButtonBarAlign.CENTER, children: [_jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(SaveIcon, {}), onClick: handleSaveClick, children: "Save" }), _jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(CancelIcon, {}), color: EButtonColor.SECONDARY, onClick: onClose, children: "Cancel" })] })] })),
        SAVING: (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Saving..." }), _jsx(LinearProgress, { variant: "determinate", value: progress }), _jsxs("h3", { children: [progress, "%"] }), _jsxs("h5", { children: [_jsx(DownloadedIcon, { sx: sxProgressSmallIcon }), "Download failed: ", loadFailed] }), _jsxs("h5", { children: [_jsx(SavedIcon, { sx: sxProgressSmallIcon }), "Save failed: ", saveFailed] }), _jsx("br", {}), _jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(CancelIcon, {}), color: EButtonColor.ERROR, onClick: handleCancelSaving, children: "Cancel" })] })),
        COMPLETED: (_jsxs(_Fragment, { children: [!!loadFailed || !!saveFailed
                    ? _jsx(FailedIcon, { sx: sxFailedIcon })
                    : _jsx(SuccessIcon, { sx: sxSuccessIcon }), _jsx("h3", { children: "Completed" }), !!loadFailed && _jsxs("h5", { children: ["...with ", loadFailed, " load failed of ", saveMapsCount, " total"] }), !!saveFailed && (_jsxs(_Fragment, { children: [_jsxs("h3", { children: ["Couldn't save ", saveFailed, " map", saveFailed > 1 ? 's' : '', "."] }), _jsx("h5", { children: "The device's disk is probably full, or the allocated disk space for the app is not enough. Try to delete the maps and retry." })] })), _jsxs(ButtonBar, { align: EButtonBarAlign.CENTER, spacing: loadFailed ? 1 : 0, children: [_jsx(Button, { size: EButtonSize.LARGE, icon: loadFailed ? _jsx(BackIcon, {}) : undefined, onClick: onClose, children: "Done" }), !!loadFailed && (_jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(RetryIcon, {}), color: EButtonColor.SECONDARY, onClick: handleRetryClick, children: "Retry" }))] })] })),
    });
    return (_jsx(Box, { sx: Object.assign({ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 2500, color: theme.palette.text.primary, backgroundColor: alpha(theme.palette.background.default, 0.8) }, sx), dataComponentName: dataComponentName, children: _jsx(FlexContainerVertical, { fullHeight: true, alignHorizontal: "center", alignVertical: "middle", spacing: 3, sx: { textAlign: 'center' }, children: content }) }));
};
