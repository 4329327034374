import { Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
export const Condition = (props) => {
    const { "if": _if, "not": _not, then = null, children = null, "else": _else = null, } = props;
    const isTrue = (() => {
        if (_not === undefined && _if)
            return true;
        if (_not && !_if)
            return true;
        return false;
    })();
    return isTrue
        ? (_jsxs(_Fragment, { children: [then, children] }))
        : _else;
};
