export const setDeepValue = ({ data: userData, propName, value: newValue, }) => {
    const data = Object.assign({}, userData);
    const paths = propName.split('.');
    const lastPath = paths[paths.length - 1];
    const pathObjects = [data];
    paths
        .forEach(path => {
        const lastObjectPath = pathObjects[pathObjects.length - 1];
        if (lastObjectPath === undefined) {
            pathObjects.push(undefined);
            return;
        }
        pathObjects.push(lastObjectPath[path]);
    });
    const targetObject = pathObjects[pathObjects.length - 2];
    const propFound = targetObject !== undefined && targetObject[lastPath] !== undefined;
    let changed = false;
    if (propFound) {
        const currentValue = targetObject[lastPath];
        const diff = currentValue !== newValue;
        if (diff) {
            targetObject[lastPath] = newValue;
            changed = true;
        }
    }
    return {
        data,
        changed,
        propFound,
    };
};
