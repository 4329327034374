import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {ITypeOfClosureIconProps} from "./TypeOfClosureIcon";

const TypeOfClosureIconLazy = lazy(() => import("./TypeOfClosureIcon"));

export const TypeOfClosureIcon = (props: ITypeOfClosureIconProps) => (
  <LazyLoadComponent>
    <TypeOfClosureIconLazy {...props}/>
  </LazyLoadComponent>
);
