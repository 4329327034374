import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useMemo, } from "react";
import { pluralize } from "utils-library/dist/utils";
import MuiBox from "@mui/material/Box";
import { getDataComponentName, } from "../ui-interfaces";
import { Condition } from "../Condition";
export const Box = React.forwardRef(function Box(props, ref) {
    const { id, sx = {}, style = {}, inline = false, dir, dataComponentName = [], dataAttributes: userDataAttributes, lowLevelClassName, component, show = true, visible = true, not, hidden = false, fullWidth = false, fullHeight = false, title, tabIndex, children, onClick, onMouseMove, onMouseDown, onMouseUp, onMouseEnter, onMouseLeave, onScroll, onWheel, } = props;
    const dataAttributes = useMemo(() => {
        if (!userDataAttributes)
            return;
        let invalidDataAttributes = 0;
        const dataAttributes = Object.entries(userDataAttributes)
            .reduce((acc, [key, value]) => {
            key.startsWith('data-')
                ? acc[key] = value
                : invalidDataAttributes++;
            return acc;
        }, {});
        if (invalidDataAttributes) {
            console.error(`Box component 20230608114528 error: The dataAttributes contain ${invalidDataAttributes} ${pluralize('key', invalidDataAttributes)} that ${pluralize('does', invalidDataAttributes)} not start with 'data-'.`, { dataAttributes: userDataAttributes });
        }
        return dataAttributes;
    }, []);
    return (_jsx(Condition, { if: show, not: not, children: _jsx(MuiBox, Object.assign({ id: id, className: lowLevelClassName, sx: Object.assign(Object.assign(Object.assign(Object.assign({}, (hidden
                ? {
                    height: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    right: '100000px',
                }
                : {})), (inline === 'flex'
                ? { display: 'inline-flex' }
                : inline
                    ? { display: 'inline-block' }
                    : {})), { visibility: visible ? undefined : 'hidden' }), sx), ref: ref, style: Object.assign(Object.assign(Object.assign({}, style), (fullWidth
                ? { width: '100%' }
                : {})), (fullHeight
                ? { height: '100%' }
                : {})), "data-component-name": getDataComponentName(dataComponentName, "Box") }, dataAttributes, { dir: dir, title: title, tabIndex: tabIndex, component: component, onClick: onClick, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onScroll: onScroll, onWheel: onWheel, children: children })) }));
});
