/**
 * Builds path string based on react router path signature, and path & query data
 * @param routePath
 * @param pathData
 * @param queryParams
 */
export const buildUrlByRoutePath = ({ routePath, pathParams = {}, queryParams = {}, }) => {
    let path = routePath.split('/')
        .map(segment => {
        if (segment.startsWith(":")) {
            const key = segment.slice(1).replace(/\?$/, ""); // Remove the leading ":" and any trailing "?"
            const value = pathParams[key];
            if (value !== undefined) {
                return value;
            }
            else if (segment.endsWith("?")) {
                // No value for this optional segment (will be removed)
                return "";
            }
            else {
                // No value of this required segment, leave it as it is
                return segment;
            }
        }
        else {
            return segment;
        }
    })
        .filter(segment => segment !== "")
        .join('/');
    if (routePath.startsWith('/'))
        path = `/${path}`;
    const hasQueryValues = !!Object.values(queryParams).filter(v => v !== undefined).length;
    if (hasQueryValues) {
        path += '?';
        Object.entries(queryParams)
            .forEach(([key, value], index) => {
            if (value === undefined)
                return;
            if (index !== 0)
                path += '&';
            path += `${key}=${value}`;
        });
    }
    return path;
};
