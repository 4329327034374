import { convertEnumKeyToHumanReadable } from "./convertEnumKeyToHumanReadable";
export const convertEnumToValueLabel = (anEnum, args) => {
    const { capitalize = true, everyWord = false, } = args || {
        capitalize: true,
        everyWord: false,
    };
    return Object.values(anEnum)
        .map((v) => {
        return {
            label: capitalize ? convertEnumKeyToHumanReadable(v, everyWord) : v,
            value: v,
        };
    });
};
