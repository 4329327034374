import { jsx as _jsx } from "react/jsx-runtime";
import { Popup as _Popup } from 'react-leaflet'; // Todo: MHC-00783 react-leaflet v4 has some typescript errors https://trello.com/c/U7DFYEIA
import { Box } from "../../../../../../Box";
const Popup = _Popup;
export const MapPopUp = (props) => {
    const { children, position, onOpen, onClose, } = props;
    if (!children)
        return null;
    return (_jsx(Popup, { position: position, onOpen: onOpen, onClose: onClose, children: _jsx(Box, { sx: { color: theme => theme.palette.common.black }, children: children }) }));
};
