//#region "Format value"
import moment from "moment";
import momentTimezone from "moment-timezone";
import { dynaError } from "dyna-error";
import { getUserLocale, round, } from "../utils";
import { getSystemTimezone } from "../time";
import { EDateFormatter, EDurationFormatter, ETimeFormatter, EValueType, EDateTimeOrder, } from "./IFieldConfiguration";
/**
 * Use the `formatField` function to format any kind of `value` according to a `fieldConfiguration`.
 *
 * The purpose of this tool is to convert any type of value into a string.
 * This is necessary because the DOM can only display strings, and `formatField` ensures that the data is
 * converted into a string that is safe for DOM rendering.
 *
 * The `fieldConfiguration` aims to be as simple as possible and can be provided from a JSON source such as a server.
 * `formatField` utilizes the Webdesk's global `getUserLocale` function to determine the user's locale by default,
 * but this behavior can be overridden by the `fieldConfiguration`.
 *
 * `formatField` supports formatting text, numbers, dates/times, durations,
 * and also allows for custom formatters through the `customValueTypeConverters` property.
 * This allows for objects to be formatted into strings as well.
 */
export const formatField = ({ fieldConfiguration, fieldConfiguration: { valueType, currency = "eur", currencySymbolPosition = "auto", precision, leadingZeros, trailingZeros, decimalPoint, thousandsSeparator, dateFormatter = EDateFormatter.STANDARD, timeFormatter = ETimeFormatter.HOUR_MINUTE, dateTimeOrder, durationFormatter = EDurationFormatter.SHORT_DURATION, locale, }, value, timezone, customValueTypeConverters, }) => {
    switch (valueType) {
        case EValueType.TEXT:
            return String(value).trim();
        case EValueType.INTEGER:
            return formatNumberField({
                value: Number(value),
                precision: 0,
                leadingZeros,
                thousandsSeparator,
            });
        case EValueType.NUMBER:
            return formatNumberField({
                value: Number(value),
                precision,
                leadingZeros,
                trailingZeros,
                decimalPoint,
                thousandsSeparator,
            });
        case EValueType.CURRENCY:
            return formatCurrencyField({
                value: Number(value),
                currency,
                currencySymbolPosition,
                precision,
                leadingZeros,
                trailingZeros,
                decimalPoint,
                thousandsSeparator,
            });
        case EValueType.DATE:
            return formatDateField({
                dateFormatter,
                value,
                timezone,
                locale,
            });
        case EValueType.TIME:
            return formatTimeField({
                timeFormatter,
                value,
                timezone,
                locale,
            });
        case EValueType.DATE_TIME:
            return formatDateTimeField({
                dateFormatter,
                timeFormatter,
                value,
                timezone,
                dateTimeOrder,
                locale,
            });
        case EValueType.DURATION:
            return formatDurationField({
                ms: Number(value),
                durationFormatter,
                precision,
                leadingZeros,
                trailingZeros,
                decimalPoint,
                thousandsSeparator,
                locale,
            });
        default: {
            const converter = (customValueTypeConverters || {})[valueType];
            if (!converter) {
                console.error(`formatField error ER992: unknown value type [${valueType}]`, {
                    fieldConfiguration,
                    value,
                    customValueTypeConverters,
                });
                return `err938474(${valueType})`;
            }
            return converter(value);
        }
    }
};
//#endregion "Format value"
//#region "Format number"
const regExpThousands = /\B(?=(\d{3})+(?!\d))/g;
const getThousandSeparatorOfCurrentLocale = (locale) => (12345).toLocaleString(locale).replace(/\d+/g, '');
const getDecimalPointOfCurrentLocale = (locale) => (12.345).toLocaleString(locale).replace(/\d+/g, '');
export const formatNumberField = ({ value, precision = 0, leadingZeros = 0, trailingZeros = 0, decimalPoint, thousandsSeparator, locale: overrideLocale, }) => {
    if (value === "" || value === undefined || value === null)
        return "";
    const locale = getUserLocale(overrideLocale);
    let [integerPortion, decimalPortion] = (round(value, precision).toString() + ".").split('.');
    while (integerPortion.length < leadingZeros)
        integerPortion = '0' + integerPortion;
    while (decimalPortion.length < trailingZeros)
        decimalPortion += "0";
    const applyThousandSeparator = thousandsSeparator === undefined
        ? ""
        : thousandsSeparator === "auto"
            ? getThousandSeparatorOfCurrentLocale(locale)
            : thousandsSeparator;
    const applyDecimalPoint = decimalPoint !== undefined
        ? decimalPoint
        : getDecimalPointOfCurrentLocale(locale);
    integerPortion = integerPortion.replace(regExpThousands, applyThousandSeparator);
    if (precision === 0)
        return integerPortion;
    return integerPortion + applyDecimalPoint + decimalPortion;
};
//#endregion "Format number"
//#region "Format currency"
export const formatCurrencyField = ({ value, currency, currencySymbolPosition = "auto", precision = 2, leadingZeros = 0, trailingZeros = 0, decimalPoint, thousandsSeparator = "auto", }) => {
    if (value === "" || value === undefined || value === null)
        return "";
    const formattedValue = formatNumberField({
        value,
        precision,
        leadingZeros,
        trailingZeros,
        decimalPoint,
        thousandsSeparator,
    });
    if (!currency) {
        console.error('formatCurrency error ER388: type EValueType.CURRENCY requires the `currency` property but was not provided, only the value returned');
        return `${formattedValue} (ER388)`;
    }
    const currencySymbol = getCurrencySymbol(currency);
    const applyPosition = currencySymbolPosition === "auto"
        ? currency.toLowerCase() === "usd" ? "begin" : "end"
        : "end";
    return applyPosition === "begin"
        ? `${currencySymbol} ${formattedValue}`
        : `${formattedValue} ${currencySymbol}`;
};
const getCurrencySymbol = (currency) => {
    try {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
        });
        const parts = formatter.formatToParts(1);
        const symbolPart = parts.find(part => part.type === 'currency');
        if (!symbolPart)
            throw new Error("Internal error 20240523085725: Intl.NumberFormat returned unexpected format.");
        return symbolPart.value || currency.toUpperCase();
    }
    catch (e) {
        const error = dynaError(e);
        console.error(`getCurrencySymbol error ER912: Error getting currency symbol for currency: [${currency}]:`, error);
        return currency.toUpperCase(); // Return the uppercase currency code as a fallback
    }
};
//#endregion "Format currency"
//#region "Format date"
const dateFormatters = {
    [EDateFormatter.HIDDEN]: '',
    [EDateFormatter.ISO]: 'YYYY-MM-DD',
    [EDateFormatter.STANDARD]: '--handled-by-code--',
    [EDateFormatter.LONG_DATE]: 'MMMM DD, YYYY',
    [EDateFormatter.FULL_DATE]: 'dddd DD MMMM YYYY',
    [EDateFormatter.YEAR_MONTH]: 'YYYY-MM',
    [EDateFormatter.MONTH_YEAR]: 'MM-YYYY',
    [EDateFormatter.DAY_OF_WEEK]: 'dddd',
    [EDateFormatter.SHORT_MONTH]: 'MMM',
    [EDateFormatter.FULL_MONTH]: 'MMMM',
    [EDateFormatter.SHORT_YEAR]: 'YY',
    [EDateFormatter.FULL_YEAR]: 'YYYY',
};
export const formatDateField = ({ dateFormatter, value, timezone, locale: overrideLocale, }) => {
    if (value === "" || value === undefined || value === null)
        return "";
    const locale = getUserLocale(overrideLocale);
    const mValue = getMomentTimezone(value, timezone);
    if (dateFormatter === EDateFormatter.STANDARD) {
        return mValue
            .toDate()
            .toLocaleDateString(locale, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    }
    const getFallbackDateFormat = (errorCode) => `${mValue.format(dateFormatters[EDateFormatter.STANDARD])} (${errorCode})`;
    if (!dateFormatter) {
        console.error('formatDate error ER322: type EValueType.DATE requires the `formatDate` property but was not provided, a fallback format applied');
        return getFallbackDateFormat("ER322");
    }
    const dateFormatterApply = dateFormatters[dateFormatter];
    if (!dateFormatterApply) {
        console.error(`formatDate error ER323: the provided formatDate [${dateFormatter}] for type EValueType.DATE is unknown, a fallback format applied`);
        return getFallbackDateFormat("ER323");
    }
    return mValue
        .locale(locale)
        .format(dateFormatterApply);
};
//#endregion "Format date"
//#region "Format time"
const timeFormatters = {
    [ETimeFormatter.HIDDEN]: () => "",
    [ETimeFormatter.HOUR]: (value, locale, timezone) => getMomentTimezone(getDateTimeFromTextValue(value), timezone)
        .toDate()
        .toLocaleTimeString(locale, {
        hour: '2-digit',
        timeZone: timezone,
    }),
    [ETimeFormatter.HOUR_MINUTE]: (value, locale, timezone) => getMomentTimezone(getDateTimeFromTextValue(value), timezone)
        .toDate()
        .toLocaleTimeString(locale, {
        hour: getHour12(locale) ? 'numeric' : '2-digit',
        minute: '2-digit',
        hour12: getHour12(locale),
        timeZone: timezone,
    }),
    [ETimeFormatter.HOUR_MINUTE_SECONDS]: (value, locale, timezone) => getMomentTimezone(getDateTimeFromTextValue(value), timezone)
        .toDate()
        .toLocaleTimeString(locale, {
        hour: getHour12(locale) ? 'numeric' : '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: getHour12(locale),
        timeZone: timezone,
    }),
    [ETimeFormatter.HOUR_MINUTE_SECONDS_MILLISECONDS]: (value, locale, timezone) => getMomentTimezone(getDateTimeFromTextValue(value), timezone)
        .toDate()
        .toLocaleTimeString(locale, {
        hour: getHour12(locale) ? 'numeric' : '2-digit',
        minute: '2-digit',
        second: '2-digit',
        // eslint-disable-next-line
        ["fractionalSecondDigits" + ""]: 3,
        hour12: getHour12(locale),
        timeZone: timezone,
    }),
};
/**
 * Converts a time value in the format "hh:mm", "hh:mm:ss", "hh:mm:ss.MMM", or "hh:mm:ss:MMM" to a timestamp (number).
 * If the input does not match any of these formats, it returns the input value as it is.
 *
 * @param {string} value - The time value to be converted to a timestamp.
 * @returns {number|string} - Returns a timestamp (number) if the input is in a valid time format, otherwise returns the input as it is.
 *
 * @example
 * const timestampOrValue = getTimestampFromValue("12:34:56.789");
 * console.log(timestampOrValue); // It will log the timestamp if the format is correct, otherwise, it will log the original value.
 */
const getDateTimeFromTextValue = (value) => {
    if (typeof value !== "string")
        return value;
    const match = value.match(timeHhMmSsMmmRegex);
    if (!match)
        return value;
    const hours = match[1] || match[5] || '00';
    const minutes = match[2] || match[6] || '00';
    const seconds = match[3] || '00';
    const milliseconds = match[4] || '000';
    const timestamp = new Date();
    timestamp.setHours(parseInt(hours));
    timestamp.setMinutes(parseInt(minutes));
    timestamp.setSeconds(parseInt(seconds));
    timestamp.setMilliseconds(parseInt(milliseconds));
    return timestamp.getTime();
};
const timeHhMmSsMmmRegex = /^(?:(?:([01]?\d|2[0-3]):([0-5]?\d):([0-5]?\d)(?:\.(\d{1,3}))?)|(?:([01]?\d|2[0-3]):([0-5]?\d)))$/;
export const formatTimeField = ({ timeFormatter = ETimeFormatter.HOUR_MINUTE_SECONDS, value, timezone, locale: overrideLocale, }) => {
    if (value === "" || value === undefined || value === null)
        return "";
    const locale = getUserLocale(overrideLocale);
    const timeFormatterMethod = timeFormatters[timeFormatter];
    if (!timeFormatterMethod) {
        console.error(`formatTime error ER333: the provided formatTime [${timeFormatter}] for type EValueType.TIME is unknown, a fallback format applied`);
        return `${timeFormatters[ETimeFormatter.HOUR_MINUTE](value, locale, timezone)} (ER333)`;
    }
    return timeFormatterMethod(value, locale, timezone);
};
//#endregion "Format time"
//#region "Format timezone"
export const formatTimezoneField = ({ timezone }) => {
    return timezone
        .replace(/_/g, ' ')
        .replace(/\//g, ', ');
};
//#endregion "Format timezone"
//#region "Format date/time"
export const formatDateTimeField = ({ dateFormatter = EDateFormatter.STANDARD, timeFormatter = ETimeFormatter.HOUR_MINUTE_SECONDS, dateTimeOrder = EDateTimeOrder.DATE_TIME, value, timezone, locale, }) => {
    if (value === "" || value === undefined || value === null)
        return "";
    const dateTimeParts = [
        dateFormatter
            ? formatDateField({
                dateFormatter,
                value,
                timezone,
                locale,
            })
            : "",
        timeFormatter
            ? formatTimeField({
                timeFormatter,
                value,
                timezone,
                locale,
            })
            : "",
    ];
    return dateTimeOrder === EDateTimeOrder.DATE_TIME
        ? dateTimeParts.join(' ')
        : dateTimeParts.reverse().join(' ');
};
//#endregion "Format date/time"
//#region "Format duration"
const durationFormatters = {
    [EDurationFormatter.INDUSTRIAL_MINUTES]: 'INDUSTRIAL_MINUTES',
    [EDurationFormatter.INDUSTRIAL_HOURS]: 'INDUSTRIAL_HOURS',
    [EDurationFormatter.HOURS_MINUTES]: 'HH:mm',
    [EDurationFormatter.HOURS_MINUTES_SECONDS]: 'HH:mm:ss',
    [EDurationFormatter.DAYS_HOURS_MINUTES]: 'D [days], HH [hours], mm [minutes]',
    [EDurationFormatter.MINUTES]: 'mm [minutes]',
    [EDurationFormatter.SECONDS]: 'ss [seconds]',
    [EDurationFormatter.HOURS_ONLY]: 'HH [hours]',
    [EDurationFormatter.DAYS_ONLY]: 'D [days]',
    [EDurationFormatter.FULL_DURATION]: 'D [days], HH:mm:ss',
    [EDurationFormatter.SHORT_DURATION]: 'H[h] m[m]',
    [EDurationFormatter.LONG_DURATION]: 'D [days] HH[h] mm[m] ss[s]',
    [EDurationFormatter.SHORT_HOURS_MINUTES]: 'H[h] mm[m]',
    [EDurationFormatter.SHORT_HOURS_MINUTES_SECONDS]: 'H[h] mm[m] ss[s]',
};
export const formatDurationField = ({ ms, durationFormatter, precision, leadingZeros, trailingZeros, decimalPoint, thousandsSeparator, locale: overrideLocale, }) => {
    const duration = moment.duration(ms);
    const getFallbackTimeFormat = (errorCode) => `${moment.utc(duration.as('milliseconds')).locale(locale)
        .format(durationFormatters[EDurationFormatter.MINUTES])}min (${errorCode})`;
    if (ms === "" || ms === undefined || ms === null)
        return "";
    if (!durationFormatter) {
        console.error('formatDuration error ER365: type EValueType.DURATION requires the `formatDuration` property but was not provided, a fallback format applied');
        return getFallbackTimeFormat('ER365');
    }
    const locale = getUserLocale(overrideLocale);
    if (durationFormatter === EDurationFormatter.INDUSTRIAL_MINUTES) {
        return formatNumberField({
            value: convertDurationInIndustrialMinutes(ms),
            precision,
            leadingZeros,
            trailingZeros,
            decimalPoint,
            thousandsSeparator,
            locale,
        });
    }
    if (durationFormatter === EDurationFormatter.INDUSTRIAL_HOURS) {
        return formatNumberField({
            value: convertDurationInIndustrialHours(ms),
            precision,
            leadingZeros,
            trailingZeros,
            decimalPoint,
            thousandsSeparator,
            locale,
        });
    }
    const formatter = durationFormatters[durationFormatter];
    if (!formatter) {
        console.error(`formatDuration error ER366: the provided formatDuration [${durationFormatter}] for type EValueType.DURATION is unknown, a fallback format applied`);
        return getFallbackTimeFormat('ER366');
    }
    return moment
        .utc(duration.as('milliseconds'))
        .locale(locale)
        .format(formatter);
};
const convertDurationInIndustrialMinutes = (ms) => ms / 60000;
const convertDurationInIndustrialHours = (ms) => ms / 3600000;
//#endregion "Format duration"
//#region "Date utils"
const getMomentTimezone = (value, timezone) => {
    if (!timezone)
        return moment(value);
    if (!momentTimezone.tz.names().includes(timezone)) {
        console.error(`getMomentTimezone error ER368: Timezone [${timezone}] is not supported by momentjs, terminal's default [${getSystemTimezone()}] is applied`);
        moment(value);
    }
    return momentTimezone(value).tz(timezone);
};
const getHour12 = (locale) => [
    "en-US", "en-CA", "en-AU", "en-NZ", "en-PH", "es-MX", "es-CO", "ar-SA", "en-IN", "en-MY", "en-PK", "en-BD", "en-NG", "en-KE", "en-ZA", "en-IE", "en-SG", "en-ZW", "en-GH", "en-JM", "en-TT", "en-BZ", "en-LR", "en-BW", "en-FJ", "en-MT", "en-US", "en-CA", "en-AU", "en-NZ", "en-PH", "es-MX", "es-CO", "ar-SA", "en-IN", "en-MY", "en-PK", "en-BD", "en-NG", "en-KE", "en-ZA", "en-IE", "en-SG", "en-ZW", "en-GH", "en-JM", "en-TT", "en-BZ", "en-LR", "en-BW", "en-FJ", "en-MT", "en-HK", "en-AG", "en-BB", "en-GY", "en-KY", "en-SL", "en-UG", "en-TZ",
]
    .includes(locale);
//#endregion "Date utils"
