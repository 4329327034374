import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "../../../Box";
export const AppLogo = (props) => {
    const { squareLogoImage, landscapeLogoImage, } = props;
    return (_jsxs(Box, { sx: { width: 256 }, children: [_jsx("img", { style: {
                    width: 56,
                    height: 56,
                    margin: "4px",
                }, src: squareLogoImage, alt: "Application logo" }), _jsx("img", { style: {
                    maxWidth: 200,
                    height: 56,
                    margin: "4px",
                }, src: landscapeLogoImage, alt: "Application logo" })] }));
};
