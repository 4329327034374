import {IAppRoute} from "../config/IAppRoute";

export const routeBroadcasterClientConnectionTestPagePaths: IAppRoute = {
  menuId: '',
  title: 'Broadcaster Client ping/pong test page',
  routePath: '/test/broadcaster-client-connection',
  getRoutePath: () => '/test/broadcaster-client-connection',
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => null,
};
