import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IDeviceManagementVirtualTreeProps} from "./DeviceManagementVirtualTree";

const DeviceManagementVirtualTreeLazy = lazy(() => import("./DeviceManagementVirtualTree"));

export const DeviceManagementVirtualTree = connect((props: IDeviceManagementVirtualTreeProps) => (
  <LazyLoadComponent>
    <DeviceManagementVirtualTreeLazy {...props}/>
  </LazyLoadComponent>
));
