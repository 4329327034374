export var EWZBroadcastSelectionMode;
(function (EWZBroadcastSelectionMode) {
    EWZBroadcastSelectionMode["REGION"] = "REGION";
    EWZBroadcastSelectionMode["RADIUS"] = "RADIUS";
})(EWZBroadcastSelectionMode || (EWZBroadcastSelectionMode = {}));
export var EWZShoulder;
(function (EWZShoulder) {
    EWZShoulder["NONE"] = "NONE";
    EWZShoulder["LEFT"] = "LEFT";
    EWZShoulder["RIGHT"] = "RIGHT";
})(EWZShoulder || (EWZShoulder = {}));
export var EWZLane;
(function (EWZLane) {
    EWZLane["NONE"] = "NONE";
    EWZLane["RIGHT"] = "RIGHT";
    EWZLane["LEFT"] = "LEFT";
    EWZLane["CENTER"] = "CENTER";
    EWZLane["ALL"] = "ALL";
})(EWZLane || (EWZLane = {}));
export var EWZSimpleClosureType;
(function (EWZSimpleClosureType) {
    EWZSimpleClosureType["NONE"] = "NONE";
    EWZSimpleClosureType["LANE_CLOSED"] = "LANE_CLOSED";
    EWZSimpleClosureType["SHOULDER_CLOSED"] = "SHOULDER_CLOSED";
    EWZSimpleClosureType["REDUCED_SPEED"] = "REDUCED_SPEED";
    EWZSimpleClosureType["LEFT_TURN"] = "CURVE_SPEED_WARNING_LEFT_TURN";
    EWZSimpleClosureType["RIGHT_TURN"] = "CURVE_SPEED_WARNING_RIGHT_TURN";
    EWZSimpleClosureType["HEIGHT_LIMIT"] = "HEIGHT_LIMIT";
})(EWZSimpleClosureType || (EWZSimpleClosureType = {}));
export var EWZSimpleClosureTypeLane;
(function (EWZSimpleClosureTypeLane) {
    EWZSimpleClosureTypeLane["LEFT_LANE_CLOSED_MERGE_RIGHT"] = "LEFT_LANE_CLOSED_MERGE_RIGHT";
    EWZSimpleClosureTypeLane["RIGHT_LANE_CLOSED_MERGE_LEFT"] = "RIGHT_LANE_CLOSED_MERGE_LEFT";
})(EWZSimpleClosureTypeLane || (EWZSimpleClosureTypeLane = {}));
export var EWZSimpleClosureTypeShoulder;
(function (EWZSimpleClosureTypeShoulder) {
    EWZSimpleClosureTypeShoulder["LEFT_SHOULDER_CLOSED"] = "LEFT_SHOULDER_CLOSED";
    EWZSimpleClosureTypeShoulder["RIGHT_SHOULDER_CLOSED"] = "RIGHT_SHOULDER_CLOSED";
})(EWZSimpleClosureTypeShoulder || (EWZSimpleClosureTypeShoulder = {}));
export var EWZSimpleClosureTypeReducedSpeed;
(function (EWZSimpleClosureTypeReducedSpeed) {
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_15_MPH"] = "REDUCED_SPEED_15_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_20_MPH"] = "REDUCED_SPEED_20_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_25_MPH"] = "REDUCED_SPEED_25_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_30_MPH"] = "REDUCED_SPEED_30_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_35_MPH"] = "REDUCED_SPEED_35_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_40_MPH"] = "REDUCED_SPEED_40_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_45_MPH"] = "REDUCED_SPEED_45_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_50_MPH"] = "REDUCED_SPEED_50_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_55_MPH"] = "REDUCED_SPEED_55_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_60_MPH"] = "REDUCED_SPEED_60_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_65_MPH"] = "REDUCED_SPEED_65_MPH";
    EWZSimpleClosureTypeReducedSpeed["REDUCED_SPEED_70_MPH"] = "REDUCED_SPEED_70_MPH";
})(EWZSimpleClosureTypeReducedSpeed || (EWZSimpleClosureTypeReducedSpeed = {}));
export var EWZSimpleClosureTypeLeftTurn;
(function (EWZSimpleClosureTypeLeftTurn) {
    EWZSimpleClosureTypeLeftTurn["LEFT_TURN_SPEED_15_MPH"] = "LEFT_TURN_SPEED_15_MPH";
    EWZSimpleClosureTypeLeftTurn["LEFT_TURN_SPEED_20_MPH"] = "LEFT_TURN_SPEED_20_MPH";
    EWZSimpleClosureTypeLeftTurn["LEFT_TURN_SPEED_25_MPH"] = "LEFT_TURN_SPEED_25_MPH";
    EWZSimpleClosureTypeLeftTurn["LEFT_TURN_SPEED_30_MPH"] = "LEFT_TURN_SPEED_30_MPH";
    EWZSimpleClosureTypeLeftTurn["LEFT_TURN_SPEED_35_MPH"] = "LEFT_TURN_SPEED_35_MPH";
    EWZSimpleClosureTypeLeftTurn["LEFT_TURN_SPEED_40_MPH"] = "LEFT_TURN_SPEED_40_MPH";
    EWZSimpleClosureTypeLeftTurn["LEFT_TURN_SPEED_45_MPH"] = "LEFT_TURN_SPEED_45_MPH";
    EWZSimpleClosureTypeLeftTurn["LEFT_TURN_SPEED_50_MPH"] = "LEFT_TURN_SPEED_50_MPH";
    EWZSimpleClosureTypeLeftTurn["LEFT_TURN_SPEED_55_MPH"] = "LEFT_TURN_SPEED_55_MPH";
})(EWZSimpleClosureTypeLeftTurn || (EWZSimpleClosureTypeLeftTurn = {}));
export var EWZSimpleClosureTypeRightTurn;
(function (EWZSimpleClosureTypeRightTurn) {
    EWZSimpleClosureTypeRightTurn["RIGHT_TURN_SPEED_15_MPH"] = "RIGHT_TURN_SPEED_15_MPH";
    EWZSimpleClosureTypeRightTurn["RIGHT_TURN_SPEED_20_MPH"] = "RIGHT_TURN_SPEED_20_MPH";
    EWZSimpleClosureTypeRightTurn["RIGHT_TURN_SPEED_25_MPH"] = "RIGHT_TURN_SPEED_25_MPH";
    EWZSimpleClosureTypeRightTurn["RIGHT_TURN_SPEED_30_MPH"] = "RIGHT_TURN_SPEED_30_MPH";
    EWZSimpleClosureTypeRightTurn["RIGHT_TURN_SPEED_35_MPH"] = "RIGHT_TURN_SPEED_35_MPH";
    EWZSimpleClosureTypeRightTurn["RIGHT_TURN_SPEED_40_MPH"] = "RIGHT_TURN_SPEED_40_MPH";
    EWZSimpleClosureTypeRightTurn["RIGHT_TURN_SPEED_45_MPH"] = "RIGHT_TURN_SPEED_45_MPH";
    EWZSimpleClosureTypeRightTurn["RIGHT_TURN_SPEED_50_MPH"] = "RIGHT_TURN_SPEED_50_MPH";
    EWZSimpleClosureTypeRightTurn["RIGHT_TURN_SPEED_55_MPH"] = "RIGHT_TURN_SPEED_55_MPH";
})(EWZSimpleClosureTypeRightTurn || (EWZSimpleClosureTypeRightTurn = {}));
export var EWZSimpleClosureTypeHeightLimit;
(function (EWZSimpleClosureTypeHeightLimit) {
    EWZSimpleClosureTypeHeightLimit["HEIGHT_LIMIT_14_FT_0_IN"] = "HEIGHT_LIMIT_14_FT_0_IN";
})(EWZSimpleClosureTypeHeightLimit || (EWZSimpleClosureTypeHeightLimit = {}));
// Using as composite enum for all closure types
export var EWZClosureType;
(function (EWZClosureType) {
    EWZClosureType["NONE"] = "NONE";
    EWZClosureType["LEFT_LANE_CLOSED_MERGE_RIGHT"] = "LEFT_LANE_CLOSED_MERGE_RIGHT";
    EWZClosureType["RIGHT_LANE_CLOSED_MERGE_LEFT"] = "RIGHT_LANE_CLOSED_MERGE_LEFT";
    EWZClosureType["LEFT_SHOULDER_CLOSED"] = "LEFT_SHOULDER_CLOSED";
    EWZClosureType["RIGHT_SHOULDER_CLOSED"] = "RIGHT_SHOULDER_CLOSED";
    EWZClosureType["REDUCED_SPEED_15_MPH"] = "REDUCED_SPEED_15_MPH";
    EWZClosureType["REDUCED_SPEED_20_MPH"] = "REDUCED_SPEED_20_MPH";
    EWZClosureType["REDUCED_SPEED_25_MPH"] = "REDUCED_SPEED_25_MPH";
    EWZClosureType["REDUCED_SPEED_30_MPH"] = "REDUCED_SPEED_30_MPH";
    EWZClosureType["REDUCED_SPEED_35_MPH"] = "REDUCED_SPEED_35_MPH";
    EWZClosureType["REDUCED_SPEED_40_MPH"] = "REDUCED_SPEED_40_MPH";
    EWZClosureType["REDUCED_SPEED_45_MPH"] = "REDUCED_SPEED_45_MPH";
    EWZClosureType["REDUCED_SPEED_50_MPH"] = "REDUCED_SPEED_50_MPH";
    EWZClosureType["REDUCED_SPEED_55_MPH"] = "REDUCED_SPEED_55_MPH";
    EWZClosureType["REDUCED_SPEED_60_MPH"] = "REDUCED_SPEED_60_MPH";
    EWZClosureType["REDUCED_SPEED_65_MPH"] = "REDUCED_SPEED_65_MPH";
    EWZClosureType["REDUCED_SPEED_70_MPH"] = "REDUCED_SPEED_70_MPH";
    EWZClosureType["LEFT_TURN_SPEED_15_MPH"] = "LEFT_TURN_SPEED_15_MPH";
    EWZClosureType["LEFT_TURN_SPEED_20_MPH"] = "LEFT_TURN_SPEED_20_MPH";
    EWZClosureType["LEFT_TURN_SPEED_25_MPH"] = "LEFT_TURN_SPEED_25_MPH";
    EWZClosureType["LEFT_TURN_SPEED_30_MPH"] = "LEFT_TURN_SPEED_30_MPH";
    EWZClosureType["LEFT_TURN_SPEED_35_MPH"] = "LEFT_TURN_SPEED_35_MPH";
    EWZClosureType["LEFT_TURN_SPEED_40_MPH"] = "LEFT_TURN_SPEED_40_MPH";
    EWZClosureType["LEFT_TURN_SPEED_45_MPH"] = "LEFT_TURN_SPEED_45_MPH";
    EWZClosureType["LEFT_TURN_SPEED_50_MPH"] = "LEFT_TURN_SPEED_50_MPH";
    EWZClosureType["LEFT_TURN_SPEED_55_MPH"] = "LEFT_TURN_SPEED_55_MPH";
    EWZClosureType["RIGHT_TURN_SPEED_15_MPH"] = "RIGHT_TURN_SPEED_15_MPH";
    EWZClosureType["RIGHT_TURN_SPEED_20_MPH"] = "RIGHT_TURN_SPEED_20_MPH";
    EWZClosureType["RIGHT_TURN_SPEED_25_MPH"] = "RIGHT_TURN_SPEED_25_MPH";
    EWZClosureType["RIGHT_TURN_SPEED_30_MPH"] = "RIGHT_TURN_SPEED_30_MPH";
    EWZClosureType["RIGHT_TURN_SPEED_35_MPH"] = "RIGHT_TURN_SPEED_35_MPH";
    EWZClosureType["RIGHT_TURN_SPEED_40_MPH"] = "RIGHT_TURN_SPEED_40_MPH";
    EWZClosureType["RIGHT_TURN_SPEED_45_MPH"] = "RIGHT_TURN_SPEED_45_MPH";
    EWZClosureType["RIGHT_TURN_SPEED_50_MPH"] = "RIGHT_TURN_SPEED_50_MPH";
    EWZClosureType["RIGHT_TURN_SPEED_55_MPH"] = "RIGHT_TURN_SPEED_55_MPH";
    EWZClosureType["HEIGHT_LIMIT_14_FT_0_IN"] = "HEIGHT_LIMIT_14_FT_0_IN";
})(EWZClosureType || (EWZClosureType = {}));
export var EWZClosureTypeDeprecated;
(function (EWZClosureTypeDeprecated) {
    EWZClosureTypeDeprecated["RIGHT_2_LANES_CLOSED_AHEAD"] = "RIGHT_2_LANES_CLOSED_AHEAD";
    EWZClosureTypeDeprecated["LEFT_2_LANES_CLOSED_AHEAD"] = "LEFT_2_LANES_CLOSED_AHEAD";
    EWZClosureTypeDeprecated["LEFT_LANE_CLOSED_AHEAD_MERGE_RIGHT"] = "LEFT_LANE_CLOSED_AHEAD_MERGE_RIGHT";
    EWZClosureTypeDeprecated["RIGHT_LANE_CLOSED_AHEAD_MERGE_LEFT"] = "RIGHT_LANE_CLOSED_AHEAD_MERGE_LEFT";
    EWZClosureTypeDeprecated["LEFT_SHOULDER_CLOSED_AHEAD"] = "LEFT_SHOULDER_CLOSED_AHEAD";
    EWZClosureTypeDeprecated["RIGHT_SHOULDER_CLOSED_AHEAD"] = "RIGHT_SHOULDER_CLOSED_AHEAD";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_15_MPH"] = "REDUCED_SPEED_AHEAD_15_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_20_MPH"] = "REDUCED_SPEED_AHEAD_20_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_25_MPH"] = "REDUCED_SPEED_AHEAD_25_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_30_MPH"] = "REDUCED_SPEED_AHEAD_30_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_35_MPH"] = "REDUCED_SPEED_AHEAD_35_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_40_MPH"] = "REDUCED_SPEED_AHEAD_40_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_45_MPH"] = "REDUCED_SPEED_AHEAD_45_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_50_MPH"] = "REDUCED_SPEED_AHEAD_50_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_55_MPH"] = "REDUCED_SPEED_AHEAD_55_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_60_MPH"] = "REDUCED_SPEED_AHEAD_60_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_65_MPH"] = "REDUCED_SPEED_AHEAD_65_MPH";
    EWZClosureTypeDeprecated["REDUCED_SPEED_AHEAD_70_MPH"] = "REDUCED_SPEED_AHEAD_70_MPH";
})(EWZClosureTypeDeprecated || (EWZClosureTypeDeprecated = {}));
export var EWZHeading;
(function (EWZHeading) {
    EWZHeading["NORTH"] = "from000-0to022-5degrees";
    EWZHeading["NORTH_NORTH_EAST"] = "from022-5to045-0degrees";
    EWZHeading["NORTH_EAST"] = "from045-0to067-5degrees";
    EWZHeading["EAST_NORTH_EAST"] = "from067-5to090-0degrees";
    EWZHeading["EAST"] = "from090-0to112-5degrees";
    EWZHeading["EAST_SOUTH_EAST"] = "from112-5to135-0degrees";
    EWZHeading["SOUTH_EAST"] = "from135-0to157-5degrees";
    EWZHeading["SOUTH_SOUTH_EAST"] = "from157-5to180-0degrees";
    EWZHeading["SOUTH"] = "from180-0to202-5degrees";
    EWZHeading["SOUTH_SOUTH_WEST"] = "from202-5to225-0degrees";
    EWZHeading["SOUTH_WEST"] = "from225-0to247-5degrees";
    EWZHeading["WEST_SOUTH_WEST"] = "from247-5to270-0degrees";
    EWZHeading["WEST"] = "from270-0to292-5degrees";
    EWZHeading["WEST_NORTH_WEST"] = "from292-5to315-0degrees";
    EWZHeading["NORTH_WEST"] = "from315-0to337-5degrees";
    EWZHeading["NORTH_NORTH_WEST"] = "from337-5to360-0degrees";
})(EWZHeading || (EWZHeading = {}));
