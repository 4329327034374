import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { keyframes } from "@emotion/react";
import { Box } from "../../Box";
export const DoublePulseProgressIcon = ({ sizePx, duration = 1000, }) => {
    const ballPulseDoubleAnimation = keyframes `
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.0);
    }
  `;
    const sxBall = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme => theme.palette.grayShades.gray7,
        borderRadius: '50%',
        animation: `${ballPulseDoubleAnimation} ${duration}ms ease-in-out infinite`,
        opacity: 0.5,
    };
    return (_jsxs(Box, { sx: {
            width: sizePx,
            height: sizePx,
            position: 'relative',
        }, children: [_jsx(Box, { sx: sxBall }), _jsx(Box, { sx: Object.assign(Object.assign({}, sxBall), { animationDelay: `-${duration / 2}ms` }) })] }));
};
