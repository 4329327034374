import {IAppRoute} from "../../application/config/IAppRoute";

import {routeDeviceMgtmSettings} from "./routeDeviceMgtmSettings";
import {routeDeviceManagementMap} from "./routeDeviceManagementMap";
import {routeDeviceManagementTree} from "./routeDeviceManagementTree";
import {routeDeviceManagerPingPongTestPage} from "./routeDeviceManagerPingPongTestPage";
import {routeDeviceManagementVirtualTree} from "./routeDeviceManagementVirtualTree";

export const routesDeviceManagement: IAppRoute<any>[] = [
  routeDeviceMgtmSettings,
  routeDeviceManagementMap,
  routeDeviceManagementTree,  // Old tree
  routeDeviceManagementVirtualTree,
  routeDeviceManagerPingPongTestPage,
];
