import {IAppRoute} from "../../application/config/IAppRoute";

export const routeDeviceManagerPingPongTestPagePaths: IAppRoute = {
  menuId: '',
  title: 'Device Manager ping/pong test page',
  routePath: '/test/device-manager-ping-pong',
  getRoutePath: () => '/test/device-manager-ping-pong',
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => null,
};
