import { dynaError } from "dyna-error";
export const getImageDimensionByFile = (file) => {
    if (!file.type.startsWith('image/')) {
        return Promise.reject(dynaError({
            code: 20231105102351,
            message: "File is not an image",
            userMessage: "File is not an image",
        }));
    }
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            const width = image.width;
            const height = image.height;
            resolve({
                width,
                height,
            });
        };
        image.onerror = event => {
            reject(dynaError({
                code: 20231105102353,
                message: 'Error loading the image',
                userMessage: `Error loading the image ${file.name}`,
                parentError: { event },
                data: {
                    file,
                    url: image.src,
                },
            }));
        };
        image.src = URL.createObjectURL(file);
    });
};
