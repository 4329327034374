/**
 * Checks email for validity.
 * @param email
 * @returns {boolean}
 */
// eslint-disable-next-line no-useless-escape
const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const alphanumericStartEndRegExp = /^[a-zA-Z0-9_].*[a-zA-Z0-9_]+$/;
const singleDotRegExp = /\./;
const doubleDotRegExp = /\.\./;
const likeIp4AddressRegExp = /[0-9]+\.[0-9]+\.[0-9]+\.[0-9]+/;
const ip4AddressRegExp = /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/;
const endWithWebRegExp = /\.web$/;
const extractEmailSpecs = (email) => {
    const split = email.split('@');
    const user = split[0];
    const domain = split.length > 1 ? split[1] : '';
    const extension = domain ? domain.split('.')[1] : '';
    return {
        user,
        domain,
        extension,
    };
};
export const validateEmailLengthMessage = (email) => {
    const { user, domain, extension, } = extractEmailSpecs(email);
    const userLengthIsNotValid = user.length > 64;
    const domainExtensionLengthIsNotValid = domain.concat(extension).length > 255;
    const fullEmailLengthIsNotValid = user.concat(domain, extension).length > 320;
    return !userLengthIsNotValid && !domainExtensionLengthIsNotValid && !fullEmailLengthIsNotValid;
};
export const validateIsEmail = (email) => {
    const successRegExp = emailRegExp.test(email);
    if (!successRegExp)
        return false;
    const { user, domain, extension, } = extractEmailSpecs(email);
    if (!alphanumericStartEndRegExp.test(user))
        return false;
    if (!alphanumericStartEndRegExp.test(domain))
        return false;
    if (doubleDotRegExp.test(user))
        return false;
    if (doubleDotRegExp.test(domain))
        return false;
    if (!singleDotRegExp.test(domain))
        return false;
    if (endWithWebRegExp.test(domain))
        return false;
    if (likeIp4AddressRegExp.test(domain) && !ip4AddressRegExp.test(domain))
        return false;
    if (!extension)
        return false;
    if (extension.length === 0)
        return false;
    if (extension.length === 1 && !(typeof extension === "string" && !isNaN(Number(extension))))
        return false;
    if (extension.length > 64)
        return false;
    return true;
};
