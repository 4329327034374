import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import MuiTooltip from "@mui/material/Tooltip";
import Fade from '@mui/material/Fade';
import Zoom from '@mui/material/Zoom';
export var ETooltipPosition;
(function (ETooltipPosition) {
    ETooltipPosition["BOTTOM_END"] = "bottom-end";
    ETooltipPosition["BOTTOM_START"] = "bottom-start";
    ETooltipPosition["BOTTOM"] = "bottom";
    ETooltipPosition["LEFT_END"] = "left-end";
    ETooltipPosition["LEFT_START"] = "left-start";
    ETooltipPosition["LEFT"] = "left";
    ETooltipPosition["RIGHT_END"] = "right-end";
    ETooltipPosition["RIGHT_START"] = "right-start";
    ETooltipPosition["RIGHT"] = "right";
    ETooltipPosition["TOP_END"] = "top-end";
    ETooltipPosition["TOP_START"] = "top-start";
    ETooltipPosition["TOP"] = "top";
})(ETooltipPosition || (ETooltipPosition = {}));
export var ETooltipTransition;
(function (ETooltipTransition) {
    ETooltipTransition["NONE"] = "NONE";
    ETooltipTransition["FADE"] = "FADE";
    ETooltipTransition["ZOOM"] = "ZOOM";
})(ETooltipTransition || (ETooltipTransition = {}));
const transitionComponents = {
    NONE: undefined,
    FADE: Fade,
    ZOOM: Zoom,
};
export const Tooltip = ({ title, showDelay = 1000, showNextDelay = 500, position, arrow = true, transitionType = ETooltipTransition.NONE, transitionDuration = 150, children, }) => {
    if (!title)
        return children;
    return (_jsx(MuiTooltip, { title: title, enterDelay: showDelay, enterNextDelay: showNextDelay, placement: position, arrow: arrow, TransitionComponent: transitionComponents[transitionType], TransitionProps: { timeout: transitionDuration }, PopperProps: { style: { zIndex: 100000 } }, children: _jsxs("span", { children: [" ", children] }) }));
};
