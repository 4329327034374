import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../config/IAppRoute";
import {routeBroadcasterClientConnectionTestPagePaths} from "./routeBroadcasterClientConnectionTestPage.paths";

const BroadcasterConnectionTestPage = lazy(() => import('../pages/BroadcasterConnectionTestPage'));

export const routeBroadcasterClientConnectionTestPage: IAppRoute = {
  ...routeBroadcasterClientConnectionTestPagePaths,
  render: () => (
    <LazyLoadComponent>
      <BroadcasterConnectionTestPage/>
    </LazyLoadComponent>
  ),
};
