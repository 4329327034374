import { validateDataMethods } from "utils-library/dist/validation-engine";
import { validationEngine } from "utils-library/dist/validation-engine";
export const getDefaultTrafficVehiclesCountConfig = () => ({
    speedBinsInMph: [],
    lengthBinsInFeet: [],
});
const validateDataBinTypes = (dataBins) => {
    if (!Array.isArray(dataBins))
        return "Should be array";
    const validationMessages = dataBins
        .map(dataBin => validationEngine({
        data: dataBin,
        validationRules: dataBinValidationRules,
    }))
        .reduce((acc, validation) => acc.concat(validation.messages), []);
    return validationMessages.join(' ');
};
const dataBinValidationRules = {
    label: value => validateDataMethods.isValidText(value, 0, 30),
    startingFrom: value => {
        if (value === "*")
            return "";
        return validateDataMethods.isNumberInRange(value, 0, 99999);
    },
};
const validateDataBinValues = (dataBins) => {
    if (!dataBins.length)
        return "At least one bin is required";
    if (dataBins.length > 16)
        return "Bins would be up to 16";
    const validationMessages = [];
    dataBins.forEach((dataBin, index, array) => {
        if (index === 0)
            return;
        const prevDataBin = array[index - 1];
        if (!prevDataBin)
            return; // 4TS
        if (prevDataBin.startingFrom > dataBin.startingFrom) {
            validationMessages.push(`Value ${dataBin.startingFrom} at position ${index + 1} should be bigger than the prev ${prevDataBin.startingFrom}`);
        }
    });
    return validationMessages.join(', ');
};
export const trafficVehiclesCountConfigTypesValidationRules = {
    speedBinsInMph: validateDataBinTypes,
    lengthBinsInFeet: validateDataBinTypes,
};
export const trafficVehiclesCountConfigValuesValidationRules = {
    speedBinsInMph: validateDataBinValues,
    lengthBinsInFeet: validateDataBinValues,
};
