import {IAppMenu} from "./IAppMenu";

import {convertRouteToAppMenu} from "./IAppMenu";

// Mercury
import {routeDeviceManagementMapPaths} from "../../device-management/routes/routeDeviceManagementMap.paths";
import {routeDeviceManagementVirtualTreePaths} from "../../device-management/routes/routeDeviceManagementVirtualTree.paths";
import {routeDeviceMgtmSettingsPaths} from "../../device-management/routes/routeDeviceMgtmSettings.paths";

// CIMs
import {routeWorkZonesManagementPaths} from "../../workzones-management/routes/routeWorkZonesManagement.paths";
import {routeWorkZonesList} from "../../workzones-management/routes/routeWorkZonesList";
import {routeSaveOfflineMaps} from "../../workzones-management/routes/routeSaveOfflineMaps";
import {routeWorkzonesAppSettings} from "../../workzones-management/routes/routeWorkzonesAppSettings";
import {routeWorkzoneOfflinePrepare} from "../../workzones-management/routes/routeWorkzoneOfflinePrepare";
import {routeWorkzoneOfflinePaths} from "../../workzones-management/routes/routeWorkzoneOffline.paths";

// Alerts management
import {routeAlertMgtmListPage} from "../../alerts-management/routes/routeAlertMgtmListPage";
import {routeAlertMgtmSettingsPage} from "../../alerts-management/routes/routeAlertMgtmSettingsPage";

// Lane planning
import {routeLanePlanningPagePaths} from "../../lane-planning/routes/routeLanePlanningPage.paths";

// Traffic studies
import {routeTrafficStudyList} from "../../traffic-studies/routes/routeTrafficStudyList";

// Administrator
import {routeAdministratorPagePaths} from "../routes/routeAdministratorPage.paths";
import {routeListUsers} from "../../user-management/routes/routeListUsers";
import {routeUsersInvitationsList} from "../../users-invitations/routes/routeUsersInvitationsList";
import {routeCreateCompanyPage} from "../../user-authnentication/routes/routeCreateCompanyPage";

// App options
import {routeOptionsPage} from "../routes/routeOptionsPage";
import AppSettingsIcon from "@mui/icons-material/Settings";


export const appMenusTop: (IAppMenu | "DIVIDER")[] = [
  convertRouteToAppMenu(
    routeDeviceManagementMapPaths,
    {
      children: [
        convertRouteToAppMenu(routeDeviceManagementVirtualTreePaths),
        convertRouteToAppMenu(routeDeviceMgtmSettingsPaths),
      ],
    },
  ),
  convertRouteToAppMenu(
    routeAlertMgtmListPage,
    {children: [convertRouteToAppMenu(routeAlertMgtmSettingsPage)]},
  ),
  convertRouteToAppMenu(
    routeWorkZonesManagementPaths,
    {
      title: 'CIMs',
      children: [
        convertRouteToAppMenu(routeWorkZonesList),
        convertRouteToAppMenu(routeWorkzonesAppSettings),
      ],
    },
  ),
  convertRouteToAppMenu(
    routeLanePlanningPagePaths,
  ),
  convertRouteToAppMenu(routeTrafficStudyList),
  convertRouteToAppMenu(
    routeWorkzoneOfflinePaths,
    {
      children: [
        convertRouteToAppMenu(routeWorkzoneOfflinePrepare),
        convertRouteToAppMenu(routeSaveOfflineMaps),
      ],
    },
  ),
  convertRouteToAppMenu(
    routeAdministratorPagePaths,
    {
      children: [
        convertRouteToAppMenu(routeListUsers),
        convertRouteToAppMenu(routeUsersInvitationsList),
        convertRouteToAppMenu(routeCreateCompanyPage),
      ],
    },
  ),
];

export const appMenusBottom: IAppMenu[] = [
  {
    menuId: routeOptionsPage.menuId,
    navigateTo: routeOptionsPage.routePath,
    userHasAllRights: routeOptionsPage.userHasAllRights,
    userHasAnyOfRights: routeOptionsPage.userHasAnyOfRights,
    title: 'Options',
    icon: <AppSettingsIcon/>,
  },
];
