var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useRef, } from "react";
import { Confirm } from "../Confirm";
/**
 * The `useConfirm` hook returns an object that contains the `confirm` function, which returns a `Promise<boolean>`.
 * Additionally, it returns the `confirmViewer` JSX.Element, which must be rendered **always**!
 * Internally, this hook manages the actual visibility of the confirmation dialog.
 */
export const useConfirm = () => {
    const [show, setShow] = useState(false);
    const [args, setArgs] = useState({ title: '' });
    const refPromise = useRef(null);
    const handleOk = () => {
        var _a;
        setShow(false);
        (_a = refPromise.current) === null || _a === void 0 ? void 0 : _a.resolve(true);
        refPromise.current = null;
    };
    const handleCancel = () => {
        var _a;
        setShow(false);
        (_a = refPromise.current) === null || _a === void 0 ? void 0 : _a.resolve(false);
        refPromise.current = null;
    };
    const handleClose = (confirm) => {
        var _a;
        setShow(false);
        (_a = refPromise.current) === null || _a === void 0 ? void 0 : _a.resolve(confirm);
        refPromise.current = null;
    };
    return {
        confirm: (confirmArgs) => __awaiter(void 0, void 0, void 0, function* () {
            setArgs(confirmArgs);
            setShow(true);
            return new Promise(resolve => {
                refPromise.current = { resolve };
            });
        }),
        confirmViewer: (_jsx(Confirm, Object.assign({}, args, { show: show, onOk: handleOk, onCancel: handleCancel, onClose: handleClose }))),
    };
};
