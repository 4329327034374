import moment from "moment";
import { getDurationString, getRuntimeStack, } from "../utils";
import { isLocalhost } from "./isLocalhost";
export var ESeverity;
(function (ESeverity) {
    ESeverity["LOG"] = "log";
    ESeverity["INFO"] = "info";
    ESeverity["DEBUG"] = "debug";
    ESeverity["ERROR"] = "error";
    ESeverity["CAUGHT_ERROR"] = "caught-error";
    ESeverity["WARN"] = "warn";
})(ESeverity || (ESeverity = {}));
/**
 * ConsoleLogger stores all the console logs in memory for later processing and easier access to the logged error objects or data.
 *
 * You can access the logs from the `consoleLogger.logs` array, which also includes other information like timestamps, elapsed time from the previous log, stack trace, etc.
 *
 * Tip: You can add `consoleLogger.logs` to your debugger's watcher for easy access.
 */
class ConsoleLogger {
    constructor() {
        this.logs = [];
        this._isEnabled = localStorage.getItem('ConsoleLogger-Enabled') === "true";
        this._lastConsole = Date.now();
        this._originalConsole = {
            log: window.console.log,
            info: window.console.info,
            debug: window.console.debug,
            error: window.console.error,
            warn: window.console.warn,
            [ESeverity.CAUGHT_ERROR]: () => undefined, // It is not used anyway
        };
        this._perform = (severity, args) => {
            const date = new Date();
            if (this.enabled) {
                this.logs.push({
                    time: moment(date).format('HH:mm:ss.SSS'),
                    elapsed: getDurationString(this._lastConsole, date),
                    severity,
                    args,
                    message: typeof args[0] === "string"
                        ? args[0]
                        : "---no text console---",
                    date,
                    elapsedMs: date.valueOf() - this._lastConsole,
                    stack: getRuntimeStack(),
                });
            }
            this._lastConsole = date.valueOf();
            this._originalConsole[severity](...args);
        };
        window.console.log = (...args) => this._perform(ESeverity.LOG, args);
        window.console.info = (...args) => this._perform(ESeverity.INFO, args);
        window.console.debug = (...args) => this._perform(ESeverity.DEBUG, args);
        window.console.error = (...args) => this._perform(ESeverity.ERROR, args);
        window.console.warn = (...args) => this._perform(ESeverity.WARN, args);
        window.consoleLogger = this;
        window.addEventListener('error', (error) => {
            this._perform(ESeverity.CAUGHT_ERROR, ["consoleLogger: Uncaught error", error.message, error]);
        });
        window.addEventListener('unhandledrejection', (event) => {
            this._perform(ESeverity.CAUGHT_ERROR, ["consoleLogger: Uncaught promise rejection", event.reason, event]);
        });
        if (this.enabled) {
            console.warn([
                "consoleLogger: Is enabled and logging for this terminal.",
                "Run `consoleLogger.enabled=false` to turn it off.",
            ].join('\n'));
        }
        else {
            if (isLocalhost) {
                console.info([
                    "consoleLogger: Started but it is not enabled for this terminal.",
                    "Run `consoleLogger.enabled=true` to start logging the consoles.",
                    "This message is shown only in dev environment.",
                ].join('\n'));
            }
        }
    }
    get enabled() {
        return this._isEnabled;
    }
    set enabled(enabled) {
        this._isEnabled = enabled;
        localStorage.setItem('ConsoleLogger-Enabled', enabled.toString());
    }
    clear() {
        this.logs.length = 0;
        console.info('ConsoleLogger: clear() called');
    }
}
export const startConsoleLogger = () => {
    new ConsoleLogger();
};
