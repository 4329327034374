import { jsx as _jsx } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import { pickValidValue } from "utils-library/dist/utils";
import { Box } from "../Box";
import { getDataComponentName, } from "../ui-interfaces";
export const GridContainer = (props) => {
    const { show = true, fullHeight = false, dataComponentName, spacing = 0, spacingHorizontal = spacing, spacingVertical = spacing, leftRightSpace = true, topBottomSpace = true, verticalAlign = "top", children, } = props;
    const horizontalSpace = pickValidValue(spacingHorizontal, spacing, 0);
    const verticalSpace = pickValidValue(spacingVertical, spacing, 0);
    if (!show)
        return null;
    return (_jsx(Box, { dataComponentName: getDataComponentName(dataComponentName, "GridContainer"), sx: {
            overflow: (spacingHorizontal || spacingVertical)
                && (!leftRightSpace || !topBottomSpace)
                ? 'hidden'
                : undefined,
        }, fullHeight: fullHeight, children: _jsx(Grid, { container: true, sx: {
                position: 'relative',
                height: fullHeight ? '100%' : undefined,
                left: theme => leftRightSpace
                    ? undefined
                    : theme.spacing(horizontalSpace * -1),
                width: theme => leftRightSpace
                    ? 'initial' // Fix scrollbars caused by the spacing
                    : `calc(100% + ${theme.spacing(horizontalSpace * 2)})`,
                my: theme => topBottomSpace
                    ? undefined
                    : theme.spacing(verticalSpace * -1),
                paddingLeft: horizontalSpace,
                paddingTop: verticalSpace,
                '& > div': {
                    paddingRight: horizontalSpace,
                    paddingBottom: verticalSpace,
                },
            }, alignItems: (() => {
                if (verticalAlign === "start")
                    return "flex-start";
                if (verticalAlign === "end")
                    return "flex-end";
                return verticalAlign;
            })(), children: children }) }));
};
