import {
  API_PATH_ApiCreateCompanyDelete,
  IApiCreateCompanyDeleteBodyRequest,
  IApiCreateCompanyDeleteResponse,
} from "mhc-server/dist/interfaces";

import {apiFetch} from "../../../api/apiFetch";

export const apiCreateCompanyDelete = async (args: IApiCreateCompanyDeleteBodyRequest): Promise<IApiCreateCompanyDeleteResponse> => {
  return apiFetch.request<null, IApiCreateCompanyDeleteBodyRequest, IApiCreateCompanyDeleteResponse>({
    method: 'DELETE',
    path: API_PATH_ApiCreateCompanyDelete,
    body: args,
  });
};
