import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ETableSize, } from "./interfaces";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getDeepValue } from "utils-library/dist/utils";
import { Box } from "../Box";
import { SortButton } from "./components/SortButton";
import { BreakpointDeviceRender } from "../BreakpointDeviceRender";
import { useTheme, } from "../ThemeProvider";
import { sxHover } from "../sxHover";
export const Table = (props) => {
    const { sx, show = true, ariaLabel, columns, sort = {
        fieldName: undefined,
        direction: 0,
    }, rowHover = true, size = ETableSize.LARGE, rows, onRowClick, onChangeSort, } = props;
    const theme = useTheme();
    const handleChangeSort = (fieldName, direction) => {
        if (!onChangeSort)
            return;
        onChangeSort({
            fieldName,
            direction,
        });
    };
    const handleRowClick = (event, rowData) => {
        if (['BUTTON', 'A'].includes(event.target.nodeName))
            return;
        onRowClick && onRowClick(rowData);
    };
    const renderValue = (column, row) => {
        const value = getDeepValue(row, column.fieldName);
        if (column.cellRender)
            return column.cellRender(value, row);
        return value;
    };
    const cellPadding = (() => {
        switch (size) {
            case ETableSize.SMALL:
                return theme.spacing(0.5);
            case ETableSize.MEDIUM:
                return theme.spacing(1);
            case ETableSize.LARGE:
                return theme.spacing(2);
        }
    })();
    const mapColumnHeader = (column, index) => (_jsxs(TableCell, { style: {
            whiteSpace: "nowrap",
            padding: cellPadding,
        }, align: column.align, children: [_jsx(Box, { sx: column.headerSx, inline: true, title: column.headerTooltip, children: column.headerLabel }), !!column.sortable && (_jsx(SortButton, { fieldName: column.fieldName, sort: sort.fieldName === column.fieldName ? sort.direction : 0, onChange: handleChangeSort }))] }, index));
    const mapColumnBody = (row, column, index) => (_jsx(TableCell, { sx: column.cellSx, align: column.align, style: { padding: cellPadding }, component: "th", scope: "row", children: renderValue(column, row) }, index));
    const showColumnByBreakpoint = (column, breakpoint) => {
        if (!column.breakpoints)
            return true; // 4TS
        return column.breakpoints.includes("allExclude")
            ? !column.breakpoints.includes(breakpoint)
            : column.breakpoints.includes(breakpoint);
    };
    if (!show)
        return null;
    return (_jsx(TableContainer, { sx: sx, component: Paper, children: _jsxs(MuiTable, { "aria-label": ariaLabel, children: [_jsx(TableHead, { children: _jsx(TableRow, { children: _jsx(BreakpointDeviceRender, { render: breakpoint => (columns
                                .filter(column => !column.hidden)
                                .filter(column => showColumnByBreakpoint(column, breakpoint))
                                .map(mapColumnHeader)) }) }) }), _jsx(TableBody, { children: rows.map((row, index) => (_jsx(TableRow, { sx: sxHover({
                            hover: rowHover,
                            pointer: !!onRowClick,
                        }), onClick: (e) => handleRowClick(e, row), children: _jsx(BreakpointDeviceRender, { render: breakpoint => (columns
                                .filter(column => !column.hidden)
                                .filter(column => showColumnByBreakpoint(column, breakpoint))
                                .map((column, index) => mapColumnBody(row, column, index))) }) }, index))) })] }) }));
};
