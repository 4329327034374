import { dynaObjectScan } from "dyna-object-scan";
/**
 * Cleans up IDBEntityBase properties that have GUIDs and timestamps, replacing them with string placeholders.
 * This makes the snapshots easier to compare.
 */
export const clearDynamicValuesForSnapshotsAdvanced = (
/**
 * Object to clean up
 */
obj, { replaceExtraProperties = [], excludeDBEntityBaseProperties = [], replaceValue, } = {}) => {
    if (obj === null)
        return null;
    const obj_ = JSON.parse(JSON.stringify(obj));
    const replaceProperties = [
        'id',
        'docId',
        'date',
        'createdAt',
        'createdBy',
        'updatedAt',
        'updatedBy',
        'archivedAt',
        'archivedBy',
        'deletedAt',
        'deletedBy',
        ...replaceExtraProperties,
    ]
        .filter(propertyName => !excludeDBEntityBaseProperties.includes(propertyName));
    dynaObjectScan(obj_, ({ propertyName, parent, }) => {
        if (!propertyName)
            return;
        if (replaceValue) {
            const oldValue = parent[propertyName];
            parent[propertyName] = replaceValue(propertyName, parent[propertyName]);
            if (oldValue !== parent[propertyName])
                return; // Exit, it is already changed
        }
        if (!replaceProperties.includes(propertyName))
            return;
        if (parent[propertyName] instanceof Date) {
            parent[propertyName] = getNumberEqualization('DATE', parent[propertyName].valueOf());
        }
        else if (parent[propertyName].valueOf() === undefined) {
            parent[propertyName] = "---UNDEFINED---";
        }
        else if (parent[propertyName].valueOf() === null) {
            parent[propertyName] = "---NULL---";
        }
        else if (typeof parent[propertyName].valueOf() === "number") {
            parent[propertyName] = getNumberEqualization('NUMBER', parent[propertyName]);
        }
        else if (typeof parent[propertyName].valueOf() === "string") {
            parent[propertyName] =
                parent[propertyName] === ""
                    ? `---STRING_EMPTY---`
                    : `---STRING_NON_EMPTY---`;
        }
        else {
            parent[propertyName] = `---VALUE---`;
        }
    });
    return obj_;
};
export const clearDynamicValuesForSnapshots = (
/**
 * Object to clean up
 */
obj, 
/**
 * Add properties beyond IDBEntityBase to replace values
 */
replaceExtraProperties) => clearDynamicValuesForSnapshotsAdvanced(obj, { replaceExtraProperties });
export const clearArrayFromDynamicValuesForTestSnapshots = (
/**
 * Array of objects to clean up
 */
array, 
/**
 * Options for customizing the replacement of dynamic values in snapshots.
 * These options allow control over how properties are handled, including
 * replacing values based on property names or skipping certain default properties.
 */
options = {}) => {
    return array.map(v => clearDynamicValuesForSnapshotsAdvanced(v, options));
};
const getNumberEqualization = (label, n) => {
    const eq = n > 0
        ? ">0"
        : n < 0
            ? "<0"
            : "=0";
    return `---${label}${eq}---`;
};
