/**
 * Scan the child elements of any depth within an element. Access the parents of each child and skip an element to avoid scanning its children.
 */
export const scanElement = (element, scan) => {
    const recursiveScan = (el, parents) => {
        let skipChildren = false;
        const api = {
            element: el,
            parents,
            skip: () => {
                skipChildren = true;
            },
        };
        scan(api);
        if (!skipChildren) {
            Array.from(el.children).forEach((child) => {
                recursiveScan(child, [...parents, el]);
            });
        }
    };
    recursiveScan(element, []);
};
