export var EColumnAlign;
(function (EColumnAlign) {
    EColumnAlign["LEFT"] = "left";
    EColumnAlign["RIGHT"] = "right";
    EColumnAlign["CENTER"] = "center";
})(EColumnAlign || (EColumnAlign = {}));
export var ETableSize;
(function (ETableSize) {
    ETableSize["SMALL"] = "SMALL";
    ETableSize["MEDIUM"] = "MEDIUM";
    ETableSize["LARGE"] = "LARGE";
})(ETableSize || (ETableSize = {}));
