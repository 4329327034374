import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getDataComponentName, } from "../ui-interfaces";
import { BreakpointDeviceContainer } from "../BreakpointDeviceContainer";
import { FlexContainerVertical } from "./FlexContainerVertical";
import { FlexContainerHorizontal } from "./FlexContainerHorizontal";
/**
 *  The FlexContainerResponsive component displays the items vertically according to the boolean prop "verticalOnXxx." Otherwise, it shows the items horizontally.
 */
export const FlexContainerResponsive = (props) => {
    const { sx, dataComponentName, inline, spacing, fullHeight, alignVertical, alignHorizontal, reverseOrder, verticalOnMobile = false, verticalOnTablet = false, verticalOnLaptop = false, verticalOnDesktop = false, verticalOnDnWide = false, children, } = props;
    const renderContent = (vertical) => vertical
        ? (_jsx(FlexContainerVertical, { sx: sx, dataComponentName: getDataComponentName(dataComponentName, 'FlexContainerResponsive'), inline: inline, spacing: spacing, alignHorizontal: alignHorizontal, fullHeight: fullHeight, reverseOrder: reverseOrder, children: children }))
        : (_jsx(FlexContainerHorizontal, { sx: sx, dataComponentName: getDataComponentName(dataComponentName, 'FlexContainerResponsive'), inline: inline, spacing: spacing, alignVertical: alignVertical, fullHeight: fullHeight, reverseOrder: reverseOrder, children: children }));
    return (_jsxs(_Fragment, { children: [_jsx(BreakpointDeviceContainer, { mobile: true, children: renderContent(verticalOnMobile) }), _jsx(BreakpointDeviceContainer, { tablet: true, children: renderContent(verticalOnTablet) }), _jsx(BreakpointDeviceContainer, { laptop: true, children: renderContent(verticalOnLaptop) }), _jsx(BreakpointDeviceContainer, { desktop: true, children: renderContent(verticalOnDesktop) }), _jsx(BreakpointDeviceContainer, { wide: true, children: renderContent(verticalOnDnWide) })] }));
};
