import {IProfileOption} from "mhc-ui-components/dist/AppContainerDashboard";
import {ProgressIcon} from "mhc-ui-components/dist/ProgressIcon";

import {IAppStore} from "../../../state/IAppStore";
import {ESignStatus} from "../../user-authnentication/state/userAuthSection";

import {routeUserProfilePage} from "../../user-authnentication/routes/routeUserProfilePage";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ViewProfileIcon from '@mui/icons-material/AccountBox';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/ExitToApp';

export const getProfileIcon = (store: IAppStore): JSX.Element => {
  const {
    signStatus,
    user: {avatarUrl},
  } = store.userAuth.state;

  if (signStatus === ESignStatus.SIGNING_IN) {
    return (
      <ProgressIcon size={19}/>
    );
  }

  if (signStatus === ESignStatus.SIGNED_IN) {
    return (
      avatarUrl
        ? (
          <img
            src={avatarUrl}
            alt="User profile icon"
            referrerPolicy="no-referrer"
          />
        )
        : <AccountCircleIcon/>
    );
  }

  return (
    <AccountCircleIcon style={{opacity: 0.5}}/>
  );
};

export const getProfileIconOptions = (store: IAppStore): IProfileOption[] => {
  const signedIn = store.userAuth.state.signStatus === ESignStatus.SIGNED_IN;
  return [
    {
      hidden: signedIn,
      label: 'Login',
      icon: <LoginIcon/>,
      onClick: () => store.userAuth.actions.signIn('/'),
    },
    {
      hidden: !signedIn,
      label: 'Profile',
      icon: <ViewProfileIcon/>,
      onClick: () => store.app.actions.navigateTo({url: routeUserProfilePage.getRoutePath()}),
    },
    {
      hidden: !signedIn,
      label: 'Logout',
      icon: <LogoutIcon/>,
      onClick: () => store.userAuth.actions.signOut(null),
    },
  ];
};
