import { EWZLane, EWZShoulder, EWZBroadcastSelectionMode, EWZClosureType, EWZClosureTypeDeprecated, } from "../interfaces/IWorkzone";
import { EWZClosureSelectionMode } from "../interfaces/IWorkzonesAppSettings";
import { validateDataMethods } from "utils-library/dist/validation-engine";
import { validateWorkzoneElements } from "../interfaces/validateWorkzoneElements";
// This is very basic validation, we check only security and if the props exist and have correct type.
// We don't validate the logic or if the values are right since we allow the user , this is done on broadcast.
export const validateWorkzoneForUpdate = {
    id: validateDataMethods.hasValue,
    kapschId: validateDataMethods.isNumber,
    active: validateDataMethods.isBoolean,
    name: (name) => validateDataMethods.isValidText(name, 2, 300),
    start: validateDataMethods.isValidDateValue,
    end: validateDataMethods.isValidDateValue,
    forEver: validateDataMethods.isBoolean,
    speedLimitInMiles: validateDataMethods.isInteger,
    closureSelectionMode: value => validateDataMethods.isEnumValue(value, EWZClosureSelectionMode),
    closureType: (value, workzone) => {
        if (workzone.closureSelectionMode === EWZClosureSelectionMode.CUSTOM)
            return "";
        return validateDataMethods.isEnumValue(value, EWZClosureType, EWZClosureTypeDeprecated);
    },
    itisCodes: validateDataMethods.isArrayOfNumbers,
    closedLane: (value) => validateDataMethods.isEnumValue(value, EWZLane),
    closedShoulder: (value) => validateDataMethods.isEnumValue(value, EWZShoulder),
    closedLaneWidthInMeters: validateDataMethods.isNumber,
    workersPresent: validateDataMethods.isBoolean,
    notes: (value) => validateDataMethods.isValidText(value, 0, 10000, true),
    point: validateWorkzoneElements.isValidGeoPoint,
    path: validateWorkzoneElements.isValidGeoLine,
    heading: validateWorkzoneElements.isValidHeadings,
    broadcast: validateDataMethods.hasValue,
    "broadcast.selectionMode": (value) => validateDataMethods.isEnumValue(value, EWZBroadcastSelectionMode),
    "broadcast.selectionByRegion": validateDataMethods.hasValue,
    "broadcast.selectionByRegion.region": (region, workzone) => {
        if (workzone.broadcast.selectionMode === EWZBroadcastSelectionMode.RADIUS)
            return "";
        return validateWorkzoneElements.isValidPoints(region.points);
    },
    "broadcast.selectionByRadius": validateDataMethods.hasValue,
    "broadcast.selectionByRadius.radiusInMeters": (value, workzone) => {
        if (workzone.broadcast.selectionMode === EWZBroadcastSelectionMode.REGION)
            return "";
        return validateDataMethods.isNumberInRange(value, 0, 999999);
    },
    createdAt: () => "",
    createdBy: () => "",
    updatedAt: () => "",
    updatedBy: () => "",
    deletedAt: () => "",
    deletedBy: () => "",
    archivedAt: () => "",
    archivedBy: () => "",
};
