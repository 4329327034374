import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, } from "react";
import { Box } from "../Box";
import { useResizeDetector } from 'react-resize-detector';
import { getDataComponentName, } from "../ui-interfaces";
export const ContainerDetectResize = (props) => {
    const { sx, dataComponentName, children, debounce, fullHeight = false, onClick, onScroll, } = props;
    const { width = 0, height = 0, ref, } = useResizeDetector({
        refreshMode: debounce ? 'debounce' : undefined,
        skipOnMount: false,
    });
    const [initialDimension, setInitialDimension] = // We use the initial dimension in the first place because the useResizeDetector is delaying producing blank areas for a short time!
     useState({
        width: 0,
        height: 0,
    });
    useEffect(() => {
        if (!ref.current)
            return;
        setInitialDimension({
            width: ref.current.clientWidth,
            height: ref.current.clientHeight,
        });
    }, [ref.current]);
    return (_jsx(Box, { dataComponentName: getDataComponentName(dataComponentName, "ContainerDetectResize"), sx: sx, fullHeight: fullHeight, ref: ref, onClick: onClick, onScroll: onScroll, children: children({
            width: width || initialDimension.width,
            height: height || initialDimension.height,
        }) }));
};
