var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class BrowserGeolocation {
    constructor(config = {}) {
        this.config = config;
        if (config.onPosition) {
            navigator.geolocation.watchPosition(config.onPosition, config.onError, { enableHighAccuracy: config.enableHighAccuracy });
        }
    }
    getFullGeolocationPosition() {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject, { enableHighAccuracy: this.config.enableHighAccuracy });
        });
    }
    getGeoPosition() {
        return __awaiter(this, void 0, void 0, function* () {
            const { coords: { latitude: lat, longitude: lng, altitude: alt, }, } = yield this.getFullGeolocationPosition();
            return {
                lat,
                lng,
                alt: alt === null ? undefined : alt,
            };
        });
    }
}
