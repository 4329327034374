import { validateDataMethods } from "utils-library/dist/validation-engine";
import { EDeviceType } from "../interfaces/EDeviceType";
export const DemoConnectITSLocationHub = {
    deviceType: EDeviceType.CONNECT_ITS_LOCATION_HUB,
    label: '(demo) Connect ITS location hub',
    validateConfig: { maintenanceMode: validateDataMethods.isBoolean },
    validateStatus: {
        acPower: validateDataMethods.isBoolean,
        dcVoltage: validateDataMethods.isNumber,
        flasherStatus: validateDataMethods.isBoolean,
        batteryChargePercentage: value => validateDataMethods.isNumberInRange(value, 0, 100),
    },
};
export const getDefaultDemoConnectITSLocationHubConfig = () => ({ maintenanceMode: false });
