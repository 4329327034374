import { jsx as _jsx } from "react/jsx-runtime";
import { dynaSwitchEnum } from "dyna-switch";
import { Box } from "../Box";
import MuiModal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
export var EFullModeMargin;
(function (EFullModeMargin) {
    EFullModeMargin["XSMALL"] = "XSMALL";
    EFullModeMargin["SMALL"] = "SMALL";
    EFullModeMargin["MEDIUM"] = "MEDIUM";
    EFullModeMargin["LARGE"] = "LARGE";
})(EFullModeMargin || (EFullModeMargin = {}));
export const Modal = (props) => {
    const { sx = {}, dataComponentName, show, fullMode = false, fullWidth = false, fullModeMargin = EFullModeMargin.MEDIUM, minWidth = 320, maxWidth = "90%", dimBackground = true, children, onBackdropClick, } = props;
    const fullWidthMarginValue = dynaSwitchEnum(fullModeMargin, {
        [EFullModeMargin.XSMALL]: '98%',
        [EFullModeMargin.SMALL]: '95%',
        [EFullModeMargin.MEDIUM]: '90%',
        [EFullModeMargin.LARGE]: '80%',
    });
    return (_jsx(MuiModal, { sx: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 3200,
        }, open: show, closeAfterTransition: true, components: { Backdrop: dimBackground ? Backdrop : undefined }, onClose: onBackdropClick, children: _jsx(Box, { sx: Object.assign({ backgroundColor: theme => theme.palette.background.paper, border: '2px solid #000', boxShadow: theme => theme.shadows[5], zIndex: 1, minWidth: typeof minWidth === "number"
                    ? minWidth + 'px'
                    : minWidth, maxWidth: typeof maxWidth === "number"
                    ? maxWidth + 'px'
                    : maxWidth, maxHeight: fullWidthMarginValue, overflowY: 'auto', width: fullWidth
                    ? '100%'
                    : fullMode
                        ? fullWidthMarginValue
                        : undefined, height: fullMode
                    ? fullWidthMarginValue
                    : undefined, position: 'relative' }, sx), dataComponentName: dataComponentName, children: children }) }));
};
