export const monitorOnline = (onOnlineChange) => {
    const onlineStatusHandler = () => {
        onOnlineChange(window.navigator.onLine);
    };
    return {
        get online() {
            return window.navigator.onLine;
        },
        get offline() {
            return !window.navigator.onLine;
        },
        start: () => {
            window.addEventListener('online', onlineStatusHandler);
            window.addEventListener('offline', onlineStatusHandler);
        },
        stop: () => {
            window.removeEventListener('online', onlineStatusHandler);
            window.removeEventListener('offline', onlineStatusHandler);
        },
    };
};
