import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { dynaSwitchEnum } from "dyna-switch";
import { useTheme, } from "../ThemeProvider";
import { IconText } from "../IconText";
import { createIcon } from "../IconComponent";
import DefaultIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarnIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import DebugIcon from '@mui/icons-material/BugReport';
export var EHelperTextType;
(function (EHelperTextType) {
    EHelperTextType["DEFAULT"] = "DEFAULT";
    EHelperTextType["SUCCESS"] = "SUCCESS";
    EHelperTextType["ERROR"] = "ERROR";
    EHelperTextType["INFO"] = "INFO";
    EHelperTextType["WARNING"] = "WARNING";
    EHelperTextType["DEBUG"] = "DEBUG";
})(EHelperTextType || (EHelperTextType = {}));
// Like MUI's Helper Text but doesn't complain for nested divs
export const HelperText = (props) => {
    const { sx = {}, Icon, show = true, dataComponentName, type = EHelperTextType.DEFAULT, line, lines, children, } = props;
    const theme = useTheme();
    if (!children && !line && !(lines === null || lines === void 0 ? void 0 : lines.length))
        return null;
    if (!show)
        return null;
    return (_jsxs(IconText, { sx: Object.assign({ fontSize: theme.typography.fontSize * 0.8, color: dynaSwitchEnum(type, {
                [EHelperTextType.DEFAULT]: "",
                [EHelperTextType.INFO]: theme.palette.info.main,
                [EHelperTextType.SUCCESS]: theme.palette.success.main,
                [EHelperTextType.ERROR]: theme.palette.error.main,
                [EHelperTextType.WARNING]: theme.palette.warning.main,
                [EHelperTextType.DEBUG]: theme.palette.secondary.main,
            }), opacity: 0.8 }, sx), dataComponentName: dataComponentName, Icon: (() => {
            if (Icon)
                return Icon;
            return dynaSwitchEnum(type, {
                [EHelperTextType.DEFAULT]: createIcon.byMuiIcon(DefaultIcon),
                [EHelperTextType.INFO]: createIcon.byMuiIcon(InfoIcon),
                [EHelperTextType.SUCCESS]: createIcon.byMuiIcon(SuccessIcon),
                [EHelperTextType.ERROR]: createIcon.byMuiIcon(ErrorIcon),
                [EHelperTextType.WARNING]: createIcon.byMuiIcon(WarnIcon),
                [EHelperTextType.DEBUG]: createIcon.byMuiIcon(DebugIcon),
            });
        })(), children: [new Array()
                .concat(line, lines)
                .filter(Boolean)
                .map((line, index) => (_jsx("div", { children: line }, index))), children] }));
};
