import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";
import {routeDeviceManagementTreePaths} from "./routeDeviceManagementTree.paths";

const DeviceManagementTree = lazy(() => import ("../pages/DeviceManagementTree"));

export const routeDeviceManagementTree: IAppRoute = {
  ...routeDeviceManagementTreePaths,
  render: () => (
    <LazyLoadComponent>
      <DeviceManagementTree/>
    </LazyLoadComponent>
  ),
};
