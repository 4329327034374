var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BrowserDbCollection } from "./BrowserDbCollection";
export var EOfflineAction;
(function (EOfflineAction) {
    EOfflineAction["CREATE"] = "CREATE";
    EOfflineAction["UPDATE"] = "UPDATE";
    EOfflineAction["DELETE"] = "DELETE";
    EOfflineAction["UNDELETE"] = "UNDELETE";
    EOfflineAction["PERMANENT_DELETE"] = "PERMANENT_DELETE";
})(EOfflineAction || (EOfflineAction = {}));
export class BrowserDb {
    constructor(config) {
        this.config = config;
        this.dbChangesCollection = new BrowserDbCollection({
            dbName: this.config.dbName,
            version: 2,
            collectionName: 'browserDbChangesCollection',
            keyFieldName: 'collectionChangeId',
            indexFieldNames: 'updatedAt'.split(','),
            onCreate: (doc) => __awaiter(this, void 0, void 0, function* () {
                if (localStorage.getItem('_debug_offlineMessages') === 'true')
                    console.log('DEBUG-OFFLINE: dbChangesCollection NEW_DOC', doc);
            }),
        });
    }
    collection({ collectionName, version, keyFieldName, buildSearchContent, indexFieldNames = [], }) {
        return new BrowserDbCollection({
            dbName: this.config.dbName,
            collectionName,
            version,
            keyFieldName,
            indexFieldNames,
            buildSearchContent,
            onCreate: (doc) => __awaiter(this, void 0, void 0, function* () {
                if (!this.config.collectChanges)
                    return;
                yield this.dbChangesCollection.update({
                    collectionChangeId: '',
                    key: doc[keyFieldName],
                    offlineAction: EOfflineAction.CREATE,
                    collectionName,
                    doc,
                });
            }),
            onUpdate: (key, doc) => __awaiter(this, void 0, void 0, function* () {
                if (!this.config.collectChanges)
                    return;
                yield this.dbChangesCollection.update({
                    collectionChangeId: '',
                    key,
                    offlineAction: EOfflineAction.UPDATE,
                    collectionName,
                    doc,
                });
            }),
            onDelete: (key, doc) => __awaiter(this, void 0, void 0, function* () {
                if (!this.config.collectChanges)
                    return;
                yield this.dbChangesCollection.update({
                    collectionChangeId: '',
                    key,
                    offlineAction: EOfflineAction.DELETE,
                    collectionName,
                    doc,
                });
            }),
            onUndelete: (key, doc) => __awaiter(this, void 0, void 0, function* () {
                if (!this.config.collectChanges)
                    return;
                yield this.dbChangesCollection.update({
                    collectionChangeId: '',
                    key,
                    offlineAction: EOfflineAction.UNDELETE,
                    collectionName,
                    doc,
                });
            }),
            onPermanentDelete: (key) => __awaiter(this, void 0, void 0, function* () {
                if (!this.config.collectChanges)
                    return;
                yield this.dbChangesCollection.update({
                    collectionChangeId: '',
                    key,
                    offlineAction: EOfflineAction.PERMANENT_DELETE,
                    collectionName,
                });
            }),
        });
    }
    getDbChangesByCount(count = 1) {
        return this.dbChangesCollection.find({
            pagination: {
                skip: 0,
                limit: count,
            },
            sort: {
                fieldName: 'updatedAt',
                direction: 1,
            },
        });
    }
    getDbChangesAll() {
        return __awaiter(this, void 0, void 0, function* () {
            const count = yield this.getDbChangesCount();
            return this.getDbChangesByCount(count);
        });
    }
    getDbChangesCount() {
        return __awaiter(this, void 0, void 0, function* () {
            return this.dbChangesCollection.count();
        });
    }
    deleteDbChanges(collectionChangeIds) {
        return __awaiter(this, void 0, void 0, function* () {
            yield Promise.all(collectionChangeIds
                .map(collectionChangeId => this.dbChangesCollection.deletePermanent(collectionChangeId)));
        });
    }
}
