import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EOpenMode } from "../../interfaces";
import { Box } from "../../../Box";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../../../FlexContainer";
import { Condition } from "../../../Condition";
import { CenterContainer } from "../../../CenterContainer";
import { BreakpointDeviceContainer } from "../../../BreakpointDeviceContainer";
import { AppNotificationIcons } from "./components/AppNotificationIcons";
import { IconButton } from "../../../IconButton";
import { useTheme } from "../../../ThemeProvider";
import { sxTransitionShowHide } from "../../../sxTransition";
import MenuIcon from '@mui/icons-material/Menu';
const MENU_ICON_SIZE = 24;
export const AppHeader = (props) => {
    const theme = useTheme();
    const { appTitle, appTitleHelper, appTitleContent, openMode, notificationIcons, profileUserName, profileIcon, profileOptions, onAsideMenuOpenChange, } = props;
    const showMenuIcon = openMode === EOpenMode.COLLAPSED;
    const handleMenuClick = () => onAsideMenuOpenChange(EOpenMode.EXPANDED);
    return (_jsxs(FlexContainerHorizontal, { dataComponentName: "AppHeader", alignVertical: "middle", sx: {
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
        }, children: [_jsx(FlexItemMin, { sx: {
                    marginLeft: showMenuIcon ? undefined : '0 !important',
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                    transition: sxTransitionShowHide(theme, 'margin-left', showMenuIcon),
                }, children: _jsx(Box, { sx: {
                        transition: sxTransitionShowHide(theme, 'width', showMenuIcon),
                        width: showMenuIcon ? MENU_ICON_SIZE + parseInt(theme.spacing(2)) : 0,
                        overflow: 'hidden',
                    }, children: _jsx(IconButton, { ariaLabel: "Application main menu button", icon: _jsx(MenuIcon, { sx: { color: theme.palette.primary.contrastText }, width: MENU_ICON_SIZE }), onClick: handleMenuClick }) }) }), _jsx(Condition, { if: !!appTitleContent, children: _jsx(FlexItemMax, { sx: {
                        margin: 0,
                        paddingLeft: theme.spacing(1),
                    }, children: appTitleContent }) }), _jsx(Condition, { not: true, if: !!appTitleContent, children: _jsx(FlexItemMax, { children: _jsxs(CenterContainer, { horizontally: "left", children: [_jsx(Box, { sx: {
                                    fontSize: theme.typography.fontSize * 1.2,
                                    fontWeight: 'bold',
                                    color: theme.palette.primary.contrastText,
                                }, children: appTitle }), _jsx(BreakpointDeviceContainer, { allExcept: true, mobile: true, children: _jsx(Box, { sx: {
                                        fontSize: theme.typography.fontSize * 1,
                                        color: theme.palette.primary.contrastText,
                                    }, children: appTitleHelper }) })] }) }) }), _jsx(FlexItemMin, { children: _jsx(AppNotificationIcons, { notificationIcons: notificationIcons, profileUserName: profileUserName, profileIcon: profileIcon, profileOptions: profileOptions }) })] }));
};
