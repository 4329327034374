import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { dynaSwitch } from "dyna-switch";
import { Box } from "../Box";
import MuiCircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
export var ECircularProgressVariant;
(function (ECircularProgressVariant) {
    ECircularProgressVariant["DETERMINATE"] = "DETERMINATE";
    ECircularProgressVariant["INDETERMINATE"] = "INDETERMINATE";
})(ECircularProgressVariant || (ECircularProgressVariant = {}));
export var ECircularProgressColor;
(function (ECircularProgressColor) {
    ECircularProgressColor["INHERIT"] = "INHERIT";
    ECircularProgressColor["PRIMARY"] = "PRIMARY";
    ECircularProgressColor["SECONDARY"] = "SECONDARY";
})(ECircularProgressColor || (ECircularProgressColor = {}));
export var ECircularProgressSize;
(function (ECircularProgressSize) {
    ECircularProgressSize["SMALL"] = "SMALL";
    ECircularProgressSize["MEDIUM"] = "MEDIUM";
    ECircularProgressSize["LARGE"] = "LARGE";
    ECircularProgressSize["XLARGE"] = "XLARGE";
    ECircularProgressSize["XXLARGE"] = "XXLARGE";
})(ECircularProgressSize || (ECircularProgressSize = {}));
export const CircularProgress = (props) => {
    const { sx = {}, variant = ECircularProgressVariant.DETERMINATE, color = ECircularProgressColor.PRIMARY, thickness, size = ECircularProgressSize.MEDIUM, label, value = 0, } = props;
    const muiCircularProgressVariant = dynaSwitch(variant, 'determinate', {
        [ECircularProgressVariant.DETERMINATE]: 'determinate',
        [ECircularProgressVariant.INDETERMINATE]: 'indeterminate',
    });
    const muiCircularProgressColor = dynaSwitch(color, 'primary', {
        [ECircularProgressColor.PRIMARY]: 'primary',
        [ECircularProgressColor.SECONDARY]: 'secondary',
        [ECircularProgressColor.INHERIT]: 'inherit',
    });
    const muiCircularProgressSize = dynaSwitch(size, 40, {
        [ECircularProgressSize.SMALL]: 30,
        [ECircularProgressSize.MEDIUM]: 40,
        [ECircularProgressSize.LARGE]: 55,
        [ECircularProgressSize.XLARGE]: 70,
        [ECircularProgressSize.XXLARGE]: 85,
    });
    return (_jsxs(Box, { sx: Object.assign({ display: 'inline-flex', position: 'relative', padding: 2 }, sx), dataComponentName: "CircularProgress", children: [_jsx(MuiCircularProgress, { variant: muiCircularProgressVariant, color: muiCircularProgressColor, thickness: thickness, size: muiCircularProgressSize, value: value }), _jsx(Box, { sx: {
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }, children: _jsx(Typography, { variant: "caption", component: "div", color: "textSecondary", children: label }) })] }));
};
