import {Helmet} from "react-helmet";
import {Box} from "mhc-ui-components/dist/Box";
import {appConfig} from "../config/appConfig";

export interface IAppPageTitleProps {
  children?: string | string[];
  h1?: boolean; // Apply it as hidden h1 too
}

export const AppPageTitle = (props: IAppPageTitleProps): JSX.Element => {
  const {
    children: multiChildren = [],
    h1 = false,
  } = props;
  const children: string =
    Array.isArray(multiChildren)
      ? multiChildren
        .map(v => v.trim())
        .filter(Boolean)
        .join(' - ')
      : multiChildren;

  const title = [
    children,
    appConfig.appName,
  ]
    .filter(Boolean)
    .join(' - ')
    .replace(/"/g, '');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Box
        show={h1}
        component="h1"
        hidden
      >
        {title}
      </Box>
    </>
  );
};
