import {createIcon} from "mhc-ui-components/dist/IconComponent";
import SourceTrafficStudiesIcon from '@iconify-icons/carbon/vehicle-insights';
import SourceTrafficStudyConfiguration from '@mui/icons-material/Tune';
import SourcePublishIcon from '@mui/icons-material/Send';
import SourceOverviewIcon from '@mui/icons-material/ViewCompact';
import SourceCopyToNew from '@mui/icons-material/FileCopy';
import SourceCollectedDataIcon from '@mui/icons-material/Storage';
import SourceReportsIcon from '@mui/icons-material/AutoGraph';
import SourceLogsIcon from '@mui/icons-material/FormatListBulleted';

export const TrafficStudiesIcon = createIcon.byIconifyIcon(SourceTrafficStudiesIcon);
export const TrafficStudyConfiguration = createIcon.byMuiIcon(SourceTrafficStudyConfiguration);
export const PublishIcon = createIcon.byMuiIcon(SourcePublishIcon);
export const OverviewIcon = createIcon.byMuiIcon(SourceOverviewIcon);
export const CopyToNew = createIcon.byMuiIcon(SourceCopyToNew);
export const TrafficDataIcon = createIcon.byMuiIcon(SourceCollectedDataIcon);
export const ReportsIcon = createIcon.byMuiIcon(SourceReportsIcon);
export const LogsIcon = createIcon.byMuiIcon(SourceLogsIcon);
