import { jsx as _jsx } from "react/jsx-runtime";
import MuiTypography from "@mui/material/Typography";
import { getDataComponentName, } from "../ui-interfaces";
import { useBreakpointDevice } from "../useBreakpointDevice";
import { useTheme, } from "../ThemeProvider";
export const Typography = (props) => {
    const theme = useTheme();
    const { sx = {}, show = true, dataComponentName, variant: userVariant, v, align, bold = false, italic = false, underline = false, strikethrough = false, gutterBottom = true, noWrap = false, noSpace = false, ariaLabel, children, } = props;
    const { isMobile, isTablet, isLaptop, } = useBreakpointDevice();
    const style = {};
    if (bold)
        style.fontWeight = "bold";
    if (italic)
        style.fontStyle = "italic";
    if (underline)
        style.textDecoration = "underline";
    if (strikethrough)
        style.textDecoration = "line-through";
    if (strikethrough && underline)
        style.textDecoration = "line-through underline";
    const variant = userVariant || v || "body1";
    const component = variant[0] === "h" ? "" : "div";
    const paragraph = variant.startsWith('body');
    // Check if it's a size-reducing variant and apply the scale
    if (['body1', 'body2', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(variant)) {
        if (isMobile) {
            style.fontSize = `calc(${theme.typography[variant].fontSize} * 0.8)`;
        }
        else if (isTablet) {
            style.fontSize = `calc(${theme.typography[variant].fontSize} * 0.85)`;
        }
        else if (isLaptop) {
            style.fontSize = `calc(${theme.typography[variant].fontSize} * 0.9)`;
        }
    }
    if (!show)
        return null;
    return (_jsx(MuiTypography, { "data-component-name": getDataComponentName(dataComponentName, "Typography"), component: component, sx: Object.assign(Object.assign(Object.assign({}, theme.typography[variant]), sx), (noSpace ? { margin: 0 } : {})), style: style, variant: variant, paragraph: paragraph, align: align, noWrap: noWrap, gutterBottom: gutterBottom, "aria-label": ariaLabel, children: children }));
};
