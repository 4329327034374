// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-marker-div-icon {
  background: transparent;
  border: none;
}
.leaflet-control {
  background-color: white;
}
.leaflet-touch .leaflet-bar a {
  min-width: 38px;
  height: 38px;
  line-height: 38px;
  width: auto;
}
`, "",{"version":3,"sources":["webpack://./src/GeoMapLeafletCore/GeoMapLeafletCore.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,YAAA;AACF;AAEA;EACE,uBAAA;AAAF;AAGA;EACE,eAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;AADF","sourcesContent":[".leaflet-marker-div-icon {\n  background: transparent;\n  border: none;\n}\n\n.leaflet-control {\n  background-color: white;\n}\n\n.leaflet-touch .leaflet-bar a {\n  min-width: 38px;\n  height: 38px;\n  line-height: 38px;\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
