export var EUsersInvitationsSortField;
(function (EUsersInvitationsSortField) {
    EUsersInvitationsSortField["EMAIL"] = "email";
    EUsersInvitationsSortField["TITLE"] = "title";
    EUsersInvitationsSortField["FIRST_NAME"] = "firstName";
    EUsersInvitationsSortField["LAST_NAME"] = "lastName";
    EUsersInvitationsSortField["LAST_SEND_DATE"] = "lastSendDate";
    EUsersInvitationsSortField["LAST_SEND_STATUS"] = "lastSendStatus";
    EUsersInvitationsSortField["CREATED_AT"] = "createdAt";
    EUsersInvitationsSortField["UPDATED_AT"] = "updatedAt";
    EUsersInvitationsSortField["DELETED_AT"] = "deletedAt";
})(EUsersInvitationsSortField || (EUsersInvitationsSortField = {}));
export var ESendUserInvitationStatus;
(function (ESendUserInvitationStatus) {
    ESendUserInvitationStatus["NOT_CREATED"] = "NOT_CREATED";
    ESendUserInvitationStatus["NOT_SENT"] = "NOT_SENT";
    ESendUserInvitationStatus["SENDING"] = "SENDING";
    ESendUserInvitationStatus["SENT"] = "SENT";
    ESendUserInvitationStatus["SEND_FAILED"] = "SEND_FAILED";
    ESendUserInvitationStatus["ACCEPTED"] = "ACCEPTED";
    ESendUserInvitationStatus["DELETED"] = "DELETED";
    ESendUserInvitationStatus["UNDELETED"] = "UNDELETED";
})(ESendUserInvitationStatus || (ESendUserInvitationStatus = {}));
