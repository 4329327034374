import { useState } from "react";
import { isObject } from "utils-library/dist/utils";
import { useOnChange } from "../useOnChange";
/**
 * UseStateWithPropValue combines updates made to a component's prop value with updates made to its state value
 * The usage is the same with this of React's useState.
 * This hook makes the components to work as managed and unmanaged on the same time.
 * @param name The name of the property of the value
 * @param defaultValue Default value (required). This can be from the props or hardcoded.
 * @param propValue Value from the prop (optional), changes to it will change the value!
 * @param disabled When true, changes are ignored and not applied
 * @param readOnly When true, changes are ignored and not applied
 * @param onChange Called only when setValue() is called
 */
export const useStateWithPropValue = ({ name, defaultValue, propValue, disabled, readOnly, onChange, }) => {
    const [value, setValue] = useState(propValue !== null && propValue !== void 0 ? propValue : defaultValue);
    useOnChange({
        dep: propValue,
        onChange: v => setValue(v),
    });
    const handleChange = (userValue) => {
        if (disabled || readOnly)
            return;
        let newValue = null;
        setValue(currentValue => {
            const resolvedUserValue = typeof userValue === "function"
                ? userValue(currentValue)
                : userValue;
            if (resolvedUserValue === undefined)
                return currentValue; // No changes requested
            newValue =
                isObject(currentValue)
                    ? Object.assign(Object.assign({}, currentValue), resolvedUserValue) : resolvedUserValue;
            return newValue;
        });
        onChange && onChange(newValue, name);
    };
    return [
        value,
        handleChange,
    ];
};
