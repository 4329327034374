import * as Sentry from "@sentry/react";
import {CaptureConsole} from '@sentry/integrations';
// Help Sentry: https://docs.sentry.io/platforms/javascript/guides/react/configuration

import {isLocalhost} from "utils-library/dist/web";

import {appConfig} from "./appConfig";

(() => {
  const {
    appVersion,
    appCodeName,
    builtAt,
    sentryIoDsn,
    webBaseUrl,
    apiBaseUrl,
  } = appConfig;

  if (!sentryIoDsn) {
    if (!isLocalhost) console.warn('Sentry.io not started since there is no Sentry DSN (sentryIoDsn).');
    return;
  }

  Sentry.init({
    dsn: sentryIoDsn,
    environment: appConfig.mode,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          webBaseUrl,
          apiBaseUrl,
        ],
      }),
      new Sentry.Replay(),
      new CaptureConsole({
        levels: [
          'warn',
          'error',
          // Not log, debug & info
        ],
      }),
    ],
    beforeSend: (event, hint) => {
      // Help: https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-namebefore-send-
      if (event.type === 'transaction' && hint.originalException instanceof XMLHttpRequest) {
        // Customize this function to decide which XHR calls to trace as transactions
        // For example, trace only if there's an error status code.
        const xhr = hint.originalException;
        if (xhr.status < 400) return null; // Filter out non errors
      }

      return event;
    },
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  Sentry.configureScope((scope) => {
    scope.setExtra('release', appVersion);
    scope.setExtra('codename', appCodeName);
    scope.setExtra('buildDate', builtAt);
  });
})();
