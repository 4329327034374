import { useState, useEffect, } from "react";
import { areValuesEqual } from "utils-library/dist/utils";
/**
 * UseOnChange is like useEffect but triggers only when something really changed in data and not on reference change.
 */
export const useOnChange = ({ dep, callOnStartup = false, onChange, onUnmount, }) => {
    const [lastDep, setLastDep] = useState(dep);
    useEffect(() => {
        if (callOnStartup)
            onChange(dep, dep);
    }, []);
    useEffect(() => {
        if (!areValuesEqual(dep, lastDep)) {
            onChange(dep, lastDep);
            setLastDep(dep);
        }
        return onUnmount;
    }, [dep]);
};
