import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { dynaSwitchEnum } from "dyna-switch";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { Box } from "../Box";
import { CenterContainer } from "../CenterContainer";
import { Collapse } from "../Collapse";
import { Button, EButtonVariant, EButtonSize, } from "../Button";
import { IconViewer } from "../IconViewer";
import { useTheme, } from "../ThemeProvider";
import { alpha } from "@mui/material/styles";
import { sxTransitionShowHide } from "../sxTransition";
import { createIcon, } from "../IconComponent";
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Report';
import CloseIcon from "@mui/icons-material/Close";
import NeutralIcon from '@mui/icons-material/PlayArrow';
import BugIcon from '@mui/icons-material/BugReport';
export var EAlertVariant;
(function (EAlertVariant) {
    EAlertVariant["FILLED"] = "filled";
    EAlertVariant["OUTLINED"] = "outlined";
    EAlertVariant["STANDARD"] = "standard";
})(EAlertVariant || (EAlertVariant = {}));
export var EAlertType;
(function (EAlertType) {
    EAlertType["SUCCESS"] = "SUCCESS";
    EAlertType["INFO"] = "INFO";
    EAlertType["WARNING"] = "WARNING";
    EAlertType["ERROR"] = "ERROR";
    EAlertType["NEUTRAL"] = "NEUTRAL";
    EAlertType["DEBUG"] = "DEBUG";
})(EAlertType || (EAlertType = {}));
const alertColors = (theme) => ({
    light: {
        [EAlertType.INFO]: {
            baseColor: theme.palette.info.dark,
            contrastColor: theme.palette.background.default,
        },
        [EAlertType.WARNING]: {
            baseColor: theme.palette.warning.dark,
            contrastColor: theme.palette.background.default,
        },
        [EAlertType.SUCCESS]: {
            baseColor: theme.palette.success.dark,
            contrastColor: theme.palette.background.default,
        },
        [EAlertType.ERROR]: {
            baseColor: theme.palette.error.dark,
            contrastColor: theme.palette.background.default,
        },
        [EAlertType.NEUTRAL]: {
            baseColor: theme.palette.grayShades.gray8,
            contrastColor: theme.palette.grayShades.gray2,
        },
        [EAlertType.DEBUG]: {
            baseColor: theme.palette.warning.dark,
            contrastColor: theme.palette.background.default,
        },
    },
    dark: {
        [EAlertType.INFO]: {
            baseColor: theme.palette.info.light,
            contrastColor: theme.palette.background.default,
        },
        [EAlertType.WARNING]: {
            baseColor: theme.palette.warning.light,
            contrastColor: theme.palette.background.default,
        },
        [EAlertType.SUCCESS]: {
            baseColor: theme.palette.success.light,
            contrastColor: theme.palette.background.default,
        },
        [EAlertType.ERROR]: {
            baseColor: theme.palette.error.light,
            contrastColor: theme.palette.background.default,
        },
        [EAlertType.NEUTRAL]: {
            baseColor: theme.palette.grayShades.gray8,
            contrastColor: theme.palette.grayShades.gray2,
        },
        [EAlertType.DEBUG]: {
            baseColor: theme.palette.warning.light,
            contrastColor: theme.palette.background.default,
        },
    },
});
export const Alert = ({ sx = {}, dataComponentName, type = EAlertType.INFO, variant = EAlertVariant.FILLED, Icon, title = '', show = true, children = null, rightPlace, closeButton, marginBottom = 1, }) => {
    const theme = useTheme();
    const colors = alertColors(theme);
    const baseColor = colors[theme.palette.mode][type].baseColor;
    const contrastColor = colors[theme.palette.mode][type].contrastColor;
    return (_jsx(Box, { sx: Object.assign({ transition: sxTransitionShowHide(theme, 'margin-bottom', show), marginBottom: show ? marginBottom : 0 }, sx), dataComponentName: [dataComponentName, "Alert"], children: _jsx(Collapse, { expanded: show, children: _jsxs(FlexContainerHorizontal, { sx: Object.assign({ overflowWrap: 'anywhere', borderRadius: 2, borderWidth: 1, borderStyle: 'solid' }, dynaSwitchEnum(variant, {
                    [EAlertVariant.FILLED]: {
                        color: contrastColor,
                        background: baseColor,
                        borderColor: baseColor,
                    },
                    [EAlertVariant.OUTLINED]: {
                        color: baseColor,
                        borderColor: baseColor,
                    },
                    [EAlertVariant.STANDARD]: {
                        color: baseColor,
                        background: alpha(baseColor, 0.1),
                        borderColor: alpha(baseColor, 0.3),
                    },
                })), spacing: 1, children: [_jsx(FlexItemMin, { sx: { pt: '2px' }, children: _jsx(IconViewer, { width: 32, Icon: Icon
                                ? Icon
                                : dynaSwitchEnum(type, {
                                    [EAlertType.INFO]: createIcon.byMuiIcon(InfoIcon),
                                    [EAlertType.WARNING]: createIcon.byMuiIcon(WarningIcon),
                                    [EAlertType.SUCCESS]: createIcon.byMuiIcon(SuccessIcon),
                                    [EAlertType.ERROR]: createIcon.byMuiIcon(ErrorIcon),
                                    [EAlertType.NEUTRAL]: createIcon.byMuiIcon(NeutralIcon),
                                    [EAlertType.DEBUG]: createIcon.byMuiIcon(BugIcon),
                                }) }) }), _jsx(FlexItemMax, { children: _jsxs(CenterContainer, { horizontally: "left", sx: { '>div': { width: "100%" } }, children: [_jsx(Box, { sx: {
                                        fontSize: theme => theme.typography.fontSize * 1.1,
                                        fontWeight: 'bold',
                                    }, children: title }), _jsx(Box, { sx: {
                                        fontSize: theme => theme.typography.fontSize * 0.9,
                                        whiteSpace: 'pre-wrap',
                                    }, children: children })] }) }), _jsx(FlexItemMin, { show: !!rightPlace, children: rightPlace }), !!closeButton && (_jsx(FlexItemMin, { sx: { pt: '6px' }, children: _jsx(Button, { show: !!closeButton, variant: EButtonVariant.TRANSPARENT, sx: {
                                color: dynaSwitchEnum(variant, {
                                    [EAlertVariant.FILLED]: contrastColor,
                                    [EAlertVariant.OUTLINED]: baseColor,
                                    [EAlertVariant.STANDARD]: baseColor,
                                }),
                            }, size: EButtonSize.XXSMALL, Icon: closeButton.Icon || createIcon.byMuiIcon(CloseIcon), onClick: closeButton.onClick, children: closeButton.label }) }))] }) }) }));
};
