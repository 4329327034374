import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { useMemo, useState, useEffect, } from "react";
import { Marker } from 'react-leaflet'; // Dev note: Why not use our MapMarker? Because, for some reason, when re-rendering our MapMarker, the drag functionality stops.
import { arrayInsert, arrayRemove, } from "utils-library/dist/array";
import { isMac } from "utils-library/dist/web";
import { Box } from "../../../../Box";
import { IconViewer } from "../../../../IconViewer";
import { useKeyStatus } from "../../../../useKeyStatus";
import { customIconMarkerByJSXElement } from "../../../markers";
import { MapPolyline } from "../MapPolyline";
import { getDirectionDegreesByGeoPositions } from "../../../utils";
import { getBreakLinePositions } from "./utils/getBreakLinePositions";
import { useTheme } from "../../../../ThemeProvider";
import { createIcon } from "../../../../IconComponent";
import ConnectionIcon from '@mui/icons-material/Circle';
import BreakIcon from '@mui/icons-material/RadioButtonChecked';
import DirectionStartIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import DirectionEndIcon from '@mui/icons-material/DoubleArrow';
export const MapDirectionLine = ({ points: userPoints, focusedPointIndex, canEditShape = false, lineWidth = 4, color: userColor, onLineClick, onChange, }) => {
    const [points, setPoints] = useState(userPoints);
    useEffect(() => {
        setPoints(userPoints);
        setBreakPoints(getBreakLinePositions(userPoints));
    }, [JSON.stringify(userPoints)]);
    const [breakPoints, setBreakPoints] = useState(getBreakLinePositions(points));
    const [isBreakingPoint, setIsBreakingPoint] = useState(null);
    const { altKeyDown } = useKeyStatus();
    const theme = useTheme();
    const lineColor = userColor || theme.palette.warning.main;
    const arrowColor = userColor || theme.palette.warning.main;
    const directionStartIcon = useMemo(() => createLineIcon({
        Icon: createIcon.byMuiIcon(DirectionStartIcon),
        rotateDeg: getDirectionDegreesByGeoPositions(points[0], isBreakingPoint === 0
            ? breakPoints[isBreakingPoint]
            : points[1]) - 90,
        size: lineWidth * 4,
        color: arrowColor,
    }), [
        points[0].lat,
        points[0].lng,
        points[1].lat,
        points[1].lng,
        isBreakingPoint === 0 && breakPoints[isBreakingPoint].lat,
        isBreakingPoint === 0 && breakPoints[isBreakingPoint].lng,
    ]);
    const directionEndIcon = useMemo(() => createLineIcon({
        Icon: createIcon.byMuiIcon(DirectionEndIcon),
        rotateDeg: getDirectionDegreesByGeoPositions(points[points.length - 1], isBreakingPoint === points.length - 2
            ? breakPoints[isBreakingPoint]
            : points[points.length - 2]) + 90,
        size: lineWidth * 4,
        color: arrowColor,
    }), [
        points[points.length - 2].lat,
        points[points.length - 2].lng,
        points[points.length - 1].lat,
        points[points.length - 1].lng,
        isBreakingPoint === points.length - 2 && breakPoints[isBreakingPoint].lat,
        isBreakingPoint === points.length - 2 && breakPoints[isBreakingPoint].lng,
    ]);
    const generateConnectionIcon = (focused) => {
        return useMemo(// eslint-disable-line
        () => createLineIcon({
            Icon: createIcon.byMuiIcon(ConnectionIcon),
            cursor: altKeyDown && points.length > 2 ? 'not-allowed' : 'move',
            title: !altKeyDown && points.length > 2 ? `${isMac ? 'Option' : 'Alt'}-Click to delete` : undefined,
            size: lineWidth * 1.5,
            opacity: 0.8,
            hover: true,
            focused: focused,
        }), [
            altKeyDown,
            points.length,
            isMac,
            lineWidth,
            focusedPointIndex,
            focused,
        ]);
    };
    const connectionIcon = generateConnectionIcon(false);
    const connectionIconFocused = generateConnectionIcon(true);
    const breakIcon = useMemo(() => createLineIcon({
        Icon: createIcon.byMuiIcon(BreakIcon),
        cursor: 'move',
        size: lineWidth * 1.5,
        opacity: 0.8,
        hover: true,
    }), [lineWidth]);
    const handleConnectionClick = (pointIndex) => {
        if (altKeyDown && points.length > 2) {
            const newPoints = arrayRemove(points, pointIndex);
            setPoints(newPoints);
            setBreakPoints(getBreakLinePositions(newPoints));
            onChange && onChange(newPoints);
        }
    };
    const handleConnectionDrag = (pointIndex, position) => {
        const newPoints = points.map((scanPoint, index) => index === pointIndex ? position : scanPoint);
        setPoints(newPoints);
        setBreakPoints(getBreakLinePositions(newPoints));
    };
    const handleConnectionDragEnd = () => {
        onChange && onChange(points);
    };
    const handleBreakDragStart = (breakPointIndex) => {
        setIsBreakingPoint(breakPointIndex);
    };
    const handleBreakDrag = (breakPointIndex, position) => {
        setBreakPoints(breakPoints
            .map((scanBreakPoint, index) => breakPointIndex === index
            ? position
            : scanBreakPoint));
    };
    const handleBreakDragEnd = () => {
        if (isBreakingPoint === null)
            return; // 4TS
        const newPoints = arrayInsert(points, isBreakingPoint + 1, breakPoints[isBreakingPoint]);
        setPoints(newPoints);
        setBreakPoints(getBreakLinePositions(newPoints));
        setIsBreakingPoint(null);
        onChange && onChange(newPoints);
    };
    const applyPoints = isBreakingPoint === null
        ? points
        : arrayInsert(points, isBreakingPoint + 1, breakPoints[isBreakingPoint]);
    return (_jsxs(_Fragment, { children: [_jsx(MapPolyline, { points: applyPoints, cursor: canEditShape ? "pointer" : undefined, pathOptions: {
                    color: lineColor,
                    weight: lineWidth,
                }, onClick: onLineClick }), canEditShape && (points.map((position, index) => (_jsxs(React.Fragment, { children: [_jsx(Marker, { position: position, icon: index === focusedPointIndex
                            ? connectionIconFocused.iconMap
                            : connectionIcon.iconMap, draggable: true, zIndexOffset: 1, eventHandlers: {
                            click: () => handleConnectionClick(index),
                            drag: event => handleConnectionDrag(index, event.target.getLatLng()),
                            dragend: handleConnectionDragEnd,
                        } }), _jsx(Box, { hidden // Render this too but hidden, because we need the classes of it!
                        : true, children: index === focusedPointIndex
                            ? connectionIconFocused.iconJSXElement
                            : connectionIcon.iconJSXElement })] }, index)))), canEditShape && (breakPoints
                .map((position, index) => (_jsxs(React.Fragment, { children: [_jsx(Marker, { position: position, icon: breakIcon.iconMap, draggable: true, zIndexOffset: 1, eventHandlers: {
                            dragstart: () => handleBreakDragStart(index),
                            drag: event => handleBreakDrag(index, event.target.getLatLng()),
                            dragend: handleBreakDragEnd,
                        } }), _jsx(Box, { hidden // Render this too but hidden, because we need the classes of it!
                        : true, children: breakIcon.iconJSXElement })] }, index)))), _jsx(Marker, { position: points[0], icon: directionStartIcon.iconMap, zIndexOffset: 0 }), _jsx(Box, { hidden // Render this too but hidden, because we need the classes of it!
                : true, children: directionStartIcon.iconJSXElement }), _jsx(Marker, { position: points[points.length - 1], icon: directionEndIcon.iconMap, zIndexOffset: 0 }), _jsx(Box, { hidden // Render this too but hidden, because we need the classes of it!
                : true, children: directionEndIcon.iconJSXElement })] }));
};
const createLineIcon = ({ Icon, cursor, title, color, hover, focused, size, opacity, rotateDeg, }) => {
    const padding = 4; // Padding makes easier to catch the marker.
    const iconJSXElement = (_jsx(Box, { title: title, sx: {
            position: 'absolute',
            top: -(size / 2) - 1 - padding,
            left: -(size / 2) - padding,
            cursor,
            opacity,
            padding: `${padding}px`,
            ':hover': hover
                ? { opacity: 0.5 }
                : undefined,
        }, children: _jsx(IconViewer, { sxSvg: {
                transform: rotateDeg ? `rotate(${rotateDeg}deg)` : undefined,
                color: focused ? "red" : color,
            }, Icon: Icon, width: size }) }));
    return {
        iconJSXElement,
        iconMap: customIconMarkerByJSXElement(iconJSXElement),
    };
};
