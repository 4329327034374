import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ErrorBanner } from "../ErrorBanner";
import { useLoadExternalDependencies, } from "../useLoadExternalDependencies";
import { LabelOverlayDataProgress, EProgressType, } from "../LabelOverlayDataProgress";
/**
 * Load external dependencies like `<style type="text/css" href="...">` & `<script src="...">`
 * only when they are rendered.
 *
 * Once loaded, they stay in the DOM, are not removed on unmount.
 *
 * Dependencies can't be re-imported in the DOM, so this component can safely be used with the same
 * dependencies from multiple other components that are used on the same time or not.
 */
export const LoadExternalDependencies = ({ dependencies, fullHeightProgress, loadingMessage = "loading...", children, }) => {
    const { isLoading, errors, } = useLoadExternalDependencies(dependencies);
    return (_jsxs(_Fragment, { children: [_jsx(LabelOverlayDataProgress, { show: isLoading, type: EProgressType.IS_LOADING_ANIMATION, fullHeight: fullHeightProgress, title: loadingMessage }), !isLoading && !!errors.length && (_jsxs("div", { children: [_jsx("h1", { children: "Loading dependencies failed" }), errors.map((error, index) => _jsx(ErrorBanner, { error: error }, index))] })), !isLoading && !errors.length && children] }));
};
