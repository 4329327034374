import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { guid } from "dyna-guid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { Box } from "../Box";
import { FlexContainerHorizontal, FlexItemMax, FlexItemMin, } from "../FlexContainer";
import { HelperText, EHelperTextType, } from "../HelperText";
import { getDataComponentName, } from "../ui-interfaces";
import { useTheme, } from "../ThemeProvider";
import { ECSSDuration, sxTransition, } from "../sxTransition";
export const InputSlider = (props) => {
    const { sx = {}, dataComponentName, name = "", label, ariaLabel = label || "Slider", disabled = false, readonly = false, required = false, step = 1, min, max, marks, value, getAriaValueLabel, getValueLabel, labelWidth, valueLabelDisplay, valueLabelFormat, helperLabel, validationError, onChange, } = props;
    const id = useMemo(guid, []);
    const theme = useTheme();
    const handleChange = (_event, v) => {
        if (readonly)
            return;
        if (disabled)
            return;
        const values = Array.isArray(v) ? v : [v];
        for (const value of values)
            onChange(value, name);
    };
    return (_jsxs(Box, { sx: Object.assign({ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }, sx), dataComponentName: getDataComponentName(dataComponentName, "InputSlider"), children: [_jsxs(Typography, { id: id, "aria-label": ariaLabel, gutterBottom: true, children: [label, _jsx(Box, { sx: {
                            color: theme.palette.error.main,
                            position: "relative",
                            top: -3,
                        }, show: required, component: "span", children: "*" })] }), _jsxs(FlexContainerHorizontal, { children: [_jsx(FlexItemMax, { children: _jsx(Slider, { sx: {
                                transition: sxTransition(theme, 'opacity', ECSSDuration.SHORTEST),
                                opacity: readonly ? 0.6 : undefined,
                            }, "aria-labelledby": id, getAriaValueText: getAriaValueLabel, disabled: disabled, step: step, min: min, max: max, marks: marks, value: value, valueLabelDisplay: valueLabelDisplay, valueLabelFormat: valueLabelFormat, onChange: handleChange }) }), _jsx(FlexItemMin, { sx: {
                            whiteSpace: "nowrap",
                            paddingLeft: theme.spacing(2),
                            minWidth: labelWidth,
                            textAlign: 'right',
                        }, children: getValueLabel(value) })] }), _jsx(HelperText, { children: helperLabel }), _jsx(HelperText, { type: EHelperTextType.ERROR, children: validationError })] }));
};
