import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
export const getDefaultLocation = () => getDefaultDBEntityDocument({
    order: -1,
    showOnMap: true,
    maintenanceMode: false,
    regionId: '',
    masterDeviceId: '',
    label: '',
    description: '',
    geoPosition: {
        lat: -1,
        lng: -1,
        alt: 0,
    },
    notes: '',
});
