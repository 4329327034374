import { dynaError, } from "dyna-error";
import { hashMd5 } from "../hash-md5";
/**
 * Aggregates multiple calls and resolves them with the latest result.
 *
 * Example:
 *
 * ```js
 * // Before
 * export const apiLoadUsers = (args: ILoadUsers): Promise<IUsers> => //...
 *
 * // After
 * const promisesAggregator = new PromisesAggregator({
 *   onCall: (args: ILoadUsers): Promise<IUsers> => //...
 * });
 *
 * export const apiLoadUsers = promisesAggregator.exec;
 * ```
 */
export class PromisesAggregator {
    constructor(config) {
        this.config = config;
        this.calls = {};
        this.loads = {};
        /**
         * Execute the onCall promise
         */
        this.call = (args) => {
            return new Promise((resolve, reject) => {
                const callId = this.getIdByArgs(args);
                if (!this.calls[callId])
                    this.calls[callId] = [];
                this.calls[callId].push({
                    resolve,
                    reject,
                });
                if (!this.loads[callId]) {
                    this.loads[callId] = {
                        isLoading: true,
                        response: null,
                        error: null,
                    };
                    this.config
                        .onCall(args)
                        .then((response) => {
                        if (!this.loads[callId])
                            return; // It is cleared
                        this.loads[callId].isLoading = false;
                        this.loads[callId].response = response;
                        this.fulfill(callId);
                    })
                        .catch((error) => {
                        if (!this.loads[callId])
                            return; // It is cleared
                        this.loads[callId].isLoading = false;
                        this.loads[callId].error = error;
                        this.fulfill(callId);
                    });
                }
                this.fulfill(callId);
            });
        };
        /**
         * Clear the "cached" fulfill for these arguments
         */
        this.clear = (args) => {
            const callId = this.getIdByArgs(args);
            const load = this.loads[callId];
            if (!load)
                return;
            load.error = dynaError({
                code: 20240104093329,
                message: "PromisesAggregator: Promise cleared",
            });
            load.isLoading = false;
            this.fulfill(callId);
            delete this.loads[callId];
        };
    }
    fulfill(callId) {
        const load = this.loads[callId];
        if (!load)
            return;
        if (load.isLoading)
            return;
        const calls = this.calls[callId];
        if (!calls)
            return;
        while (calls.length) {
            const call = calls.shift();
            if (!call)
                return;
            if (load.error)
                call.reject(load.error);
            else
                call.resolve(load.response);
        }
    }
    getIdByArgs(args) {
        return hashMd5(args);
    }
}
