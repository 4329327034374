import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
import { getDefaultAppItisCodes } from "./getDefaultAppItisCodes";
import { closureTypePresetValues } from "./closureTypePresetValues";
export var EWZTXMode;
(function (EWZTXMode) {
    EWZTXMode["CONT"] = "CONT";
    EWZTXMode["ALT"] = "ALT";
})(EWZTXMode || (EWZTXMode = {}));
export var EWZClosureSelectionMode;
(function (EWZClosureSelectionMode) {
    EWZClosureSelectionMode["CUSTOM"] = "CUSTOM";
    EWZClosureSelectionMode["SIMPLE"] = "SIMPLE";
})(EWZClosureSelectionMode || (EWZClosureSelectionMode = {}));
export const getDefaultWorkzoneAppSettings = () => getDefaultDBEntityDocument({
    deviceId: '',
    serviceChannel: "172",
    psid: '8002',
    txmode: EWZTXMode.CONT,
    intervalInMs: 1000,
    priority: 4,
    useSignature: false,
    useEncryption: false,
    closureSelectionMode: EWZClosureSelectionMode.SIMPLE,
    itisCodes: getDefaultAppItisCodes(),
});
export const getRemovedSimpleClosureTypeItisCodes = (itisCodes) => {
    const closureTypeRemovedItisCodes = [];
    const codesList = itisCodes.map(itisCode => (itisCode.code));
    Object.keys(closureTypePresetValues)
        .forEach((_key) => {
        var _a;
        const key = _key;
        const newRecord = {
            closureType: key,
            removedItisCodes: [],
        };
        (_a = closureTypePresetValues[key]
            .itisCodes) === null || _a === void 0 ? void 0 : _a.forEach((cti) => {
            const exists = codesList.includes(cti);
            if (exists)
                return;
            newRecord.removedItisCodes.push(cti);
        });
        closureTypeRemovedItisCodes.push(newRecord);
    });
    return closureTypeRemovedItisCodes.filter(closureType => closureType.removedItisCodes.length > 0);
};
