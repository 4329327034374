const successValidationResult = {
    isValid: true,
    dataValidation: {},
    messages: [],
};
export const validationMergeResults = (resultA = successValidationResult, resultB = successValidationResult) => {
    return {
        isValid: resultA.isValid && resultB.isValid,
        dataValidation: [
            ...Object.keys(resultA.dataValidation),
            ...Object.keys(resultB.dataValidation),
        ]
            .reduce((acc, key) => {
            if (!acc.includes(key))
                acc.push(key);
            return acc;
        }, [])
            .reduce((acc, key) => {
            const r1 = resultA.dataValidation[key];
            const r2 = resultB.dataValidation[key];
            acc[key] = [r1, r2].filter(Boolean).join(' ');
            return acc;
        }, {}),
        messages: [
            ...resultA.messages,
            ...resultB.messages,
        ],
        customValidation: Object.assign(Object.assign({}, resultA.customValidation || {}), resultB.customValidation || {}),
    };
};
