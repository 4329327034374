import { EWZHeading, } from "./IWorkzone";
import { validateDataMethods } from "utils-library/dist/validation-engine";
export const validateWorkzoneElements = {
    isValidGeoPoint: (point) => {
        if (!point)
            return 'Required';
        const validationErrorGeo = validateDataMethods.isGeoPosition(point);
        if (validationErrorGeo)
            return validationErrorGeo;
        if (point.alt !== undefined) {
            const validationErrorAlt = validateDataMethods.isNumber(point.alt);
            if (validationErrorAlt)
                return validationErrorAlt;
        }
        return "";
    },
    isValidPoints: (points) => {
        let error = "";
        if (!Array.isArray(points))
            return 'points array is not array';
        points.forEach((point, index) => {
            if (error)
                return;
            const validationResult = validateWorkzoneElements.isValidGeoPoint(point);
            if (validationResult)
                error = `Point no [${index + 1}]: ${validationResult}`;
        });
        return error;
    },
    isValidGeoLine: (geoLine) => {
        if (!geoLine)
            return 'Required';
        if (!geoLine.points)
            return 'points array is missing';
        if (!Array.isArray(geoLine.points))
            return 'points array is not array';
        return validateWorkzoneElements.isValidPoints(geoLine.points);
    },
    isValidGeoPolygon: (polygon) => {
        if (!polygon)
            return 'Required';
        const validationErrorPoints = validateWorkzoneElements.isValidPoints(polygon.points);
        if (validationErrorPoints)
            return validationErrorPoints;
        if (polygon.points.length <= 3)
            return 'Not enough points for a polygon';
        if (polygon.points.length) {
            const start = polygon.points[0];
            const end = polygon.points[polygon.points.length - 1];
            if (start.lat !== end.lat
                || start.lng !== end.lng
                || start.alt !== end.alt)
                return "The last point should match the start point to close the polygon";
        }
        return "";
    },
    isValidGeoAreaPolygon: (polygon) => {
        const validationErrorGeoPolygon = validateWorkzoneElements.isValidGeoPolygon(polygon);
        if (validationErrorGeoPolygon)
            return validationErrorGeoPolygon;
        const uniqueLat = [];
        const uniqueLng = [];
        polygon.points.forEach(point => {
            if (uniqueLat.indexOf(point.lat) === -1)
                uniqueLat.push(point.lat);
            if (uniqueLng.indexOf(point.lng) === -1)
                uniqueLng.push(point.lng);
        });
        if (uniqueLng.length === 1)
            return 'This polygon is a line, not a shape';
        return "";
    },
    isValidHeading: (heading) => {
        if (!heading)
            return 'Required';
        return validateDataMethods.isEnumValue(heading, EWZHeading);
    },
    isValidHeadings: (headings) => {
        let error = "";
        if (!Array.isArray(headings))
            return 'Headings array is not an array';
        headings.forEach((heading, index) => {
            if (error)
                return;
            const validationErrorHeading = validateWorkzoneElements.isValidHeading(heading);
            if (validationErrorHeading)
                error = `No Heading [${index + 1}]: ${validationErrorHeading}`;
        });
        return error;
    },
};
