import {
  useState,
  useEffect,
} from "react";

import {connect} from "react-dynadux";

import {
  EWorkZonesManagementRights,
  IWorkzonesAppSettings,
  getDefaultWorkzoneAppSettings,
} from "mhc-server/dist/interfaces";

import {IAppStore} from "../../../../state/IAppStore";

import {
  IUIWorkzone,
  getDefaultUIWorkzone,
  convertIUIWorkzoneToIWorkzone,
  convertIWorkzoneToIUIWorkzone,
} from "./IUIWorkzone";

import {
  Alert,
  EAlertType,
} from "mhc-ui-components/dist/Alert";
import {PromptRouteChange} from "mhc-ui-components/dist/PromptRouteChange";
import {
  FlexContainerVertical,
  FlexItemMin,
  FlexItemMax,
} from "mhc-ui-components/dist/FlexContainer";
import {Box} from "mhc-ui-components/dist/Box";
import {IsLoading} from "mhc-ui-components/dist/IsLoading";
import {ErrorBanner} from "mhc-ui-components/dist/ErrorBanner";
import {
  Tabs,
  ETabVariant,
  ETabSize,
  ETabIconPosition,
} from "mhc-ui-components/dist/Tabs";
import {useLoadData} from "mhc-ui-components/dist/useLoadData";
import {
  useForm,
  EFormType,
} from "mhc-ui-components/dist/useForm";

import {routeWorkZonesList} from "../../routes/routeWorkZonesList";
import {routeWorkzoneCreatePaths} from "../../routes/routeWorkzoneCreate.paths";
import {routeWorkZonesEditPaths} from "../../routes/routeWorkzoneEdit.paths";

import {OfflineInfo} from "../OfflineInfo/OfflineInfo";
import {NotFound404Page} from "../../../application/pages/NotFound404Page";
import {WorkzoneToolbar} from "./components/WorkzoneToolbar/WorkzoneToolbar";
import {WorkzoneFormGeneral} from "./components/tab-general/WorkzoneFormGeneral";
import {WorkzoneFormMapView} from "./components/tab-map/tab-view/WorkzoneFormMapView";
import {WorkzoneFormReferencePoint} from "./components/tab-map/tab-rerefrence-point/WorkzoneFormReferencePoint";
import {WorkzoneFormPathSelection} from "./components/tab-map/tab-path/WorkzoneFormPathSelection";
import {WorkzoneFormHeading} from "./components/tab-map/tab-heading/WorkzoneFormHeading";
import {WorkzoneFormBroadcastAreaSelection} from "./components/tab-map/tab-broadcast/WorkzoneFormBroadcastAreaSelection";
import {WorkzoneFormBroadcastAction} from "./components/tab-broadcast/WorkzoneFormBroadcastAction";
import {WorkzoneNotes} from "./components/tab-notes/WorkzoneNotes";

import {
  IOfflineInfo,
  EOfflineStatus,
} from "../../api/interfaces";
import {apiWorkzoneAppSettingsLoad} from "../../api/apiWorkzoneAppSettingsLoad";
import {apiWorkzoneItemPost} from "../../api/apiWorkzoneItemPost";
import {apiWorkzoneItemGet} from "../../api/apiWorkzoneItemGet";
import {apiWorkzoneItemPut} from "../../api/apiWorkzoneItemPut";
import {apiWorkzoneItemDelete} from "../../api/apiWorkzoneItemDelete";
import {apiWorkzoneItemUndelete} from "../../api/apiWorkzoneItemUndelete";

import {getValidationErrorByProps} from "./utils/getValidationErrorByProps";

import {createIcon} from "mhc-ui-components/dist/IconComponent";
import GeneralIcon from '@mui/icons-material/LineWeight';
import PointIcon from '@mui/icons-material/Room';
import ExploreIcon from '@mui/icons-material/Explore';
import PathIcon from '@mui/icons-material/AddLocation';
import MapIcon from '@mui/icons-material/Map';
import MapViewIcon from '@mui/icons-material/Map';
import BroadcastSelection from '@mui/icons-material/ZoomOutMap';
import BroadcastAction from '@mui/icons-material/RssFeed';
import NotesIcon from '@mui/icons-material/Note';

export interface IWorkzoneFormProps {
  store: IAppStore;
  id?: string;
  allowBroadcast: boolean;
  tabRoot?: ECIMTabRoot;
  tabMap?: ECIMTabMap;
  performAction?:
    | 'validate'
    | 'broadcast';
}

export enum ECIMTabRoot {
  GENERAL = "general",
  MAP = "map",
  BROADCAST = "broadcast",
  NOTES = "notes",

}

export enum ECIMTabMap {
  VIEW = "view",
  POINT = "point",
  PATH = "path",
  HEADING = "heading",
  BROADCAST_AREA = "broadcast-area",
}

export const WorkzoneForm = connect((props: IWorkzoneFormProps): JSX.Element | null => {
  const {
    store: {
      app: {
        state: {
          online,
          companyId,
        },
        actions: {
          disableThemeChange,
          navigateTo,
        },
      },
      userAuth: {
        state: {user: {id: userId}},
        utils: {userHasAllRights},
      },
      workzonesManagement: {actions: {refreshOfflineItemsCounter}},
    },
    id,
    allowBroadcast,
    tabRoot: userTabRoot = ECIMTabRoot.GENERAL,
    tabMap: userTabMap = ECIMTabMap.VIEW,
    performAction,
  } = props;

  const userCanModify = userHasAllRights([EWorkZonesManagementRights.WORKZONES_EDIT]);

  const {
    data: workzoneAppSettings,
    error: workzoneAppSettingLoadError,
    load: reloadworkzoneAppSettings,
  } = useLoadData<IWorkzonesAppSettings>({
    defaultData: {...getDefaultWorkzoneAppSettings()},
    errorHandling: {
      consoleMessage: 'Cannot load itisCodes',
      userMessage: 'Error initializing\nCannot load ITIS codes',
    },
    load: async () => {
      const response = await apiWorkzoneAppSettingsLoad(companyId, userId);
      return response.data;
    },
  });

  const {
    useFormApi,
    isNew,
    isChanged,
    isLoading,
    alertViewer,
    confirmViewer,
    loadFatalError,
    validationResult: {dataValidation: validationErrors},
    data: {id: dataId},
    formProps,
    save,
  } = useForm<IUIWorkzone, string>({
    formType: EFormType.VIEW_EDIT,
    loadDataId: id || null,
    emptyFormData: {
      ...getDefaultUIWorkzone(),
      closureSelectionMode: workzoneAppSettings.closureSelectionMode,
    },
    userCanEdit: userCanModify,
    userCanDelete: userCanModify,
    userCanUnDelete: userCanModify,

    disabledEditOnDeleted: true,

    onApiPost: async (workzone) => {
      const response = await apiWorkzoneItemPost(companyId, userId, convertIUIWorkzoneToIWorkzone(workzone));
      setOfflineInfo(response.offlineInfo);
      if (!online) refreshOfflineItemsCounter();
      const uiWorkzone = convertIWorkzoneToIUIWorkzone(response.data);
      navigateTo({
        url: routeWorkZonesEditPaths.getRoutePath({
          id: uiWorkzone.id,
          tabRoot,
          tabMap,
        }),
      });
      return {
        dataId: uiWorkzone.id,
        data: uiWorkzone,
      };
    },
    onApiGet: async (id) => {
      const result = await apiWorkzoneItemGet(companyId, userId, id);
      setOfflineInfo(result.offlineInfo);
      if (!online) refreshOfflineItemsCounter();
      return convertIWorkzoneToIUIWorkzone(result.data);
    },
    onApiPut: async (workzone) => {
      const result = await apiWorkzoneItemPut(companyId, userId, convertIUIWorkzoneToIWorkzone(workzone));
      setOfflineInfo(result.offlineInfo);
      if (!online) refreshOfflineItemsCounter();
      return convertIWorkzoneToIUIWorkzone(result.data);
    },
    onApiDelete: async (id) => {
      const result = await apiWorkzoneItemDelete(companyId, userId, id);
      if (!online) refreshOfflineItemsCounter();
      setOfflineInfo(result.offlineInfo);
      return convertIWorkzoneToIUIWorkzone(result.data);
    },
    onApiUndelete: async (id) => {
      const result = await apiWorkzoneItemUndelete(companyId, userId, id);
      if (!online) refreshOfflineItemsCounter();
      setOfflineInfo(result.offlineInfo);
      return convertIWorkzoneToIUIWorkzone(result.data);
    },

    onBeforeFormSave: (workzone) => {
      setOfflineInfo({
        status: EOfflineStatus.ACTUAL_VERSION,
        userMessage: '',
      });

      // Close the broadcast region is not closed
      const broadcastRegion = workzone.broadcast.selectionByRegion.region;
      if (
        broadcastRegion.points.length > 2
        && broadcastRegion.points[0].lat !== broadcastRegion.points[broadcastRegion.points.length - 1].lat
        && broadcastRegion.points[0].lng !== broadcastRegion.points[broadcastRegion.points.length - 1].lng
      ) {
        broadcastRegion.points = broadcastRegion.points.concat({...broadcastRegion.points[0]});
      }
      return workzone;
    },
    onFormSave: () => {
      reloadworkzoneAppSettings();
    },
    onFormCancel: () => {
      if (isNew) navigateTo({url: routeWorkZonesList.getRoutePath({})});
    },
  });

  const [tabRoot, setTabRoot] = useState<ECIMTabRoot>(userTabRoot);
  const [tabMap, setTabMap] = useState<ECIMTabMap>(userTabMap);
  const [offlineInfo, setOfflineInfo] = useState<IOfflineInfo>({
    status: EOfflineStatus.ACTUAL_VERSION,
    userMessage: '',
  });

  useEffect(() => {
    setTabRoot(userTabRoot);
    setTabMap(userTabMap);
  }, [
    userTabRoot,
    userTabMap,
  ]);

  useEffect(() => {
    disableThemeChange(tabRoot === ECIMTabRoot.MAP);
    return () => disableThemeChange(false);
  }, [tabRoot]);

  const handleGoToReferenceTab = (): void => setTabMap(ECIMTabMap.POINT);

  if (workzoneAppSettingLoadError) return <ErrorBanner error={workzoneAppSettingLoadError}/>;
  if (loadFatalError) return <ErrorBanner error={loadFatalError}/>;

  const tabGeneralValidationError = getValidationErrorByProps(
    validationErrors,
    [
      'name',
      'active',
      'itisCodes',
      'start',
      'end',
      'closedLane',
      'closureType',
      'closedShoulder',
      'closedLaneWidthInFeet',
      'workersPresent',
    ],
  );

  const tabMapsValidationError = getValidationErrorByProps(
    validationErrors,
    [
      'point',
      'path',
      'broadcast.selectionByRegion.region',
      'broadcast.selectionByRadius.radiusInMeters',
    ],
  );

  const tabReferencePointValidationError = getValidationErrorByProps(
    validationErrors,
    [
      'point',
    ],
  );

  const tabPathPointValidationError = getValidationErrorByProps(
    validationErrors,
    [
      'path',
    ],
  );

  const tabHeadingValidationError = getValidationErrorByProps(
    validationErrors,
    [
      'heading',
    ],
  );

  const tabBroadcastAreaSelectionValidationError = getValidationErrorByProps(
    validationErrors,
    [
      'broadcast.selectionByRegion.region',
      'broadcast.selectionByRadius.radiusInMeters',
    ],
  );

  const navigateByTabs = (tabRoot: ECIMTabRoot, tabMap?: ECIMTabMap): void => {
    const pathParams = {
      id: dataId,
      tabRoot,
      tabMap,
    };
    isNew
      ? navigateTo({url: routeWorkzoneCreatePaths.getRoutePath(pathParams)})
      : navigateTo({url: routeWorkZonesEditPaths.getRoutePath(pathParams)});
  };

  const handleTabRootNavigation = (tab: ECIMTabRoot) => {
    setTabRoot(tab);
    navigateByTabs(tab, id === ECIMTabRoot.MAP ? ECIMTabMap.VIEW : undefined);
  };

  const handleTabMapNavigation = (tab: ECIMTabMap) => {
    setTabMap(tab);
    navigateByTabs(tabRoot, tab);
  };

  const handleNewSaveAndValidate = async (): Promise<void> => {
    const cim = await save();
    navigateTo({
      url: routeWorkZonesEditPaths.getRoutePath(
        {
          id: cim.id,
          tabRoot: ECIMTabRoot.BROADCAST,
          performAction: 'validate',
        },
      ),
    });

  };
  const handleNewSaveAndBroadcast = async (): Promise<void> => {
    const cim = await save();
    navigateTo({
      url: routeWorkZonesEditPaths.getRoutePath({
        id: cim.id,
        tabRoot: ECIMTabRoot.BROADCAST,
        performAction: 'broadcast',
      }),
    });
  };

  if (!Object.values(ECIMTabRoot).includes(tabRoot)) {
    return (
      <NotFound404Page
        info={`CIM form: Unknown Main main tab: [${tabRoot}]`}
        description="Most likely, the link is broken or not supported anymore."
      />
    );
  }
  if (tabRoot === ECIMTabRoot.MAP && tabMap && !Object.values(ECIMTabMap).includes(tabMap)) {
    return (
      <NotFound404Page
        info={`CIM form: Unknown Map tab: [${tabMap}]`}
        description="Most likely, the link is broken or not supported anymore."
      />
    );
  }

  return (
    <Box
      dataComponentName="CIMInternalForm"
      fullHeight
      sx={{
        maxWidth: 900,
        margin: 'auto',
      }}
    >
      <PromptRouteChange
        active={isChanged}
        getPrompt={(location) => {
          const isCreatePath = location.pathname.startsWith(routeWorkzoneCreatePaths.getRoutePath({}));
          const isEditPath = id && location.pathname.startsWith(routeWorkZonesEditPaths.getRoutePath({id}));
          const isCimFormPath = isCreatePath || isEditPath;
          if (!isCimFormPath) {
            return {
              title: 'You have unsaved changes',
              message: 'Are you sure you want to leave?',
            };
          }
          return null;
        }}
      />
      <form
        style={{height: '100%'}}
        {...formProps}
      >
        <FlexContainerVertical fullHeight>
          <FlexItemMin>
            {confirmViewer}
            <WorkzoneToolbar
              workzoneAppSettings={workzoneAppSettings}
              useFormApi={useFormApi}
            />
            <Alert
              type={EAlertType.WARNING}
              show={!isLoading && offlineInfo.status !== EOfflineStatus.ACTUAL_VERSION}
              title="Offline warning"
            >
              <OfflineInfo
                offlineInfo={offlineInfo}
              />
            </Alert>
            {offlineInfo.status === EOfflineStatus.ACTUAL_VERSION && alertViewer}
          </FlexItemMin>

          <FlexItemMax fullHeight>
            <IsLoading
              fullHeight
              isLoading={isLoading}
            >
              <Tabs<ECIMTabRoot>
                ariaLabel="CIM main tabs"
                variant={ETabVariant.FULL_WIDTH}
                tabSize={ETabSize.MEDIUM}
                iconPosition={ETabIconPosition.LEFT}
                hideLabelsOnMobile
                hideLabelsOnTablet
                fullHeight
                tab={tabRoot}
                onChange={handleTabRootNavigation}
                tabs={{
                  [ECIMTabRoot.GENERAL]: {
                    label: 'General',
                    ariaLabel: 'General',
                    Icon: createIcon.byMuiIcon(GeneralIcon),
                    validationError: tabGeneralValidationError,
                    content:
                      <WorkzoneFormGeneral
                        workzoneAppSettings={workzoneAppSettings}
                        useFormApi={useFormApi}
                      />,
                  },
                  [ECIMTabRoot.MAP]: {
                    label: 'Map',
                    ariaLabel: 'Map',
                    Icon: createIcon.byMuiIcon(MapIcon),
                    validationError: tabMapsValidationError,
                    content:
                      <Tabs<ECIMTabMap>
                        ariaLabel="CIMs map tabs"
                        variant={ETabVariant.FULL_WIDTH}
                        tabSize={ETabSize.SMALL}
                        iconPosition={ETabIconPosition.TOP}
                        hideLabelsOnMobile
                        hideLabelsOnTablet
                        fullHeight
                        tab={tabMap}
                        onChange={handleTabMapNavigation}
                        tabs={{
                          [ECIMTabMap.VIEW]: {
                            label: 'View',
                            ariaLabel: 'View',
                            Icon: createIcon.byMuiIcon(MapViewIcon),
                            content: <WorkzoneFormMapView useFormApi={useFormApi}/>,
                          },
                          [ECIMTabMap.POINT]: {
                            label: 'Reference Point',
                            ariaLabel: 'Reference Point',
                            Icon: createIcon.byMuiIcon(PointIcon),
                            validationError: tabReferencePointValidationError,
                            content:
                              <WorkzoneFormReferencePoint useFormApi={useFormApi}/>,
                          },
                          [ECIMTabMap.PATH]: {
                            label: 'Path',
                            ariaLabel: 'Path',
                            Icon: createIcon.byMuiIcon(PathIcon),
                            validationError: tabPathPointValidationError,
                            content: <WorkzoneFormPathSelection useFormApi={useFormApi}/>,
                          },
                          [ECIMTabMap.HEADING]: {
                            label: 'Heading',
                            ariaLabel: 'Heading',
                            Icon: createIcon.byMuiIcon(ExploreIcon),
                            validationError: tabHeadingValidationError,
                            content:
                              <WorkzoneFormHeading useFormApi={useFormApi}/>,
                          },
                          [ECIMTabMap.BROADCAST_AREA]: {
                            label: 'Broadcast area',
                            ariaLabel: 'Broadcast area',
                            Icon: createIcon.byMuiIcon(BroadcastSelection),
                            validationError: tabBroadcastAreaSelectionValidationError,
                            content: (
                              <WorkzoneFormBroadcastAreaSelection
                                useFormApi={useFormApi}
                                onGoToReferencePointTab={handleGoToReferenceTab}
                              />
                            ),
                          },
                        }}
                      />,
                  },
                  [ECIMTabRoot.BROADCAST]: {
                    label: 'Broadcast',
                    ariaLabel: 'Broadcast',
                    Icon: createIcon.byMuiIcon(BroadcastAction),
                    content:
                      <WorkzoneFormBroadcastAction
                        useFormApi={useFormApi}
                        allowBroadcast={allowBroadcast}
                        performAction={performAction}
                        onNewSaveAndValidate={handleNewSaveAndValidate}
                        onNewSaveAndBroadcast={handleNewSaveAndBroadcast}
                      />,
                  },
                  [ECIMTabRoot.NOTES]: {
                    label: 'Notes',
                    ariaLabel: 'Notes',
                    Icon: createIcon.byMuiIcon(NotesIcon),
                    content: <WorkzoneNotes useFormApi={useFormApi}/>,
                  },
                }}
              />
            </IsLoading>
          </FlexItemMax>
        </FlexContainerVertical>
      </form>
    </Box>
  );
});
