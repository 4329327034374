import { EDeviceType, } from "../interfaces/EDeviceType";
import { DemoConnectITSLocationHub } from "./DemoConnectITSLocationHub";
import { DemoSnowSensor } from "./DemoSnowSensor";
import { DemoSpeedCamera } from "./DemoSpeedCamera";
import { ConnectITSLocationHub } from "./ConnectITSLocationHub";
import { TrafficVehiclesCounterMHCDriver } from "./TrafficVehiclesCounterMHCDriver";
export * from "./client";
export const deviceDrivers = {
    [EDeviceType.UNKNOWN]: undefined,
    [EDeviceType.CONNECT_ITS_LOCATION_HUB]: ConnectITSLocationHub,
    [EDeviceType.DEMO_CONNECT_ITS_LOCATION_HUB]: DemoConnectITSLocationHub,
    [EDeviceType.DEMO_SNOW_SENSOR]: DemoSnowSensor,
    [EDeviceType.DEMO_SPEED_CAMERA]: DemoSpeedCamera,
    [EDeviceType.MHC_TRAFFIC_VEHICLES_COUNTER]: TrafficVehiclesCounterMHCDriver,
};
