import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {ILanePlanningPageProps} from "./LanePlanningPage";

const LanePlanningPageLazy = lazy(() => import("./LanePlanningPage"));

export const LanePlanningPage = (props: ILanePlanningPageProps) => (
  <LazyLoadComponent>
    <LanePlanningPageLazy {...props}/>
  </LazyLoadComponent>
);
