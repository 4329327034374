import {Typography} from "mhc-ui-components/dist/Typography";
import {GoBackHomeButtons} from "../components/GoBackHomeButtons";

export interface INotFound404PageProps {
  info?: string;
  description?: string;
}

export const NotFound404Page = (
  {
    info,
    description,
  }: INotFound404PageProps,
): JSX.Element => {
  return (
    <div>
      <Typography v="h1">404 not found</Typography>
      <h2>{info}</h2>
      <p>{description}</p>
      <GoBackHomeButtons/>
    </div>
  );
};
