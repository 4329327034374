import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, } from "react";
import { Box } from "../Box";
export const Ellipsis = () => {
    const [dots, setDots] = useState('');
    useEffect(() => {
        const intervalId = setInterval(() => {
            setDots(prevDots => prevDots === '...'
                ? ''
                : prevDots + '.');
        }, 250);
        return () => clearInterval(intervalId);
    }, []);
    return (_jsx(Box, { sx: {
            display: 'inline-block',
            textAlign: 'left',
            width: theme => theme.typography.fontSize * 3 / 2,
        }, children: dots }));
};
