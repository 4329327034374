import { jsx as _jsx } from "react/jsx-runtime";
import Grid from "@mui/material/Grid";
import { getDataComponentName, } from "../ui-interfaces";
import { sxTransition, ECSSDuration, } from "../sxTransition";
export const GridItem = (props) => {
    const { sx = {}, dataComponentName, show = true, hidden = false, noSpacing = false, mobile = 12, tablet = mobile || 12, laptop = tablet || 12, desktop = laptop || 12, wide = desktop || 12, children = null, } = props;
    if (!show)
        return null;
    return (_jsx(Grid, { sx: Object.assign(Object.assign(Object.assign({}, (hidden
            ? {
                position: 'absolute',
                left: -100000,
            }
            : {})), { padding: noSpacing
                ? 0
                : undefined, transition: theme => sxTransition(theme, "padding", ECSSDuration.SHORT) }), sx), "data-component-name": getDataComponentName(dataComponentName, "GridItem"), item: true, xs: mobile, sm: tablet, md: laptop, lg: desktop, xl: wide, children: children }));
};
