import * as React from "react";
import {connect} from "react-dynadux";

import {ButtonBar} from "mhc-ui-components/dist/ButtonBar";
import {
  Button,
  EButtonSize,
} from "mhc-ui-components/dist/Button";
import {IAppStore} from "../../../state/IAppStore";

import {routeHomePage} from "../routes/routeHomePage";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HomeIcon from "@mui/icons-material/Home";

export interface IGoHomeButtonProps {
  store: IAppStore;
}


type TWithoutStoreProp<TProps> = Omit<TProps, 'store'>;

type TConnectedComponent<TProps> =
  React.FunctionComponent<TWithoutStoreProp<TProps>> |
  React.ComponentClass<TWithoutStoreProp<TProps>>;


export const GoBackHomeButtons = connect((props: IGoHomeButtonProps): React.JSX.Element => {
  const {store: {app: {actions: {navigateTo}}}} = props;

  return (
    <ButtonBar>
      <Button
        icon={<ArrowBackIosIcon/>}
        size={EButtonSize.LARGE}
        onClick={() => navigateTo({url: '<'})}
      >
        Go Back
      </Button>
      <Button
        icon={<HomeIcon/>}
        size={EButtonSize.LARGE}
        onClick={() => navigateTo({url: routeHomePage.getRoutePath()})}
      >
        Go Home
      </Button>
    </ButtonBar>
  );
}) as TConnectedComponent<IGoHomeButtonProps>;
