import { searchTextEngine } from "./searchTextEngine";
import { getValuesFromObjectAsStrings } from "./getValuesFromObjectAsStrings";
/**
 * Searches for a specified set of words within a given object values (in deep mode).
 * This method doesn't use the property names of the object.
 */
export const searchTextObjectEngine = ({ searchText, object, convertNumberToString = value => String(value), convertDateToString = date => date.toString(), }) => {
    return searchTextEngine({
        searchText: searchText
            .toLowerCase(),
        sourceText: getValuesFromObjectAsStrings({
            object,
            convertNumberToString,
            convertDateToString,
        })
            .join(' '),
    });
};
