import { jsx as _jsx } from "react/jsx-runtime";
import { getDataComponentName, } from "../ui-interfaces";
import { ContainerDetectResize } from "../ContainerDetectResize";
import { FlexContainerVertical } from "./FlexContainerVertical";
import { FlexContainerHorizontal } from "./FlexContainerHorizontal";
/**
 * FlexContainerByParent displays the items vertically based on the verticalMaxWidth. Beyond that width, the items are shown in a horizontal manner.
 */
export const FlexContainerParent = ({ sx, dataComponentName, spacing, inline, fullHeight, alignVertical, alignHorizontal, reverseOrder, verticalMaxWidth, children, onClick, onMouseMove, onMouseDown, onMouseUp, onMouseEnter, onMouseLeave, onScroll, onWheel, }) => {
    return (_jsx(ContainerDetectResize, { children: ({ width }) => (width <= verticalMaxWidth
            ? (_jsx(FlexContainerVertical, { sx: sx, dataComponentName: getDataComponentName(dataComponentName, 'FlexContainerParent'), inline: inline, spacing: spacing, alignHorizontal: alignHorizontal, fullHeight: fullHeight, reverseOrder: reverseOrder, onClick: onClick, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onScroll: onScroll, onWheel: onWheel, children: children }))
            : (_jsx(FlexContainerHorizontal, { sx: sx, dataComponentName: getDataComponentName(dataComponentName, 'FlexContainerParent'), inline: inline, spacing: spacing, alignVertical: alignVertical, fullHeight: fullHeight, reverseOrder: reverseOrder, onClick: onClick, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onScroll: onScroll, onWheel: onWheel, children: children }))) }));
};
