var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Help for leaflet general: https://leafletjs.com/
// Help for react-leaflet general: https://react-leaflet.js.org/
// Help for react-leaflet Child components (map elements): https://react-leaflet.js.org/docs/api-components/
// Help for offline mode: https://walkingtree.tech/play-with-maps-in-react-online-and-offline-using-leaflet-libraries/
// API of the offline library: https://www.npmjs.com/package/leaflet-offline
// Wanna group markers? Not implemented. https://www.npmjs.com/package/react-leaflet-markercluster
import * as React from "react";
import { memo, useRef, useMemo, useState, useEffect, useCallback, useImperativeHandle, } from "react";
import { dynaTry } from "dyna-try";
import { useResizeEvent, } from "../useResizeEvent";
import { dynaSwitchEnum } from "dyna-switch";
import { DynaLocalStorageData } from "dyna-local-storage";
import { latLngBounds, } from "leaflet";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, ZoomControl, useMapEvents, } from "react-leaflet";
// Google maps layer
import ReactLeafletGoogleLayer from "react-leaflet-google-layer"; // Dev note: Upgrading to v2.2.0 has broken types (in combination of react-leaflet's version)
import { leafletOffline, } from "./offline";
import { BrowserGeolocation } from "utils-library/dist/web";
import { EMapType } from "../geoInterfaces";
import { Box } from "../Box";
import { IsLoading } from "../IsLoading";
import { LoadExternalDependencies, EDependencyType, } from "../LoadExternalDependencies";
import { useIsMounted } from "../useIsMounted";
import { MapButton } from "./components/ui-components/MapButton";
import { MapTypeButton } from "./components/ui-components/MapTypeButton";
import { FullScreenButton } from "./components/ui-components/FullScreenButton";
import { MapMarker, } from "./components/map-components/MapMarker";
import { MapCircle, } from "./components/map-components/MapCircle";
import { MapPolyline, } from "./components/map-components/MapPolyline";
import { MapComponent, } from "./components/map-components/MapComponent";
import { MapToolWindow, } from "./components/map-components/MapToolWindow";
import { MapDirectionLine, } from "./components/map-components/MapDirectionLine";
import { colorMarker, ELeafletMarkerColor, } from "./markers/colorMarker";
import { HeadingSelector, } from "./components/map-components/MapHeadingSelector";
import { getRadiusByPositionAndScreenSize } from "./utils/getRadiusByPositionAndScreenSize";
import "./GeoMapLeafletCore.less";
import { useTheme, } from "../ThemeProvider";
import CenterCurrentLocationIcon from "@mui/icons-material/FilterCenterFocus";
import CenterMarkersIcon from '@mui/icons-material/CenterFocusWeak';
import CenterItemsIcon from '@mui/icons-material/CenterFocusStrong';
const DEFAULT_MAP_VIEW_STATE = {
    type: EMapType.ROADMAP,
    position: {
        lng: 16.315104299999998,
        lat: 48.197407,
        alt: 0,
    },
    centerRadiusInMeters: -1,
    zoom: 13,
};
const LS_KEY = "GeoMapLeaflet-id-v202206081021";
const OFFLINE_MIN_ZOOM_TO_SAVE = 14;
export const GeoMapLeafletCore = memo(React.forwardRef((props, ref) => {
    const { sx = {}, dataComponentName, id, dataAttributes, darkThemeActive = false, defaultTravelDuration = 0, delayStart = 0, readOnly = false, disabled = false, rememberLastUserPosition = true, googleMapApiKey, defaultMapType, availableMapTypes = [EMapType.ROADMAP], defaultZoom, minZoom = 1, maxZoom = 21, defaultCenter, markers = [], polylines = [], directionLines = [], circles = [], mapComponents = [], toolWindows = [], headingSelector, centerButton = true, centerMarkersButton = false, centerAllItemsButton = false, gestureZoom = false, fullScreenButton = false, showOfflineSaveDeleteButtons = false, rightToolbar = null, mapOverlay, alerts, onOfflineSaveMaps = () => console.error('Dev error: onOfflineSaveMaps is not provided'), onOfflineDeleteMaps = () => console.error('Dev error: onOfflineDeleteMaps is not provided'), onClick, onFullScreenClick, onCenterRadiusDistanceChange, onMapViewStateChange, } = props;
    const theme = useTheme();
    const getIsMounted = useIsMounted();
    const refMarkers = useRef({});
    const [leafletMap, setLeafletMap] = useState();
    const [leafletOfflineApi, setLeafletOfflineApi] = useState();
    const closeMarkerPopUp = () => Object.values(refMarkers.current)
        .forEach(marker => {
        if (!marker)
            return;
        if (marker.isPopUpOpen)
            marker.closePopUp();
    });
    useImperativeHandle(ref, () => ({
        refresh,
        center: centerMap,
        get zoom() {
            return ls.data.zoom;
        },
        get mapViewState() {
            return ls.data;
        },
        centerAllMarkers,
        centerAllItems,
        centerToPosition: (geoPosition, zoom, travelDuration) => __awaiter(void 0, void 0, void 0, function* () {
            closeMarkerPopUp();
            yield setCenter({
                geoPosition,
                zoom,
                travelDuration,
            });
        }),
        openMarkerPopUp: (markerId_1, ...args_1) => __awaiter(void 0, [markerId_1, ...args_1], void 0, function* (markerId, travelDuration = defaultTravelDuration) {
            const openMarkerId = Object.keys(refMarkers.current).find(markerId => { var _a; return (_a = refMarkers.current[markerId]) === null || _a === void 0 ? void 0 : _a.isPopUpOpen; });
            if (markerId === openMarkerId)
                return; // Exit, the marker is already opened
            const marker = markers.find(marker => marker.markerId === markerId);
            const refMarker = refMarkers.current[markerId];
            if (!marker)
                return;
            if (!refMarker)
                return;
            closeMarkerPopUp();
            yield setCenter({
                geoPosition: marker.position,
                zoom: 15,
                travelDuration,
            });
            refMarker.openPopUp();
        }),
        closeMarkerPopUp: () => {
            closeMarkerPopUp();
        },
    }));
    const getCenterRadiusInMeters = (_ls) => getRadiusByPositionAndScreenSize({
        position: _ls.data.position,
        zoom: _ls.data.zoom,
        mapComponentSize: {
            width: (leafletMap === null || leafletMap === void 0 ? void 0 : leafletMap.getSize().x) || -1,
            height: (leafletMap === null || leafletMap === void 0 ? void 0 : leafletMap.getSize().y) || -1,
        },
    });
    const updateTriggerRadiusInMeters = () => {
        ls.data.centerRadiusInMeters = getCenterRadiusInMeters(ls);
        onCenterRadiusDistanceChange === null || onCenterRadiusDistanceChange === void 0 ? void 0 : onCenterRadiusDistanceChange(ls.data);
    };
    // Initialize local storage
    const ls = useMemo(() => {
        const ls = new DynaLocalStorageData(`${LS_KEY}---${id}`);
        ls.data = Object.assign(Object.assign(Object.assign({}, DEFAULT_MAP_VIEW_STATE), ls.data), { centerRadiusInMeters: -1 });
        ls.data.centerRadiusInMeters = getCenterRadiusInMeters(ls);
        if (rememberLastUserPosition)
            ls.save();
        return ls;
    }, [leafletMap]);
    const initialCenter = useMemo(() => ls.data.position, [leafletMap]);
    const initialZoom = useMemo(() => ls.data.zoom, [leafletMap]);
    // Todo: Once the MHC-00277 is fixed, delete the following line and uncomment the very next one.
    const [mapType, setMapType] = useState(defaultMapType || EMapType.ROADMAP);
    const [isCentering, setIsCentering] = useState(false);
    const [centerPosition, setCenterPosition] = useState(null);
    const [fullScreen, setFullScreen] = useState(false);
    const handleFullScreenClick = (fullScreen) => {
        let prevent = false;
        onFullScreenClick === null || onFullScreenClick === void 0 ? void 0 : onFullScreenClick({
            fullScreen,
            preventDefault: () => prevent = true,
            refreshMap: () => leafletMap === null || leafletMap === void 0 ? void 0 : leafletMap.invalidateSize(),
        });
        if (!prevent)
            setFullScreen(fullScreen);
    };
    // Check for duplicate marketIds and console.error them
    useEffect(() => {
        const duplicatedDic = {};
        markers.forEach(marker => {
            if (!duplicatedDic[marker.markerId])
                duplicatedDic[marker.markerId] = 0;
            duplicatedDic[marker.markerId]++;
        });
        Object.entries(duplicatedDic)
            .map(([markerId, count]) => ({
            markerId,
            count,
        }))
            .filter(item => item.count > 1)
            .forEach(item => console.error(`GeoMapLeaflet: The markerId: [${item.markerId}] is duplicated ${item.count} times`));
    }, [markers.map(marker => marker.markerId).join()]);
    // MHC-00308 Frozen map after Validate Broadcast
    // MHC-00250 Bugfix: Show the map in a tick later
    const [show, setShow] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            if (getIsMounted())
                setShow(true);
        }, delayStart);
    }, []);
    // Validate the google map key
    useEffect(() => {
        if (mapType !== EMapType.ROADMAP && !googleMapApiKey) {
            console.error(`Map type: ${mapType} requires googleMapApiKey!`);
        }
    }, [mapType, googleMapApiKey]);
    // Start - resolve the center & zoom
    useEffect(() => {
        if (!leafletMap)
            return;
        const zoom = defaultZoom || ls.data.zoom;
        if (defaultCenter
            && defaultCenter.lat !== 0
            && defaultCenter.lng !== 0
            && defaultCenter.lat !== -1
            && defaultCenter.lng !== -1) {
            setCenter({
                geoPosition: defaultCenter,
                zoom,
                travelDuration: 0,
                savePosition: false,
            });
            return;
        }
        const elementsPositions = getAllItemPositions();
        const middleMarkerPosition = (!!elementsPositions.length
            && elementsPositions
                .concat()
                .sort((pA, pB) => pA.lng - pB.lng)[Math.floor(elementsPositions.length / 2)]);
        if (middleMarkerPosition) {
            setCenter({
                geoPosition: middleMarkerPosition,
                zoom,
                savePosition: false,
            });
            return;
        }
        if (initialCenter.lat !== DEFAULT_MAP_VIEW_STATE.position.lat &&
            initialCenter.lng !== DEFAULT_MAP_VIEW_STATE.position.lng) {
            setCenter({
                geoPosition: initialCenter,
                zoom,
                savePosition: false,
            });
            return;
        }
        const browserGeolocation = new BrowserGeolocation();
        browserGeolocation.getGeoPosition()
            .then(geoPosition => {
            setCenter({
                geoPosition,
                zoom,
            });
        })
            .catch(error => {
            console.error('GeoMapLeaflet cannot get the geolocation from browser', error);
        });
    }, [leafletMap]);
    // Refresh the tiles on start. They might be missing if, on the initial render, the size of it was smaller.
    // MHC-00613 https://trello.com/c/9rjwaUIJ
    useEffect(() => {
        if (!leafletMap)
            return;
        setTimeout(() => {
            if (getIsMounted())
                leafletMap.invalidateSize();
        }, 100);
        setTimeout(() => {
            if (getIsMounted())
                leafletMap.invalidateSize();
        }, 200);
    }, [leafletMap]);
    // Load the Offline layer/feature
    useEffect(() => {
        if (!leafletMap)
            return;
        setLeafletOfflineApi(leafletOffline({
            leafletMap,
            showOfflineSaveDeleteButtons,
            onSaveOfflineMaps: onOfflineSaveMaps,
            onDeleteOfflineMaps: onOfflineDeleteMaps,
        }));
    }, [leafletMap]);
    // Trigger initially some events
    useEffect(() => {
        onCenterRadiusDistanceChange === null || onCenterRadiusDistanceChange === void 0 ? void 0 : onCenterRadiusDistanceChange(ls.data);
        onMapViewStateChange === null || onMapViewStateChange === void 0 ? void 0 : onMapViewStateChange(ls.data);
    }, []);
    // On parent's resize, invalidateSize to add missing tiles
    const handleContainerResize = useCallback(({ diffPercentage }) => {
        updateTriggerRadiusInMeters();
        if (diffPercentage < 10)
            return;
        leafletMap === null || leafletMap === void 0 ? void 0 : leafletMap.invalidateSize();
    }, [leafletMap]);
    const { ref: refContainer } = useResizeEvent({
        refreshRate: 500,
        leading: false,
        onResize: handleContainerResize,
    });
    // Render useMapEvents under <MapContainer>, to get the map reference and to handle it's events.
    const MapEvents = () => {
        const _leafletMap = useMapEvents({
            click(event) {
                if (readOnly)
                    return;
                onClick === null || onClick === void 0 ? void 0 : onClick({
                    lat: event.latlng.lat,
                    lng: event.latlng.lng,
                    alt: event.latlng.alt || 0,
                });
            },
            dragend() {
                ls.data.position = _leafletMap.getBounds().getCenter();
                updateTriggerRadiusInMeters();
                onMapViewStateChange === null || onMapViewStateChange === void 0 ? void 0 : onMapViewStateChange(ls.data);
                if (rememberLastUserPosition)
                    ls.save();
            },
            zoomend() {
                ls.data.zoom = _leafletMap.getZoom();
                updateTriggerRadiusInMeters();
                onMapViewStateChange === null || onMapViewStateChange === void 0 ? void 0 : onMapViewStateChange(ls.data);
                if (!readOnly)
                    leafletOfflineApi === null || leafletOfflineApi === void 0 ? void 0 : leafletOfflineApi.enableSaveButton(ls.data.zoom >= OFFLINE_MIN_ZOOM_TO_SAVE);
                if (rememberLastUserPosition)
                    ls.save();
            },
        });
        leafletOfflineApi === null || leafletOfflineApi === void 0 ? void 0 : leafletOfflineApi.enableSaveButton(_leafletMap.getZoom() >= OFFLINE_MIN_ZOOM_TO_SAVE);
        setTimeout(() => {
            // In timeout since at this point we render.
            if (getIsMounted())
                setLeafletMap(_leafletMap);
        }, 10);
        return null;
    };
    const refresh = (timeout = 1000) => {
        return new Promise(resolve => {
            setShow(false);
            setTimeout(() => {
                if (!getIsMounted())
                    return;
                setShow(true);
                resolve();
            }, timeout);
        });
    };
    const setCenter = (_a) => __awaiter(void 0, [_a], void 0, function* ({ geoPosition, zoom, travelDuration = defaultTravelDuration, easeLinearity, savePosition = true, }) {
        if (leafletMap) {
            leafletMap.setView(geoPosition, zoom, {
                animate: !!travelDuration,
                duration: travelDuration / 1000,
                easeLinearity,
            });
        }
        else {
            console.error('Internal dev error: GeoMapLeaflet: setCenter(): leafletMap reference is undefined. You should use the setCenter() after the 1st render.');
        }
        if (savePosition) {
            ls.data.position = geoPosition;
            ls.data.zoom = zoom;
            updateTriggerRadiusInMeters();
            onMapViewStateChange === null || onMapViewStateChange === void 0 ? void 0 : onMapViewStateChange(ls.data);
            ls.save();
        }
        yield new Promise(r => setTimeout(r, travelDuration));
    });
    const centerMap = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (zoom = 17, travelDuration = defaultTravelDuration) {
        return dynaTry({
            timeout: 5000,
            try: () => __awaiter(void 0, void 0, void 0, function* () {
                setIsCentering(true);
                const browserGeolocation = new BrowserGeolocation({ enableHighAccuracy: true });
                const geoPosition = yield browserGeolocation.getGeoPosition();
                setCenter({
                    geoPosition,
                    zoom,
                    travelDuration,
                });
                setCenterPosition(geoPosition);
            }),
        })
            .catch((e) => {
            alert('Cannot center. Check if the location access is turned off for this app.');
            console.error('Cannot center', e);
        })
            .finally(() => {
            setIsCentering(false);
        });
    });
    const centerAllMarkers = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (travelDuration = defaultTravelDuration) {
        return centerToPositions(travelDuration, markers.map(marker => marker.position));
    });
    const centerAllItems = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (travelDuration = defaultTravelDuration) {
        return centerToPositions(travelDuration, getAllItemPositions());
    });
    const centerToPositions = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (travelDuration = defaultTravelDuration, positions) {
        if (!positions.length)
            return; // Exit, nothing to center
        if (!leafletMap)
            return; // 4TS
        try {
            setIsCentering(true);
            const bounds = latLngBounds([]);
            positions.forEach(position => {
                bounds.extend([position.lat, position.lng]);
            });
            if (!bounds.isValid()) {
                console.error('Internal error: GeoMapLeafletCore: centerToPositions: Built bounds are invalid', { bounds });
                return;
            }
            travelDuration
                ? leafletMap.flyToBounds(bounds, {
                    animate: true,
                    duration: travelDuration / 1000,
                })
                : leafletMap.fitBounds(bounds);
            ls.data = Object.assign(Object.assign({}, ls.data), { position: Object.assign(Object.assign({}, ls.data.position), { lat: bounds.getCenter().lat, lng: bounds.getCenter().lng }) });
            if (rememberLastUserPosition)
                ls.save();
            yield new Promise(r => setTimeout(r, travelDuration));
            updateTriggerRadiusInMeters();
            onMapViewStateChange === null || onMapViewStateChange === void 0 ? void 0 : onMapViewStateChange(ls.data);
        }
        catch (e) {
            console.error('Cannot center around markers', e);
        }
        finally {
            setIsCentering(false);
        }
    });
    const getAllItemPositions = () => [
        ...markers.map(marker => marker.position),
        ...polylines.reduce((acc, polyline) => acc.concat(polyline.points), []),
        ...directionLines.reduce((acc, polyline) => acc.concat(polyline.points), []),
        ...circles.reduce((acc, circle) => acc.concat(circle.center), []),
        ...mapComponents.reduce((acc, mapComponent) => acc.concat(mapComponent.position), []),
        ...toolWindows.reduce((acc, toolWindow) => acc.concat(toolWindow.ownerPosition), []),
    ];
    const handleMapTypeChange = (mapType) => {
        if (!navigator.onLine && mapType !== EMapType.ROADMAP) {
            setMapType(EMapType.ROADMAP);
            alert('No internet connection, only the Roadmap view is available.');
        }
        else {
            setMapType(mapType);
            ls.data.type = mapType;
            ls.save();
            updateTriggerRadiusInMeters();
            onMapViewStateChange === null || onMapViewStateChange === void 0 ? void 0 : onMapViewStateChange(ls.data);
            // Bugfix for Google map, doesn't refresh when zoom in is high.
            if ((mapType === EMapType.SATELLITE || mapType === EMapType.HYBRID) // If the new map type is one of these
                && leafletMap && leafletMap.getZoom() > 20 // And if the zoom was above this
            ) {
                leafletMap.setZoom(20); // Zoom out to refresh the Google map
            }
        }
    };
    const isDarkTheme = darkThemeActive && theme.palette.mode === 'dark';
    const sxRoot = Object.assign(Object.assign(Object.assign({ height: '100%', position: 'relative', overflow: 'hidden', zIndex: 0, '& .leaflet-container': {
            height: '100%',
            backgroundColor: isDarkTheme ? '#222222' : undefined,
        } }, (fullScreen
        ? {
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 200,
        }
        : {})), (disabled
        ? {
            pointerEvents: "none",
            opacity: 0.8,
        }
        : {})), sx);
    if (!show) {
        return (_jsx(IsLoading, { sx: sxRoot, isLoading: true }));
    }
    const handleCenterMap = () => centerMap();
    const handleCenterToAllItems = () => centerAllItems();
    return (_jsx(LoadExternalDependencies, { fullHeightProgress: true, dependencies: [
            {
                // Dev note: This LoadExternalDependencies is trying to avoid the console warning:
                // "Google Maps JavaScript API has been loaded directly without loading=async. This can result in suboptimal performance. For best-practice loading patterns please see https://goo.gle/js-api-loading"
                // But we still get this for some reason.
                // Ideally, this LoadExternalDependencies would be removed completely along with the useGoogMapsLoader props below too.
                type: EDependencyType.SCRIPT,
                src: `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}`,
            },
        ], children: _jsxs(Box, { sx: sxRoot, dataComponentName: ["GeoMapLeafletCore", dataComponentName], dataAttributes: dataAttributes, ref: refContainer, children: [_jsxs(MapContainer, { center: initialCenter, zoomControl: false, zoom: initialZoom, scrollWheelZoom: gestureZoom, children: [_jsx(ZoomControl, { position: "bottomright" }), _jsx(TileLayer, { attribution: '\u00A9 <a href="http://osm.org/copyright">OpenStreetMap</a> contributors', url: isDarkTheme
                                ? 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
                                : 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', minZoom: minZoom, maxZoom: maxZoom, maxNativeZoom: 19 }), mapType !== EMapType.ROADMAP && (
                        // @ts-ignore --> This is because the dep returns ReactElement and not JSX.Element
                        _jsx(ReactLeafletGoogleLayer, { apiKey: googleMapApiKey, useGoogMapsLoader: false, type: dynaSwitchEnum(mapType, {
                                [EMapType.ROADMAP]: "roadmap",
                                [EMapType.SATELLITE]: "satellite",
                                [EMapType.TERRAIN]: "terrain",
                                [EMapType.HYBRID]: "hybrid",
                            }), googleMapsAddLayers: [
                                { name: 'TrafficLayer' },
                                { name: 'BicyclingLayer' },
                                { name: 'TransitLayer' },
                            ] }, mapType)), markers.map(marker => (_jsx(MapMarker
                        // @ts-ignore
                        , Object.assign({ 
                            // @ts-ignore
                            ref: (m) => refMarkers.current[marker.markerId] = m }, marker), marker.markerId))), polylines.map((polyline, index) => (_jsx(MapPolyline, Object.assign({}, polyline), index))), directionLines.map(directionLine => (_jsx(MapDirectionLine, Object.assign({}, directionLine), directionLine.directionLineId))), circles.map((circle, index) => (_jsx(MapCircle, Object.assign({}, circle), index))), mapComponents.map((mapComponent) => (_jsx(MapComponent, Object.assign({}, mapComponent), mapComponent.componentId))), toolWindows.map((toolWindow) => (_jsx(MapToolWindow, Object.assign({}, toolWindow), toolWindow.windowId))), !!centerPosition && (
                        // @ts-ignore --> This is because the dep returns ReactElement and not JSX.Element
                        _jsx(Marker, { position: centerPosition, icon: colorMarker(ELeafletMarkerColor.GREY) })), !!headingSelector && (_jsx(HeadingSelector, { position: headingSelector.position, headings: headingSelector.headings, circleRadius: headingSelector.circleRadius, readOnly: readOnly, onSliceClick: headingSelector.onSliceClick, onSelectDeselectClick: headingSelector.onSelectDeselectClick })), _jsx(MapEvents, {})] }), _jsxs(Box, { sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        position: 'absolute',
                        top: 12,
                        right: 12,
                        zIndex: 1100,
                        '& > *': {
                            display: 'block',
                            marginBottom: theme.spacing(1),
                        },
                    }, children: [fullScreenButton && (_jsx(FullScreenButton, { fullScreen: fullScreen, onChange: handleFullScreenClick })), centerButton && (_jsx(MapButton, { title: "Go to current location", icon: _jsx(CenterCurrentLocationIcon, {}), isLoading: isCentering, onClick: handleCenterMap })), centerMarkersButton && (_jsx(MapButton, { title: "Center to markers", icon: _jsx(CenterMarkersIcon, {}), isLoading: isCentering, onClick: handleCenterToAllItems })), centerAllItemsButton && (_jsx(MapButton, { title: "Center to items", icon: _jsx(CenterItemsIcon, {}), isLoading: isCentering, onClick: handleCenterToAllItems })), (availableMapTypes.length > 1 && (_jsx(MapTypeButton, { availableMapTypes: availableMapTypes, mapType: mapType, onClick: handleMapTypeChange }))), rightToolbar] }), mapOverlay, _jsx(Box, { dataComponentName: "MapAlertsContainer", sx: {
                        position: "absolute",
                        left: '8px',
                        right: '64px',
                        bottom: '20px',
                        zIndex: 2000,
                    }, children: alerts })] }, `map-dark-theme--${isDarkTheme.toString()}`) }));
}));
GeoMapLeafletCore.displayName = GeoMapLeafletCore.name;
