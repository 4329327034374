export const scrollToElement = ({ selector = 'html', animation = true, position = 'center', }) => {
    const element = document.querySelector(selector);
    if (!element) {
        console.error(`scrollToElement: element with selector [${selector}] not found`);
        return;
    }
    element.scrollIntoView({
        block: position,
        inline: position,
        behavior: animation ? 'smooth' : undefined,
    });
};
