import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from "../ThemeProvider";
import { CircularProgressIcon } from "./components/CircularProgressIcon";
import { DoublePulseProgressIcon } from "./components/DoublePulseProgressIcon";
import { convertSizeToPx } from "../utils";
export var EProgressIconType;
(function (EProgressIconType) {
    EProgressIconType["MUI_CIRCULAR_PROGRESS"] = "MUI_CIRCULAR_PROGRESS";
    EProgressIconType["DOUBLE_PULSE"] = "DOUBLE_PULSE";
})(EProgressIconType || (EProgressIconType = {}));
export const ProgressIcon = (props) => {
    const { show = true, iconType = localStorage.getItem(LS_KEY) || EProgressIconType.MUI_CIRCULAR_PROGRESS, size: userSize = 16, } = props;
    const theme = useTheme();
    const sizePx = convertSizeToPx(theme, userSize);
    const width = parseFloat(sizePx);
    if (!show)
        return null;
    switch (iconType) {
        case EProgressIconType.MUI_CIRCULAR_PROGRESS:
            return _jsx(CircularProgressIcon, { width: width });
        case EProgressIconType.DOUBLE_PULSE:
            return _jsx(DoublePulseProgressIcon, { sizePx: sizePx });
        default:
            console.error(`ProgressIcon 20230618165628 error: iconType: [${iconType}] is unknown`);
            return _jsx(CircularProgressIcon, { width: width });
    }
};
const LS_KEY = 'Dyna-ProgressIcon--default';
export const setDefaultProgressIcon = (iconType) => localStorage.setItem(LS_KEY, iconType);
