import {IAppRoute} from "../../application/config/IAppRoute";
import {routeLanePlanningPagePaths} from "./routeLanePlanningPage.paths";
import {LanePlanningPage} from "../pages/LanePlanningPage";

export const routeLanePlanningPage: IAppRoute = {
  ...routeLanePlanningPagePaths,
  render: ()=>(
    <LanePlanningPage/>
  ),
};
