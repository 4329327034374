import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo, useState, } from "react";
import { DynaLocalStorageData } from "dyna-local-storage";
import { ModalDialog } from "../ModalDialog";
import { createIcon } from "../IconComponent";
import AgreeIcon from '@mui/icons-material/CheckCircleOutline';
import DisagreeIcon from '@mui/icons-material/HighlightOff';
export const DisclaimerModal = (props) => {
    const { rememberId = '', title, content, onAgree, onDisagree, } = props;
    const ls = useMemo(() => new DynaLocalStorageData('DisclaimerModalLSKey', {}), []);
    const [show, setShow] = useState(rememberId ? !ls.data[rememberId] : true);
    const handleAgreeClick = () => {
        if (rememberId) {
            ls.data[rememberId] = true;
            ls.save();
        }
        onAgree && onAgree();
        setShow(false);
    };
    const handleDisagreeClick = () => {
        onDisagree();
        setShow(false);
    };
    return (_jsx(ModalDialog, { show: show, title: title, buttons: [
            {
                Icon: createIcon.byMuiIcon(AgreeIcon),
                label: 'Agree',
                onClick: handleAgreeClick,
            },
            {
                Icon: createIcon.byMuiIcon(DisagreeIcon),
                label: 'Disagree',
                onClick: handleDisagreeClick,
            },
        ], children: content }));
};
