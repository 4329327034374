import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../config/IAppRoute";

const HomePage = lazy(() => import("../pages/HomePage"));

export const routeHomePage: IAppRoute = {
  menuId: '',
  title: 'Main',
  routePath: '/',
  getRoutePath: () => routeHomePage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EUserAuthenticationRights.ACTIVE],
  exact: true,
  render: () => (
    <LazyLoadComponent>
      <HomePage/>
    </LazyLoadComponent>
  ),
};
