export const isCurrentAddressSame = (pathOrUrl) => {
    if (!sameQueries(window.location.href, pathOrUrl))
        return false;
    const isFullUrl = pathOrUrl.startsWith('http');
    if (isFullUrl)
        return sameSlugs(window.location.href, pathOrUrl);
    return sameSlugs(window.location.pathname, pathOrUrl);
};
const sameSlugs = (aLink, bLink) => {
    return (aLink
        .split('/')
        .filter(Boolean)
        .join('/')
        === bLink.split('/')
            .filter(Boolean)
            .join('/'));
};
const sameQueries = (aLink, bLink) => {
    return (aLink
        .split('?')
        .slice(1)
        .join('?')
        === bLink.split('?')
            .slice(1)
            .join('?'));
};
