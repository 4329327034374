import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { useState, useEffect, useImperativeHandle, } from "react";
import { useDebouncedCallback } from 'use-debounce';
import { dynaSwitchEnum } from "dyna-switch";
import { pluralize } from "utils-library/dist/utils";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Box } from "../Box";
import { IsLoadingBox } from "../IsLoadingBox";
import { HelperText, EHelperTextType, } from "../HelperText";
import { IconViewer } from "../IconViewer";
import { convertSizeToPx } from "../utils";
import EndlessIcon from '@mui/icons-material/AllInclusive';
export var EInputVariant;
(function (EInputVariant) {
    EInputVariant["FILLED"] = "filled";
    EInputVariant["OUTLINED"] = "outlined";
    EInputVariant["STANDARD"] = "standard";
})(EInputVariant || (EInputVariant = {}));
export var EInputType;
(function (EInputType) {
    EInputType["TEXT"] = "text";
    EInputType["NUMBER"] = "number";
    EInputType["PASSWORD"] = "password";
})(EInputType || (EInputType = {}));
export var EInputSpacing;
(function (EInputSpacing) {
    EInputSpacing["NONE"] = "none";
    EInputSpacing["SMALL"] = "small";
    EInputSpacing["MEDIUM"] = "medium";
    EInputSpacing["LARGE"] = "large";
})(EInputSpacing || (EInputSpacing = {}));
const InputCore = React.forwardRef(function InputCore(props, ref) {
    const { sx = {}, show = true, styleInput, dataComponentName, variant = EInputVariant.STANDARD, spacing = EInputSpacing.NONE, disabled = false, readOnly = false, required = false, autoFocus = false, disableScroll = true, browserAutoComplete = false, name, nameOnInput = true, type = EInputType.TEXT, label, ariaLabel: userAriaLabel, placeholder, helperLabel, HelperIcon, hidden = false, prefix, suffix, isLoading, fontFamily, fontStyle, fontWeight = 'inherit', fontSize, minLength, maxLengthWarn, maxLength, maxLengthRestriction = true, showLengthHelperLabel = false, validationError, minRows = 1, maxRows = 1, buttons = [], defaultValue, value: userValue, debounceOnChangeMs = 0, onFocus = () => undefined, onBlur = () => undefined, onEnter = () => undefined, onChange: userOnChange = () => undefined, } = props;
    const [value, setValue] = useState(userValue || defaultValue || "");
    const onChange = useDebouncedCallback(userOnChange, debounceOnChangeMs);
    useImperativeHandle(ref, () => ({
        get value() {
            return value;
        },
        set value(newValue) {
            setValue(newValue);
        },
    }));
    useEffect(() => {
        if (userValue === undefined)
            return;
        setValue(userValue);
    }, [userValue]);
    const handleFocus = () => {
        onFocus(name);
    };
    const handleBlur = () => {
        onBlur(value, name);
    };
    const handleKeyUp = (event) => {
        if (event.keyCode === 13)
            onEnter(value, name);
    };
    const handleChange = (e) => {
        if (value === e.target.value)
            return;
        setValue(e.target.value);
        onChange(e.target.value, name);
    };
    const ariaLabel = userAriaLabel ||
        (label && `Input for ${label}`) ||
        undefined;
    const onWheel = (e) => {
        if (disableScroll)
            e.target.blur();
    };
    const whiteSpace = _jsx("span", { style: { whiteSpace: 'pre' }, children: " " });
    return (_jsxs(Box, { dataComponentName: [dataComponentName, "Input"], show: show, sx: Object.assign(Object.assign({ margin: dynaSwitchEnum(spacing, {
                [EInputSpacing.NONE]: 0,
                [EInputSpacing.SMALL]: '0 4px 4px 0',
                [EInputSpacing.MEDIUM]: '0 8px 8px 0',
                [EInputSpacing.LARGE]: '0 16px 16px 0',
            }), '& input, & textarea': {
                fontStyle,
                fontSize: theme => convertSizeToPx(theme, fontSize),
            } }, (fontFamily
            ? {
                'div': {
                    fontWeight,
                    fontFamily,
                    'div': {
                        fontFamily,
                        fontWeight,
                    },
                },
            }
            : {})), sx), hidden: hidden, children: [_jsx(TextField, { sx: { width: '100%' }, variant: variant, onWheel: onWheel, disabled: disabled, required: !disabled && !readOnly && required, autoFocus: autoFocus, autoComplete: browserAutoComplete ? undefined : "off", inputProps: {
                    style: styleInput,
                    readOnly,
                    maxLength: maxLengthRestriction ? maxLength : undefined,
                }, InputProps: {
                    startAdornment: (_jsx(InputAdornment, { position: "start", children: prefix })),
                    endAdornment: (_jsxs(InputAdornment, { position: "end", sx: variant === EInputVariant.STANDARD
                            ? {
                                position: 'relative',
                                left: '-12px',
                            }
                            : undefined, children: [suffix, _jsx(IsLoadingBox, { sx: {
                                    position: 'relative',
                                    top: '2px',
                                }, inline: true, isLoading: !!isLoading, iconSize: 3, spacing: 0 }), buttons
                                .filter(button => button.show !== false)
                                .map((button, index) => (_jsx(IconButton, { sx: { marginLeft: '16px' }, title: button.title, edge: "end", color: button.color, disabled: button.disabled, onClick: button.onClick, children: _jsx(IconViewer, { Icon: button.Icon, width: 28 }) }, index)))] })),
                }, name: nameOnInput ? name : undefined, type: type, label: label, "aria-label": ariaLabel, placeholder: placeholder, multiline: minRows > 1 || maxRows > 1, minRows: minRows, maxRows: minRows > maxRows ? minRows : maxRows, error: !!validationError, value: value, onFocus: handleFocus, onBlur: handleBlur, onKeyUp: handleKeyUp, onChange: handleChange }), _jsx(HelperText, { Icon: HelperIcon, children: helperLabel }), _jsxs(HelperText, { show: showLengthHelperLabel, type: (() => {
                    if (minLength !== undefined
                        && maxLengthWarn
                        && maxLength
                        && value.length >= minLength
                        && value.length >= maxLengthWarn
                        && value.length <= maxLength)
                        return EHelperTextType.WARNING;
                    if ((minLength !== undefined && value.length < minLength) ||
                        (maxLength !== undefined && value.length > maxLength))
                        return EHelperTextType.ERROR;
                    return;
                })(), children: [_jsxs("span", { children: [value.length, " chars."] }), minLength !== undefined && (_jsxs(Box, { inline: true, show: value.length < minLength, children: [whiteSpace, minLength - value.length, " ", pluralize('char', minLength - value.length), " more."] })), maxLength !== undefined && (_jsxs(Box, { inline: true, show: value.length > maxLength, children: [whiteSpace, value.length - maxLength, " ", pluralize('char', value.length - maxLength), " less."] })), _jsxs(Box, { inline: true, children: [whiteSpace, "[Valid length: ", minLength || 0, maxLengthWarn ? `..${maxLengthWarn}` : '', "...", maxLength || _jsx(IconViewer, { MuiIcon: EndlessIcon }), "]"] })] }), _jsx(HelperText, { type: EHelperTextType.ERROR, children: validationError })] }));
});
export const Input = InputCore;
