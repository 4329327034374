var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setIntervals } from "utils-library/dist/utils";
import { isInViewPort } from "utils-library/dist/web";
import { watchElementRemoval } from "./watchElementRemoval";
export const watchElementVisibility = ({ element, onVisibilityChange, }) => {
    let lastIsShown = null;
    const handleVisibilityChange = (isShown) => {
        if (isShown === lastIsShown)
            return;
        lastIsShown = isShown;
        onVisibilityChange(isShown);
    };
    const observer = new IntersectionObserver((entries) => {
        const isShown = entries[0].isIntersecting;
        handleVisibilityChange(isShown);
    });
    observer.observe(element);
    watchElementRemoval({
        element,
        onRemove: observer.disconnect,
    });
    // Sometimes, on startup only, the above observer still cannot resolve the initial state visibility.
    // For a white watch the visibility and apply it.
    setIntervals({
        timeout: 100,
        times: 10,
        leading: false,
        exec: () => __awaiter(void 0, void 0, void 0, function* () {
            const isShown = yield isInViewPort(element);
            handleVisibilityChange(isShown);
        }),
    });
};
