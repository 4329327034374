import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";

const MainTestPage = lazy(() => import('../pages/MainTestPage'));

export const routeTestsPage: IAppRoute = {
  menuId: '',
  title: 'App tests',
  routePath: '/test',
  exact: true,
  getRoutePath: () => '/test',
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => (
    <LazyLoadComponent>
      <MainTestPage/>
    </LazyLoadComponent>
  ),
};
