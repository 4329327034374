export var ECSSDuration;
(function (ECSSDuration) {
    ECSSDuration["SHORTEST"] = "shortest";
    ECSSDuration["SHORTER"] = "shorter";
    ECSSDuration["SHORT"] = "short";
    ECSSDuration["STANDARD"] = "standard";
    ECSSDuration["COMPLEX"] = "complex";
    ECSSDuration["ENTERING"] = "enteringScreen";
    ECSSDuration["LEAVING"] = "leavingScreen";
})(ECSSDuration || (ECSSDuration = {}));
export var ECSSEasing;
(function (ECSSEasing) {
    ECSSEasing["IN"] = "easeIn";
    ECSSEasing["OUT"] = "easeOut";
    ECSSEasing["IN_OUT"] = "easeInOut";
    ECSSEasing["SHARP"] = "sharp";
})(ECSSEasing || (ECSSEasing = {}));
export const sxTransition = (theme_, cssAttrs, duration, easing = ECSSEasing.IN_OUT) => {
    const theme = theme_;
    const applyDuration = typeof duration === "string"
        ? theme.transitions.duration[duration]
        : duration;
    return ((Array.isArray(cssAttrs)
        ? cssAttrs
        : [cssAttrs])
        .map((cssAttr) => theme.transitions.create(cssAttr, {
        easing: theme.transitions.easing[easing],
        duration: applyDuration,
    }))
        .join(', '));
};
export const sxTransitionShowHide = (theme, attr, currentShowValue) => currentShowValue
    ? sxTransition(theme, attr, ECSSDuration.LEAVING, ECSSEasing.IN_OUT)
    : sxTransition(theme, attr, ECSSDuration.ENTERING, ECSSEasing.SHARP);
