import { getFilenameWithDotSuffix } from "utils-library/dist/utils";
export var EImageSizes;
(function (EImageSizes) {
    EImageSizes["W192"] = "W192";
    EImageSizes["W384"] = "W384";
    EImageSizes["W768"] = "W768";
    EImageSizes["W1024"] = "W1024";
    EImageSizes["W2048"] = "W2048";
    EImageSizes["W4096"] = "W4096";
})(EImageSizes || (EImageSizes = {}));
export const getImageSrcSet = (mainUrl) => ({
    main: mainUrl,
    W192: getFilenameWithDotSuffix(mainUrl, EImageSizes.W192),
    W384: getFilenameWithDotSuffix(mainUrl, EImageSizes.W384),
    W768: getFilenameWithDotSuffix(mainUrl, EImageSizes.W768),
    W1024: getFilenameWithDotSuffix(mainUrl, EImageSizes.W1024),
    W2048: getFilenameWithDotSuffix(mainUrl, EImageSizes.W2048),
    W4096: getFilenameWithDotSuffix(mainUrl, EImageSizes.W4096),
});
