export var ELogPostType;
(function (ELogPostType) {
    ELogPostType["LOGS_CONNECTION"] = "LOGS_CONNECTION";
    ELogPostType["LOG"] = "LOG";
    ELogPostType["COMMENT"] = "COMMENT";
})(ELogPostType || (ELogPostType = {}));
export var ELogLevel;
(function (ELogLevel) {
    ELogLevel["INFO"] = "INFO";
    ELogLevel["LOG"] = "LOG";
    ELogLevel["WARNING"] = "WARNING";
    ELogLevel["ERROR"] = "ERROR";
    ELogLevel["DEBUG"] = "DEBUG";
})(ELogLevel || (ELogLevel = {}));
export var ELogOrigin;
(function (ELogOrigin) {
    ELogOrigin["SERVER_EXTERNAL"] = "SERVER_EXTERNAL";
    ELogOrigin["SERVER_APP"] = "SERVER_APP";
    ELogOrigin["WEB_APP_EXTERNAL"] = "WEB_APP_EXTERNAL";
    ELogOrigin["WEB_APP_APP"] = "WEB_APP_APP";
    ELogOrigin["USER"] = "USER";
})(ELogOrigin || (ELogOrigin = {}));
export const getDefaultLogContent = () => ({
    level: ELogLevel.LOG,
    title: '',
});
