import * as L from "leaflet";
export var ELeafletMarkerColor;
(function (ELeafletMarkerColor) {
    ELeafletMarkerColor["BLUE"] = "BLUE";
    ELeafletMarkerColor["GOLD"] = "GOLD";
    ELeafletMarkerColor["GREEN"] = "GREEN";
    ELeafletMarkerColor["RED"] = "RED";
    ELeafletMarkerColor["ORANGE"] = "ORANGE";
    ELeafletMarkerColor["YELLOW"] = "YELLOW";
    ELeafletMarkerColor["VIOLET"] = "VIOLET";
    ELeafletMarkerColor["GREY"] = "GREY";
    ELeafletMarkerColor["BLACK"] = "BLACK";
})(ELeafletMarkerColor || (ELeafletMarkerColor = {}));
export const colorMarker = (color) => {
    const baseUrl = `${window.location.origin}/assets/leaflet-map/markers`;
    return L.icon({
        iconUrl: `${baseUrl}/marker-icon-${color.toLowerCase()}.png`,
        iconRetinaUrl: `${baseUrl}/marker-icon-2x-${color.toLowerCase()}.png`,
        shadowUrl: `${baseUrl}/marker-shadow.png`,
        iconAnchor: [13, 40], // Position is calibration
        iconSize: [25, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });
};
