import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "../Box";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker, TimePicker, DatePicker, LocalizationProvider, } from '@mui/x-date-pickers';
import { HelperText, EHelperTextType, } from "../HelperText";
export var EInputDateTimeMode;
(function (EInputDateTimeMode) {
    EInputDateTimeMode["DATE"] = "DATE";
    EInputDateTimeMode["TIME"] = "TIME";
    EInputDateTimeMode["DATE_TIME"] = "DATE_TIME";
})(EInputDateTimeMode || (EInputDateTimeMode = {}));
export var EInputTimeMode;
(function (EInputTimeMode) {
    EInputTimeMode["AM_PM"] = "AM_PM";
    EInputTimeMode["H24"] = "H24";
})(EInputTimeMode || (EInputTimeMode = {}));
export var EInputDateTimePickerVariant;
(function (EInputDateTimePickerVariant) {
    EInputDateTimePickerVariant["FILLED"] = "filled";
    EInputDateTimePickerVariant["OUTLINED"] = "outlined";
    EInputDateTimePickerVariant["STANDARD"] = "standard";
})(EInputDateTimePickerVariant || (EInputDateTimePickerVariant = {}));
export const InputDateTimePicker = (props) => {
    const { mode = EInputDateTimeMode.DATE_TIME, inputVariant = EInputDateTimePickerVariant.STANDARD, timeMode = EInputTimeMode.H24, name, label, ariaLabel = label || "Date time picker", helperLabel, validationError, required = false, readOnly = false, disabled = false, openPickerToYear = false, minutesStep = 1, value, minDate, maxDate, onChange, } = props;
    const amPm = timeMode === EInputTimeMode.AM_PM;
    const handleValueChange = (value) => {
        // Should not be undefined or null or "Invalid date" at any time during editing (especially with keyboard)
        if (!(value instanceof Date))
            return; // Would be string, null
        if (isNaN(value))
            return; // Is "Invalid date"
        onChange(value, name);
    };
    return (_jsxs(LocalizationProvider, { dateAdapter: AdapterDateFns, children: [_jsx(Box, { "aria-label": ariaLabel, sx: {
                    '& .MuiInputAdornment-root button': {
                        position: 'relative',
                        left: -4, // Fix the position of the icon
                    },
                }, dataComponentName: "InputDateTimePicker", children: (() => {
                    switch (mode) {
                        case EInputDateTimeMode.DATE_TIME:
                            return (_jsx(DateTimePicker, { componentsProps: { actionBar: { actions: ['today'] } }, ampm: amPm, label: label, readOnly: readOnly, disabled: disabled, hideTabs: false, openTo: openPickerToYear ? "year" : "day", minDate: minDate, maxDate: maxDate, value: value, minutesStep: minutesStep, renderInput: (props) => (_jsx(TextField, Object.assign({}, props, { sx: Object.assign(Object.assign({}, (props.sx || {})), { width: '100%' }), variant: inputVariant, required: required, error: !!validationError }))), onChange: handleValueChange }));
                        case EInputDateTimeMode.DATE:
                            return (_jsx(DatePicker, { componentsProps: { actionBar: { actions: ['today'] } }, label: label, readOnly: readOnly, disabled: disabled, openTo: openPickerToYear ? "year" : "day", minDate: minDate, maxDate: maxDate, value: value, renderInput: (props) => (_jsx(TextField, Object.assign({}, props, { sx: Object.assign(Object.assign({}, (props.sx || {})), { width: '100%' }), variant: inputVariant, error: !!validationError, required: required }))), onChange: handleValueChange }));
                        case EInputDateTimeMode.TIME:
                            return (_jsx(TimePicker, { ampm: amPm, label: label, readOnly: readOnly, disabled: disabled, minutesStep: minutesStep, value: value, renderInput: (props) => (_jsx(TextField, Object.assign({}, props, { sx: Object.assign(Object.assign({}, (props.sx || {})), { width: '100%' }), variant: inputVariant, error: !!validationError, required: required }))), onChange: handleValueChange }));
                    }
                })() }), _jsx(HelperText, { children: helperLabel }), _jsx(HelperText, { type: validationError ? EHelperTextType.ERROR : undefined, children: validationError })] }));
};
