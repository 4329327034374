import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ETableFilterComparison } from "../../interfaces";
import { ETableFilterValueType } from "../../interfaces";
import { TextFilter } from "./components/TextFilter";
import { BooleanFilter } from "./components/BooleanFilter";
import { SingleOptionFilter } from "./components/SingleOptionFilter";
import { MultiOptionFilter } from "./components/MultiOptionFilter";
export const FilterInput = (props) => {
    const { filter, filter: { type, label, options = [], value: _value, }, onChange, } = props;
    const value = _value; // Cast it as any
    const helperText = (() => {
        const { filter: { comparison } } = props;
        switch (comparison) {
            case ETableFilterComparison.CONTAINS: return '(*) Containing';
            case ETableFilterComparison.EQUAL: return '(=) Exact';
            case ETableFilterComparison.BIGGER: return '(>) Bigger than';
            case ETableFilterComparison.EQUAL_BIGGER: return '(=>) Equal of bigger than';
            case ETableFilterComparison.LESS: return '(<) Less than';
            case ETableFilterComparison.EQUAL_LESS: return '(=<) Equal or less than';
        }
    })();
    const handleInputChange = (value) => {
        onChange(Object.assign(Object.assign({}, filter), { value }));
    };
    switch (type) {
        case ETableFilterValueType.TEXT:
            return (_jsx(TextFilter, { label: label, helperLabel: helperText, value: value || '', onChange: handleInputChange }));
        case ETableFilterValueType.BOOLEAN:
            return (_jsx(BooleanFilter, { label: label, value: value, onChange: handleInputChange }));
        case ETableFilterValueType.OPTION:
            return (_jsx(SingleOptionFilter, { label: label, options: options, value: value, onChange: handleInputChange }));
        case ETableFilterValueType.OPTIONS:
            return (_jsx(MultiOptionFilter, { label: label, options: options, value: value, onChange: handleInputChange }));
        case ETableFilterValueType.DATE:
        case ETableFilterValueType.NUMBER:
            return _jsxs("div", { children: ["Err: Filter type [", type, "] is not implemented"] });
    }
};
