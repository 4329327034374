import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { dynaSwitchEnum } from "dyna-switch";
import { Link as RouterLink_ } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import { getDataComponentName, } from "../ui-interfaces";
import { Box } from "../Box";
import { IconViewer } from "../IconViewer";
import { useKeyStatus } from "../useKeyStatus";
import { sxTransition, ECSSDuration, } from "../sxTransition";
import { createIcon } from "../IconComponent";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
export var ELinkDisplay;
(function (ELinkDisplay) {
    ELinkDisplay["INLINE"] = "INLINE";
    ELinkDisplay["BLOCK"] = "BLOCK";
})(ELinkDisplay || (ELinkDisplay = {}));
export var ELinkColor;
(function (ELinkColor) {
    ELinkColor["INHERIT"] = "INHERIT";
    ELinkColor["PRIMARY"] = "PRIMARY";
    ELinkColor["SECONDARY"] = "SECONDARY";
    ELinkColor["SUCCESS"] = "SUCCESS";
    ELinkColor["ERROR"] = "ERROR";
    ELinkColor["INFO"] = "INFO";
    ELinkColor["WARNING"] = "WARNING";
    ELinkColor["WHITE"] = "WHITE";
})(ELinkColor || (ELinkColor = {}));
export var ELinkUnderline;
(function (ELinkUnderline) {
    ELinkUnderline["ALWAYS"] = "always";
    ELinkUnderline["HOVER"] = "hover";
    ELinkUnderline["NONE"] = "none";
})(ELinkUnderline || (ELinkUnderline = {}));
export const Link = (props) => {
    const { sx = {}, show = true, display = ELinkDisplay.INLINE, dataComponentName, color = ELinkColor.PRIMARY, disabled = false, underline = ELinkUnderline.HOVER, title, href = "", target, showOpenInNewIcon, rel, hidden = false, tabIndex, routerLinkForLocals = true, forLocalLinksScrollToTop = true, children, onClick, } = props;
    const { shiftKeyDown, ctrlKeyDown, cmdKeyDown, } = useKeyStatus();
    if (!show)
        return null;
    const muiButtonColor = dynaSwitchEnum(color, {
        [ELinkColor.INHERIT]: 'inherit',
        [ELinkColor.PRIMARY]: 'primary',
        [ELinkColor.SECONDARY]: 'secondary',
        [ELinkColor.SUCCESS]: 'success',
        [ELinkColor.ERROR]: 'error',
        [ELinkColor.INFO]: 'info',
        [ELinkColor.WARNING]: 'warning',
        [ELinkColor.WHITE]: 'white',
    });
    const handleClick = (event) => {
        if (disabled)
            return;
        if (href
            && forLocalLinksScrollToTop
            && href.startsWith('/')
            && target !== "_blank"
            && !shiftKeyDown
            && !ctrlKeyDown
            && !cmdKeyDown) {
            window.scrollTo(0, 0);
        }
        onClick && onClick(event);
    };
    if (hidden)
        return null;
    const isLocalLink = href.startsWith('/') && routerLinkForLocals;
    const sxLink = Object.assign(Object.assign({}, sx), { opacity: disabled ? 0.6 : undefined, cursor: !disabled && (!!href || !!onClick) ? 'pointer' : undefined });
    return (_jsxs(Box, { "data-component-name": getDataComponentName(dataComponentName, "Link"), inline: display === ELinkDisplay.INLINE, children: [_jsx(MuiLink, { sx: sxLink, component: isLocalLink
                    ? RouterLink_
                    : undefined, title: title, color: muiButtonColor, underline: underline, href: href ? isLocalLink ? undefined : href : undefined, to: href ? isLocalLink ? href : undefined : undefined, rel: rel, target: target, tabIndex: tabIndex, onClick: handleClick, children: children }), showOpenInNewIcon && (_jsx(MuiLink, { sx: Object.assign(Object.assign({}, sxLink), { position: 'relative', top: 2, p: 1, transition: theme => sxTransition(theme, 'background', ECSSDuration.SHORT), ':hover': { background: theme => theme.palette.grayShades.gray1 } }), underline: underline, rel: rel, href: href, target: "_blank", tabIndex: tabIndex, title: "Open in a new tab", children: _jsx(IconViewer, { Icon: createIcon.byMuiIcon(OpenInNewIcon) }) }))] }));
};
