import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import L from 'leaflet';
import { useEffect, useState, } from "react";
import * as ReactDOM from 'react-dom';
import { useMap } from 'react-leaflet';
export const MapComponent = ({ componentId, position, interactive = true, draggable = false, zIndex, children, onDragStart, onDrag, onDragEnd, }) => {
    const map = useMap();
    const [container, setContainer] = useState(null);
    useEffect(() => {
        if (!map)
            return;
        const divIcon = L.divIcon({
            className: `element-id--${componentId}`,
            html: '',
        });
        const marker = L.marker(position, {
            icon: divIcon,
            interactive,
            draggable,
            zIndexOffset: zIndex,
        });
        marker.addTo(map);
        if (onDragStart) {
            marker.on('dragstart', () => {
                onDragStart(marker.getLatLng());
            });
        }
        if (onDrag) {
            marker.on('drag', () => {
                onDrag(marker.getLatLng());
            });
        }
        if (onDragEnd) {
            marker.on('dragend', () => {
                onDragEnd(marker.getLatLng());
            });
        }
        const containerElement = marker.getElement();
        if (containerElement) {
            setContainer(containerElement);
        }
        return () => {
            marker.removeFrom(map);
            setContainer(null);
        };
    }, [
        componentId,
        map,
        position,
        draggable,
        !!onDragStart,
        !!onDrag,
        !!onDragEnd,
    ]);
    const handleDoubleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };
    if (!container)
        return null;
    return (_jsx("div", { onDoubleClick: handleDoubleClick, children: ReactDOM.createPortal(_jsx(_Fragment, { children: children }), container) }));
};
