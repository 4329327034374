import moment from "moment";
import { EDeviceType } from "../../device-management/interfaces/EDeviceType";
import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
export const getDefaultTrafficStudy = () => getDefaultDBEntityDocument({
    deviceId: '',
    deviceType: EDeviceType.UNKNOWN,
    label: '',
    start: Date.now(),
    end: moment().add(1, 'week')
        .valueOf(),
    binningPeriodInMinutes: 5,
    timezone: '',
    location: '',
    stateId: '',
    city: '',
    street: '',
    county: '',
    lane: '',
    speedLimitInMph: 0,
    operator: '',
    locked: false,
    lockedReason: '',
    deviceConfig: {},
    copiedFromStudyId: '',
    trafficDataCollectedAt: 0,
    lastPublishedAt: 0,
    notes: '',
});
