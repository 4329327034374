import {IAppRoute} from "../../application/config/IAppRoute";

import {routeTrafficStudyList} from "./routeTrafficStudyList";
import {routeTrafficStudyReports} from "./routeTrafficStudyReports";
import {routeTrafficStudyManage} from "./routeTrafficStudyManage";

export const routesTrafficStudies: IAppRoute<any, any>[] = [
  routeTrafficStudyList,
  routeTrafficStudyReports,
  routeTrafficStudyManage,
];
