/**
 * Returns the style as a string, including only the actual styles applied to the element, compared to the browser's default styles.
 */
export const getElementStyleString = (element) => {
    const computedStyle = window.getComputedStyle(element);
    const defaultComputedStyle = getNodeDefaultStyle(element);
    let styleString = "";
    for (const key of computedStyle) {
        const defaultComputedStyleValue = defaultComputedStyle[key];
        const computedStyleValue = computedStyle[key];
        if (defaultComputedStyleValue === computedStyleValue)
            continue;
        styleString += `${key}: ${computedStyleValue};`;
    }
    return styleString;
};
const cache = {};
const getNodeDefaultStyle = (element) => {
    const nodeName = element.nodeName;
    const cached = cache[nodeName];
    if (cached)
        return cached;
    const defaultElement = document.createElement(nodeName);
    document.body.appendChild(defaultElement);
    const computedStyleSource = window.getComputedStyle(defaultElement);
    const computedStyle = {};
    for (const key of computedStyleSource)
        computedStyle[key] = computedStyleSource[key];
    document.body.removeChild(defaultElement);
    cache[nodeName] = computedStyle;
    return computedStyle;
};
