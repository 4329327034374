import {EWorkZonesManagementRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {WorkzonesAppSettings} from "../pages/WorkzonesAppSettings/WorkzonesAppSettings";

import WorkzoneAppSettingsIcon from "@mui/icons-material/Tune";

export const routeWorkzonesAppSettings: IAppRoute = {
  title: 'Settings',
  icon: <WorkzoneAppSettingsIcon/>,
  routePath: '/cimt/settings',
  getRoutePath: () => routeWorkzonesAppSettings.routePath,
  menuId: 'cimt-settings',
  userHasAllRights: [EWorkZonesManagementRights.WORKZONES_EDIT],
  userHasAnyOfRights: [],
  render: () => <WorkzonesAppSettings/>,
};
