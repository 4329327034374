import * as L from "leaflet";
import { convertJSXElementToHTML } from "../../utils";
import "./customIconMarker.less";
/**
 * Create a custom icon marker based on JSX.Element
 *
 * # Warning, currently no support for MUI's SX classes
 *
 * Due to the nature of the React's renderToString, the MUI's sx classes are not added to the DOM.
 * To fix this problem, render the jsxElement like this <Box hidden children={jsxElement}/>
 *
 * Since currently the MUI's sx classes are not added on the DOM
 * @param element
 */
export const customIconMarkerByJSXElement = (element) => {
    const iconMarkup = convertJSXElementToHTML({
        jsxElement: element,
        prettify: true,
    });
    return L.divIcon({
        className: 'leaflet-marker-div-icon',
        html: iconMarkup,
        iconAnchor: [0, 0], // Allow elements to customize top/bottom/left/right for custom positioning
        popupAnchor: [-3, -24],
    });
};
