import { convertDictionaryToViewLabelArray } from "utils-library/dist/utils";
export var ETrafficStudiesRights;
(function (ETrafficStudiesRights) {
    ETrafficStudiesRights["READ"] = "TS_R";
    ETrafficStudiesRights["WRITE"] = "TS_W";
    ETrafficStudiesRights["COMMENT"] = "TS_C";
    ETrafficStudiesRights["CREATE_MOCK_TRAFFIC_DATA"] = "TS_CMTD";
})(ETrafficStudiesRights || (ETrafficStudiesRights = {}));
export const ETrafficStudiesRightsArray = convertDictionaryToViewLabelArray({
    [ETrafficStudiesRights.READ]: "Traffic studies / Read",
    [ETrafficStudiesRights.WRITE]: "Traffic studies / Write",
    [ETrafficStudiesRights.COMMENT]: "Traffic studies / Comment",
    [ETrafficStudiesRights.CREATE_MOCK_TRAFFIC_DATA]: "Mock / Create Mock Traffic Data",
});
