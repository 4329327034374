import moment from "moment-timezone";
import { formatTime } from "./formatTime";
import { getSystemTimezone } from "./getSystemTimezone";
import { getIndustrialHours } from "./getIndustrialHours";
import { getIndustrialMinutes } from "./getIndustrialMinutes";
import { getWeekNoOfMonth } from "./getWeekNoOfMonth";
/**
 * Gets the local time, formatted time label, and industrial hours for a given date and timezone.
 */
export const getDateTimeDetails = ({ date, timezone = getSystemTimezone(), format = 'US', }) => {
    if (typeof date === "string"
        && (date[date.length - 1] || "").toLowerCase() !== "z") {
        console.error(`getLocalDateTime: The given date value [${date}] is not UTC ISO 8601 (should end with Z and be UTC time). The value parsed as local and returned value would be wrong!`, {
            args: {
                date,
                timezone,
                format,
            },
        });
    }
    const localDate = moment(date).tz(timezone);
    return {
        moment: localDate,
        timezone,
        timestamp: localDate.valueOf(),
        year: localDate.year(),
        month: localDate.month(),
        day: localDate.date(),
        daysInMonth: localDate.daysInMonth(),
        weekday: localDate.weekday(),
        weekOfYear: localDate.weeks(),
        weekOfMonth: getWeekNoOfMonth(date, timezone),
        hour: localDate.hours(),
        minute: localDate.minutes(),
        second: localDate.seconds(),
        labelTime: formatTime(localDate.hours(), localDate.minutes(), format),
        labelDate: localDate.format(format === "US" ? "MM/DD/YYYY" : "DD/MM/YYYY"),
        industrialHours: getIndustrialHours(localDate.hours(), localDate.minutes()),
        industrialMinutes: getIndustrialMinutes(localDate.hours(), localDate.minutes()),
    };
};
