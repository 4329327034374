import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "../Box";
import { Collapse } from "../Collapse";
import { ProgressIcon } from "../ProgressIcon";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { sxTransition, ECSSDuration, } from "../sxTransition";
export const IsLoadingBox = (props) => {
    const { sx = {}, isLoading = true, collapsible = true, animation = true, spacing = 1, iconSize = 6, children, inline = false, } = props;
    return (_jsx(Box, { dataComponentName: "IsLoadingBox", sx: Object.assign({ margin: inline ? undefined : "auto", opacity: isLoading ? 1 : 0, transition: theme => sxTransition(theme, 'opacity', ECSSDuration.SHORT) }, sx), inline: inline, children: _jsx(Collapse, { expanded: collapsible ? isLoading : true, animation: animation, children: _jsxs(FlexContainerHorizontal, { spacing: spacing, alignVertical: "middle", children: [_jsx(FlexItemMin, { children: _jsx(ProgressIcon, { size: iconSize }) }), _jsx(FlexItemMax, { show: !!children, children: _jsx(Box, { sx: { opacity: 0.7 }, children: children }) })] }) }) }));
};
