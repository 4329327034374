export const getDefaultAppItisCodes = () => {
    return [
        {
            code: 1025,
            label: "Road Construction",
        },
        {
            code: 769,
            label: "Closed to Traffic",
        },
        {
            code: 12545,
            label: "1",
        },
        {
            code: 8196,
            label: "Right Lane",
        },
        {
            code: 8195,
            label: "Left Lane",
        },
        {
            code: 771,
            label: "Closed Ahead",
        },
        {
            code: 7451,
            label: "Merge",
        },
        {
            code: 13580,
            label: "Left",
        },
        {
            code: 13579,
            label: "Right",
        },
        {
            code: 8208,
            label: "Right Shoulder",
        },
        {
            code: 8209,
            label: "Left Shoulder",
        },
        {
            code: 13594,
            label: "Left Turn",
        },
        {
            code: 13599,
            label: "Right Turn",
        },
        {
            code: 268,
            label: "Speed Limit",
        },
        {
            code: 12559,
            label: "15",
        },
        {
            code: 12564,
            label: "20",
        },
        {
            code: 12569,
            label: "25",
        },
        {
            code: 12574,
            label: "30",
        },
        {
            code: 12579,
            label: "35",
        },
        {
            code: 12584,
            label: "40",
        },
        {
            code: 12589,
            label: "45",
        },
        {
            code: 12594,
            label: "50",
        },
        {
            code: 12599,
            label: "55",
        },
        {
            code: 12604,
            label: "60",
        },
        {
            code: 12609,
            label: "65",
        },
        {
            code: 12614,
            label: "70",
        },
        {
            code: 8720,
            label: "MPH",
        },
        {
            code: 2574,
            label: "Height Limit",
        },
        {
            code: 13569,
            label: "Ahead",
        },
        {
            code: 12712,
            label: "168",
        },
        {
            code: 8709,
            label: "Inches",
        },
    ];
};
