import {IAppRoute} from "../config/IAppRoute";
import {ViewMenuItems} from "../pages/ViewMenuItems";
import {routeAdministratorPagePaths} from "./routeAdministratorPage.paths";

export const routeAdministratorPage: IAppRoute = {
  ...routeAdministratorPagePaths,
  render: () => (
    <ViewMenuItems
      menuId={routeAdministratorPagePaths.menuId}
    />
  ),
};
