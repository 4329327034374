import * as React from "react";
import {connect} from "react-dynadux";

import {IAppStore} from "../../../state/IAppStore";

export interface IAppRouteProps {
  store: IAppStore;
  appTitle: string;
  menuId?: string;
  children: any;
}

class AppRoute_ extends React.Component<IAppRouteProps> {
  public componentDidMount() {
    const {
      appTitle,
      menuId,
      store: {
        app: {
          actions: {
            setTitle,
            setMenuId,
          },
        },
      },
    } = this.props;
    setTimeout(() => {
      // Set the title but on next tick
      setTitle(appTitle);
    }, 0);
    if (menuId) setMenuId(menuId);
  }

  public componentDidUpdate(prevProps: Readonly<IAppRouteProps>) {
    const {
      appTitle,
      menuId,
      store: {
        app: {
          actions: {
            setTitle,
            setMenuId,
          },
        },
      },
    } = this.props;
    if (appTitle !== prevProps.appTitle) setTitle(appTitle);
    if (menuId && menuId !== prevProps.menuId) setMenuId(menuId);
  }

  public render(): JSX.Element {
    const {children} = this.props;
    return children;
  }
}

export const AppRoute = connect(AppRoute_, {debounce: {timeout: 500}});
