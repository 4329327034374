import { useState, useEffect, } from "react";
import { useHistory } from "react-router-dom";
import { useConfirm } from "../useConfirm";
export const PromptRouteChange = (props) => {
    const { active = true, getPrompt, } = props;
    const { confirm, confirmViewer, } = useConfirm();
    const { lastBlockedLocation, clearLastBlockedLocation, } = useBlockNavigation(history => {
        if (!active)
            return false;
        return !!getPrompt(history);
    });
    useEffect(() => {
        if (!lastBlockedLocation)
            return;
        const prompt = getPrompt(lastBlockedLocation);
        if (!prompt)
            return; // 4TS
        confirm({
            title: prompt.title,
            message: prompt.message,
            labelConfirmButton: 'Leave',
            labelCancelButton: 'No, stay',
        })
            .then(leave => {
            if (leave)
                window.location.pathname = lastBlockedLocation.pathname; // Dev info: history.push(lastBlockedLocation.pathname); doesn't work for some reason!
            clearLastBlockedLocation();
        });
    }, [lastBlockedLocation]);
    return confirmViewer;
};
const useBlockNavigation = (shouldBlock) => {
    const history = useHistory();
    const [lastBlockedLocation, setLastBlockedLocation] = useState(null);
    useEffect(() => {
        const unblock = history.block((location) => {
            const block = shouldBlock(location);
            block
                ? setLastBlockedLocation(location)
                : setLastBlockedLocation(null);
            return block
                ? false
                : undefined;
        });
        return () => {
            unblock();
        };
    }, [history, shouldBlock]);
    return {
        lastBlockedLocation,
        clearLastBlockedLocation: () => setLastBlockedLocation(null),
    };
};
