import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";

import {routeUserInvitationManagePaths} from "./routeUserInvitationManage.paths";

const UserInvitationManagePage = lazy(() => import("../pages/UserInvitationManagePage/UserInvitationManagePage"));

export const routeUserInvitationManage: IAppRoute<{
  email: string | 'create-a-new-one';
}> = {
  ...routeUserInvitationManagePaths,
  render: ({pathParams: {email}}) => (
    <LazyLoadComponent>
      <UserInvitationManagePage
        email={email}
      />
    </LazyLoadComponent>
  ),
};
