import { roundToString } from "dyna-loops";
import { isNumber } from "./isNumber";
import { pluralize } from "./pluralize";
export const pluralizedCount = ({ this: _this = false, isAtFront = false, unit, items, precision = 0, }) => {
    const count = isNumber(items)
        ? items
        : items.length;
    const countLabel = (() => {
        if (count === 1)
            return "one";
        if (count === 2)
            return "two";
        if (count === 3)
            return "three";
        if (count === 10)
            return "ten";
        return roundToString(count, precision);
    })();
    const wordPluralized = pluralize(unit, items);
    return [
        _this && pluralize('this', items),
        isAtFront && pluralize('is', items),
        countLabel,
        wordPluralized,
    ]
        .filter(Boolean)
        .join(' ');
};
