import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MuiIconButton from '@mui/material/IconButton';
export var EIconButtonSize;
(function (EIconButtonSize) {
    EIconButtonSize["SMALL"] = "small";
    EIconButtonSize["MEDIUM"] = "medium";
    EIconButtonSize["LARGE"] = "large";
})(EIconButtonSize || (EIconButtonSize = {}));
export const IconButton = (props) => {
    const { ariaLabel, ariaHasPopUp = false, ariaControls, sx = {}, show = true, noPadding, icon, Icon, title, size, disabled, disableRipple, onClick, } = props;
    if (!show)
        return null;
    if (!icon && !Icon)
        return null;
    return (_jsxs(MuiIconButton, { "aria-label": ariaLabel, "aria-haspopup": ariaHasPopUp, "aria-controls": ariaControls, size: size, sx: sx, style: { padding: noPadding ? 0 : undefined }, title: title, disabled: disabled, disableRipple: disableRipple, onClick: onClick, children: [icon, Icon && (_jsx("span", { style: {
                    borderRadius: '50%',
                    overflow: 'hidden',
                    fontSize: 0,
                }, children: _jsx(Icon, { width: 32 }) }))] }));
};
