var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { dynaSwitchEnum } from "dyna-switch";
import { textEllipsis, convertLengthMetersToFeet, convertLengthFeetToMeters, } from "utils-library/dist/utils";
import { FlexContainerParent, FlexContainer, FlexItemMax, FlexItemMin, } from "../../FlexContainer";
import { IconButton, EIconButtonSize, } from "../../IconButton";
import { Condition } from "../../Condition";
import { Tooltip } from "../../Tooltip";
import { InputDualUnit } from "../../InputDualUnit";
import { useClipboardPaste } from "../../useClipboardPaste";
import { useSetTimeout } from "../../useSetTimeout";
import { useIsMounted } from "../../useIsMounted";
import { EGeoCoordinatesEditorSize } from "../interfaces";
import { getGeoPositionByString } from "../utils/getGeoPositionByString";
import { CoordinateInput } from "./components/CoordinateInput";
import { createIcon } from "../../IconComponent";
import { sxTransition, ECSSDuration, } from "../../sxTransition";
import ResolveAltIcon from '@mui/icons-material/AutoMode';
import PasteIcon from '@mui/icons-material/ContentPaste';
import InsertIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
export const InputCoordinate = ({ size = EGeoCoordinatesEditorSize.MEDIUM, readOnly = false, disableAdd = true, focused = false, showAlt = true, coordinate, coordinate: { lat, lng, alt = 0, }, onElevationResolve, onFocus, onInsert, onDelete, onChange, }) => {
    const getIsMounted = useIsMounted();
    const [disableTheElevInput, setDisableTheElevInput] = useState(false);
    const { clipboardText } = useClipboardPaste();
    const [pasteError, setPasteError] = useState(null);
    const { start: pasteErrorStart, clear: pasteErrorTimerClear, } = useSetTimeout({ cb: () => setPasteError(null) });
    const updateAlt = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (forPosition = coordinate) {
        if (!onElevationResolve)
            return;
        try {
            if (!getIsMounted())
                return;
            setDisableTheElevInput(true);
            const alt = yield onElevationResolve(forPosition);
            onChange({
                lat: forPosition.lat,
                lng: forPosition.lng,
                alt,
            });
        }
        catch (error) {
            console.error(`Cannot resolve the elevation`, {
                forPosition,
                error,
            });
        }
        finally {
            if (getIsMounted())
                setDisableTheElevInput(false);
        }
    });
    const handleContainerClick = (event) => {
        if (event.target.nodeName === 'INPUT')
            return;
        onFocus && onFocus();
    };
    const handleLatChange = (newLat) => {
        if (newLat === lat)
            return;
        onChange({
            lat: newLat,
            lng,
            alt,
        });
    };
    const handleLngChange = (newLng) => {
        if (newLng === lng)
            return;
        onChange({
            lat,
            lng: newLng,
            alt,
        });
    };
    const handleElevChange = (newAlt) => {
        if (newAlt === alt)
            return;
        onChange({
            lat,
            lng,
            alt: newAlt,
        });
    };
    const handleResolveAlt = () => updateAlt();
    const handlePasteClick = () => {
        const { coordinate: newPosition, error: parseError, } = getGeoPositionByString(clipboardText);
        pasteErrorTimerClear();
        if (newPosition) {
            setPasteError(null);
            onChange(newPosition);
            if (newPosition.alt === undefined)
                updateAlt(newPosition);
        }
        else {
            setPasteError(parseError);
            pasteErrorStart(3000);
        }
    };
    const buttonScale = dynaSwitchEnum(size, {
        [EGeoCoordinatesEditorSize.SMALL]: 0.5,
        [EGeoCoordinatesEditorSize.MEDIUM]: 0.6,
    });
    const sxIconContainer = {
        position: 'relative',
        top: dynaSwitchEnum(size, {
            [EGeoCoordinatesEditorSize.SMALL]: '-6px',
            [EGeoCoordinatesEditorSize.MEDIUM]: '-7px',
        }),
        mb: dynaSwitchEnum(size, {
            [EGeoCoordinatesEditorSize.SMALL]: '-14px',
            [EGeoCoordinatesEditorSize.MEDIUM]: '-16px',
        }),
    };
    const sxIcon = { transform: `scale(${buttonScale})` };
    return (_jsxs(FlexContainerParent, { sx: {
            py: theme => theme.spacing(dynaSwitchEnum(size, {
                [EGeoCoordinatesEditorSize.SMALL]: 0.5,
                [EGeoCoordinatesEditorSize.MEDIUM]: 0.75,
            })),
            transition: theme => sxTransition(theme, 'background', ECSSDuration.SHORT),
            background: theme => focused ? theme.palette.grayShades.gray1 : undefined,
        }, dataComponentName: "CoordinateEditor", spacing: dynaSwitchEnum(size, {
            [EGeoCoordinatesEditorSize.SMALL]: 0,
            [EGeoCoordinatesEditorSize.MEDIUM]: 0.5,
        }), alignVertical: "top", verticalMaxWidth: 460, onClick: handleContainerClick, children: [_jsx(FlexItemMax, { flex: 3, children: _jsx(CoordinateInput, { size: size, title: "Latitude", label: "LAT", readOnly: readOnly, validation: {
                        min: -90,
                        max: 90,
                    }, value: lat, onFocus: onFocus, onBlur: handleLatChange }) }), _jsx(FlexItemMin, { children: _jsx("div", { style: { width: '6px' } }) }), _jsx(FlexItemMax, { flex: 3, children: _jsx(CoordinateInput, { size: size, title: "Longitude", label: "LNG", readOnly: readOnly, validation: {
                        min: -180,
                        max: 180,
                    }, value: lng, onFocus: onFocus, onBlur: handleLngChange }) }), _jsxs(Condition, { if: showAlt, children: [_jsx(FlexItemMin, { children: _jsx("div", { style: { width: '6px' } }) }), _jsx(FlexItemMax, { flex: 2, children: _jsx(InputDualUnit, { convertActualToUserValue: convertLengthMetersToFeet, convertUserValueToActual: convertLengthFeetToMeters, value: alt, onBlur: handleElevChange, children: ({ value, validationError, onBlur, }) => (_jsxs(FlexContainer, { children: [_jsx(FlexItemMax, { children: _jsx(CoordinateInput, { size: size, title: "Elevation", label: "ELEV", readOnly: readOnly, validation: {
                                                min: -5000,
                                                max: 5000,
                                            }, disabled: disableTheElevInput, helperLabel: "feet", value: Number(value), validationError: validationError, onFocus: onFocus, onBlur: n => onBlur(n.toString()) }) }), _jsx(FlexItemMin, { show: !!onElevationResolve, sx: sxIconContainer, children: _jsx(IconButton, { sx: sxIcon, show: !readOnly, size: EIconButtonSize.SMALL, ariaLabel: "Retrieve the elevation", title: "Retrieve the elevation from the internet", noPadding: true, Icon: createIcon.byMuiIcon(ResolveAltIcon), onClick: handleResolveAlt }) })] })) }) })] }), _jsxs(FlexItemMin, { sx: Object.assign({ textAlign: 'right', whiteSpace: 'nowrap', paddingLeft: theme => theme.spacing(1) }, sxIconContainer), children: [_jsx(Tooltip, { title: pasteError
                            ? textEllipsis('Clipboard is not a coordinate, error: ' + pasteError, 120)
                            : '', showDelay: 0, children: _jsx(IconButton, { sx: Object.assign(Object.assign({}, sxIcon), { color: theme => pasteError ? theme.palette.error.main : undefined }), show: !readOnly, size: EIconButtonSize.SMALL, ariaLabel: "Paste coordinates", title: "Paste coordinates from clipboard", noPadding: true, disabled: !clipboardText, Icon: createIcon.byMuiIcon(PasteIcon), onClick: handlePasteClick }) }), _jsx(IconButton, { show: !!onInsert && !disableAdd && !readOnly, sx: sxIcon, size: EIconButtonSize.SMALL, ariaLabel: "Insert coordinate", title: "Insert new coordinate next of it", noPadding: true, Icon: createIcon.byMuiIcon(InsertIcon), onClick: onInsert }), _jsx(IconButton, { show: !!onDelete && !readOnly, sx: sxIcon, size: EIconButtonSize.SMALL, ariaLabel: "Delete", title: "Delete this coordinate", noPadding: true, Icon: createIcon.byMuiIcon(DeleteIcon), onClick: onDelete })] })] }));
};
