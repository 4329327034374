import { round } from "./round";
export const getRatio = (width, height, precision = 0) => {
    const ratio = width > height ? width / height : height / width;
    if (width > height) {
        return {
            a: round(ratio, precision),
            b: 1,
        };
    }
    else {
        return {
            a: 1,
            b: round(ratio, precision),
        };
    }
};
