import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

export const routeUserInvitationEntryPagePaths: IAppRoute = {
  menuId: '',
  title: 'Welcome...',
  routePath: '/invitation',
  getRoutePath: () => routeUserInvitationEntryPagePaths.routePath,
  signWithInvitation: true,
  userHasAllRights: [],
  userHasAnyOfRights: [EUserAuthenticationRights.ACTIVE],
  exact: true,
  render: () => null,
};
