import {lazy} from "react";

import {LazyLoadComponent} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";

const WrongWayResolutionCreateZipTestPage = lazy(() => import('../pages/WrongWayResolutionCreateZipTestPage'));

export const routeWrongWayResolutionCreateZipTestPage: IAppRoute = {
  menuId: '',
  title: 'WWR Zip creation',
  routePath: '/test/WWRZipCreation',
  exact: true,
  getRoutePath: () => routeWrongWayResolutionCreateZipTestPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => (
    <LazyLoadComponent>
      <WrongWayResolutionCreateZipTestPage/>
    </LazyLoadComponent>
  ),
};
