/**
 * Interface representing the configuration for a field or column.
 */
export var EComponentName;
(function (EComponentName) {
    EComponentName["PROFILE_ICON"] = "PROFILE_ICON";
})(EComponentName || (EComponentName = {}));
export var EAlign;
(function (EAlign) {
    EAlign["LEFT"] = "LEFT";
    EAlign["RIGHT"] = "RIGHT";
    EAlign["CENTER"] = "CENTER";
})(EAlign || (EAlign = {}));
export var EValueType;
(function (EValueType) {
    /**
     * Simple text, as sting
     *
     * Configuration: value
     */
    EValueType["TEXT"] = "FC__TEXT";
    /**
     * Integer (number without decimal points)
     *
     * Configuration: value, leadingZeros, thousandsSeparator
     */
    EValueType["INTEGER"] = "FC__INTEGER";
    /**
     * As number (the precision will be used)
     *
     * Configuration: value, ?precision, ?leadingZeros, ?trailingZeros, ?thousandsSeparator, ?decimalPoint
     */
    EValueType["NUMBER"] = "FC__NUMBER";
    /**
     * As number (the precision will be used)
     *
     * Configuration: value, currency, ?currencySymbolPosition, ?precision, ?leadingZeros, ?trailingZeros, ?thousandsSeparator, ?decimalPoint
     */
    EValueType["CURRENCY"] = "FC__CURRENCY";
    /**
     * Date
     *
     * Configuration: value, formatDate, ?timezone, ?overrideUserLocale
     */
    EValueType["DATE"] = "FC__DATE";
    /**
     * Time
     *
     * Configuration: value, formatTime, ?timezone, ?overrideUserLocale
     */
    EValueType["TIME"] = "FC__TIME";
    /**
     * Date time
     *
     * Configuration: value, formatDate, formatTime, ?dateTimeOrder, ?timezone, ?overrideUserLocale
     */
    EValueType["DATE_TIME"] = "FC__DATE_TIME";
    /**
     * Date time
     *
     * Configuration: value (in ms only), ?formatDuration, ?precision, ?leadingZeros, ?trailingZeros, ?decimalPoint, ?thousandsSeparator, ?overrideUserLocale
     */
    EValueType["DURATION"] = "FC__DURATION";
})(EValueType || (EValueType = {}));
export var EDateFormatter;
(function (EDateFormatter) {
    EDateFormatter["HIDDEN"] = "HIDDEN";
    // Combinations
    EDateFormatter["STANDARD"] = "STANDARD";
    EDateFormatter["LONG_DATE"] = "LONG_DATE";
    EDateFormatter["FULL_DATE"] = "FULL_DATE";
    EDateFormatter["YEAR_MONTH"] = "YEAR_MONTH";
    EDateFormatter["MONTH_YEAR"] = "MONTH_YEAR";
    // Week
    EDateFormatter["DAY_OF_WEEK"] = "DAY_OF_WEEK";
    // Month
    EDateFormatter["SHORT_MONTH"] = "SHORT_MONTH";
    EDateFormatter["FULL_MONTH"] = "FULL_MONTH";
    // Year
    EDateFormatter["SHORT_YEAR"] = "SHORT_YEAR";
    EDateFormatter["FULL_YEAR"] = "FULL_YEAR";
    // ISO
    EDateFormatter["ISO"] = "ISO";
})(EDateFormatter || (EDateFormatter = {}));
export var ETimeFormatter;
(function (ETimeFormatter) {
    ETimeFormatter["HIDDEN"] = "HIDDEN";
    ETimeFormatter["HOUR"] = "HOUR";
    ETimeFormatter["HOUR_MINUTE"] = "HOUR_MINUTE";
    ETimeFormatter["HOUR_MINUTE_SECONDS"] = "HOUR_MINUTE_SECONDS";
    ETimeFormatter["HOUR_MINUTE_SECONDS_MILLISECONDS"] = "HOUR_MINUTE_SECONDS_MILLISECONDS";
})(ETimeFormatter || (ETimeFormatter = {}));
export var EDateTimeOrder;
(function (EDateTimeOrder) {
    EDateTimeOrder["DATE_TIME"] = "DATE_TIME";
    EDateTimeOrder["TIME_DATE"] = "TIME_DATE";
})(EDateTimeOrder || (EDateTimeOrder = {}));
export var EDurationFormatter;
(function (EDurationFormatter) {
    EDurationFormatter["INDUSTRIAL_MINUTES"] = "INDUSTRIAL_MINUTES";
    EDurationFormatter["INDUSTRIAL_HOURS"] = "INDUSTRIAL_HOURS";
    EDurationFormatter["HOURS_MINUTES"] = "HOURS_MINUTES";
    EDurationFormatter["HOURS_MINUTES_SECONDS"] = "HOURS_MINUTES_SECONDS";
    EDurationFormatter["DAYS_HOURS_MINUTES"] = "DAYS_HOURS_MINUTES";
    EDurationFormatter["MINUTES"] = "MINUTES";
    EDurationFormatter["SECONDS"] = "SECONDS";
    EDurationFormatter["HOURS_ONLY"] = "HOURS_ONLY";
    EDurationFormatter["DAYS_ONLY"] = "DAYS_ONLY";
    EDurationFormatter["FULL_DURATION"] = "FULL_DURATION";
    EDurationFormatter["SHORT_DURATION"] = "SHORT_DURATION";
    EDurationFormatter["LONG_DURATION"] = "LONG_DURATION";
    EDurationFormatter["SHORT_HOURS_MINUTES"] = "SHORT_HOURS_MINUTES";
    EDurationFormatter["SHORT_HOURS_MINUTES_SECONDS"] = "SHORT_HOURS_MINUTES_SECONDS";
})(EDurationFormatter || (EDurationFormatter = {}));
