import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { guid } from "dyna-guid";
import { dynaSwitchEnum } from "dyna-switch";
import SwipeableViews from "react-swipeable-views";
import { BreakpointDeviceContainer } from "../BreakpointDeviceContainer";
import { Condition } from "../Condition";
import { IconViewer } from "../IconViewer";
import { FlexContainer, EFlexContainerOrientation, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { Box } from "../Box";
import { useTheme, } from "../ThemeProvider";
import AppBar from "@mui/material/AppBar";
import MuiTabs from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import ErrorIcon from "@mui/icons-material/Error";
export var ETabIconPosition;
(function (ETabIconPosition) {
    ETabIconPosition["TOP"] = "TOP";
    ETabIconPosition["LEFT"] = "LEFT";
})(ETabIconPosition || (ETabIconPosition = {}));
export var ETabSize;
(function (ETabSize) {
    ETabSize["XSMALL"] = "XSMALL";
    ETabSize["SMALL"] = "SMALL";
    ETabSize["MEDIUM"] = "MEDIUM";
    ETabSize["LARGE"] = "LARGE";
})(ETabSize || (ETabSize = {}));
export var ETabOrientation;
(function (ETabOrientation) {
    ETabOrientation["HORIZONTAL"] = "HORIZONTAL";
    ETabOrientation["VERTICAL"] = "VERTICAL";
})(ETabOrientation || (ETabOrientation = {}));
export var ETabVariant;
(function (ETabVariant) {
    ETabVariant["STANDARD"] = "standard";
    ETabVariant["FULL_WIDTH"] = "fullWidth";
    ETabVariant["SCROLLABLE"] = "scrollable";
})(ETabVariant || (ETabVariant = {}));
export const Tabs = (props) => {
    const theme = useTheme();
    const { sx = {}, dataComponentName, slideMode = false, variant = ETabVariant.STANDARD, orientation = ETabOrientation.HORIZONTAL, hideLabelsOnMobile = false, hideLabelsOnTablet = false, fullHeight = false, ariaLabel, tabs: tabDic, tab, iconPosition = ETabIconPosition.TOP, tabSize = ETabSize.MEDIUM, onChange, } = props;
    const idBase = useMemo(() => guid(0), []);
    const value = Math.max(Object.keys(tabDic).indexOf(tab), 0);
    const handleChange = (_event, newValue) => onChange(Object.keys(tabDic)[newValue]);
    const handleChangeIndex = (index) => handleChange(null, index);
    const sxContent = {
        overflowY: fullHeight ? 'auto' : undefined,
        height: fullHeight ? '100%' : undefined, // Just a random number, is needed to activate DOM's scrolling on first render.
        minHeight: fullHeight ? '100%' : undefined,
    };
    return (_jsxs(FlexContainer, { sx: sx, fullHeight: fullHeight, orientation: dynaSwitchEnum(orientation, {
            [ETabOrientation.HORIZONTAL]: EFlexContainerOrientation.VERTICAL,
            [ETabOrientation.VERTICAL]: EFlexContainerOrientation.HORIZONTAL,
        }), dataComponentName: [dataComponentName, "Tabs"].filter(Boolean).join(' '), children: [_jsx(FlexItemMin, { children: _jsx(AppBar, { position: "static", color: "default", children: _jsx(MuiTabs, { variant: variant, indicatorColor: "primary", textColor: "primary", "aria-label": ariaLabel, orientation: dynaSwitchEnum(orientation, {
                            [ETabOrientation.HORIZONTAL]: 'horizontal',
                            [ETabOrientation.VERTICAL]: 'vertical',
                        }), value: value, onChange: handleChange, children: Object.values(tabDic)
                            .filter(tab => tab.show !== false)
                            .map(({ Icon, label, ariaLabel, validationError, }, index) => (_jsx(MuiTab, { sx: {
                                minWidth: dynaSwitchEnum(tabSize, {
                                    [ETabSize.XSMALL]: 38,
                                    [ETabSize.SMALL]: 42,
                                    [ETabSize.MEDIUM]: 52,
                                    [ETabSize.LARGE]: 58,
                                }),
                                padding: dynaSwitchEnum(tabSize, {
                                    [ETabSize.XSMALL]: '2px',
                                    [ETabSize.SMALL]: undefined,
                                    [ETabSize.MEDIUM]: undefined,
                                    [ETabSize.LARGE]: undefined,
                                }),
                            }, id: `tab-${index}--${idBase}`, "aria-controls": `tabpanel-${index}--${idBase}`, "aria-label": ariaLabel, label: (_jsxs(FlexContainer, { alignCenter: true, orientation: iconPosition === ETabIconPosition.TOP
                                    ? EFlexContainerOrientation.VERTICAL
                                    : EFlexContainerOrientation.HORIZONTAL, children: [_jsxs(Box, { sx: {
                                            minWidth: 50,
                                            position: 'relative',
                                            '& > svg': (() => {
                                                const size = dynaSwitchEnum(tabSize, {
                                                    [ETabSize.XSMALL]: 14,
                                                    [ETabSize.SMALL]: 18,
                                                    [ETabSize.MEDIUM]: 24,
                                                    [ETabSize.LARGE]: 32,
                                                });
                                                return {
                                                    width: size,
                                                    height: size,
                                                };
                                            })(),
                                        }, children: [_jsx(IconViewer, { Icon: Icon, width: 24 }), !!validationError && (_jsx(Box, { component: "span", sx: Object.assign({ position: 'absolute', top: -6, left: 4 }, (tabSize === ETabSize.SMALL
                                                    ? { top: -11 }
                                                    : {})), title: validationError, children: _jsx(ErrorIcon, { sx: {
                                                        color: theme.palette.error.main,
                                                        width: dynaSwitchEnum(tabSize, {
                                                            [ETabSize.XSMALL]: 14,
                                                            [ETabSize.SMALL]: 18,
                                                            [ETabSize.MEDIUM]: 20,
                                                            [ETabSize.LARGE]: 22,
                                                        }),
                                                    } }) }))] }), _jsx(BreakpointDeviceContainer, { mobile: !hideLabelsOnMobile, tablet: !hideLabelsOnTablet, laptop: true, desktop: true, wide: true, children: _jsx(Box, { sx: {
                                                textTransform: 'none',
                                                fontSize: theme => dynaSwitchEnum(tabSize, {
                                                    [ETabSize.XSMALL]: theme.typography.fontSize * 1,
                                                    [ETabSize.SMALL]: theme.typography.fontSize * 1.1,
                                                    [ETabSize.MEDIUM]: theme.typography.fontSize * 1.2,
                                                    [ETabSize.LARGE]: theme.typography.fontSize * 1.3,
                                                }),
                                            }, children: label }) })] })) }, index))) }) }) }), _jsx(FlexItemMax, { sx: { paddingTop: theme.spacing(1) }, children: _jsx(Condition, { if: slideMode && orientation === ETabOrientation.HORIZONTAL, then: 
                    // @ts-ignore --> This is because the dep returns ReactElement and not JSX.Element
                    _jsx(SwipeableViews, { axis: dynaSwitchEnum(orientation, {
                            [ETabOrientation.VERTICAL]: theme.direction === 'rtl' ? 'y-reverse' : 'y',
                            [ETabOrientation.HORIZONTAL]: theme.direction === 'rtl' ? 'x-reverse' : 'x',
                        }), index: value, onChangeIndex: handleChangeIndex, children: Object.values(tabDic).map((tab, index) => (_jsx(Box, { id: `tabpanel-${index}--${idBase}`, sx: sxContent, "aria-labelledby": `tab-${index}--${idBase}`, dir: theme.direction, children: tab.content }, index))) }), else: Object.values(tabDic).map((tab, index) => (_jsx(Condition, { if: value === index, children: _jsx(Box, { id: `tabpanel-${index}--${idBase}`, sx: sxContent, "aria-labelledby": `tab-${index}--${idBase}`, dir: theme.direction, children: tab.content }, index) }, index))) }) })] }));
};
