import { useState, useEffect, } from "react";
export const useHover = (ref) => {
    const [hovering, setHovering] = useState(false);
    const handleMouseEnter = () => setHovering(true);
    const handleMouseLeave = () => setHovering(false);
    useEffect(() => {
        const node = ref.current;
        if (!node)
            return;
        node.addEventListener('mouseenter', handleMouseEnter);
        node.addEventListener('mouseleave', handleMouseLeave);
        return () => {
            node.removeEventListener('mouseenter', handleMouseEnter);
            node.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [ref]);
    return hovering;
};
