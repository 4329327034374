import { useState } from "react";
export const useLocalStorageState = ({ key: _key, "default": defaultState, doNotSave = false, }) => {
    const key = `useLocalStorageState--${_key}`;
    const initialState = (() => {
        const lsStateRaw = localStorage.getItem(key);
        if (lsStateRaw === undefined)
            return defaultState;
        if (lsStateRaw === null)
            return defaultState;
        return JSON.parse(lsStateRaw);
    })();
    const [state, _setState] = useState(initialState);
    const setState = (state) => {
        _setState(state);
        if (!doNotSave)
            localStorage.setItem(key, JSON.stringify(state));
    };
    return [
        state,
        setState,
    ];
};
