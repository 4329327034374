import { isGuid as dynaGuidIsGuid, isShortGuid, } from "dyna-guid";
import { isNumber as utilsIsNumber, textArrayEllipsis, convertEnumKeyToHumanReadable, getRatioAsString, getRatio, } from "../utils";
import { validateIsEmail, validateEmailLengthMessage, } from "./utils/validateIsEmail";
import { isSanitizedHtml as utilsIsSanitizedHtml } from "./utils/isSanitizedHtml";
import { containsHtml } from "./utils/containsHtml";
//#region "Validation methods implementation"
export const createValidationMethodOptionalVersion = (originalMethod) => (...args) => {
    if (args[0] === undefined)
        return "";
    return originalMethod(...args);
};
const isObject = (item) => {
    if (item === null)
        return "Null is unexpected here";
    if (item === undefined)
        return "This should not be undefined";
    if (!item)
        return "This should be an object";
    if (Array.isArray(item))
        return "This should not be an array";
    if (item instanceof Date)
        return "This should not be a Date";
    if (typeof item !== 'object')
        return "This should be an object";
    return "";
};
const isId = (v) => {
    if (!v)
        return "This is required";
    const validationErrorString = isString(v);
    if (validationErrorString)
        return validationErrorString;
    if (v.indexOf(' ') > -1)
        return "Spaces are not allowed";
    return '';
};
const isGuid = (v) => {
    return (isString(v) ||
        (() => {
            const result = dynaGuidIsGuid(v);
            return result === true
                ? ""
                : result;
        })() ||
        "");
};
const isGuidWithBlocks = (v, blocks) => {
    const result = dynaGuidIsGuid(v, blocks);
    if (result === true)
        return "";
    return "Invalid GUID format";
};
const isString = (text) => {
    return typeof text === 'string'
        ? ""
        : "This should be a string";
};
const isNotEmptyString = (text) => {
    const validationErrorString = isString(text);
    if (validationErrorString)
        return validationErrorString;
    if (text === "")
        return "This should not be empty";
    return "";
};
const isBoolean = (bool) => {
    if (bool === true || bool === false)
        return "";
    return "This should be a boolean value";
};
const isBooleanText = (bool) => {
    if (bool === "true" || bool === "false")
        return "";
    return 'This should be "true" or "false" (as text)';
};
const isBooleanOrBoth = (bool) => {
    if (bool === "both")
        return "";
    return isBoolean(bool);
};
const isNumber = (n) => {
    return utilsIsNumber(n)
        ? ""
        : "This should be a number";
};
const isNumberInArray = (value, array) => {
    const validationErrorNumber = isNumber(value);
    if (validationErrorNumber)
        return validationErrorNumber;
    if (!array.includes(value))
        return `Should be one of these ${textArrayEllipsis(array.map(mapValue), 5)}`;
    return "";
};
const isStringInArray = (value, array) => {
    if (!array.includes(value))
        return `Should be one of these ${textArrayEllipsis(array.map(mapValue), 5)}`;
    return "";
};
const isNumberInRange = (v, min, max) => {
    const validationErrorNumber = isNumber(v);
    if (validationErrorNumber)
        return validationErrorNumber;
    if (v >= min && v <= max)
        return "";
    return `Should be in range ${min}...${max}`;
};
const isInteger = (v) => {
    if (!Number.isInteger(v))
        return "This should be an integer";
    return "";
};
const isIntegerInRange = (v, min, max) => {
    const validationErrorInteger = isInteger(v);
    if (validationErrorInteger)
        return validationErrorInteger;
    return isNumberInRange(v, min, max);
};
const isTextNumber = (n) => {
    const validationErrorString = isString(n);
    if (validationErrorString)
        return validationErrorString;
    if (!utilsIsNumber(Number(n)))
        return "This should be a number as text";
    return "";
};
const isTextNumberInRange = (value, min, max) => (isTextNumber(value) ||
    isNumberInRange(Number(value), min, max) ||
    "");
const isTextInteger = (n) => (isTextNumber(n) ||
    isInteger(Number(n)) ||
    "");
const isTextIntegerInRange = (value, min, max) => (isTextInteger(value) ||
    isNumberInRange(Number(value), min, max) ||
    ""), isArray = (obj) => {
    return Array.isArray(obj)
        ? ""
        : "This should be an array";
};
const isArrayOfStrings = (obj) => {
    const validationErrorIsArray = isArray(obj);
    if (validationErrorIsArray)
        return validationErrorIsArray;
    const isValid = obj.reduce((acc, value) => {
        if (!acc)
            return false;
        return (!isString(value)
            && !isValidTextAdvanced({
                text: value,
                minLength: 0,
                maxLength: 99990,
            }));
    }, true);
    return isValid ? '' : 'One or more items are not valid strings';
};
const isArrayOfIds = (value) => {
    const validationError = isArrayOfStrings(value);
    if (validationError)
        return validationError;
    const invalidId = value.find(item => isId(item));
    if (invalidId)
        return 'One or more IDs are invalid';
    return '';
};
const isArrayOfNumbers = (array) => (isArray(array) ||
    (array.find(value => !utilsIsNumber(value)) ? "One or more values are not numbers" : "") ||
    "");
const isArrayOfValues = (values, validValues) => {
    const validationErrorIsArray = isArray(values);
    if (validationErrorIsArray)
        return validationErrorIsArray;
    const unexpectedValues = values
        .map(value => {
        if (!validValues.includes(value))
            return value;
        return null;
    })
        .filter(v => v !== null);
    return unexpectedValues.length
        ? `Unexpected valued: ${textArrayEllipsis(unexpectedValues.map(mapValue), 3)}`
        : "";
};
const isOneOfValues = (value, ...validValues) => {
    if (validValues.indexOf(value) !== -1)
        return "";
    return `Invalid value, should be one of these: ${textArrayEllipsis(validValues.map(mapValue), 5)}`;
};
const isOneOfDataFields = (value, ...validValues) => {
    if (validValues.indexOf(value) !== -1)
        return "";
    return `Invalid value, should be one of these: ${textArrayEllipsis(validValues.map(mapValue), 5)}`;
};
const isEnumValue = (value, enum_, deprecatedEnum = {}) => {
    if (Object.values(deprecatedEnum).includes(value))
        return "Value is deprecated";
    if (Object.values(enum_).includes(value))
        return "";
    return `Invalid value, expected one of these: ${textArrayEllipsis(Object.keys(enum_).map(k => convertEnumKeyToHumanReadable(k)), 3)}`;
};
const isOneOfEnums = (value, ...enums) => {
    const enumValues = new Array().concat(...enums.map((e) => Object.values(e)));
    const enumKeys = new Array().concat(...enums.map((e) => Object.keys(e)));
    if (enumValues.includes(value))
        return '';
    return `Invalid value, expected any of these: ${textArrayEllipsis(enumKeys.map(v => convertEnumKeyToHumanReadable(v)), 3)}`;
};
const isEnumValues = (values, enum_, allowEmpty = true) => {
    const validationErrorArray = isArray(values);
    if (validationErrorArray)
        return validationErrorArray;
    if (!allowEmpty && values.length === 0)
        return 'Array should not be empty';
    const allItemsAreValid = values.reduce((acc, value) => acc && !isEnumValue(value, enum_), true);
    if (!allItemsAreValid)
        return `Some items are invalid enum value. Expected: ${textArrayEllipsis(Object.values(enum_).map(mapValue), 2)}`;
    return '';
};
const hasValue = (data) => {
    if (data !== '' && data !== null && data !== undefined)
        return "";
    return "Required";
};
const hasLength = (text, minLength, maxLength) => {
    const validationErrorString = isString(text);
    if (validationErrorString)
        return validationErrorString;
    const errors = [];
    if (text.length < minLength)
        errors.push(`minimum length ${minLength}`);
    if (text.length > maxLength)
        errors.push(`max length ${maxLength}`);
    return errors.length
        ? `Should have ${errors.join(" and ")} characters`
        : "";
};
const isValidCollectionName = (collectionName) => {
    if (!collectionName)
        return 'This is required';
    const validationErrorHasLength = hasLength(collectionName, 3, 127);
    if (validationErrorHasLength)
        return validationErrorHasLength;
    if (collectionName.startsWith(' '))
        return 'It cannot start with a space';
    if (collectionName.endsWith(' '))
        return 'It cannot end with a space';
    if (collectionName.startsWith('-'))
        return 'It cannot start with a "-"';
    if (collectionName.endsWith('-'))
        return 'It cannot end with a "-"';
    if (collectionName.startsWith('_'))
        return 'It cannot start with a "_"';
    if (collectionName.endsWith('_'))
        return 'It cannot end with a "_"';
    if (collectionName.includes('--'))
        return 'It cannot contain "--" (only one is valid)';
    if (!alphanumericWithHyphensUnderscoresRegExp.test(collectionName))
        return 'Invalid characters provided. Only lowercase letters, numbers, hyphens, and underscores are allowed.';
    return '';
};
const isValidCompanyId = (companyId) => {
    const validationErrorCollestionName = isValidCollectionName(companyId);
    if (validationErrorCollestionName)
        return validationErrorCollestionName;
    if (companyId.includes('--'))
        return 'Double hyphens "--" are not allowed';
    return "";
};
const isValidUserId = (userId) => {
    if (!userId)
        return "This is required";
    if (!alphanumericWithHyphensRegExp.test(userId))
        return "This should be alphanumeric with optional hyphens";
    if (userId.includes('--'))
        return "It should not have double hyphens";
    if (userId.startsWith('-'))
        return "It should not start with a hyphen";
    if (userId.endsWith('-'))
        return "It should not end with a hyphen";
    const validationErrorLength = hasLength(userId, 20, 50);
    if (validationErrorLength)
        return validationErrorLength;
    return "";
};
const isEmail = (email) => {
    if (!email)
        return 'This is required';
    if (!validateEmailLengthMessage(email))
        return 'Email should have a maximum of 320 characters: user (before @) maximum allowed is 64 characters, domain and extension (after @) maximum allowed is 255 characters';
    if (!validateIsEmail(email))
        return 'Invalid email form';
    return "";
};
const isHexadecimal = (value) => {
    const validationHasValue = hasValue(value);
    if (validationHasValue)
        return validationHasValue;
    const validationErrorString = isString(value);
    if (validationErrorString)
        return validationErrorString;
    const isHexValue = Boolean(value.match(hexademicalRegExp));
    if (!isHexValue)
        return "This should be a hex value";
    return "";
};
const isNotHtml = (text) => {
    return containsHtml(text)
        ? "This should not contain HTML code"
        : "";
};
const isSanitizedHtml = (html) => {
    return utilsIsSanitizedHtml(html)
        ? ""
        : "This HTML contains invalid elements (not sanitized HTML)";
};
const isArrayWithUniqueValues = (values) => {
    const scannedValues = [];
    const duplicatedValues = [];
    values.forEach(value => {
        if (scannedValues.includes(value))
            duplicatedValues.push(value);
        scannedValues.push(value);
    });
    return duplicatedValues.length
        ? typeof duplicatedValues[0] === "string"
            ? `No duplicated values allowed: ${textArrayEllipsis(duplicatedValues.map(mapValue), 5)}`
            : "This should not contain duplicated values"
        : "";
};
const isValidTextAdvanced = ({ text, minLength, maxLength, allowWhiteSpace = false, allowUrls = false, }) => {
    const validationErrorIsString = isString(text);
    if (validationErrorIsString)
        return validationErrorIsString;
    const validationErrorHasLength = hasLength(text, minLength, maxLength);
    if (validationErrorHasLength)
        return validationErrorHasLength;
    if (!text.trim() && minLength > 0)
        return 'It cannot be just spaces';
    if (!allowWhiteSpace) {
        if (text.startsWith(' '))
            return 'It cannot start with a space';
        if (text.endsWith(' '))
            return 'It cannot end with a space';
    }
    if (!allowUrls && (text.includes('http://') || text.includes('https://')))
        return 'URLs are not allowed here';
    const validationErrorIsNotHtml = isNotHtml(text);
    if (validationErrorIsNotHtml)
        return validationErrorIsNotHtml;
    return '';
};
const isValidText = (text, minLength, maxLength, allowWhiteSpace = false, allowUrls = false) => {
    return isValidTextAdvanced({
        text,
        minLength,
        maxLength,
        allowWhiteSpace,
        allowUrls,
    });
};
const isISODate = (value) => {
    if (!isString(value))
        return 'This is not a string';
    if (value[10] !== 'T' || value[23] !== 'Z')
        return 'Incorrect ISO date format';
    const d = (new Date(value)).valueOf();
    if (isNaN(d))
        return 'Invalid date, cannot parse it';
    return "";
};
const isTimestamp = (value) => {
    if (typeof value !== "number")
        return "This should be a numeric value of a date";
    if (value < (new Date("1000-01-01").valueOf()))
        return "This should be a numeric value >= 1000 AC";
    return "";
};
const isValidDateValue = (value) => {
    const errorMessage = "This is required";
    if (value === null)
        return errorMessage;
    if (value === undefined)
        return errorMessage;
    const date = new Date(value);
    const isValid = !isNaN(date.valueOf());
    return isValid
        ? ""
        : errorMessage;
};
const isGeoPosition = (position) => {
    return ((!position && "This is required") ||
        isNumberInRange(position.lat, -90, 90) ||
        isNumberInRange(position.lng, -180, 180) ||
        (position.alt !== undefined ? isNumber(position.alt) : "") ||
        "");
};
const isGeoPositionSet = (position) => {
    const validationError = isGeoPosition(position);
    if (validationError)
        return validationError;
    if ([0, -1].includes(position.lat))
        return "Not set";
    if ([0, -1].includes(position.lng))
        return "Not set";
    return "";
};
const isGeoPositionPartial = (position) => {
    if (!position)
        return "This is required";
    return isGeoPosition(Object.assign({ lat: 0, lng: 0, alt: 0 }, position));
};
const isGeoPositions = (positions) => {
    if (!positions)
        return "This is required";
    if (!Array.isArray(positions))
        return "This should be an array";
    return positions
        .reduce((acc, position) => {
        const validation = isGeoPosition(position);
        if (validation)
            acc.push(validation);
        return acc;
    }, [])
        .join(', ');
};
const isURL = (value) => {
    if (typeof value !== 'string')
        return "This should be text";
    if (!value.startsWith('http://') && !value.startsWith('https://'))
        return "This should start with http:// or https://";
    if (value.trim().includes(' '))
        return "Spaces are not allowed";
    if (value.startsWith(`javascript${':'}`))
        return "No JavaScript code is allowed";
    return '';
};
const isURLOrEmpty = (value) => {
    if (value === "")
        return "";
    return isURL(value);
};
export const hasValidWidthHeight = ({ width, height, minWidth, maxWidth, minHeight, maxHeight, }) => {
    const hasValidMinWidth = minWidth === undefined || width >= minWidth;
    const hasValidMaxWidth = maxWidth === undefined || width <= maxWidth;
    const hasValidMinHeight = minHeight === undefined || height >= minHeight;
    const hasValidMaxHeight = maxHeight === undefined || height <= maxHeight;
    if (!hasValidMinWidth ||
        !hasValidMaxWidth ||
        !hasValidMinHeight ||
        !hasValidMaxHeight) {
        return `Image dimensions should be between ${minWidth || 0}-${maxWidth || Infinity}px in width and ${minHeight || 0}-${maxHeight || Infinity}px in height.`;
    }
    return "";
};
export const hasValidRatio = (args) => {
    const { width, height, min = {
        width: 0,
        height: 0,
    }, max, } = args;
    const precision = 1;
    const minWidth = (min === null || min === void 0 ? void 0 : min.width) || 0;
    const minHeight = (min === null || min === void 0 ? void 0 : min.height) || 0;
    const maxWidth = (max === null || max === void 0 ? void 0 : max.width) || width;
    const maxHeight = (max === null || max === void 0 ? void 0 : max.height) || width;
    const ratio = getRatio(width, height, precision);
    const minRatio = getRatio(minWidth, minHeight, precision);
    const maxRatio = getRatio(maxWidth, maxHeight, precision);
    const maxWidthLabel = (max === null || max === void 0 ? void 0 : max.width) || '∞';
    const maxHeightLabel = (max === null || max === void 0 ? void 0 : max.height) || '∞';
    if (ratio.a >= minRatio.a &&
        ratio.b >= minRatio.b &&
        ratio.a <= maxRatio.a &&
        ratio.b <= maxRatio.b)
        return "";
    return `Your image has size ${width}x${height} and the aspect ratio is about ${getRatioAsString(width, height)}. The expected ratio is ${minWidth}:${minHeight} to ${maxWidthLabel}:${maxHeightLabel}.`;
};
const isDBSkip = (value) => (validateDataMethods.isNumber(value) ||
    (value < 0 ? "Should be greater or equal to 0" : "") ||
    "");
const isDBLimit = (value) => validateDataMethods.isNumberInRange(value, 1, 500);
const isDBSortObject = (value, ...validKeys) => {
    if (typeof value !== "object")
        return "Should be object";
    const invalidKeys = validKeys.filter(key => !validKeys.includes(key));
    if (invalidKeys.length)
        return `Invalid keys [${invalidKeys.join(', ')}], valid are only [${validKeys.join(', ')}]`;
    const validSortValues = [0, 1, -1];
    const errors = [];
    Object.entries(value)
        .forEach(([property, sortValue]) => {
        if (!validSortValues.includes(sortValue))
            errors.push(`Sort property [${property}] has invalid value [${sortValue}], should `);
    });
    return errors.join(', ');
};
const isOptionalDBSortObject = (value, ...validKeys) => {
    if (value === undefined)
        return "";
    return isDBSortObject(value, ...validKeys);
};
//#endregion "Validation methods implementation"
//#region "Internal utils"
const alphanumericWithHyphensRegExp = /^[a-z0-9-]*$/;
const alphanumericWithHyphensUnderscoresRegExp = /^[a-z0-9\-_]*$/;
const hexademicalRegExp = /^[0-9a-f]+$/i;
const mapValue = (value) => `[${value}]`;
//#endregion "Internal utils"
//#region "Export validationDataMethods"
export const validateDataMethods = {
    isUndefined: (value) => {
        if (value === undefined)
            return "";
        return "This should be undefined";
    },
    isNotUndefined: (value) => {
        if (value === undefined)
            return "Should not be undefined";
        return "";
    },
    isObject,
    isOptionalObject: createValidationMethodOptionalVersion(isObject),
    isId,
    isOptionalId: createValidationMethodOptionalVersion(isId),
    isGuid,
    isOptionalGuid: createValidationMethodOptionalVersion(isGuid),
    isShortGuid,
    isOptionalShortGuid: createValidationMethodOptionalVersion(isShortGuid),
    isGuidWithBlocks,
    isOptionalGuidWithBlocks: createValidationMethodOptionalVersion(isGuidWithBlocks),
    isNotEmptyString,
    isOptionalNotEmptyString: createValidationMethodOptionalVersion(isNotEmptyString),
    isBoolean,
    isOptionalBoolean: createValidationMethodOptionalVersion(isBoolean),
    isBooleanText,
    isOptionalBooleanText: createValidationMethodOptionalVersion(isBooleanText),
    isBooleanOrBoth,
    isOptionalBooleanOrBoth: createValidationMethodOptionalVersion(isBooleanOrBoth),
    isNumber,
    isOptionalNumber: createValidationMethodOptionalVersion(isNumber),
    isNumberInArray,
    isOptionalNumberInArray: createValidationMethodOptionalVersion(isNumberInArray),
    isStringInArray,
    isOptionalStringInArray: createValidationMethodOptionalVersion(isStringInArray),
    isNumberInRange,
    isOptionalNumberInRange: createValidationMethodOptionalVersion(isNumberInRange),
    isInteger,
    isOptionalInteger: createValidationMethodOptionalVersion(isInteger),
    isTextInteger,
    isOptionalTextInteger: createValidationMethodOptionalVersion(isTextInteger),
    isIntegerInRange,
    isOptionalIntegerInRange: createValidationMethodOptionalVersion(isIntegerInRange),
    isTextNumber,
    isOptionalTextNumber: createValidationMethodOptionalVersion(isTextNumber),
    isString,
    isOptionalString: createValidationMethodOptionalVersion(isString),
    isTextNumberInRange,
    isOptionalTextNumberInRange: createValidationMethodOptionalVersion(isTextNumberInRange),
    isTextIntegerInRange,
    isOptionalTextIntegerInRange: createValidationMethodOptionalVersion(isTextIntegerInRange),
    isArray,
    isOptionalArray: createValidationMethodOptionalVersion(isArray),
    isArrayOfIds,
    isOptionalArrayOfIds: createValidationMethodOptionalVersion(isArrayOfIds),
    isArrayOfNumbers,
    isOptionalArrayOfNumbers: createValidationMethodOptionalVersion(isArrayOfNumbers),
    isArrayOfValues,
    isOptionalArrayOfValues: createValidationMethodOptionalVersion(isArrayOfValues),
    isArrayOfStrings,
    isOptionalArrayOfStrings: createValidationMethodOptionalVersion(isArrayOfStrings),
    isOneOfValues,
    isOptionalOneOfValues: createValidationMethodOptionalVersion(isOneOfValues),
    isOneOfDataFields,
    isOptionalOneOfDataFields: createValidationMethodOptionalVersion(isOneOfDataFields),
    isEnumValue,
    isOptionalEnumValue: createValidationMethodOptionalVersion(isEnumValue),
    isOneOfEnums,
    isOptionalOneOfEnums: createValidationMethodOptionalVersion(isOneOfEnums),
    isEnumValues,
    isOptionalEnumValues: createValidationMethodOptionalVersion(isEnumValues),
    hasValue,
    hasOptionalValue: createValidationMethodOptionalVersion(hasValue),
    hasLength,
    hasOptionalLength: createValidationMethodOptionalVersion(hasLength),
    isValidCollectionName,
    isOptionalValidCollectionName: createValidationMethodOptionalVersion(isValidCollectionName),
    isValidCompanyId,
    isOptionalValidCompanyId: createValidationMethodOptionalVersion(isValidCompanyId),
    isValidUserId,
    isOptionalValidUserId: createValidationMethodOptionalVersion(isValidUserId),
    isEmail,
    isOptionalEmail: createValidationMethodOptionalVersion(isEmail),
    isHexadecimal,
    isOptionalHexadecimal: createValidationMethodOptionalVersion(isHexadecimal),
    isNotHtml,
    isOptionalNotHtml: createValidationMethodOptionalVersion(isNotHtml),
    isSanitizedHtml,
    isOptionalSanitizedHtml: createValidationMethodOptionalVersion(isSanitizedHtml),
    isArrayWithUniqueValues,
    isOptionalArrayWithUniqueValues: createValidationMethodOptionalVersion(isArrayWithUniqueValues),
    isValidTextAdvanced,
    isOptionalValidTextAdvanced: createValidationMethodOptionalVersion(isValidTextAdvanced),
    isValidText,
    isOptionalValidText: createValidationMethodOptionalVersion(isValidText),
    isISODate,
    isOptionalISODate: createValidationMethodOptionalVersion(isISODate),
    isTimestamp,
    isOptionalTimestamp: createValidationMethodOptionalVersion(isTimestamp),
    isValidDateValue,
    isOptionalValidDateValue: createValidationMethodOptionalVersion(isValidDateValue),
    isGeoPosition,
    isOptionalGeoPosition: createValidationMethodOptionalVersion(isGeoPosition),
    isGeoPositionSet,
    isOptionalGeoPositionSet: createValidationMethodOptionalVersion(isGeoPositionSet),
    isGeoPositionPartial,
    isOptionalGeoPositionPartial: createValidationMethodOptionalVersion(isGeoPositionPartial),
    isGeoPositions,
    isOptionalGeoPositions: createValidationMethodOptionalVersion(isGeoPositions),
    isURL,
    isOptionalURL: createValidationMethodOptionalVersion(isURL),
    isURLOrEmpty,
    isOptionalURLOrEmpty: createValidationMethodOptionalVersion(isURLOrEmpty),
    hasValidRatio,
    hasOptionalValidRatio: createValidationMethodOptionalVersion(hasValidRatio),
    hasValidWidthHeight,
    hasOptionalValidWidthHeight: createValidationMethodOptionalVersion(hasValidWidthHeight),
    isDBSkip,
    isOptionalDBSkip: createValidationMethodOptionalVersion(isDBSkip),
    isDBLimit,
    isOptionalDBLimit: createValidationMethodOptionalVersion(isDBLimit),
    isDBSortObject,
    isOptionalDBSortObject,
};
//#endregion "Export validationDataMethods"
//#region "Tools"
export const convertAllValidationRulesToOptional = (rules) => {
    return Object.entries(rules)
        .reduce((acc, [fieldName, method]) => {
        acc[fieldName] = createValidationMethodOptionalVersion(method);
        return acc;
    }, {});
};
//#endregion "Tools"
