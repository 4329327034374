import { useState, useEffect, } from "react";
export const useKeyStatus = () => {
    const [keyStatus, setKeyStatus] = useState({
        shiftKeyDown: false,
        ctrlKeyDown: false,
        cmdKeyDown: false,
        altKeyDown: false,
    });
    useEffect(() => {
        const handleKeyDown = (event) => {
            setKeyStatus({
                shiftKeyDown: event.shiftKey,
                ctrlKeyDown: event.ctrlKey,
                cmdKeyDown: event.metaKey,
                altKeyDown: event.altKey,
            });
        };
        const handleKeyUp = (event) => {
            setKeyStatus({
                shiftKeyDown: event.shiftKey,
                ctrlKeyDown: event.ctrlKey,
                cmdKeyDown: event.metaKey,
                altKeyDown: event.altKey,
            });
        };
        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, []);
    return keyStatus;
};
