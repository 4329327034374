import { jsx as _jsx } from "react/jsx-runtime";
import MuiPaper from '@mui/material/Paper';
export var EPaperVariant;
(function (EPaperVariant) {
    EPaperVariant["ELEV"] = "elevation";
    EPaperVariant["OUTLINED"] = "outlined";
})(EPaperVariant || (EPaperVariant = {}));
export const Paper = (props) => {
    const { sx = {}, variant = EPaperVariant.ELEV, square = false, elevation, fullWidth = false, fullHeight = false, children, onClick, } = props;
    return (_jsx(MuiPaper, { "data-component-name": "Paper", sx: Object.assign({ padding: theme => theme.spacing(1), width: fullWidth ? '100%' : undefined, height: fullHeight ? '100%' : undefined }, sx), variant: variant, elevation: elevation, square: square, onClick: onClick, children: children }));
};
