import { useState, useEffect, } from "react";
import { round, isConvertibleToNumber, countDecimalPoints, } from "utils-library/dist/utils";
export const InputDualUnit = ({ convertActualToUserValue, convertUserValueToActual, value, children: input, onBlur, onChange, }) => {
    const [userValue, setUserValue] = useState(value);
    const [validationError, setValidationError] = useState('');
    // Watch external changes
    useEffect(() => {
        const fullUserValue = convertActualToUserValue(value);
        const decimalPoints = countDecimalPoints(fullUserValue);
        if (decimalPoints >= 14) {
            // Too many decimal points or floating-point precision issue!
            // Just round the number to one decimal place less.
            const fixedUserValue = round(fullUserValue, decimalPoints - 1);
            setUserValue(fixedUserValue);
        }
        else {
            setUserValue(fullUserValue);
        }
    }, [value]);
    const handleUserValueChange = (userValue) => {
        if (!isConvertibleToNumber(userValue)) {
            setValidationError('Invalid numeric value');
            return null;
        }
        if (validationError)
            setValidationError("");
        const numericValue = Number(userValue);
        const actualValue = convertUserValueToActual(numericValue);
        return actualValue;
    };
    const handleBlur = (userValue) => {
        const actualValue = handleUserValueChange(userValue);
        if (!onBlur)
            return;
        if (actualValue === null)
            return;
        onBlur(actualValue);
    };
    const handleChange = (userValue) => {
        const actualValue = handleUserValueChange(userValue);
        if (!onChange)
            return;
        if (actualValue === null)
            return;
        onChange(actualValue);
    };
    return (input({
        value: userValue.toString(),
        validationError,
        onBlur: handleBlur,
        onChange: handleChange,
    }));
};
