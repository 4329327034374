export const isInViewPort = (element) => {
    return new Promise((resolve) => {
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            resolve(entry.isIntersecting);
            observer.disconnect();
        });
        observer.observe(element);
    });
};
