import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {ITrafficStudiesListProps} from "./TrafficStudiesList";

const TrafficStudiesListLazy = lazy(() => import("./TrafficStudiesList"));

export const TrafficStudiesList = connect((props: ITrafficStudiesListProps) => (
  <LazyLoadComponent>
    <TrafficStudiesListLazy {...props}/>
  </LazyLoadComponent>
));

export type {
  ITrafficStudiesListProps,
} from "./TrafficStudiesList";
export type {
  IFilter,
  ISort,
} from "./interfaces";
