import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState, useEffect, } from "react";
import MuiCollapse from "@mui/material/Collapse";
import { useIsMounted } from "../useIsMounted";
export const Collapse = (props) => {
    const { sx, expanded, show = true, animation = true, animationDuration = 150, doNotRemoveFromDom = false, orientation = 'vertical', children, } = props;
    const getIsMounted = useIsMounted();
    const refCollapseTimer = useRef(null);
    const [renderContent, setRenderContent] = useState(expanded);
    useEffect(() => {
        if (expanded) {
            if (!doNotRemoveFromDom)
                setRenderContent(true);
            if (refCollapseTimer.current)
                clearTimeout(refCollapseTimer.current);
            refCollapseTimer.current = null;
        }
        else {
            refCollapseTimer.current = setTimeout(() => {
                if (expanded)
                    return;
                if (!getIsMounted())
                    return;
                if (!doNotRemoveFromDom)
                    setRenderContent(false);
            }, animationDuration);
        }
    }, [expanded]);
    if (!show)
        return null;
    if (!animation)
        return expanded ? children : null;
    return (_jsx(MuiCollapse, { sx: sx, in: expanded, timeout: animationDuration, orientation: orientation, children: renderContent && children }));
};
