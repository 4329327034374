import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EButtonBarAlign, ButtonBar, } from "../ButtonBar";
import { Button, EButtonColor, } from "../Button";
import { EFormMode, } from "../useForm";
import { useBreakpointDevice } from "../useBreakpointDevice";
import CreateIcon from '@mui/icons-material/AddCircle';
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from '@mui/icons-material/HighlightOff';
export const FormToolbar = (props) => {
    const { useFormApi: { formMode, isChanged, buttons, }, disableEditButton, disableSaveButton, disableCancelButton, hideEditButton, hideSaveButton, hideCancelButton, showCancelAsClose = false, additionalButtons, labels = {
        save: 'Save',
        cancel: 'Cancel',
        close: 'Close',
    }, } = props;
    const { isMobile, isTablet, } = useBreakpointDevice();
    const handleSaveSubmit = (e) => {
        e.preventDefault();
        buttons.save.onClick();
    };
    return (_jsxs(ButtonBar, { spacing: 1, nowrap: true, reverseOnMac: true, align: EButtonBarAlign.RIGHT, noHorizontalSpace: true, children: [additionalButtons, _jsx(Button, { icon: _jsx(EditIcon, {}), show: buttons.edit.show && !hideEditButton, color: buttons.edit.active
                    ? EButtonColor.SECONDARY
                    : EButtonColor.PRIMARY, disabled: disableEditButton === true || buttons.edit.disabled, hideLabelOnMobile: true, hideLabelOnTablet: true, onClick: buttons.edit.onClick, children: "Edit" }), _jsx(Button, { sx: { width: isMobile || isTablet ? 64 : undefined }, icon: formMode === EFormMode.CREATE
                    ? _jsx(CreateIcon, {})
                    : _jsx(SaveIcon, {}), show: buttons.save.show && !hideSaveButton, disabled: disableSaveButton === true || buttons.save.disabled, children: isMobile || isTablet ? undefined : labels.save, onClick: handleSaveSubmit }), _jsx(Button, { sx: { width: isMobile || isTablet ? 64 : undefined }, icon: isChanged ? _jsx(CancelIcon, {}) : _jsx(CloseIcon, {}), color: EButtonColor.SECONDARY, show: buttons.cancel.show && !hideCancelButton, disabled: disableCancelButton === true || buttons.cancel.disabled, children: (() => {
                    if (isMobile || isTablet)
                        return undefined; // No room in mobile devices
                    if (isChanged)
                        return labels.cancel;
                    if (showCancelAsClose)
                        return labels.close;
                    return labels.cancel;
                })(), onClick: buttons.cancel.onClick })] }));
};
