import {INotificationIcon} from "mhc-ui-components/dist/AppContainerDashboard";

import {IAppStore} from "../../../state/IAppStore";
import {themeNotificationIcon} from "./notificationIcons/themeNotificationIcon";
import {onlineNotificationIcon} from "./notificationIcons/onlineNotificationIcon";

export const notificationIcons = (store: IAppStore): INotificationIcon[] => {
  const notificationIcons: (INotificationIcon | null)[] = [];

  notificationIcons.push(themeNotificationIcon(store));
  notificationIcons.push(onlineNotificationIcon(store));

  return notificationIcons.filter(Boolean) as any;
};
