import * as React from "react";

import {connect} from "react-dynadux";

import {IAppStore} from "../../../state/IAppStore";

import {Box} from "mhc-ui-components/dist/Box";
import {
  Alert,
  EAlertType,
} from "mhc-ui-components/dist/Alert";
import {Typography} from "mhc-ui-components/dist/Typography";
import {
  Button,
  EButtonDisplay,
  EButtonSize,
} from "mhc-ui-components/dist/Button";

import {IAppMenu} from "../config/IAppMenu";

import {appMenusTop} from "../config/appMenus";
import {appMenusBottom} from "../config/appMenus";
import {GoBackHomeButtons} from "../components/GoBackHomeButtons";

import {
  SxProps,
  useTheme,
  Theme,
} from "mhc-ui-components/dist/ThemeProvider";

export interface IViewMenuItemsProps {
  sx?: SxProps<Theme>;
  store: IAppStore;
  menuId: string;
}


type TWithoutStoreProp<TProps> = Omit<TProps, 'store'>;

type TConnectedComponent<TProps> =
  | React.FunctionComponent<TWithoutStoreProp<TProps>>
  | React.ComponentClass<TWithoutStoreProp<TProps>>;


export const ViewMenuItems = connect((props: IViewMenuItemsProps): React.JSX.Element => {
  const {
    sx = {},
    menuId,
    store: {
      app: {actions: {navigateTo}},
      userAuth:
        {
          utils:
            {
              userHasAllRights,
              userHasAnyOfRights,
            },
        },
    },
  } = props;
  const theme = useTheme();

  const menuItems: (IAppMenu | "DIVIDER")[] = [
    ...appMenusTop,
    ...appMenusBottom,
  ];
  const menuItem =
    menuItems
      .find(menu => menu !== 'DIVIDER' && menu.menuId === menuId);

  if (menuItem === "DIVIDER") return <div/>; // 4TS, is not possible

  if (!menuItem) {
    return (
      <Alert
        title="Internal error"
        type={EAlertType.ERROR}
      >
        Menu with id: [{menuId}] not found.
      </Alert>
    );
  }

  if (!menuItem.children || !menuItem.children.length) {
    return (
      <Alert
        title="Internal error"
        type={EAlertType.ERROR}
      >
        Menu with id: [{menuId}] has no children to list.
      </Alert>
    );
  }

  const accessibleMenuItems: IAppMenu[] =
    menuItem.children
      .filter(menu => {
        if (menu === "DIVIDER") return false;
        return (
          userHasAllRights(menu.userHasAllRights) &&
          userHasAnyOfRights(menu.userHasAnyOfRights)
        );
      }) as any;

  if (!accessibleMenuItems.length) {
    return (
      <div>
        <Alert
          title="Access denied"
          type={EAlertType.ERROR}
        >
          You don't have access on any menu of "{menuItem.title}" menu.
        </Alert>
        <GoBackHomeButtons/>
      </div>
    );
  }

  return (
    <Box sx={sx}>
      <Typography v="h1">{menuItem.title}</Typography>
      <Typography v="h2">{menuItem.description}</Typography>
      <div>
        {accessibleMenuItems.map((menuItem, index) => (
          <Button
            sx={{marginBottom: theme.spacing(3)}}
            display={EButtonDisplay.BLOCK}
            size={EButtonSize.LARGE}
            key={index}
            icon={menuItem.icon}
            onClick={() => navigateTo({url: menuItem.navigateTo})}
          >
            {menuItem.title}
          </Button>
        ))}
      </div>
    </Box>
  );
}) as TConnectedComponent<IViewMenuItemsProps>;
