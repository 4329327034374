import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, forwardRef, useImperativeHandle, } from "react";
import { Marker } from "react-leaflet";
import { Box } from "../../../../Box";
import { ELeafletMarkerColor, colorMarker, customIconMarkerByJSXElement, } from "../../../markers";
import { MapPopUp, } from "./components/MapPopUp";
export const MapMarker = forwardRef(function MapMarker(props, ref) {
    const { position, customIcon, color = ELeafletMarkerColor.RED, opacity, popUp, draggable, onDragStart, onDrag, onDragEnd, } = props;
    const refMarker = useRef(null);
    useImperativeHandle(ref, () => ({
        get isPopUpOpen() {
            var _a;
            return (_a = refMarker.current) === null || _a === void 0 ? void 0 : _a.isPopupOpen();
        },
        openPopUp: () => {
            var _a;
            (_a = refMarker.current) === null || _a === void 0 ? void 0 : _a.openPopup();
        },
        closePopUp: () => {
            var _a;
            (_a = refMarker.current) === null || _a === void 0 ? void 0 : _a.closePopup();
        },
    }));
    const triggerDragEvent = (handler) => {
        if (!handler)
            return;
        if (!refMarker.current)
            return;
        handler(refMarker.current.getLatLng());
    };
    const handleDragStart = () => triggerDragEvent(onDragStart);
    const handleDrag = () => triggerDragEvent(onDrag);
    const handleDragEnd = () => triggerDragEvent(onDragEnd);
    const icon = customIcon
        ? customIconMarkerByJSXElement(customIcon)
        : colorMarker(color);
    return (_jsxs(_Fragment, { children: [_jsx(Marker, { ref: refMarker, position: position, icon: icon, opacity: opacity, draggable: draggable, eventHandlers: {
                    dragstart: handleDragStart,
                    drag: handleDrag,
                    dragend: handleDragEnd,
                }, children: !!popUp && _jsx(MapPopUp, Object.assign({}, popUp)) }), _jsx(Box
            // Rerender the customIcon in an off-screen space just to get its classes in the DOM.
            // This is because `renderToString`, being a server-side method, doesn’t add classes to the DOM!
            , { 
                // Rerender the customIcon in an off-screen space just to get its classes in the DOM.
                // This is because `renderToString`, being a server-side method, doesn’t add classes to the DOM!
                hidden: true, show: !!customIcon, children: customIcon })] }));
});
