var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import * as qs from "qs";
export const apiFetch = (_a) => __awaiter(void 0, [_a], void 0, function* ({ path, method = "GET", body, query, headers = {}, jsonHeader = true, withCredentials, auth, }) {
    var _b, _c;
    const applyHeaders = Object.assign({}, headers);
    if (jsonHeader)
        applyHeaders['content-type'] = 'application/json; charset=utf-8';
    try {
        const response = yield axios.request({
            url: [
                path,
                query ? `?${qs.stringify(query)}` : '',
            ].join(''),
            method,
            data: body,
            headers: applyHeaders,
            withCredentials,
            auth,
        });
        return response.data;
    }
    catch (error) {
        if ((_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data)
            throw (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data;
        throw error;
    }
});
