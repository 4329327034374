import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FlexContainerResponsive, FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { Box } from "../Box";
import { IconViewer } from "../IconViewer";
export const ContainerSection = (props) => {
    const { show = true, h = 1, fullHeight, Icon, SubIcon, title, subtitle, topRightToolbar, children, } = props;
    const factor = 5 + 1 - h;
    if (!show)
        return null;
    return (_jsxs(FlexContainerResponsive, { verticalOnMobile: true, fullHeight: fullHeight, spacing: 1, children: [_jsxs(FlexItemMin, { sx: { position: 'relative' }, show: !!Icon, children: [_jsx(IconViewer, { Icon: Icon, width: factor * 24 }), _jsx(Box, { show: !!SubIcon, sx: {
                            position: 'absolute',
                            right: theme => theme.spacing(-1),
                            bottom: 0,
                            padding: theme => theme.spacing(factor / 5),
                            backgroundColor: theme => theme.palette.background.paper,
                            borderRadius: (factor * 12) + 'px',
                            border: '1px solid',
                            borderColor: theme => theme.palette.text.secondary,
                            fontSize: 0,
                        }, children: _jsx(IconViewer, { Icon: SubIcon, width: factor * 5 }) })] }), _jsxs(FlexItemMax, { sx: { paddingTop: theme => theme.spacing(1) }, children: [_jsxs(FlexContainerHorizontal, { alignVertical: "top", children: [_jsxs(FlexItemMax, { children: [_jsx(Box, { sx: {
                                            fontWeight: 'bold',
                                            fontSize: factor * 5,
                                        }, children: title }), _jsx(Box, { sx: {
                                            fontWeight: 'bold',
                                            fontSize: factor * 3,
                                        }, children: subtitle })] }), _jsx(FlexItemMin, { children: topRightToolbar })] }), _jsx(Box, { sx: { paddingLeft: factor / 4 }, children: children })] })] }));
};
