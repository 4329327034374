import { jsx as _jsx } from "react/jsx-runtime";
import { getDataComponentName, } from "../ui-interfaces";
import { Box } from "../Box";
import { useTheme, } from "../ThemeProvider";
export var EFlexContainerOrientation;
(function (EFlexContainerOrientation) {
    EFlexContainerOrientation["HORIZONTAL"] = "HORIZONTAL";
    EFlexContainerOrientation["VERTICAL"] = "VERTICAL";
})(EFlexContainerOrientation || (EFlexContainerOrientation = {}));
export const FlexContainer = (props) => {
    const { show = true, inline = false, orientation = EFlexContainerOrientation.HORIZONTAL, spacing, justifyStart = false, justifyCenter = false, justifyEnd = false, justifySpaceBetween = false, alignStart = false, alignCenter = false, alignEnd = false, fullHeight = false, reverseOrder = false, sx: userSx = {}, dataComponentName, title, children, onClick, onMouseMove, onMouseDown, onMouseUp, onMouseEnter, onMouseLeave, onScroll, onWheel, } = props;
    const theme = useTheme();
    const innerBorderSpace = spacing
        ? theme.spacing(spacing)
        : undefined;
    const sx = Object.assign({ display: inline ? 'inline-flex' : 'flex', flexDirection: (() => {
            if (!reverseOrder) {
                return orientation === EFlexContainerOrientation.VERTICAL
                    ? 'column'
                    : undefined;
            }
            return orientation === EFlexContainerOrientation.HORIZONTAL
                ? 'row-reverse'
                : 'column-reverse';
        })(), justifyContent: (() => {
            if (justifySpaceBetween)
                return "space-between";
            if (justifyStart)
                return "flex-start";
            if (justifyCenter)
                return "center";
            if (justifyEnd)
                return "flex-end";
            return undefined;
        })(), alignItems: (() => {
            if (alignStart)
                return "flex-start";
            if (alignCenter)
                return "center";
            if (alignEnd)
                return "flex-end";
            return undefined;
        })(), height: fullHeight ? '100%' : undefined, paddingRight: innerBorderSpace, paddingBottom: innerBorderSpace, "& > *": {
            marginTop: innerBorderSpace,
            marginLeft: innerBorderSpace,
        } }, userSx);
    return (_jsx(Box, { show: show, sx: sx, dataComponentName: getDataComponentName(dataComponentName, "FlexContainer"), title: title, onClick: onClick, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onScroll: onScroll, onWheel: onWheel, children: children }));
};
