import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "../Box";
export const ContainerFullscreen = (props) => {
    const { sx = {}, dataComponentName, fullScreen, fullHeight, children, } = props;
    return (_jsx(Box, { dataComponentName: [dataComponentName, "ContainerFullscreen"], sx: Object.assign(Object.assign(Object.assign({}, (fullScreen
            ? {
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 500,
            }
            : {})), (fullHeight && !fullScreen
            ? { height: '100%' }
            : {})), sx), children: children }));
};
