import {IGeoPosition} from "utils-library/dist/geo";

import {
  API_PATH_apiGeoPointElevationsGet,
  IApiGeoPointElevationsGetResponse,
  IApiGeoPointElevationsGetRequest,
} from "mhc-server/dist/interfaces";

import {apiFetch} from "../../../../api/apiFetch";
import {getOnline} from "utils-library/dist/web";

// Note: This endpoint returns the altitude in meters (not in feet).
export const apiGeoPointElevationsGet = async (positions: IGeoPosition[]): Promise<IGeoPosition[]> => {
  return getOnline()
    ? apiGeoPointElevationsGetOnline(positions)
    : positions.map(position => {
      return {
        lat: position.lat,
        lng: position.lng,
        alt: 0,
      };
    }); // Return alt with 0
};

const apiGeoPointElevationsGetOnline = async (positions: IGeoPosition[]): Promise<IGeoPosition[]> => {
  const response = await apiFetch.request<IApiGeoPointElevationsGetRequest, void, IApiGeoPointElevationsGetResponse>({
    path: API_PATH_apiGeoPointElevationsGet,
    query: {positions},
  });
  return response.positions;
};
