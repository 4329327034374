import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IAlertMgtmListPageProps} from "./AlertMgtmListPage";

const AlertMgtmListPageLazy = lazy(() => import("./AlertMgtmListPage"));

export const AlertMgtmListPage = connect((props: IAlertMgtmListPageProps) => (
  <LazyLoadComponent>
    <AlertMgtmListPageLazy {...props}/>
  </LazyLoadComponent>
));
