import { dynaError, } from "dyna-error";
export const JSONParse = ({ serialized, default: defaultValue, errorCode = 20240904091614, errorMessage, }) => {
    if (!serialized)
        return { data: defaultValue };
    try {
        return { data: JSON.parse(serialized) };
    }
    catch (e) {
        const error = dynaError(e);
        return {
            data: defaultValue,
            error: dynaError({
                code: errorCode,
                message: errorMessage || `JSONParse: Parsing error: ${error.message}, default is applied`,
                data: {
                    serialized,
                    default: defaultValue,
                },
                parentError: error,
            }),
        };
    }
};
