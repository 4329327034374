export const convertSizeToPx = (theme, size) => {
    if (size === undefined)
        return '';
    if (typeof size === "string") {
        return size.endsWith('px')
            ? size
            : size + 'px';
    }
    if (size < 9)
        return theme.spacing(size);
    return size + 'px';
};
