// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UFQ0LOjugugsOdNeTygA {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-animation: Lcwn25MNourNOIjXB1Gv 0.25s infinite;
          animation: Lcwn25MNourNOIjXB1Gv 0.25s infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background-repeat: no-repeat;
  background-image: linear-gradient(83deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 85%);
  background-size: 75% 100%;
  background-position: -280% 0;
}
@-webkit-keyframes Lcwn25MNourNOIjXB1Gv {
  to {
    background-position: 400% 0;
  }
}
@keyframes Lcwn25MNourNOIjXB1Gv {
  to {
    background-position: 400% 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Ghost/Ghost.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EAEA,sDAAA;UAAA,8CAAA;EACA,yCAAA;UAAA,iCAAA;EAEA,4BAAA;EACA,8HAAA;EACA,yBAAA;EACA,4BAAA;AADF;AAGE;EACE;IACE,2BAAA;EADJ;AACF;AAFE;EACE;IACE,2BAAA;EADJ;AACF","sourcesContent":[".ghost {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n\n  animation: ghostAnimationKeyframes 0.25s infinite; // Duration updated from JS!\n  animation-timing-function: linear;\n\n  background-repeat: no-repeat;\n  background-image: linear-gradient(83deg, fade(white, 0) 15%, fade(white, 60%) 50%, fade(white, 0) 85%);\n  background-size: 75% 100%;\n  background-position: -280% 0;\n\n  @keyframes ghostAnimationKeyframes {\n    to {\n      background-position: 400% 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var ghost = `UFQ0LOjugugsOdNeTygA`;
export var ghostAnimationKeyframes = `Lcwn25MNourNOIjXB1Gv`;
export default ___CSS_LOADER_EXPORT___;
