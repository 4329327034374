import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "../Box";
import { IconViewer } from "../IconViewer";
export const LabelOverlay = ({ show = true, fullHeight = true, spacing = 3, Icon, iconColor, size = 128, iconElement, title, description, children, }) => {
    if (!show)
        return null;
    return (_jsxs(Box, { sx: {
            color: theme => theme.palette.grayShades.gray5,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            py: spacing,
            '& > *': {
                marginBottom: theme => theme.spacing(spacing),
                '&:last-child': { marginBottom: 0 },
            },
        }, fullWidth: true, fullHeight: fullHeight, dataComponentName: "No", children: [_jsx(IconViewer, { sx: { color: theme => iconColor === null || iconColor === void 0 ? void 0 : iconColor(theme) }, Icon: Icon, width: size }), iconElement, _jsx(Box, { show: !!title, sx: {
                    maxWidth: '50%',
                    fontFamily: theme => theme.typography.body1.fontFamily,
                    fontSize: theme => theme.typography.fontSize * 2,
                    fontWeight: 'bold',
                }, children: title }), _jsx(Box, { show: !!description, sx: {
                    maxWidth: '70%',
                    fontFamily: theme => theme.typography.body2.fontFamily,
                    fontSize: theme => theme.typography.fontSize - 1,
                }, children: description }), children] }));
};
