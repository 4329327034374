import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, forwardRef, useImperativeHandle, } from "react";
import { useDebouncedCallback } from "use-debounce";
import { Box } from "../Box";
import { Condition } from "../Condition";
import { Table, } from "../Table";
import { LabelOverlayDataProgress, EProgressType, } from "../LabelOverlayDataProgress";
import { GridContainer, GridItem, } from "../Grid";
import { ErrorBanner } from "../ErrorBanner";
import { FilterInput } from "./components/FilterInput";
import { IsLoadingBox } from "../IsLoadingBox";
import { Button, EButtonSize, } from "../Button";
import { ContainerSection } from "../ContainerSection";
import { CenterContainer } from "../CenterContainer";
import { useLoadMoreData, } from "../useLoadMoreData";
import { useOnChange } from "../useOnChange";
import { useTheme, } from "../ThemeProvider";
import { createIcon } from "../IconComponent";
import LoadingIcon from "@mui/icons-material/Cached";
import LoadMoreIcon from "@mui/icons-material/GetApp";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
/**
 * @deprecated Use the `ViewController` with a combination of `Table` instead.
 */
const TableLoadMoreCore = forwardRef(function TableLoadMoreCore(props, ref) {
    const { title = "", ariaLabel, columns, filters: userFilters, sort: userSort = {
        fieldName: undefined,
        direction: 0,
    }, rowHover = true, loadSize = 20, debounceTimeout, onRowClick, renderAboveTable, renderAfterFilters, renderBelowTable, onLoad, } = props;
    const theme = useTheme();
    const [filters, setFilters] = useState((userFilters || []).map(f => (Object.assign({}, f))));
    const [sort, setSort] = useState(userSort);
    useEffect(() => {
        if (!userFilters)
            return;
        const areFiltersSame = JSON.stringify(userFilters.map(f => f.options)) ===
            JSON.stringify(filters.map(f => f.options));
        if (!areFiltersSame)
            setFilters(userFilters);
    }, [userFilters]);
    const handleLoad = ({ skip, limit, }) => {
        return onLoad({
            filters,
            sort,
            pagination: {
                skip,
                limit,
            },
        });
    };
    const { isLoading, error, hasMore, reload, loadMore: loadMoreCore, items: rows, setItems, } = useLoadMoreData({
        loadSize,
        onLoad: handleLoad,
    });
    const loadMore = useDebouncedCallback(loadMoreCore, debounceTimeout);
    useImperativeHandle(ref, () => ({
        reload,
        getItems: () => rows,
        setItems,
    }));
    useOnChange({
        dep: [filters, sort],
        onChange: reload,
    });
    const handleChangeFilter = (filter) => {
        setFilters(filters.map(scanFilter => scanFilter.filterName === filter.filterName
            ? filter
            : scanFilter));
    };
    const handleSortChange = (sort) => {
        setSort(sort);
    };
    return (_jsxs(Box, { dataComponentName: "TableLoadMore", sx: { paddingTop: theme.spacing(2) }, children: [renderAboveTable
                && renderAboveTable({
                    isLoading,
                    filters,
                    sort,
                    rows,
                }), _jsx(Condition, { if: !!filters.length, children: _jsx(ContainerSection, { Icon: createIcon.byMuiIcon(FilterAltIcon), title: title, subtitle: "Filters", children: _jsx(GridContainer, { spacing: 2, children: filters
                            .filter(filter => !filter.hidden)
                            .map((filter, index) => (_jsx(GridItem, { mobile: 12, tablet: 6, desktop: 3, children: _jsx(FilterInput, { filter: filter, onChange: handleChangeFilter }) }, index))) }) }) }), renderAfterFilters
                && renderAfterFilters({
                    isLoading,
                    filters,
                    sort,
                    rows,
                }), _jsx(CenterContainer, { children: _jsx(IsLoadingBox, { spacing: 2, collapsible: false, isLoading: isLoading, children: "Loading..." }) }), _jsx(ErrorBanner, { error: error }), !!rows.length && (_jsx(Table, { ariaLabel: ariaLabel, columns: columns, sort: sort, rows: rows, rowHover: rowHover, onRowClick: onRowClick, onChangeSort: handleSortChange })), _jsx(LabelOverlayDataProgress, { show: !rows.length && !isLoading, type: EProgressType.NO_DATA_FOUND }), _jsx(CenterContainer, { textAlign: "center", sx: {
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(3),
                    display: hasMore ? undefined : "none",
                }, fullHeight: false, children: _jsx(Button, { icon: isLoading
                        ? _jsx(LoadingIcon, {})
                        : _jsx(LoadMoreIcon, {}), size: EButtonSize.LARGE, disabled: isLoading, onClick: loadMore, children: isLoading
                        ? 'loading'
                        : 'Load more' }) }), renderBelowTable
                && renderBelowTable({
                    isLoading,
                    filters,
                    sort,
                    rows,
                })] }));
});
export const TableLoadMore = TableLoadMoreCore;
