import { jsx as _jsx } from "react/jsx-runtime";
import { dynaSwitch } from "dyna-switch";
import { EMapType } from "../../../../geoInterfaces";
import { SVGIcon } from "../../../../SVGIcon";
import { MapButton } from "../MapButton";
import RoadMapIcon from '@mui/icons-material/Map';
import TerrainIcon from '@mui/icons-material/Terrain';
import HybridIcon from '@mui/icons-material/Satellite';
import satelliteImage from "../../../assets/satelllite.svg";
const SatelliteIcon = () => _jsx(SVGIcon, { src: satelliteImage });
export const MapTypeButton = (props) => {
    const { availableMapTypes, mapType, onClick, } = props;
    const handleClick = () => {
        const options = (new Array()).concat(availableMapTypes, availableMapTypes);
        onClick(options[options.indexOf(mapType) + 1]);
    };
    return (_jsx(MapButton, { title: "Map type", icon: dynaSwitch(mapType, _jsx(RoadMapIcon, {}), {
            [EMapType.ROADMAP]: _jsx(RoadMapIcon, {}),
            [EMapType.SATELLITE]: _jsx(SatelliteIcon, {}),
            [EMapType.TERRAIN]: _jsx(TerrainIcon, {}),
            [EMapType.HYBRID]: _jsx(HybridIcon, {}),
        }), onClick: handleClick }));
};
