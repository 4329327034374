var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import localforage from "localforage";
import { DynaJobQueue } from "dyna-job-queue";
import { dynaError } from "dyna-error";
import { dynaTry } from "dyna-try";
export class DbTiles {
    constructor(config) {
        this.config = config;
        this.saveTile = (tile) => __awaiter(this, void 0, void 0, function* () {
            const key = tile.key;
            const url = tile.url.replace('http://', 'https://');
            let loaded = false;
            let saved = false;
            yield dynaTry({
                timeout: 10000,
                try: () => __awaiter(this, void 0, void 0, function* () {
                    const start = Date.now();
                    const response = yield fetch(url);
                    const imageBlob = yield response.blob();
                    if (response.status < 200 && response.status >= 300) {
                        throw dynaError({
                            code: 202102251336,
                            message: `Fetch error status: ${response.status}`,
                        });
                    }
                    loaded = true;
                    yield this.store.removeItem(key);
                    yield this.store.setItem(key, imageBlob);
                    saved = true;
                    console.log(`Offline: Saved tile: ${(imageBlob.size / 1024).toFixed(1)}kb in ${Date.now() - start}ms url: ${url}`);
                }),
            })
                .catch(error => console.error(`Offline: DbTiles: saveTile: Cannot process tile url [${url}]`, error));
            return {
                loaded,
                saved,
            };
        });
        this.store = localforage.createInstance({ name: `GeoMapLeaflet-dbTiles--for-offline` });
    }
    // This function is called by setupOffline's leaflet-offline
    clear() {
        return this.store.clear();
    }
    getSavedMapsCount() {
        return this.store.length();
    }
    getOccupiedSizeInMb() {
        return __awaiter(this, void 0, void 0, function* () {
            const count = yield this.getSavedMapsCount();
            return count * 90;
        });
    }
    getSavedMapsLabel() {
        return __awaiter(this, void 0, void 0, function* () {
            const count = yield this.getSavedMapsCount();
            return this.getMapsCountLabel(count);
        });
    }
    getMapsCountLabel(maps) {
        return `${maps} maps (~${(maps * 90 / 1024).toFixed(1)}mb)`;
    }
    // This function is called by setupOffline's leaflet-offline
    getItem(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (navigator.onLine)
                return undefined; // Ignore that cached to get the most updated version of the tile.
            return this.store.getItem(key);
        });
    }
    // This function is called by setupOffline's leaflet-offline
    saveTiles(tiles) {
        return __awaiter(this, void 0, void 0, function* () {
            const currentTilesCount = yield this.getSavedMapsCount();
            return new Promise(resolve => {
                this.config.onSaveOfflineMaps({
                    saveMapsCount: tiles.length,
                    saveMapsLabel: this.getMapsCountLabel(tiles.length),
                    savedMapsCount: currentTilesCount,
                    savedMapsLabel: this.getMapsCountLabel(currentTilesCount),
                    saveMaps: ({ onProgress, onComplete, }) => {
                        const loadQueue = new DynaJobQueue({ parallels: 10 });
                        let tilesLoadFailed = 0;
                        let tilesSaveFailed = 0;
                        let canceled = false;
                        const saveNextTile = (tile, no) => __awaiter(this, void 0, void 0, function* () {
                            if (canceled)
                                return;
                            const saveResult = yield this.saveTile(tile);
                            if (!saveResult.loaded)
                                tilesLoadFailed++;
                            if (saveResult.loaded && !saveResult.saved)
                                tilesSaveFailed++;
                            onProgress({
                                progress: Number((no * 100 / tiles.length).toFixed(1)),
                                failedLoadCount: tilesLoadFailed,
                                failedSaveCount: tilesSaveFailed,
                            });
                        });
                        tiles.forEach((tile, index) => {
                            loadQueue.addJobPromised(() => saveNextTile(tile, index));
                        });
                        loadQueue.addJobPromised(() => __awaiter(this, void 0, void 0, function* () {
                            onComplete(canceled);
                            resolve();
                        }));
                        return { cancel: () => canceled = true };
                    },
                });
            });
        });
    }
}
