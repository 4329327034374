import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, } from "react";
import { guid } from "dyna-guid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { isMac } from "utils-library/dist/web";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { Box } from "../Box";
import { IconViewer } from "../IconViewer";
import { Button, EButtonColor, EButtonVariant, } from "../Button";
import { useBreakpointDevice } from "../useBreakpointDevice";
export { EButtonVariant, };
export const ModalDialog = (props) => {
    const { show, Icon, title, children, buttonVariant = EButtonVariant.TRANSPARENT, buttons, onClose, } = props;
    const id = useMemo(guid, []);
    const { isMobile } = useBreakpointDevice();
    return (_jsxs(Dialog, { "aria-labelledby": id, fullScreen: isMobile, open: show, onClose: onClose, children: [_jsx(DialogTitle, { id: id, children: _jsx(Box, { sx: {
                        fontSize: theme => theme.typography.fontSize * 2,
                        fontWeight: 'bold',
                    }, children: _jsxs(FlexContainerHorizontal, { alignVertical: "middle", children: [_jsx(FlexItemMin, { children: _jsx(IconViewer, { sx: {
                                        position: 'relative',
                                        top: 4,
                                    }, Icon: Icon }) }), _jsx(FlexItemMin, { sx: { minWidth: theme => theme.spacing(1) } }), _jsx(FlexItemMax, { children: title })] }) }) }), _jsx(DialogContent, { sx: { opacity: 0.8 }, children: children }), _jsx(DialogActions, { sx: { padding: theme => theme.spacing(2) }, children: (isMac
                    ? buttons.concat().reverse()
                    : buttons)
                    .map(({ Icon, label, onClick, }, index) => (_jsx(Button, { Icon: Icon, variant: buttonVariant, color: index === (isMac ? buttons.length - 1 : 0)
                        ? EButtonColor.PRIMARY
                        : EButtonColor.SECONDARY, onClick: onClick, children: label }, index))) })] }));
};
