import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { useRef, useMemo, useContext, forwardRef, useImperativeHandle, } from "react";
import { Rnd } from 'react-rnd';
import { guid } from 'dyna-guid';
import { value } from "utils-library/dist/utils";
import { Box } from "../Box";
import { IconViewer } from "../IconViewer";
import { Paper } from "../Paper";
import { FlexContainerHorizontal, FlexItemMax, FlexItemMin, } from "../FlexContainer";
import { IconButton, EIconButtonSize, } from "../IconButton";
import { ContainerEllipsis } from "../ContainerEllipsis";
import { WindowManagerContext } from "./WindowManager";
import { useTheme } from "../ThemeProvider";
import { createIcon } from "../IconComponent";
import CloseIcon from '@mui/icons-material/Close';
import MinimizeIcon from '@mui/icons-material/CloseFullscreen';
import RestoreIcon from '@mui/icons-material/OpenInFull';
export const Window = forwardRef((props, ref) => {
    const { show = true, Icon, title, initial, resizable = false, minWidth = 128, minHeight = 104, maxWidth, maxHeight, movable = false, minimizable = false, bounds = "window", buttons = [], children, onDrag, onDragStart, onDragEnd, onClose, } = props;
    const theme = useTheme();
    const headerHeight = 40;
    const windowId = useMemo(() => 'window-id--' + guid(1).replace(/-/g, ''), []);
    const refRnd = useRef(null);
    const { activeWindowId, setActiveWindowId, } = useContext(WindowManagerContext);
    const refChildren = useRef(null);
    const [isMinimized, setIsMinimized] = React.useState(false);
    useImperativeHandle(ref, () => ({
        handleResizeHeightToContent: () => {
            if (!refRnd.current)
                return;
            if (!refChildren.current)
                return;
            refRnd.current.updateSize({
                width: refChildren.current.offsetWidth,
                height: refChildren.current.offsetHeight + headerHeight,
            });
        },
    }));
    if (!show)
        return null;
    const handleClick = () => setActiveWindowId(windowId);
    const handleMinimize = () => setIsMinimized(!isMinimized);
    const titleFontSize = theme.typography.fontSize * 1.1;
    return (_jsx(Rnd, { ref: refRnd, style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: "hidden",
            zIndex: activeWindowId === windowId ? 1 : 0,
        }, minWidth: minWidth, minHeight: isMinimized ? headerHeight + 2 : minHeight, maxWidth: maxWidth, maxHeight: isMinimized ? headerHeight + 2 : maxHeight, default: {
            x: value(initial === null || initial === void 0 ? void 0 : initial.left, 0),
            y: value(initial === null || initial === void 0 ? void 0 : initial.top, 0),
            width: value(initial === null || initial === void 0 ? void 0 : initial.width, 'auto'),
            height: value(initial === null || initial === void 0 ? void 0 : initial.height, 'auto'),
        }, enableResizing: {
            bottom: resizable && !isMinimized,
            bottomLeft: resizable && !isMinimized,
            bottomRight: resizable && !isMinimized,
            left: resizable,
            right: resizable,
            top: resizable && !isMinimized,
            topLeft: resizable && !isMinimized,
            topRight: resizable && !isMinimized,
        }, bounds: bounds, onDrag: onDrag, onDragStart: onDragStart, onDragEnd: onDragEnd, dragHandleClassName: windowId, disableDragging: !movable, children: _jsx(Paper, { sx: {
                padding: 0,
                border: theme => `1px solid ${theme.palette.grayShades.gray7}`,
                overflow: 'hidden',
                boxShadow: '8px 8px 8px -5px rgba(0,0,0,0.2)',
            }, fullWidth: true, fullHeight: true, children: _jsxs(Box, { onMouseDown: handleClick, children: [_jsx("div", { className: movable ? windowId : undefined, style: { cursor: movable ? 'grab' : undefined }, children: _jsxs(FlexContainerHorizontal, { dataComponentName: "Window-Header", spacing: 1, alignVertical: "middle", sx: {
                                height: headerHeight,
                                backgroundColor: theme => theme.palette.grayShades.gray1,
                                userSelect: 'none',
                                cursor: movable ? 'grab' : undefined,
                                ':active': { cursor: movable ? 'grabbing' : undefined },
                            }, children: [_jsx(FlexItemMin, { show: !!Icon, children: _jsx(IconViewer, { Icon: Icon, sx: {
                                            fontSize: titleFontSize * 1.30,
                                            position: 'relative',
                                            top: (titleFontSize / 8) + 'px',
                                        } }) }), _jsx(FlexItemMax, { dataComponentName: "Window-Header-Title", sx: {
                                        fontSize: titleFontSize,
                                        fontWeight: 'bold',
                                    }, title: title, overFlowX: true, children: _jsx(ContainerEllipsis, { children: title }) }), buttons
                                    .map(({ Icon, hidden = false, disabled = false, ariaLabel, title, onClick, }, index) => (_jsx(FlexItemMin, { show: !hidden, children: _jsx(IconButton, { ariaLabel: ariaLabel, size: EIconButtonSize.SMALL, Icon: Icon, disabled: disabled, title: title, onClick: onClick }) }, index))), _jsx(FlexItemMin, { show: minimizable, children: _jsx(IconButton, { ariaLabel: isMinimized ? "Restore window" : "Minimize window", size: EIconButtonSize.SMALL, Icon: createIcon.byMuiIcon(isMinimized ? RestoreIcon : MinimizeIcon), onClick: handleMinimize }) }), _jsx(FlexItemMin, { show: !!onClose, children: _jsx(IconButton, { ariaLabel: "Close window", size: EIconButtonSize.SMALL, noPadding: true, Icon: createIcon.byMuiIcon(CloseIcon), onClick: onClose }) })] }) }), _jsx(Box, { show: !isMinimized, ref: refChildren, children: children })] }) }) }));
});
Window.displayName = Window.name;
