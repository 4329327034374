import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "../Box";
import { FlexContainerHorizontal, FlexItemMax, FlexItemMin, } from "../FlexContainer";
import { useTheme, } from "../ThemeProvider";
import { FlexContainerVertical } from "../FlexContainer";
import { IconViewer } from "../IconViewer";
export var ELabelContainerVariant;
(function (ELabelContainerVariant) {
    ELabelContainerVariant["NONE"] = "NONE";
    ELabelContainerVariant["FILLED"] = "FILLED";
    ELabelContainerVariant["OUTLINED"] = "OUTLINED";
    ELabelContainerVariant["STANDARD"] = "STANDARD";
})(ELabelContainerVariant || (ELabelContainerVariant = {}));
export const LabelContainer = (props) => {
    const { sx = {}, show = true, variant = ELabelContainerVariant.STANDARD, fullHeight = false, overFlowY = false, dataComponentName, Icon, label, labelRightContent, ariaLabel, inputId, error, children, helperContent, onLabelClick, } = props;
    const theme = useTheme();
    if (!show)
        return null;
    return (_jsx(Box, { sx: Object.assign(Object.assign({ color: error ? theme.palette.error.main : undefined }, (() => {
            switch (variant) {
                case ELabelContainerVariant.FILLED:
                    return {
                        backgroundColor: theme.palette.action.disabledBackground,
                        padding: theme.spacing(1),
                    };
                case ELabelContainerVariant.OUTLINED:
                    return {
                        border: '1px solid',
                        borderRadius: '6px',
                        borderColor: theme.palette.grayShades.gray5,
                        backgroundColor: 'transparent',
                        padding: theme.spacing(1),
                    };
                case ELabelContainerVariant.STANDARD:
                    return { borderBottom: '1px solid' };
                default:
                    return {};
            }
        })()), sx), fullHeight: fullHeight, dataComponentName: [dataComponentName, 'LabelContainer'], children: _jsxs(FlexContainerVertical, { fullHeight: fullHeight, children: [_jsxs(FlexItemMin, { children: [_jsxs(FlexContainerHorizontal, { sx: { cursor: onLabelClick ? "pointer" : undefined }, onClick: onLabelClick, children: [_jsx(FlexItemMax, { children: !!label && (_jsxs("label", { style: Object.assign({ display: 'block', fontSize: (theme.typography.fontSize * 1.2) + 'px', fontWeight: 'bold', cursor: 'inherit' }, (() => {
                                            if (variant === ELabelContainerVariant.OUTLINED) {
                                                return { marginTop: 4 };
                                            }
                                            return {};
                                        })()), "aria-label": ariaLabel, htmlFor: inputId, children: [_jsx(IconViewer, { sx: {
                                                    position: 'relative',
                                                    top: '3px',
                                                    paddingRight: 1,
                                                }, Icon: Icon }), label] })) }), _jsx(FlexItemMin, { children: labelRightContent })] }), _jsx(Box, { sx: { marginBottom: theme => theme.spacing(2) }, show: variant === ELabelContainerVariant.OUTLINED })] }), _jsx(FlexItemMax, { overFlowY: overFlowY, fullHeight: overFlowY, children: children }), _jsx(FlexItemMin, { children: helperContent })] }) }));
};
