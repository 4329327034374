
import {
  API_PATH_ApiAppVersionGet,
  IApiAppVersionGetResponse,
} from "mhc-server/dist/interfaces";

import {apiFetch} from "../../../../api/apiFetch";

export const apiAppVersionGet = async (): Promise<IApiAppVersionGetResponse> => {
  return apiFetch.request<null, void, IApiAppVersionGetResponse>({
    method: 'GET',
    path: API_PATH_ApiAppVersionGet,
  });
};
