import {JSONParse} from "utils-library/dist/utils";

import {IFilterSort} from "mhc-ui-components/dist/ViewController";
import {ErrorBanner} from "mhc-ui-components/dist/ErrorBanner";

import {IAppRoute} from "../../application/config/IAppRoute";

import {routeTrafficStudyListPaths} from "./routeTrafficStudyList.paths";
import {
  IFilter,
  ISort,
  TrafficStudiesList,
} from "../pages/TrafficStudiesList";

export const routeTrafficStudyList: IAppRoute<{ searchObjectText: string }> = {
  ...routeTrafficStudyListPaths,
  render: (
    {pathParams: {searchObjectText}},
  ) => {
    const {
      data: {
        filter,
        sort,
      },
      error,
    } = JSONParse<IFilterSort<IFilter, ISort>>({
      serialized: searchObjectText,
      default: {
        filter: {
          searchText: '',
          start: null,
          end: null,
          archived: false,
          deleted: false,
        },
        sort: {},
      },
      errorCode: 20240102094625,
      errorMessage: 'routeTrafficStudyList, cannot parse searchObjectText from URL',
    });
    return error
      ? <ErrorBanner error={error}/>
      : (
        <TrafficStudiesList
          filter={filter}
          sort={sort}
        />
      );
  },
};
