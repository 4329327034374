import { jsx as _jsx } from "react/jsx-runtime";
import { getDataComponentName, } from "../ui-interfaces";
import { Box } from "../Box";
import { sxTransition, ECSSDuration, } from "../sxTransition";
export const FlexItemMax = (props) => {
    const { innerRef, flex, fullHeight, overFlowX = false, overFlowY = false, sx: userSx = {}, dataComponentName, show = true, hidden = false, noSpacing = false, title, children = null, onClick, onMouseMove, onMouseDown, onMouseUp, onMouseEnter, onMouseLeave, onScroll, onWheel, } = props;
    if (!show)
        return null;
    const applyFullHeight = fullHeight || overFlowY;
    const sx = Object.assign({ flex: flex || `1 1 100%`, height: applyFullHeight ? '100%' : undefined, position: 'relative', margin: noSpacing
            ? 0
            : undefined, transition: theme => sxTransition(theme, "margin", ECSSDuration.SHORT) }, userSx);
    if (overFlowX || overFlowY) {
        const style = {
            overflowX: (() => {
                if (overFlowY && !overFlowX)
                    return "hidden";
                if (overFlowX)
                    return "auto";
                return undefined;
            })(),
            overflowY: (() => {
                if (overFlowX && !overFlowY)
                    return "hidden";
                if (overFlowY)
                    return "auto";
                return undefined;
            })(),
            width: overFlowX ? "1px" : undefined,
            height: overFlowY ? "1px" : undefined,
            minWidth: overFlowX ? '100%' : undefined,
            minHeight: (() => {
                if (applyFullHeight)
                    return "100%";
                return overFlowY ? '100%' : undefined;
            })(),
        };
        return (_jsx(Box, { sx: sx, dataComponentName: getDataComponentName(dataComponentName, "FlexItemMax"), hidden: hidden, title: title, onClick: onClick, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onScroll: onScroll, onWheel: onWheel, children: _jsx(Box, { ref: innerRef, sx: style, children: children }) }));
    }
    return (_jsx(Box, { ref: innerRef, sx: sx, dataComponentName: getDataComponentName(dataComponentName, "FlexItemMax"), hidden: hidden, title: title, onClick: onClick, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onScroll: onScroll, onWheel: onWheel, children: children }));
};
