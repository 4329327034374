import { EWZClosureType, EWZLane, EWZShoulder, } from "./IWorkzone";
export const closureTypePresetValues = {
    [EWZClosureType.NONE]: {
        itisCodes: [],
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 0,
    },
    [EWZClosureType.LEFT_LANE_CLOSED_MERGE_RIGHT]: {
        itisCodes: "1025 769 12545 8195 771 7451 13579".split(' ').map(Number),
        closedLane: EWZLane.LEFT,
        closedShoulder: EWZShoulder.LEFT,
        speedLimitInMiles: 0,
    },
    [EWZClosureType.RIGHT_LANE_CLOSED_MERGE_LEFT]: {
        itisCodes: "1025 769 12545 8196 771 7451 13580".split(' ').map(Number),
        closedLane: EWZLane.RIGHT,
        closedShoulder: EWZShoulder.RIGHT,
        speedLimitInMiles: 0,
    },
    [EWZClosureType.LEFT_SHOULDER_CLOSED]: {
        itisCodes: "1025 769 8209 771".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.LEFT,
        speedLimitInMiles: 0,
    },
    [EWZClosureType.RIGHT_SHOULDER_CLOSED]: {
        itisCodes: "1025 769 8208 771".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.RIGHT,
        speedLimitInMiles: 0,
    },
    [EWZClosureType.REDUCED_SPEED_15_MPH]: {
        itisCodes: "1025 268 12559 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 15,
    },
    [EWZClosureType.REDUCED_SPEED_20_MPH]: {
        itisCodes: "1025 268 12564 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 20,
    },
    [EWZClosureType.REDUCED_SPEED_25_MPH]: {
        itisCodes: "1025 268 12569 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 25,
    },
    [EWZClosureType.REDUCED_SPEED_30_MPH]: {
        itisCodes: "1025 268 12574 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 30,
    },
    [EWZClosureType.REDUCED_SPEED_35_MPH]: {
        itisCodes: "1025 268 12579 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 35,
    },
    [EWZClosureType.REDUCED_SPEED_40_MPH]: {
        itisCodes: "1025 268 12584 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 40,
    },
    [EWZClosureType.REDUCED_SPEED_45_MPH]: {
        itisCodes: "1025 268 12589 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 45,
    },
    [EWZClosureType.REDUCED_SPEED_50_MPH]: {
        itisCodes: "1025 268 12594 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 50,
    },
    [EWZClosureType.REDUCED_SPEED_55_MPH]: {
        itisCodes: "1025 268 12599 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 55,
    },
    [EWZClosureType.REDUCED_SPEED_60_MPH]: {
        itisCodes: "1025 268 12604 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 60,
    },
    [EWZClosureType.REDUCED_SPEED_65_MPH]: {
        itisCodes: "1025 268 12609 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 65,
    },
    [EWZClosureType.REDUCED_SPEED_70_MPH]: {
        itisCodes: "1025 268 12614 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 70,
    },
    [EWZClosureType.LEFT_TURN_SPEED_15_MPH]: {
        itisCodes: "13594 268 12559 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 15,
    },
    [EWZClosureType.LEFT_TURN_SPEED_20_MPH]: {
        itisCodes: "13594 268 12564 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 20,
    },
    [EWZClosureType.LEFT_TURN_SPEED_25_MPH]: {
        itisCodes: "13594 268 12569 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 25,
    },
    [EWZClosureType.LEFT_TURN_SPEED_30_MPH]: {
        itisCodes: "13594 268 12574 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 30,
    },
    [EWZClosureType.LEFT_TURN_SPEED_35_MPH]: {
        itisCodes: "13594 268 12579 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 35,
    },
    [EWZClosureType.LEFT_TURN_SPEED_40_MPH]: {
        itisCodes: "13594 268 12584 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 40,
    },
    [EWZClosureType.LEFT_TURN_SPEED_45_MPH]: {
        itisCodes: "13594 268 12589 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 45,
    },
    [EWZClosureType.LEFT_TURN_SPEED_50_MPH]: {
        itisCodes: "13594 268 12594 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 50,
    },
    [EWZClosureType.LEFT_TURN_SPEED_55_MPH]: {
        itisCodes: "13594 268 12599 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 55,
    },
    [EWZClosureType.RIGHT_TURN_SPEED_15_MPH]: {
        itisCodes: "13599 268 12559 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 15,
    },
    [EWZClosureType.RIGHT_TURN_SPEED_20_MPH]: {
        itisCodes: "13599 268 12564 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 20,
    },
    [EWZClosureType.RIGHT_TURN_SPEED_25_MPH]: {
        itisCodes: "13599 268 12569 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 25,
    },
    [EWZClosureType.RIGHT_TURN_SPEED_30_MPH]: {
        itisCodes: "13599 268 12574 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 30,
    },
    [EWZClosureType.RIGHT_TURN_SPEED_35_MPH]: {
        itisCodes: "13599 268 12579 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 35,
    },
    [EWZClosureType.RIGHT_TURN_SPEED_40_MPH]: {
        itisCodes: "13599 268 12584 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 40,
    },
    [EWZClosureType.RIGHT_TURN_SPEED_45_MPH]: {
        itisCodes: "13599 268 12589 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 45,
    },
    [EWZClosureType.RIGHT_TURN_SPEED_50_MPH]: {
        itisCodes: "13599 268 12594 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 50,
    },
    [EWZClosureType.RIGHT_TURN_SPEED_55_MPH]: {
        itisCodes: "13599 268 12599 8720".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 55,
    },
    [EWZClosureType.HEIGHT_LIMIT_14_FT_0_IN]: {
        itisCodes: "2574 13569 12712 8709".split(' ').map(Number),
        closedLane: EWZLane.NONE,
        closedShoulder: EWZShoulder.NONE,
        speedLimitInMiles: 0,
    },
};
