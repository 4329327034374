export const getQueryFromURL = (url) => {
    return (url
        .split('?').pop()
        || '')
        .split('&')
        .reduce((acc, text) => {
        const [key, value] = text.split('=');
        acc[key] = value;
        return acc;
    }, {});
};
