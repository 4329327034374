import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { sanitizeHtml } from "utils-library/dist/web";
export const HtmlContent = (props) => {
    const { component = "div", html: userHtml, sanitize = true, style, } = props;
    const html = useMemo(() => sanitize
        ? sanitizeHtml(userHtml)
        : userHtml, [userHtml]);
    const Node = component;
    return (_jsx(Node
    // eslint-disable-next-line
    , { 
        // eslint-disable-next-line
        dangerouslySetInnerHTML: { __html: html }, style: style }));
};
