import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, } from "react";
import { Window } from "../../../../Windows";
import { MapComponent } from "../MapComponent";
import { MapPolyline } from "../MapPolyline";
export const MapToolWindow = ({ show, windowId, minimizable, minWidth, ownerPosition, Icon, title, children, onClose, }) => {
    const [initialWindowPosition, setInitialWindowPosition] = useState({
        lat: ownerPosition.lat - 0.0001,
        lng: ownerPosition.lng + 0.0001,
    });
    useEffect(() => {
        if (!show)
            setInitialWindowPosition(currentWindowPosition); // Save the initialWindowPosition for the next show.
    }, [show]);
    const [currentWindowPosition, setCurrentWindowPosition] = useState(initialWindowPosition);
    const handleDragEng = (position) => setCurrentWindowPosition(position);
    if (!show)
        return null;
    return (_jsxs(MapComponent, { componentId: windowId, position: initialWindowPosition, interactive: true, draggable: true, zIndex: 1000, onDrag: handleDragEng, children: [_jsx(Window, { Icon: Icon, title: title, minimizable: minimizable, minWidth: minWidth, onClose: onClose, children: children }), _jsx(MapPolyline, { points: [
                    ownerPosition,
                    currentWindowPosition,
                ], pathOptions: {
                    weight: 3,
                    color: '#cfff00',
                    dashArray: '8, 4',
                } })] }));
};
