/**
 * The Value() is a helper method that always returns a typed result based on the default value.
 * If the provided value is undefined, the default value is returned.
 * @param value
 * @param defaultValue
 */
export const value = (value, defaultValue) => {
    if (value === undefined)
        return defaultValue;
    return value;
};
