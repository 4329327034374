import { sxTransition, ECSSDuration, } from "../sxTransition";
export const sxHover = ({ hover = true, pointer = false, additionalTransformCssAttrs = [], } = {}) => ({
    transition: theme => sxTransition(theme, [
        'background-color',
        ...additionalTransformCssAttrs,
    ], ECSSDuration.SHORT),
    ':hover': {
        backgroundColor: theme => hover ? theme.palette.grayShades.gray2 : undefined,
        cursor: pointer ? 'pointer' : undefined,
    },
    ':active': { backgroundColor: theme => hover ? theme.palette.grayShades.gray3 : undefined },
});
