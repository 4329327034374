export class ConsoleOnce {
    constructor(global) {
        this.global = global;
        this.loggedMessages = new Set();
    }
    log(title, ...messages) {
        this.logMessage(this.global.console.log, title, ...messages);
    }
    warn(title, ...messages) {
        this.logMessage(this.global.console.warn, title, ...messages);
    }
    error(title, ...messages) {
        this.logMessage(this.global.console.error, title, ...messages);
    }
    debug(title, ...messages) {
        this.logMessage(this.global.console.debug, title, ...messages);
    }
    info(title, ...messages) {
        this.logMessage(this.global.console.info, title, ...messages);
    }
    logMessage(consoleMethod, ...messages) {
        const methodKey = consoleMethod.name; // Get the name of the console method
        const uniqueMessage = `${methodKey}:${messages[0]}`; // Create a unique key for the message and method
        if (!this.loggedMessages.has(uniqueMessage)) {
            consoleMethod("[consoled once]", ...messages);
            this.loggedMessages.add(uniqueMessage);
        }
    }
}
