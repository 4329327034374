import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef, } from "react";
import { JSONStringifyForHumans } from "utils-library/dist/utils";
import { FlexContainerVertical, FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { Box } from "../Box";
import { Link, ELinkColor, } from "../Link";
import { Modal } from "../Modal";
import { FormatDateTime } from "../FormatDateTime";
import { IconButton, EIconButtonSize, } from "../IconButton";
import { ButtonBar, EButtonBarAlign, } from "../ButtonBar";
import { Button, EButtonColor, } from "../Button";
import { ButtonCopyToClipboard } from "../ButtonCopyToClipboard";
import { createIcon } from "../IconComponent";
import CloseIcon from '@mui/icons-material/Cancel';
/**
 * Use this component to display the "Show error details" link.
 * Upon clicking, it will show a modal containing the error details.
 * @constructor
 */
export const ErrorLinkModalViewer = (props) => {
    const { show: showLink = true, color = ELinkColor.ERROR, error, children = "Show error details", } = props;
    const refClipboardContent = useRef(null);
    const [showModal, setShowModal] = useState(false);
    if (!error)
        return null;
    const handleLinkClick = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const stringifiedError = JSONStringifyForHumans({ obj: error });
    const errorReport = (_jsxs(Box, { dataComponentName: "ErrorReport", ref: refClipboardContent, sx: {
            fontFamily: 'monospace',
            whiteSpace: 'pre',
        }, children: [_jsxs("p", { children: [_jsx("b", { children: "Date:" }), " ", _jsx(FormatDateTime, {})] }), _jsxs("p", { children: [_jsx("b", { children: "Website address:" }), " ", _jsx("code", { children: window.location.href })] }), stringifiedError === "{}"
                ? `Native Error toString(): ${error.toString()}`
                : stringifiedError] }));
    return (_jsxs(_Fragment, { children: [_jsx(Link, { show: showLink && !!error, href: "", color: color, onClick: handleLinkClick, children: children }), _jsx(Modal, { sx: { padding: theme => theme.spacing(2) }, show: showModal, fullMode: true, onBackdropClick: handleCloseModal, children: _jsxs(FlexContainerVertical, { fullHeight: true, spacing: 2, children: [_jsx(FlexItemMin, { children: _jsxs(FlexContainerHorizontal, { children: [_jsx(FlexItemMax, { children: _jsx("h1", { children: "Error details" }) }), _jsx(FlexItemMin, { sx: { color: theme => theme.palette.error.main }, children: _jsx(IconButton, { Icon: createIcon.byMuiIcon(CloseIcon), size: EIconButtonSize.MEDIUM, ariaLabel: "Close", onClick: handleCloseModal }) })] }) }), _jsx(FlexItemMax, { overFlowX: true, overFlowY: true, children: errorReport }), _jsx(FlexItemMin, { children: _jsxs(ButtonBar, { reverseOnMac: true, align: EButtonBarAlign.RIGHT, children: [_jsx(ButtonCopyToClipboard, { children: errorReport }), _jsx(Button, { Icon: createIcon.byMuiIcon(CloseIcon), color: EButtonColor.SECONDARY, onClick: handleCloseModal, children: "Close" })] }) })] }) })] }));
};
