var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { buildUrl } from "utils-library/dist/utils";
import { apiFetch } from "utils-library/dist/node-net";
import { API_PATH_ApiAlertMgtmAlertHandlingPost, } from "../../endpoints/alert-handling/apiAlertMgtmAlertHandlingPost.interfaces";
export const apiAlertMgtmAlertHandlingPost = (_a) => __awaiter(void 0, [_a], void 0, function* ({ deviceStatus: { regionId, locationId, deviceId, }, alertType: type, title, description, alert, alertHandle, userId = '--system--apiAlertMgtmAlertHandlingPost--', }) {
    const body = {
        accessKey: process.env.AUTHENTICATION_ENCRYPTION_KEY || '',
        type,
        title,
        description,
        regionId,
        locationId,
        deviceId,
        alert,
        alertHandle,
        userId,
    };
    yield apiFetch({
        method: 'POST',
        path: buildUrl(process.env.ZIP_CREATOR_SERVICE__ADDRESS, API_PATH_ApiAlertMgtmAlertHandlingPost),
        body,
    })
        .catch(error => {
        console.error('CRITICAL ERROR 202301031323: apiAlertMgtmAlertHandlingPost()[intranet]: Request to alerts-management service failed! Alert is lost!', {
            body,
            error,
        });
    });
});
