import { dynaError } from "dyna-error";
import { getDeepValue, camelToHuman, } from "../utils";
/**
 * The primary validation engine executes all the validation rules against the given data.
 */
export const validationEngine = ({ data, validationRules, checkForUnexpectedProperties = true, ignoreUndefinedRules = false, ignoreUndefinedValues = false, ignoreFields = [], returnDataValidationEmptyStrings = false, }) => {
    const dataValidation = validateDataEngine({
        data,
        validationRules,
        ignoreUndefinedRules,
        ignoreUndefinedValues,
        ignoreFields,
        returnDataValidationEmptyStrings,
    });
    const allMessages = Object
        .keys(dataValidation)
        .reduce((acc, property) => {
        const validationResult = dataValidation[property];
        if (!validationResult)
            return acc;
        acc.push(`${camelToHuman(property.replace(/\./g, '/'))}: ${validationResult}`);
        return acc;
    }, []);
    const validProps = Object.keys(validationRules);
    const unexpectedProperties = checkForUnexpectedProperties
        ? Object.keys(data)
            .reduce((acc, dataProperty) => validProps.includes(dataProperty)
            ? acc
            : acc.concat(`Property [${dataProperty}] was unexpected`), [])
        : [];
    return {
        isValid: allMessages.length === 0 &&
            unexpectedProperties.length === 0,
        dataValidation,
        messages: allMessages
            .concat(unexpectedProperties),
    };
};
const validateDataEngine = ({ data, validationRules, ignoreUndefinedRules = false, ignoreUndefinedValues = false, ignoreFields = [], returnDataValidationEmptyStrings = false, }) => {
    return (Object
        .keys(validationRules)
        .filter(fieldName => !ignoreFields.includes(fieldName))
        .filter(fieldName => !ignoreUndefinedRules || validationRules[fieldName] !== undefined)
        .filter(fieldName => !ignoreUndefinedValues || data[fieldName] !== undefined)
        .map(fieldName => ({
        fieldName,
        value: data[fieldName],
        result: (() => {
            const outputError = (code, message, parentError) => dynaError({
                code,
                message,
                parentError,
                data: {
                    data,
                    fieldName,
                },
            });
            if (!validationRules[fieldName])
                throw outputError(202112280808, `There is no validation rule defined for the property [${fieldName}]`);
            if (typeof validationRules[fieldName] !== "function")
                throw outputError(202112280809, `Validation rule for the property [${fieldName}] should be a function.`);
            try {
                const value = getDeepValue(data, fieldName);
                return validationRules[fieldName](value, data);
            }
            catch (e) {
                const error = dynaError(e);
                throw outputError(202109291249, `Validation method for property [${fieldName}] failed with exception: "${error.message}"`, error);
            }
        })(),
    }))
        .filter(item => returnDataValidationEmptyStrings
        || Boolean(item.result))
        .reduce((acc, item) => {
        acc[item.fieldName] = item.result;
        return acc;
    }, {}));
};
