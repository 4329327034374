import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { Box } from "../Box";
import { useTheme, } from "../ThemeProvider";
/**
 * Apply the MUI theme as default on the DOM. Applies colors and typography.
 * @param props
 * @constructor
 */
export const ThemeContainer = (props) => {
    const theme = useTheme();
    const { sx = {}, fullHeight = false, children, } = props;
    useEffect(() => {
        document.body.style.color = theme.palette.text.primary;
        document.body.style.backgroundColor = theme.palette.background.default;
    }, [theme]);
    return (_jsx(Box, { sx: Object.assign(Object.assign(Object.assign({ color: theme.palette.text.primary, backgroundColor: theme.palette.background.default }, theme.typography), (fullHeight
            ? { height: '100%' }
            : {})), sx), dataComponentName: "ThemeContainer", children: children }));
};
