import { validateDataMethods } from "utils-library/dist/validation-engine";
import { ESortPostsBy } from "./ESortPostsBy";
export const validateLoadPostsPagination = (paginations) => {
    const isPaginationInvalid = validateDataMethods.isObject(paginations);
    if (isPaginationInvalid)
        return isPaginationInvalid;
    const isSortNotObject = validateDataMethods.isObject(paginations.sort);
    if (isSortNotObject)
        return `Sort: ${isSortNotObject}`;
    const isSortByInvalid = validateDataMethods.isEnumValue(paginations.sort.by, ESortPostsBy);
    if (isSortByInvalid)
        return `Sort.by: ${isSortByInvalid}`;
    const isSortOrderInvalid = validateDataMethods.isOneOfValues(paginations.sort.order, -1, 1);
    if (isSortOrderInvalid)
        return `Sort.order: ${isSortOrderInvalid}`;
    const isLoadSizeValid = validateDataMethods.isIntegerInRange(paginations.loadSize, 0, 200);
    if (isLoadSizeValid)
        return `Sort.loadSize: ${isLoadSizeValid}`;
    const isLastPostInvalid = (() => {
        if (!paginations.lastPostCreatedAt)
            return "";
        return validateDataMethods.isTimestamp(paginations.lastPostCreatedAt);
    })();
    if (isLastPostInvalid)
        return isLastPostInvalid;
    return "";
};
