import * as L from "leaflet";
import { useTheme, } from "../../../../ThemeProvider";
import { makeStyles } from "@mui/styles";
import { sxTransition, ECSSDuration, } from "../../../../sxTransition";
export const headingCircle = ({ radius, slices, readOnly, onSliceClick, onSelectDeselectClick, }) => {
    const numOfSlices = slices.length || 0;
    const segmentRotate = 360 / numOfSlices;
    const segmentYSkew = segmentRotate + 90;
    const sliceStyles = generateSliceStyles(numOfSlices, segmentRotate, segmentYSkew);
    // eslint-disable-next-line
    const theme = useTheme();
    // eslint-disable-next-line
    const classes = useStyles({
        theme,
        radius,
        sliceStyles,
        readOnly,
    })();
    const headingIconCore = document.createElement('div');
    headingIconCore.className = classes.rootCircle;
    headingIconCore.id = 'base-circle';
    const sliceClickHandler = (event) => {
        if (readOnly)
            return;
        onSliceClick === null || onSliceClick === void 0 ? void 0 : onSliceClick(event.target.id);
    };
    const generateSlice = (slice) => {
        const item = document.createElement('div');
        item.className = classes.slice;
        item.id = slice.value.toString();
        if (!item.hasAttribute('ariaLabel'))
            item.setAttribute('ariaLabel', slice.ariaLabel);
        if (slice.selected)
            item.classList.add(classes.selectedSlice);
        if (onSliceClick)
            item.addEventListener('click', sliceClickHandler);
        headingIconCore.appendChild(item);
    };
    slices.forEach(slice => generateSlice(slice));
    const selectDeselectClickHandler = (event) => {
        event.preventDefault();
        if (readOnly)
            return;
        onSelectDeselectClick === null || onSelectDeselectClick === void 0 ? void 0 : onSelectDeselectClick();
    };
    const center = document.createElement('div');
    center.className = "__circle-center";
    const button = document.createElement('button');
    button.className = classes.centerButton;
    if (!readOnly) {
        const buttonText = slices
            .filter(slice => slice.selected)
            .length !== slices.length
            ? "Select All"
            : "Deselect All";
        if (!button.hasAttribute('ariaLabel'))
            button.setAttribute('ariaLabel', `${buttonText} Button`);
        const buttonLabel = document.createElement('span');
        buttonLabel.innerHTML = buttonText;
        button.appendChild(buttonLabel);
        if (onSelectDeselectClick)
            button.addEventListener('click', selectDeselectClickHandler);
        center.appendChild(button);
    }
    headingIconCore.appendChild(center);
    const divIcon = L.divIcon({
        className: classes.rootHeading,
        html: headingIconCore,
    });
    return divIcon;
};
const generateSliceStyles = (numOfSlices, rotate, skew) => {
    const stylekKeys = {};
    for (let i = 0; i < numOfSlices; i++) {
        stylekKeys[`&:nth-child(${(i + 1).toString()})`] = { transform: `rotate(${i * rotate}deg) skewY(${skew}deg)` };
    }
    return stylekKeys;
};
const useStyles = ({ theme, radius, sliceStyles, readOnly, }) => makeStyles(() => ({
    rootHeading: {},
    rootCircle: {
        position: 'relative',
        border: '4px solid ' + theme.palette.info.main,
        padding: 0,
        margin: '1em auto',
        width: theme.spacing(radius),
        height: theme.spacing(radius),
        borderRadius: '50%',
        overflow: 'hidden',
        bottom: '135px', // Centering to where leaflet places the marker
        right: '116px', // Centering to where leaflet places the marker
        '& .__circle-center': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            opacity: 1,
            height: theme.spacing(radius / 3),
            width: theme.spacing(radius / 3),
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'gray',
            zIndex: 3,
            borderRadius: '100%',
            border: '5px solid slategray',
            margin: theme.spacing(0.25),
        },
    },
    slice: Object.assign(Object.assign(Object.assign({ overflow: 'hidden', position: 'absolute', top: 0, left: '50%', width: theme.spacing(radius / 2), height: theme.spacing(radius / 2), transformOrigin: 'bottom left', background: 'transparent', border: '2px solid ' + theme.palette.info.main, cursor: 'grab', zIndex: 2 }, cursorStyleByReadOnly(readOnly, 'grab')), { '&:hover': styleSliceByReadOnly(readOnly, theme) }), sliceStyles),
    selectedSlice: Object.assign({ background: theme.palette.info.main, opacity: 0.5, border: '2px solid rgba(255, 87, 87, 0.35)', '&:hover': styleSliceByReadOnly(readOnly, theme, true) }, cursorStyleByReadOnly(readOnly, 'grab')),
    centerButton: Object.assign(Object.assign({ position: 'relative', width: '100%', height: '100%', borderRadius: '100%', backgroundColor: theme.palette.info.main, zIndex: 3, boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.26)', border: '1px solid ' + theme.palette.grey[400], transition: sxTransition(theme, 'background', ECSSDuration.SHORT) }, cursorStyleByReadOnly(readOnly, 'pointer')), { '&:hover': {
            boxShadow: '0 4px 12px 0 ' + theme.palette.grey[700],
            border: '1px solid #FFA000',
        }, '& span': {
            color: theme.palette.common.white,
            wordWrap: 'normal',
            whiteSpace: 'pre-wrap',
            height: '100%',
            width: '100%',
            fontSize: theme.typography.fontSize,
        } }),
}));
const cursorStyleByReadOnly = (readOnly, cursor) => {
    return { cursor: readOnly ? 'default' : cursor };
};
const styleSliceByReadOnly = (readOnly, theme, selectedSlice) => {
    if (!readOnly) {
        const sliceOpacity = selectedSlice ? 0.75 : 0.25;
        return {
            backgroundColor: theme.palette.info.main,
            opacity: sliceOpacity,
        };
    }
    return undefined;
};
