export const getDataComponentName = (dataComponentName = "", finalDataComponentName = "") => window.location.hostname === "localhost"
    ? (Array.isArray(dataComponentName)
        ? dataComponentName
        : typeof dataComponentName === "string"
            ? [dataComponentName]
            : [])
        .concat(finalDataComponentName)
        .filter(Boolean)
        .join(' ')
    : undefined;
