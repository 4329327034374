export const enumInfo = (setupDic) => ({
    dic: Object.entries(setupDic)
        .reduce((acc, [enumKey, { label, description, }]) => {
        acc[enumKey] = {
            enum: enumKey,
            label,
            description,
        };
        return acc;
    }, {}),
    array: Object.entries(setupDic)
        .reduce((acc, [enumKey, { label, description, },]) => {
        return acc.concat({
            enum: enumKey,
            label,
            description,
        });
    }, []),
});
