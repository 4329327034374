import {IsLoading} from "mhc-ui-components/dist/IsLoading";

import {Box} from "mhc-ui-components/dist/Box";

import {useTheme} from "mhc-ui-components/dist/ThemeProvider";

import {Condition} from "mhc-ui-components/dist/Condition";

export interface ILoadingPageProps {
  primaryText?: string;
  secondaryText?: string;
  children?: any;
}

export const LoadingPage = (props: ILoadingPageProps): JSX.Element => {
  const {
    primaryText,
    secondaryText,
    children,
  } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& >': {
          marginTop: theme.spacing(4),
          marginBottom: theme.spacing(4),
        },
      }}
    >
      <IsLoading
        sx={{
          position: 'relative',
          width: '80%',
          height: '20%',
        }}
        isLoading
        fadeBackground={false}
      />
      <Condition if={!!primaryText}>
        <Box
          sx={{
            opacity: 0.6,
            fontWeight: 'bold',
            fontSize: theme.typography.fontSize,
          }}
        >
          {primaryText}
        </Box>
      </Condition>
      <Condition if={!!secondaryText}>
        <Box
          sx={{
            opacity: 0.6,
            fontSize: theme.typography.fontSize * 0.7,
          }}
        >
          {secondaryText}
        </Box>
      </Condition>
      <div>{children}</div>
    </Box>
  );
};
