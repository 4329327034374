import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";
import {routeDeviceManagerPingPongTestPagePaths} from "./routeDeviceManagerPingPongTestPage.paths";

const DeviceMangerPingPongTestPage = lazy(() => import('../pages/DeviceMangerPingPongTestPage/DeviceMangerPingPongTestPage'));

export const routeDeviceManagerPingPongTestPage: IAppRoute = {
  ...routeDeviceManagerPingPongTestPagePaths,
  render: () => (
    <LazyLoadComponent>
      <DeviceMangerPingPongTestPage/>
    </LazyLoadComponent>
  ),
};
