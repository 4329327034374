import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { EDateFormatter, ETimeFormatter, EDateTimeOrder, formatDateTimeField, formatTimezoneField, } from "utils-library/dist/format-field";
import { getSystemTimezone } from "utils-library/dist/time";
import { Box } from "../Box";
import { IconViewer } from "../IconViewer";
import { createIcon } from "../IconComponent";
import GlobeIcon from '@mui/icons-material/Public';
export const FormatDateTime = (props) => {
    const { sx, value = new Date(), showLocalTimezone = false, sourceTimezone, dateFormatter = EDateFormatter.STANDARD, timeFormatter = ETimeFormatter.HOUR_MINUTE, dateTimeOrder = EDateTimeOrder.DATE_TIME, leadingSpace, trailingSpace, } = props;
    const terminalTimezone = getSystemTimezone();
    const localDateTimeLabel = formatDateTimeField({
        value: value,
        dateFormatter,
        timeFormatter,
        dateTimeOrder,
    }) + " ";
    const localTimezoneLabel = showLocalTimezone
        || (sourceTimezone && sourceTimezone !== terminalTimezone)
        ? formatTimezoneField({ timezone: getSystemTimezone() }) + " "
        : "";
    const sourceDateTimeLabel = sourceTimezone
        && sourceTimezone !== terminalTimezone
        ? formatDateTimeField({
            value: value,
            timezone: sourceTimezone,
            dateFormatter,
            timeFormatter,
            dateTimeOrder,
        })
        : "";
    const sourceTimezoneLabel = sourceTimezone
        ? formatTimezoneField({ timezone: sourceTimezone })
        : "";
    return (_jsxs(Box, { sx: sx, component: "span", children: [leadingSpace && " ", localDateTimeLabel, _jsx("i", { children: localTimezoneLabel }), sourceDateTimeLabel && (_jsxs(_Fragment, { children: ["(", _jsx(IconViewer, { Icon: createIcon.byMuiIcon(GlobeIcon), sx: {
                            position: "relative",
                            top: 3,
                        } }), " ", sourceDateTimeLabel, " ", _jsx("i", { children: sourceTimezoneLabel }), ")"] })), trailingSpace && " "] }));
};
