export const formatTime = (hours, minutes, format) => {
    const hoursFormatted = format === 'US'
        ? (hours > 12 ? hours - 12 : hours).toString()
        : hours.toString().padStart(2, '0');
    const amPm = format === 'US'
        ? hours > 11 ? ' PM' : ' AM'
        : "";
    return `${hoursFormatted}:${Math.round(minutes).toString()
        .padStart(2, '0')}${amPm}`;
};
