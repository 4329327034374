/**
 * Execute a method at intervals with the possibility of cancellation.
 */
export const setIntervals = ({ timeout, times, exec, leading = true, }) => {
    let count = 0;
    let lastHandler = null;
    let isCanceled = false;
    const handleCancel = () => {
        if (lastHandler)
            clearTimeout(lastHandler);
        isCanceled = true;
    };
    const execute = () => {
        count++;
        if (isCanceled)
            return;
        if (leading)
            exec(handleCancel);
        lastHandler = setTimeout(() => {
            if (!leading)
                exec(handleCancel);
            if (count < times)
                execute();
        }, timeout);
    };
    if (times)
        execute();
    return { cancel: handleCancel };
};
