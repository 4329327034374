import {dynaError} from "dyna-error";

import {
  API_PATH_ApiWorkzoneItemBroadcastPost,
  IApiWorkzoneItemBroadcastPostBodyRequest,
  IApiWorkzoneItemBroadcastPostResponse,
} from "mhc-server/dist/interfaces";

import {apiFetch} from "../../../api/apiFetch";

import {getOnline} from "utils-library/dist/web";

export const apiWorkzoneItemBroadcastPost = (id: string): Promise<IApiWorkzoneItemBroadcastPostResponse> => {
  if (!getOnline()) {
    throw dynaError({
      message: 'Cannot broadcast, you are offline',
      userMessage: 'You are offline.',
    });
  }

  return  apiFetch.request<null, IApiWorkzoneItemBroadcastPostBodyRequest, IApiWorkzoneItemBroadcastPostResponse>({
    path: API_PATH_ApiWorkzoneItemBroadcastPost,
    method: 'POST',
    body: {id},
  });
};
