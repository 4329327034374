import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import { FlexContainerHorizontal, FlexItemMin, } from "../../../../FlexContainer";
import { Box } from "../../../../Box";
import { Condition } from "../../../../Condition";
import { BreakpointDeviceContainer } from "../../../../BreakpointDeviceContainer";
import { IconButton, EIconButtonSize, } from "../../../../IconButton";
import { useTheme } from "../../../../ThemeProvider";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "@mui/icons-material/MoreVert";
export const AppNotificationIcons = (props) => {
    const theme = useTheme();
    const { notificationIcons = [], profileUserName, profileIcon, profileOptions = [], } = props;
    const menuId = 'app-menu--desktop';
    const mobileMenuId = 'app-menu--mobile';
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = !!anchorEl;
    const isMobileMenuOpen = !!mobileMoreAnchorEl;
    const handleNotificationIconClick = (disabled, onClick) => {
        handleMenuClose();
        if (disabled)
            return;
        onClick && onClick();
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileOptionClick = (onClick) => {
        onClick && onClick();
        handleMenuClose();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(FlexContainerHorizontal, { alignVertical: "middle", fullHeight: true, spacing: 0, children: [_jsx(BreakpointDeviceContainer, { allExcept: true, mobile: true, children: notificationIcons.map(({ icon, label, counter, disabled, onClick, }, index) => (_jsx(FlexItemMin, { children: _jsx(IconButton, { sx: { color: theme.palette.primary.contrastText }, icon: _jsx(Badge, { badgeContent: counter, color: "secondary", children: icon }), ariaLabel: label, size: EIconButtonSize.LARGE, disabled: disabled, onClick: () => handleNotificationIconClick(disabled, onClick) }) }, index))) }), _jsx(Condition, { if: !!profileIcon, children: _jsx(FlexItemMin, { children: _jsxs(FlexContainerHorizontal, { alignVertical: "middle", children: [_jsxs(FlexItemMin, { show: !!profileUserName, children: [_jsx(BreakpointDeviceContainer, { tablet: true, children: _jsx(Box, { sx: { textAlign: 'right' }, children: profileUserName }) }), _jsx(BreakpointDeviceContainer, { allExcept: true, mobile: true, tablet: true, children: _jsx(Box, { sx: { whiteSpace: 'pre' }, children: profileUserName }) })] }), _jsx(FlexItemMin, { children: _jsx(IconButton, { sx: {
                                                color: theme.palette.primary.contrastText,
                                                '& img': {
                                                    height: 40,
                                                    borderRadius: 20,
                                                },
                                            }, icon: profileIcon, ariaLabel: "User profile", size: EIconButtonSize.LARGE, ariaControls: menuId, ariaHasPopUp: true, onClick: handleProfileMenuOpen }) })] }) }) }), _jsx(BreakpointDeviceContainer, { mobile: true, children: _jsx(IconButton, { sx: { color: theme.palette.primary.contrastText }, icon: _jsx(MoreIcon, {}), ariaLabel: `Show notifications${profileIcon ? '& profile info' : ''}`, ariaControls: mobileMenuId, ariaHasPopUp: true, size: EIconButtonSize.LARGE, onClick: handleMobileMenuOpen }) })] }), _jsx(Menu, { id: mobileMenuId, anchorEl: mobileMoreAnchorEl, keepMounted: true, open: isMobileMenuOpen, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, onClose: handleMobileMenuClose, children: notificationIcons.map(({ icon, label, counter, disabled, onClick, }, index) => (_jsxs(MenuItem, { onClick: () => handleNotificationIconClick(disabled, onClick), children: [_jsx(IconButton, { icon: _jsx(Badge, { badgeContent: counter, color: "secondary", children: icon }), ariaLabel: label, size: EIconButtonSize.LARGE, disabled: disabled }), _jsx("p", { children: label })] }, index))) }), _jsx(Menu, { id: menuId, anchorEl: anchorEl, anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, keepMounted: true, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, open: isMenuOpen, onClose: handleMenuClose, children: profileOptions
                    .filter(option => !option.hidden)
                    .map(({ disabled, icon, label, onClick, }, index) => (_jsxs(MenuItem, { disabled: disabled, onClick: () => handleProfileOptionClick(onClick), children: [_jsx(IconButton, { ariaLabel: label, icon: icon }), label] }, index))) })] }));
};
