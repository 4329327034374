import { isNode } from "../node/isNode";
/**
 * Retrieves the current user's locale in ISO 639-1 and ISO 3166-1 format (e.g., "en-US").
 * @param {string} [overrideLocale] An optional locale in ISO 639-1 and/or ISO 3166-1
 */
export const getUserLocale = (overrideLocale) => {
    if (overrideLocale)
        return overrideLocale;
    if (isNode)
        return "en-US";
    const locale = (navigator.language
        || navigator.userLanguage);
    if (locale.includes('-'))
        return locale;
    console.error("Couldn't resolve the locale in ISO 639-1 and ISO 3166-1, the [en-US] applied as fallback");
    return "en-US";
};
