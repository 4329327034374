import {useRef} from "react";
import {Provider} from "react-dynadux";

import {createAppStore} from "../../../state/store";
import {INavigateTo} from "../interfaces/INavigateTo";

import {AppWithStyle} from "./AppWithStyle";

export interface IAppStoreProps {
  onNavigateTo: (navigateTo: INavigateTo) => void;
}

export const AppStore = (props: IAppStoreProps) => {
  const refStore = useRef(createAppStore({navigateTo: props.onNavigateTo}));

  return (
    <Provider store={refStore.current}>
      <AppWithStyle/>
    </Provider>
  );
};
