import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { useState } from "react";
export const WindowManagerContext = React.createContext({
    activeWindowId: null,
    setActiveWindowId: () => undefined,
});
export const WindowManager = ({ children }) => {
    const [activeWindowId, setActiveWindowId] = useState(null);
    return (_jsx(WindowManagerContext.Provider, { value: {
            activeWindowId,
            setActiveWindowId,
        }, children: children }));
};
