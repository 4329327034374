import { objectKeys } from "./objectKeys";
/**
 * Converts an object to another object with different values
 */
export const convertObject = (obj, cb) => objectKeys(obj)
    .reduce(((acc, key, index) => {
    acc[key] = cb({
        key,
        value: obj[key],
        index,
    });
    return acc;
}), {});
