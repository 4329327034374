import { validateDataMethods } from "utils-library/dist/validation-engine";
import { convertEnumKeyToHumanReadable } from "utils-library/dist/utils";
import { EWZTXMode, EWZClosureSelectionMode, getRemovedSimpleClosureTypeItisCodes, } from "../interfaces/IWorkzonesAppSettings";
// This is the complete validation for the CIMs App Settings
export const validateWorkzonesAppSettings = {
    id: () => "", // Ignore it, the actual key is the `deviceId`
    deviceId: (deviceId) => {
        if (deviceId !== '*')
            return "Only device id '*' is supported";
        return "";
    },
    psid: (psid) => (validateDataMethods.hasValue(psid) ||
        (psid.includes(' ') ? "Should not have spaces" : "") ||
        validateDataMethods.isTextIntegerInRange(psid, 0, 65535) ||
        ""),
    serviceChannel: (serviceChannel) => (validateDataMethods.hasValue(serviceChannel) ||
        validateDataMethods.isString(serviceChannel) ||
        validateDataMethods.isOneOfValues(serviceChannel, '172', '180', '183', 'SCH') ||
        ""),
    txmode: (txmode) => validateDataMethods.isEnumValue(txmode, EWZTXMode),
    intervalInMs: (intervalInMs) => validateDataMethods.isIntegerInRange(intervalInMs, 0, 5000),
    priority: (priority) => (validateDataMethods.hasValue(priority) ||
        validateDataMethods.isNumber(priority) ||
        validateDataMethods.isIntegerInRange(priority, 0, 63) ||
        ""),
    closureSelectionMode: value => validateDataMethods.isEnumValue(value, EWZClosureSelectionMode),
    useSignature: value => (validateDataMethods.hasValue(value) ||
        validateDataMethods.isBoolean(value) ||
        ""),
    useEncryption: value => (validateDataMethods.hasValue(value) ||
        validateDataMethods.isBoolean(value) ||
        ""),
    itisCodes: (itisCodes) => {
        const validationErrorHasValue = validateDataMethods.hasValue(itisCodes);
        if (validationErrorHasValue)
            return validationErrorHasValue;
        const validationErrorIsArray = validateDataMethods.isArray(itisCodes);
        if (validationErrorIsArray)
            return validationErrorIsArray;
        const errors = [];
        itisCodes.forEach(itisCode => {
            const reportItisCode = JSON.stringify(itisCode, null, 2).replace(/\n/g, '');
            const validationErrorHasValue = validateDataMethods.hasValue(itisCode.code);
            if (validationErrorHasValue)
                errors.push(`Item: ${reportItisCode} code validation error: ${validationErrorHasValue}`);
            const validationErrorIsNumber = validateDataMethods.isNumber(itisCode.code);
            if (validationErrorIsNumber)
                errors.push(`Item: ${reportItisCode} code validation error: ${validationErrorIsNumber}`);
            const validationErrorIsInteger = validateDataMethods.isInteger(itisCode.code);
            if (validationErrorIsInteger)
                errors.push(`Item: ${reportItisCode} code validation error: ${validationErrorIsInteger}`);
            const validationErrorLabelHasValue = validateDataMethods.hasValue(itisCode.label);
            if (validationErrorLabelHasValue)
                errors.push(`Item: ${reportItisCode} label validation error: ${validationErrorLabelHasValue}`);
            const validationErrorValidText = validateDataMethods.isValidTextAdvanced({
                text: itisCode.label,
                minLength: 1,
                maxLength: 30,
            });
            if (validationErrorValidText)
                errors.push(validationErrorValidText);
        });
        const closureTypesRemovedItisCodes = getRemovedSimpleClosureTypeItisCodes(itisCodes);
        if (closureTypesRemovedItisCodes.length > 0) {
            closureTypesRemovedItisCodes.forEach((closureTypesRemovedItisCode) => {
                if (closureTypesRemovedItisCode.removedItisCodes.length < 2) {
                    errors.push(`ITIS code ${closureTypesRemovedItisCode.removedItisCodes.join(', ')} is required for the simple closure type "${convertEnumKeyToHumanReadable(closureTypesRemovedItisCode.closureType)}".`);
                }
                else {
                    errors.push(`ITIS codes ${closureTypesRemovedItisCode.removedItisCodes.join(', ')} are required for the simple closure type "${convertEnumKeyToHumanReadable(closureTypesRemovedItisCode.closureType)}".`);
                }
            });
        }
        if (errors.length === 0) {
            const validationErrorDuplicatedItis = validateDataMethods.isArrayWithUniqueValues(itisCodes.map(ic => ic.code));
            if (validationErrorDuplicatedItis)
                errors.push("ITIS codes: " + validationErrorDuplicatedItis);
            const validationErrorDuplicatedLabels = validateDataMethods.isArrayWithUniqueValues(itisCodes.map(ic => ic.label));
            if (validationErrorDuplicatedLabels)
                errors.push(`Labels: ${validationErrorDuplicatedLabels}`);
        }
        return errors.join('\n');
    },
    // Ignore them since are overwritten by the db methods anyway
    createdAt: () => "",
    createdBy: () => "",
    updatedAt: () => "",
    updatedBy: () => "",
    deletedAt: () => "",
    deletedBy: () => "",
    archivedAt: () => "",
    archivedBy: () => "",
};
