import {routeAppVersion} from "./routeAppVersion";
import {IAppRoute} from "../config/IAppRoute";
import {routeHomePage} from "./routeHomePage";
import {routeOptionsPage} from "./routeOptionsPage";
import {routeAdministratorPage} from "./routeAdministratorPage";
import {routeBroadcasterClientConnectionTestPage} from "./routeBroadcasterClientConnectionTestPage";

export const routesAppCore: IAppRoute[] = [
  routeAppVersion,
  routeHomePage,
  routeOptionsPage,
  routeAdministratorPage,
  routeBroadcasterClientConnectionTestPage,
];
