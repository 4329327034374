export var EAlertHandlingUserFlag;
(function (EAlertHandlingUserFlag) {
    EAlertHandlingUserFlag["TOMATO"] = "TOMATO";
    EAlertHandlingUserFlag["FLAMINGO"] = "FLAMINGO";
    EAlertHandlingUserFlag["ORANGE"] = "ORANGE";
    EAlertHandlingUserFlag["BANANA"] = "BANANA";
    EAlertHandlingUserFlag["SAGE"] = "SAGE";
    EAlertHandlingUserFlag["BASIL"] = "BASIL";
    EAlertHandlingUserFlag["CYAN"] = "CYAN";
    EAlertHandlingUserFlag["BLUEBERRY"] = "BLUEBERRY";
    EAlertHandlingUserFlag["GRAPE"] = "GRAPE";
    EAlertHandlingUserFlag["GRAPHITE"] = "GRAPHITE";
})(EAlertHandlingUserFlag || (EAlertHandlingUserFlag = {}));
