import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
export var EAlertSeverity;
(function (EAlertSeverity) {
    EAlertSeverity["INFO"] = "INFO";
    EAlertSeverity["WARNING"] = "WARNING";
    EAlertSeverity["DANGER"] = "DANGER";
})(EAlertSeverity || (EAlertSeverity = {}));
export const getDefaultAlertConfig = ({ id, severity, postponable = true, }) => {
    const alertConfig = getDefaultDBEntityDocument({
        severity,
        postponable,
        resolveOptions: [],
        alertTypeLabel: id.toLocaleLowerCase().split('_')
            .filter(Boolean)
            .join(' '),
        notes: '',
    });
    alertConfig.id = id;
    return alertConfig;
};
