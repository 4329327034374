/**
 * It waits but torturing the thread.
 *
 * Consider to use the promised wait() instead
 *
 * @deprecated Use it only for debugging!
 */
export const waitBlockingThread = (ms) => {
    const start = Date.now();
    while (Date.now() - start < ms) {
        // Busy wait
    }
};
