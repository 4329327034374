const lsKey = "debugMode";

export const debugMode = {
  get active(): boolean {
    return localStorage.getItem(lsKey) === "true";
  },
  get on(): boolean {
    localStorage.setItem(lsKey, "true");
    console.info('Debug is turned on');
    return this.active;
  },
  get off(): boolean {
    localStorage.setItem(lsKey, "false");
    console.info('Debug is turned off');
    return this.active;
  },
  get help(): void {
    console.info(`
Debug mode activates additional user interface controls specifically designed for debugging or testing purposes. These controls are safe to remain present even in production environments.

Available commands:
 - debugMode.active: Check if debug mode is active
 - debugMode.on: Turn on debug mode
 - debugMode.off: Turn off debug mode
 - debugMode.help: Display this help message
      `);
    return;
  },
};

(window as any).debugMode = debugMode;
