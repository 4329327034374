import {IAppRoute} from "../../application/config/IAppRoute";
import {routeUsersInvitationsList} from "./routeUsersInvitationsList";
import {routeUserInvitationEntryPage} from "./routeUserInvitationEntryPage";
import {routeUserInvitationManage} from "./routeUserInvitationManage";

export const routesUserInvitations: IAppRoute<any>[] = [
  routeUsersInvitationsList,
  routeUserInvitationManage,
  routeUserInvitationEntryPage,
];
