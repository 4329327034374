export const asyncCalls = (...functions) => {
    const fs = functions.concat().filter(Boolean);
    const next = () => {
        const f = fs.shift();
        if (!f)
            return;
        if (f.length) {
            f(next);
        }
        else {
            f();
            next();
        }
    };
    next();
};
