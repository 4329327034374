import { jsx as _jsx } from "react/jsx-runtime";
import { Marker } from "react-leaflet";
import { headingCircle, } from "./headingCircle";
export const HeadingSelector = (props) => {
    const { position, circleRadius = 30, headings = [], readOnly = true, onSliceClick, onSelectDeselectClick, } = props;
    return (
    // @ts-ignore --> This is because the dep returns ReactElement and not JSX.Element
    _jsx(Marker, { position: position, icon: headingCircle({
            radius: circleRadius,
            slices: headings,
            readOnly,
            onSliceClick,
            onSelectDeselectClick,
        }) }));
};
