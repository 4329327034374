import { convertDictionaryToViewLabelArray } from "utils-library/dist/utils";
export var ELanePanningRights;
(function (ELanePanningRights) {
    ELanePanningRights["LANE_PLANNING_VIEW"] = "LP_V";
    ELanePanningRights["LANE_PLANNING_CONFIG"] = "LP_E";
})(ELanePanningRights || (ELanePanningRights = {}));
const labelsDic = {
    [ELanePanningRights.LANE_PLANNING_VIEW]: 'Lane Planning / View',
    [ELanePanningRights.LANE_PLANNING_CONFIG]: 'Lane Planning / Configure',
};
export const ELanePanningRightsArray = convertDictionaryToViewLabelArray(labelsDic);
