import moment from 'moment-timezone';
import { getUTCOffset } from "./getUTCOffset";
import { getSystemTimezone } from "./getSystemTimezone";
/**
 * Retrieves a list of timezone options based on the provided date and system timezone.
 * @param {Date | number | string} forDate - The date for which timezone options are to be retrieved. Defaults to the current date and time if not provided.
 * @param {string} userTimezone - The system timezone used as reference. Defaults to the system timezone obtained from the environment if not provided.
 * @returns {ITimezoneOption[]} An array of timezone options containing details such as continent, country, city, UTC offset, and local hours difference.
 */
export const getTimezoneOptions = (forDate = Date.now(), userTimezone = getSystemTimezone()) => {
    const timezoneOptions = [];
    const systemUTCOffset = getUTCOffset(new Date(), userTimezone);
    moment.tz.names().forEach(timezone => {
        const utcOffsetHours = getUTCOffset(forDate, timezone);
        const userOffsetHours = utcOffsetHours - systemUTCOffset;
        timezoneOptions.push({
            value: timezone,
            label: timezone
                .split('/')
                .map(v => v.replace(regExpUnderscores, ' '))
                .join(' / '),
            utcOffset: {
                hours: utcOffsetHours,
                label: `UTC${formatValueSign(utcOffsetHours)}`,
            },
            userOffset: {
                hours: userOffsetHours,
                label: `${formatValueSign(userOffsetHours)}h`,
            },
        });
    });
    return timezoneOptions;
};
const regExpUnderscores = /_/g;
const formatValueSign = (value) => value >= 0 ? `+${value}` : value.toString();
