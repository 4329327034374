var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { validateDataMethods } from "utils-library/dist/validation-engine";
import { EDeviceType } from "../interfaces/EDeviceType";
import { EAlertType } from "../../alerts-management/interfaces/EAlertType";
import { apiAlertMgtmAlertHandlingPost } from "../../alerts-management/client/for-services";
export const DemoSnowSensor = {
    deviceType: EDeviceType.DEMO_SNOW_SENSOR,
    label: '(demo) Snow sensor',
    validateConfig: {
        scanDistanceInMeters: (value) => {
            if (!validateDataMethods.isNumber(value))
                return "Should be a number";
            if (value < 5)
                return "Should be number bigger or equal to 5";
            return "";
        },
    },
    validateStatus: {
        voltage: validateDataMethods.isNumber,
        snow: validateDataMethods.isNumber,
    },
    monitorIncomingDeviceStatus: (_a) => __awaiter(void 0, [_a], void 0, function* ({ deviceStatus }) {
        if (!deviceStatus.status)
            return; // 4TS, is not possible to be null
        const snow = deviceStatus.status.snow;
        // Todo: Alert creation is disabled on purpose.
        // Activate it only for dev purposes and never deploy this anywhere (staging or production)
        if ((() => true)())
            return;
        yield apiAlertMgtmAlertHandlingPost({
            deviceStatus,
            alertType: EAlertType.DEMO_HEAVY_SNOWFALL,
            title: `High snowfall ${snow}%`,
            description: 'Snowfall above 80%',
            alert: { snow },
            alertHandle: {
                authoritiesInformed: false,
                closedLanes: -1,
            },
        });
    }),
};
export const getDefaultDemoSnowSensorConfig = () => ({ scanDistanceInMeters: 5 });
