import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, useEffect, useState, } from "react";
import { ErrorBoundary } from "../ErrorBoundary";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { Box } from "../Box";
import { Condition } from "../Condition";
import { ErrorBanner } from "../ErrorBanner";
import { Button, EButtonSize, } from "../Button";
import { ProgressIcon } from "../ProgressIcon";
import ErrorIcon from '@mui/icons-material/Error';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTheme } from "../ThemeProvider";
export const LazyLoadComponent = (props) => {
    const { children, showLoadingProgress = true, showLoadingProgressDelay = 1000, loadingIconSize = 2, loadingLabel, errorLabel = "Something went wrong. Try refreshing.", onError, } = props;
    const theme = useTheme();
    const [showIsLoading, setShowIsLoading] = useState(false);
    useEffect(() => {
        // Show the loading indicator after some ms
        const timeHandler = setTimeout(() => setShowIsLoading(true), showLoadingProgressDelay);
        return () => clearTimeout(timeHandler);
    }, []);
    const handleRefreshClick = () => {
        window.location.reload();
    };
    const handleErrorRender = (error) => {
        return (_jsxs(FlexContainerHorizontal, { alignVertical: "middle", spacing: 3, children: [_jsx(FlexItemMin, { children: _jsx(ErrorIcon, {}) }), _jsxs(FlexItemMax, { children: [_jsx(Box, { children: errorLabel }), _jsx(Box, { show: !!localStorage.getItem('debugMode'), sx: { pt: 1 }, children: _jsx(ErrorBanner, { title: "Lazy load error (debug mode)", error: error }) })] }), _jsx(FlexItemMin, { children: _jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(RefreshIcon, {}), onClick: handleRefreshClick, children: "Refresh" }) })] }));
    };
    const handleILoadingRender = () => {
        return (_jsxs(Condition, { if: showLoadingProgress && showIsLoading, children: [_jsx(ProgressIcon, { size: loadingIconSize }), _jsx(Box, { show: !!loadingLabel, sx: {
                        marginTop: theme.spacing(3),
                        fontSize: theme.typography.fontSize,
                        padding: '4px 8px',
                        borderRadius: 13,
                        color: theme.palette.background.default,
                        backgroundColor: theme.palette.action.active,
                    }, children: loadingLabel })] }));
    };
    return (_jsx(ErrorBoundary, { onErrorRender: handleErrorRender, onError: onError, children: _jsx(Suspense, { fallback: handleILoadingRender(), children: children }) }));
};
