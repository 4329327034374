var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from "react";
import { dynaError } from 'dyna-error';
import { usePerformanceState } from "../usePerformanceState";
import { useIsMounted } from "../useIsMounted";
export const useClipboardPaste = () => {
    const getIsMounted = useIsMounted();
    const [clipboardData, _setClipboardData] = usePerformanceState({
        hasClipboard: false,
        clipboardText: '',
    });
    const setClipboardData = (date) => {
        if (!getIsMounted())
            return;
        _setClipboardData(date);
    };
    // Initialize the state
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            try {
                yield new Promise(r => setTimeout(r, 500));
                const pasteText = yield window.navigator.clipboard.readText();
                setClipboardData({
                    hasClipboard: !!pasteText,
                    clipboardText: pasteText,
                });
            }
            catch (e) {
                const error = dynaError(e);
                if ((_a = error.message) === null || _a === void 0 ? void 0 : _a.includes('Document is not focused'))
                    return; // Ignore it, will be updated by the handleFocusIn.
                console.error('useClipboardPaste.init: Cannot read the clipboard', error);
            }
        }))();
    }, []);
    // Listen the global events
    useEffect(() => {
        const handleFocusIn = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const clipboardText = yield window.navigator.clipboard.readText();
                setClipboardData({
                    hasClipboard: clipboardText !== '',
                    clipboardText: clipboardText,
                });
            }
            catch (error) {
                console.error("useClipboardPaste.handleFocusIn(global): Error reading the clipboard:", error);
            }
        });
        const handleCopy = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const clipboardText = yield window.navigator.clipboard.readText();
                setClipboardData({
                    hasClipboard: clipboardText !== '',
                    clipboardText: clipboardText,
                });
            }
            catch (error) {
                console.error("useClipboardPaste.handleCopy: Error copying to clipboard:", error);
            }
        });
        const handlePaste = (event) => {
            const clipboardText = event.clipboardData.getData('text');
            setClipboardData({
                hasClipboard: !!clipboardText,
                clipboardText: clipboardText,
            });
        };
        window.addEventListener('focus', handleFocusIn);
        document.addEventListener('copy', handleCopy);
        document.addEventListener('paste', handlePaste);
        return () => {
            window.addEventListener('focus', handleFocusIn);
            document.removeEventListener('copy', handleCopy);
            document.removeEventListener('paste', handlePaste);
        };
    }, []);
    return clipboardData;
};
