/**
 * Converts an array of objects into a dictionary (Record) based on a specified key field.
 * This function is designed for high performance, using a loop to minimize overhead.
 *
 * @example
 * // Example usage:
 * const users = [
 *     { id: 'a1', name: 'Alice' },
 *     { id: 'b2', name: 'Bob' },
 *     { id: 'c3', name: 'Charlie' }
 * ];
 *
 * const userDic = arrayToDic(users, 'id');
 * // Result:
 * // {
 * //   'a1': { id: 'a1', name: 'Alice' },
 * //   'b2': { id: 'b2', name: 'Bob' },
 * //   'c3': { id: 'c3', name: 'Charlie' }
 * // }
 */
export const arrayToDic = (array, keyFieldName) => {
    const dic = {};
    for (const item of array)
        dic[item[keyFieldName]] = item;
    return dic;
};
