var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { WebSocket } from "ws";
import { DynaJobQueue } from "dyna-job-queue";
import { dynaError, } from "dyna-error";
export var EConnectionStatus;
(function (EConnectionStatus) {
    EConnectionStatus["ONLINE"] = "ONLINE";
    EConnectionStatus["CONNECTING"] = "CONNECTING";
    EConnectionStatus["RECONNECTING"] = "RECONNECTING";
    EConnectionStatus["DISCONNECTING"] = "DISCONNECTING";
    EConnectionStatus["OFFLINE"] = "OFFLINE";
})(EConnectionStatus || (EConnectionStatus = {}));
export class WSClient {
    constructor(config) {
        this.config = config;
        this.webSocket = null;
        this.queue = new DynaJobQueue({ parallels: 1 });
        this._status = EConnectionStatus.OFFLINE;
        this.handleIncomingMessage = (rawMessage) => __awaiter(this, void 0, void 0, function* () {
            const { onMessage } = this.config;
            if (!onMessage)
                return;
            const rawTextMessage = (() => {
                if (typeof rawMessage === "string")
                    return rawMessage;
                if (Buffer.isBuffer(rawMessage))
                    return rawMessage.toString();
                return "{}";
            })();
            let message = null;
            try {
                message = JSON.parse(rawTextMessage);
            }
            catch (e) {
                console.error('WSClient: error parsing incoming message', {
                    wsMessage: rawMessage,
                    error: e,
                });
            }
            if (!message)
                return; // 4TS
            return onMessage(message);
        });
        this.handleError = (error) => {
            const { onGeneralError } = this.config;
            onGeneralError && onGeneralError(error);
        };
        this.setStatus(EConnectionStatus.OFFLINE);
        this.handleIncomingMessage = this.queue.jobFactory(this.handleIncomingMessage); // That's all
    }
    connect(address) {
        this.setStatus(EConnectionStatus.CONNECTING);
        return new Promise((resolve) => {
            this.webSocket = new WebSocket(address);
            this.webSocket.on('message', this.handleIncomingMessage);
            this.webSocket.on('error', this.handleError);
            this.webSocket.on("open", () => {
                resolve();
                this.setStatus(EConnectionStatus.ONLINE);
            });
        });
    }
    disconnect() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.webSocket)
                return;
            this.webSocket.off('message', this.handleIncomingMessage);
            this.webSocket.off('error', this.handleError);
            this.webSocket.close();
            this.webSocket = null;
        });
    }
    get status() {
        return this._status;
    }
    get online() {
        return this._status === EConnectionStatus.ONLINE;
    }
    send(data) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this._status !== EConnectionStatus.ONLINE) {
                throw dynaError({
                    code: 202303011214,
                    message: 'WSClient is not in ONLINE status',
                });
            }
            let serialized = "";
            try {
                serialized = JSON.stringify(data);
            }
            catch (error) {
                throw dynaError({
                    code: 202303011215,
                    message: `WSClient.send(): Error stringifying the object: ${error.message || 'unknown'}`,
                    parentError: error,
                });
            }
            return new Promise((resolve, reject) => {
                if (!this.webSocket) {
                    reject(dynaError({
                        code: 202303011219,
                        message: 'WSClient is not ready (and not online)',
                    }));
                    return;
                }
                this.webSocket.send(serialized, (error) => {
                    if (!error) {
                        resolve();
                        return;
                    }
                    reject(dynaError({
                        code: 202303011216,
                        message: `WSClient.send(): Cennot send: ${error.message || 'unknown'}`,
                        parentError: error,
                    }));
                });
            });
        });
    }
    setStatus(status) {
        this._status = status;
        this.config.onStatusChange && this.config.onStatusChange(this._status);
    }
}
