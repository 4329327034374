import { jsx as _jsx } from "react/jsx-runtime";
import { getDataComponentName, } from "../ui-interfaces";
import { Box } from "../Box";
import { sxTransition, ECSSDuration, } from "../sxTransition";
export const FlexItemMin = (props) => {
    const { innerRef, fullHeight, sx = {}, dataComponentName, show = true, hidden = false, noSpacing = false, title, children, onClick, onMouseMove, onMouseDown, onMouseUp, onMouseEnter, onMouseLeave, onScroll, onWheel, } = props;
    return (_jsx(Box, { ref: innerRef, sx: Object.assign({ flex: '0 0', minHeight: fullHeight ? '100%' : undefined, position: 'relative', margin: noSpacing
                ? 0
                : undefined, transition: theme => sxTransition(theme, "margin", ECSSDuration.SHORT) }, sx), dataComponentName: getDataComponentName(dataComponentName, "FlexItemMin"), show: show, hidden: hidden, title: title, onClick: onClick, onMouseMove: onMouseMove, onMouseDown: onMouseDown, onMouseUp: onMouseUp, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onScroll: onScroll, onWheel: onWheel, children: children }));
};
