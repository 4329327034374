import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, } from "react";
import { Icon as Iconify } from "@iconify/react";
import { Box } from "../Box";
import { HtmlContent } from "../HtmlContent";
export const IconViewer = (props) => {
    const { show = true, sx = {}, sxSvg: userSxSvg = {}, Icon, MuiIcon, emoji, svgElement, svgScript, IconifyIcon, image, width: userWidth, height: userHeight, title, } = props;
    const width = userWidth || userHeight;
    const height = userHeight || userWidth;
    useEffect(() => {
        const imagesCount = [
            MuiIcon,
            Icon,
            emoji,
            svgElement,
            svgScript,
            IconifyIcon,
            image,
        ].filter(Boolean).length;
        if (imagesCount > 1) {
            console.error('<Icon> 20230525110318 error: More than one image provided', {
                Icon,
                MuiIcon,
                emoji,
                svgElement,
                svgScript,
                IconifyIcon: IconifyIcon,
                image,
            });
        }
    }, []);
    if (!show)
        return null;
    const sxBase = width || height
        ? {
            width,
            height,
        }
        : { fontSize: 'inherit' };
    const sxSvg = Object.assign(Object.assign({}, userSxSvg), sxBase);
    const wrapper = (content) => (_jsx(Box, { dataComponentName: "IconViewer", inline: "flex", sx: Object.assign(Object.assign({}, sx), { 'svg': sxSvg }), title: title, children: content }));
    if (svgElement)
        return wrapper(svgElement);
    if (svgScript) {
        return wrapper(_jsx(HtmlContent, { component: "span", html: svgScript }));
    }
    if (image) {
        return wrapper(_jsx("img", { src: image, alt: title, style: {
                width: width ? width + 'px' : undefined,
                height: height ? height + 'px' : undefined,
            } }));
    }
    if (Icon) {
        return wrapper(_jsx(Icon, { width: width, height: height }));
    }
    if (MuiIcon)
        return wrapper(_jsx(MuiIcon, {}));
    if (IconifyIcon) {
        // @ts-ignore --> This is because the dep returns React.JSX.Element and not JSX.Element
        return wrapper(_jsx(Iconify, { icon: IconifyIcon }));
    }
    if (emoji) {
        return (_jsx("span", { style: { fontSize: `${width}px` }, children: emoji }));
    }
    return null;
};
