import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, } from "react";
import { EOpenMode } from "./interfaces";
import Box from "@mui/material/Box/Box";
import { FlexContainerVertical, FlexContainerHorizontal, FlexItemMax, FlexItemMin, } from "../FlexContainer";
import { BreakpointDeviceContainer } from "../BreakpointDeviceContainer";
import { useBreakpointDevice } from "../useBreakpointDevice";
import { AppHeader } from "./components/AppHeader/AppHeader";
import { AsideMenu } from "./components/AsideMenu/AsideMenu";
import { useTheme } from "../ThemeProvider";
const LS_KEY = 'AppContainerDashboard-open-status';
export const AppContainerDashboard = (props) => {
    const { logoSquareImage, logoLandscapeImage, appTitle, appTitleHelper, appTitleContent, notificationIcons, profileUserName, profileIcon, profileOptions, selectedMenuId, showMinimizedOption, menuItemsTop, menuItems, menuItemsBottom, menuFullScreenOnMobile = true, menuFullScreenOnTablet = false, bottomAppInfo, children, onLogoClick, onExpand, onMinimize, onCollapse, } = props;
    const theme = useTheme();
    const { isMobile, isTablet, } = useBreakpointDevice();
    const isSmallDevixw = isMobile || isTablet;
    const [openMode, _setOpenMode] = useState(() => {
        if (isSmallDevixw)
            return EOpenMode.COLLAPSED;
        return (localStorage.getItem(LS_KEY)
            || EOpenMode.COLLAPSED);
    });
    useEffect(() => {
        if (!isSmallDevixw)
            return;
        if (openMode === EOpenMode.EXPANDED)
            setOpenMode(EOpenMode.COLLAPSED);
        if (openMode === EOpenMode.MINIMIZED)
            _setOpenMode(EOpenMode.COLLAPSED);
    }, [isSmallDevixw]);
    const setOpenMode = (openMode) => {
        _setOpenMode(openMode);
        if (isSmallDevixw)
            return;
        localStorage.setItem(LS_KEY, openMode);
    };
    const handleAsideMenuOpenModeChange = (openMode) => {
        setOpenMode(openMode);
    };
    const handleDrawerOpen = () => {
        setOpenMode(EOpenMode.EXPANDED);
        onExpand && onExpand();
    };
    const handleDrawerMinimize = () => {
        setOpenMode(EOpenMode.MINIMIZED);
        onMinimize && onMinimize();
    };
    const handleDrawerClose = () => {
        setOpenMode(EOpenMode.COLLAPSED);
        onCollapse && onCollapse();
    };
    const handleToggleClick = () => {
        switch (openMode) {
            case EOpenMode.EXPANDED:
                isSmallDevixw
                    ? handleDrawerClose()
                    : handleDrawerMinimize();
                break;
            case EOpenMode.MINIMIZED:
                handleDrawerOpen();
        }
    };
    const handleMobileClose = () => {
        if (!isSmallDevixw)
            return;
        handleDrawerClose();
    };
    const asideMenu = (_jsx(AsideMenu, { logoSquareImage: logoSquareImage, logoLandscapeImage: logoLandscapeImage, selectedMenuId: selectedMenuId, openMode: openMode, menuItemsTop: menuItemsTop, menuItems: menuItems, menuItemsBottom: menuItemsBottom, menuFullScreenOnMobile: menuFullScreenOnMobile, menuFullScreenOnTablet: menuFullScreenOnTablet, bottomAppInfo: bottomAppInfo, showMinimizedOption: showMinimizedOption, onLogoClick: onLogoClick, onDrawerClose: handleDrawerClose, onAsideMenuOpenChange: handleAsideMenuOpenModeChange, onToggleClick: handleToggleClick, onClick: handleMobileClose }));
    return (_jsxs(FlexContainerHorizontal, { dataComponentName: "AppContainerDashboard", fullHeight: true, sx: { position: 'relative' }, children: [_jsx(BreakpointDeviceContainer, { mobile: menuFullScreenOnMobile, tablet: menuFullScreenOnTablet, children: _jsx(Box, { sx: {
                        position: 'absolute',
                        zIndex: 3000,
                        top: 0,
                        left: 0,
                        bottom: 0,
                        background: theme.palette.background.default,
                    }, children: asideMenu }) }), _jsx(BreakpointDeviceContainer, { allExcept: true, mobile: menuFullScreenOnMobile, tablet: menuFullScreenOnTablet, children: _jsx(FlexItemMin, { children: asideMenu }) }), _jsx(FlexItemMax, { children: _jsxs(FlexContainerVertical, { fullHeight: true, children: [_jsx(FlexItemMin, { children: _jsx(AppHeader, { appTitle: appTitle, appTitleHelper: appTitleHelper, appTitleContent: appTitleContent, openMode: openMode, notificationIcons: notificationIcons, profileUserName: profileUserName, profileIcon: profileIcon, profileOptions: profileOptions, onAsideMenuOpenChange: handleAsideMenuOpenModeChange }) }), _jsx(FlexItemMax, { overFlowY: true, children: children })] }) })] }));
};
