import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState, useEffect, } from "react";
import { camelToHuman } from "utils-library/dist/utils";
import { Alert, EAlertType, } from "../Alert";
import { Box } from "../Box";
import { ErrorLinkModalViewer, ELinkColor, } from "../ErrorLinkModalViewer";
import { useKeyStatus } from "../useKeyStatus";
import { useHover } from "../useHover";
export const ErrorBanner = (props) => {
    const { show: userShow = true, title: userTitle, error, validationErrors = {}, children, defaultErrorMessage = 'Error, something went wrong.', showAsWarning = false, showShowErrorLink = true, closeButton, } = props;
    const hasValidationErrors = !!Object.values(validationErrors).join('');
    const hasError = !!error || !!Object.values(validationErrors).join('');
    const refContainer = useRef(null);
    const [show, setShow] = useState(hasError);
    const { altKeyDown } = useKeyStatus();
    const hovering = useHover(refContainer);
    useEffect(() => {
        setShow(hasError);
    }, [hasError]);
    const { title, body, } = (() => {
        let errorMessage = error
            ? error.userMessage || error.message || defaultErrorMessage
            : '';
        const validationErrorMessage = (hasValidationErrors ? 'Validation errors\n' : '')
            + Object.entries(validationErrors)
                .map(([fieldName, validationError]) => {
                if (validationError === "")
                    return '';
                return `${camelToHuman(fieldName)}: ${validationError}`;
            })
                .filter(Boolean)
                .join('\n');
        if (validationErrorMessage) {
            if (errorMessage)
                errorMessage += '\n';
            errorMessage += validationErrorMessage;
        }
        if (errorMessage.includes('\n')) {
            const parts = errorMessage.split('\n');
            const title = parts[0];
            const body = parts.slice(1).join('\n');
            return {
                title,
                body,
            };
        }
        else {
            return {
                title: errorMessage,
                body: '',
            };
        }
    })();
    const errorCode = (error || {}).code || '';
    const handleCloseButtonClick = () => setShow(!show);
    return (_jsx("div", { ref: refContainer, children: _jsxs(Alert, { dataComponentName: "ErrorBanner", show: show && userShow, title: userTitle || title, type: showAsWarning ? EAlertType.WARNING : EAlertType.ERROR, closeButton: closeButton
                ? closeButton === true
                    ? { onClick: () => setShow(!show) }
                    : Object.assign(Object.assign({}, closeButton), { onClick: () => {
                            var _a;
                            handleCloseButtonClick();
                            (_a = closeButton.onClick) === null || _a === void 0 ? void 0 : _a.call(closeButton);
                        } })
                : undefined, children: [_jsx(Box, { show: !!userTitle, children: _jsx("b", { children: title }) }), _jsx(Box, { show: !!body, children: body }), _jsx(Box, { show: !!children, children: children }), _jsxs(Box, { children: [_jsxs(Box, { show: !!errorCode, component: "span", sx: {
                                marginTop: theme => theme.spacing(0.5),
                                fontSize: theme => theme.typography.fontSize * 0.7,
                            }, children: ["Error code: ", _jsx("code", { children: errorCode }), " "] }), _jsx(ErrorLinkModalViewer, { error: error, show: showShowErrorLink && altKeyDown && hovering, color: ELinkColor.WHITE })] })] }) }));
};
