import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
export const getDefaultPost = () => getDefaultDBEntityDocument(getDefaultPostDataOnly());
export const getDefaultPostDataOnly = () => ({
    parentId: '',
    type: '',
    content: {},
    edited: 0,
    profileId: '',
    reactions: {},
    votes: 0,
    claps: 0,
    childrenCounters: {},
    userTags: [],
    searchTags: [],
    accessViewIds: [],
    accessReplyIds: [],
    accessInteractIds: [],
});
export const getDefaultDTOPost = () => (Object.assign(Object.assign({}, getDefaultPost()), { level: 0, author: {
        id: '',
        displayName: '',
        profileImageUrl: '',
    }, children: {
        posts: [],
        hasMore: false,
        loadMorePayload: null,
    }, viewer: {
        profileId: '',
        userId: '',
    }, viewerInteractions: {
        reactions: [],
        vote: 0,
        claps: 0,
    }, viewerCan: {
        edit: false,
        reply: false,
        delete: false,
        interact: false,
    } }));
