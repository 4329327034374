import { EValueType, } from "./IFieldConfiguration";
import { formatField } from "./formatField";
import { getDeepValue } from "../utils";
export const fieldsEngine = ({ fieldConfigurations, data, customValueTypeConverters, }) => {
    const dic = fieldConfigurations
        .reduce((acc, fieldConfiguration) => {
        if (acc[fieldConfiguration.fieldName]) {
            console.error(`formatFieldEngine(): field name [${fieldConfiguration.fieldName}] has more than one configurations`, { fieldConfigurations });
        }
        else {
            acc[fieldConfiguration.fieldName] = fieldConfiguration;
        }
        return acc;
    }, {});
    const getFieldConfiguration = (fieldName) => {
        const fieldConfiguration = dic[fieldName];
        if (fieldConfiguration) {
            return fieldConfiguration;
        }
        else {
            console.error(`formatFieldEngine(): field name [${fieldName}] doesn't have configuration, the TEXT is applied as default`, { fieldConfigurations });
            return {
                fieldName,
                valueType: EValueType.TEXT,
                label: { default: fieldName },
            };
        }
    };
    return {
        field: (fieldName, { value, timezone, } = {}) => {
            const fieldConfiguration = getFieldConfiguration(fieldName);
            const dataFieldName = fieldConfiguration.sourceFieldName || fieldConfiguration.fieldName;
            return formatField({
                fieldConfiguration,
                value: value === undefined
                    ? getDeepValue(data, dataFieldName) || ""
                    : value,
                timezone,
                customValueTypeConverters,
            });
        },
        anyField: function (fieldName, options) {
            return this.field(fieldName, options);
        },
        label: (fieldName, defaultLabel) => {
            var _a;
            return (((_a = dic[fieldName]) === null || _a === void 0 ? void 0 : _a.label)
                || defaultLabel
                || {
                    default: fieldName,
                    tk: '',
                });
        },
        data,
    };
};
