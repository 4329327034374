var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { dynaSwitch } from "dyna-switch";
import { FlexContainerVertical } from "../../FlexContainer";
import { Box } from "../../Box";
import { ButtonBar, EButtonBarAlign, } from "../../ButtonBar";
import { Button, EButtonColor, EButtonSize, } from "../../Button";
import { useTheme, } from "../../ThemeProvider";
import { alpha } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import SuccessIcon from "@mui/icons-material/CheckCircle";
import FailedIcon from "@mui/icons-material/Error";
import RetryIcon from "@mui/icons-material/Replay";
export const DeleteOfflineMapsDialog = (props) => {
    const { sx = {}, dataComponentName, deleteMapsArgs: { savedMapsLabel, savedMapsCount, deleteMaps, }, onClose, } = props;
    const theme = useTheme();
    const [step, setStep] = useState(savedMapsCount
        ? 'CONFIRM_DELETE'
        : 'NOTHING_TO_DELETE');
    const [failed, setFailed] = useState(false);
    const handleDeleteClick = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setFailed(false);
            yield deleteMaps();
        }
        catch (e) {
            setFailed(true);
        }
        finally {
            setStep('COMPLETED');
        }
    });
    const content = dynaSwitch(step, _jsx("span", { children: "Internal error" }), // 4TS this is not possible
    {
        NOTHING_TO_DELETE: (_jsxs(_Fragment, { children: [_jsx("h3", { children: "There is nothing saved" }), _jsx("h5", { children: "Nothing to delete" }), _jsx(Button, { size: EButtonSize.LARGE, onClick: onClose, children: "Done" })] })),
        CONFIRM_DELETE: (_jsxs(_Fragment, { children: [_jsx(DeleteIcon, { sx: {
                        color: theme.palette.error.main,
                        width: 128,
                        height: 128,
                    } }), _jsxs("h3", { children: ["Delete ", savedMapsLabel, " ?"] }), _jsxs(ButtonBar, { align: EButtonBarAlign.CENTER, children: [_jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(DeleteIcon, {}), onClick: handleDeleteClick, children: "Delete" }), _jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(CancelIcon, {}), color: EButtonColor.SECONDARY, onClick: onClose, children: "Cancel" })] })] })),
        DELETING: (_jsxs(_Fragment, { children: [_jsx("h1", { children: "Deleting" }), _jsx("h5", { children: "Please wait..." })] })),
        COMPLETED: (_jsxs(_Fragment, { children: [failed
                    ? (_jsx(FailedIcon, { sx: {
                            color: theme.palette.error.main,
                            width: 128,
                            height: 128,
                        } }))
                    : (_jsx(SuccessIcon, { sx: {
                            color: theme.palette.success.main,
                            width: 128,
                            height: 128,
                        } })), failed
                    ? _jsx("h3", { children: "Complete failed" })
                    : _jsx("h3", { children: "Completed" }), _jsxs(ButtonBar, { align: EButtonBarAlign.CENTER, children: [_jsx(Button, { size: EButtonSize.LARGE, onClick: onClose, children: "Done" }), failed && (_jsx(Button, { size: EButtonSize.LARGE, icon: _jsx(RetryIcon, {}), color: EButtonColor.SECONDARY, onClick: handleDeleteClick, children: "Retry" }))] })] })),
    });
    return (_jsx(Box, { sx: Object.assign({ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, zIndex: 2500, color: theme.palette.text.primary, backgroundColor: alpha(theme.palette.background.default, 0.8) }, sx), dataComponentName: dataComponentName, children: _jsx(FlexContainerVertical, { fullHeight: true, alignHorizontal: "center", alignVertical: "middle", spacing: 3, sx: { textAlign: 'center' }, children: content }) }));
};
