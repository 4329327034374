import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from "react";
import { guid } from "dyna-guid";
import MuiSwitch from '@mui/material/Switch';
import { Box } from "../Box";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { HelperText, EHelperTextType, } from "../HelperText";
import { useTheme, } from "../ThemeProvider";
import { CenterContainer } from "../CenterContainer";
export var EInputSwitchColor;
(function (EInputSwitchColor) {
    EInputSwitchColor["DEFAULT"] = "default";
    EInputSwitchColor["PRIMARY"] = "primary";
    EInputSwitchColor["SECONDARY"] = "secondary";
    EInputSwitchColor["ERROR"] = "error";
    EInputSwitchColor["INFO"] = "info";
    EInputSwitchColor["SUCCESS"] = "success";
    EInputSwitchColor["WARNING"] = "warning";
})(EInputSwitchColor || (EInputSwitchColor = {}));
export var EInputSwitchPosition;
(function (EInputSwitchPosition) {
    EInputSwitchPosition["LEFT"] = "LEFT";
    EInputSwitchPosition["RIGHT"] = "RIGHT";
})(EInputSwitchPosition || (EInputSwitchPosition = {}));
export const InputSwitch = (props) => {
    const theme = useTheme();
    const id = useMemo(guid, []);
    const { sx = {}, show = true, dataComponentName, name, hidden, label = null, title, Icon, helperLabel, ariaLabel, switchPosition = EInputSwitchPosition, disabled = false, readOnly = false, nowrap = false, color = EInputSwitchColor.PRIMARY, value, validationError, onChange, } = props;
    const handleChange = (_event, checked) => {
        if (!onChange)
            return;
        if (disabled)
            return;
        if (readOnly)
            return;
        onChange(checked, name);
    };
    const onlyLabel = !helperLabel && !validationError;
    return (_jsx(Box, { hidden: hidden, show: show, title: ariaLabel, children: _jsxs(FlexContainerHorizontal, { sx: sx, dataComponentName: [dataComponentName, "InputSwitch"].filter(Boolean).join(' '), reverseOrder: switchPosition === EInputSwitchPosition.RIGHT, children: [_jsx(FlexItemMin, { children: _jsx(MuiSwitch, { sx: { pointerEvents: readOnly ? "none" : undefined }, id: id, name: name, "aria-label": ariaLabel, title: title, readOnly: readOnly, color: color, checked: value, disabled: disabled, onChange: handleChange }) }), !!Icon && (_jsx(FlexItemMin, { sx: {
                        position: 'relative',
                        top: theme => theme.spacing(1),
                        marginLeft: theme => switchPosition === EInputSwitchPosition.RIGHT ? theme.spacing(1) : undefined,
                    }, children: _jsx(Icon, { width: 24 }) })), _jsx(FlexItemMax, { sx: { marginLeft: theme.spacing(1) }, children: _jsxs(CenterContainer, { horizontally: "left", children: [_jsx(Box, { sx: {
                                    marginBottom: onlyLabel ? undefined : theme.spacing(1),
                                    whiteSpace: nowrap ? "nowrap" : undefined,
                                }, children: _jsx("label", { "aria-label": ariaLabel, children: label }) }), _jsx(HelperText, { children: helperLabel }), _jsx(HelperText, { type: EHelperTextType.ERROR, children: validationError })] }) })] }) }));
};
