import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "../Box";
import { ProgressIcon, } from "../ProgressIcon";
import { useTheme, } from "../ThemeProvider";
export const IsLoading = (props) => {
    const { sx = {}, dataComponentName, iconType, fullHeight = false, isLoading = true, width = 40, label, showCircularIcon = true, fadeBackground = true, children = null, } = props;
    const theme = useTheme();
    return (_jsxs(Box, { sx: Object.assign({ position: 'relative', width: '100%', height: sx.height
                ? sx.height
                : fullHeight ? '100%' : undefined }, sx), dataComponentName: [dataComponentName, "IsLoading"], children: [children, _jsxs(Box, { show: isLoading, sx: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: fadeBackground ? 'rgba(155, 155, 155, 0.5)' : undefined,
                }, children: [_jsx(Box, { show: showCircularIcon, children: _jsx(ProgressIcon, { iconType: iconType, size: width }) }), _jsx(Box, { show: !!label, sx: {
                            marginTop: theme.spacing(3),
                            fontSize: theme.typography.fontSize,
                            padding: '4px 8px',
                            borderRadius: 13,
                            color: theme.palette.background.default,
                            backgroundColor: theme.palette.action.active,
                        }, children: label })] })] }));
};
