import {useMemo} from "react";
import * as qs from "qs";

import {connect} from "react-dynadux";

import {Box} from "mhc-ui-components/dist/Box";
import {
  AppContainerDashboard,
  IMenuItemDivider,
} from "mhc-ui-components/dist/AppContainerDashboard";

import {
  Route,
  Switch,
} from "react-router-dom";

import {IAppStore} from "../../../state/IAppStore";
import {notificationIcons} from "../notification-icons/notificationIcons";
import {
  getProfileIcon,
  getProfileIconOptions,
} from "../notification-icons/profileNotification";
import {AppBottomInfo} from "../config/AppBottomInfo";

import {routeHomePage} from "../routes/routeHomePage";

import {IAppRoute} from "../config/IAppRoute";
import {IAppMenu} from "../config/IAppMenu";
import {appRoutes} from "../config/appRoutes";
import {route404} from "../routes/route404";
import {
  appMenusBottom,
  appMenusTop,
} from "../config/appMenus";
import {AppRoute} from "./AppRoute";

import {AccessPageCheck} from "./AccessPageCheck";

// @ts-ignore
import logoLandscape from "../config/LogoLandscape.svg";
// @ts-ignore
import logoSquare from "../config/LogoSquare.svg";

import {useTheme} from "mhc-ui-components/dist/ThemeProvider";

export interface IAppRouterProps {
  store: IAppStore;
}

export const AppRouterSwitch = connect((props: IAppRouterProps): JSX.Element => {
  const {
    store,
    store: {
      app: {actions: {navigateTo}},
      userAuth: {
        state: {
          user: {
            displayName,
            firstName,
          },
        },
        actions: {clearUserAuthError},
        utils: {
          userHasAllRights,
          userHasAnyOfRights,
        },
      },
    },
  } = props;

  const theme = useTheme();

  const handleLogoClick = (): void => {
    clearUserAuthError();
    navigateTo({url: routeHomePage.getRoutePath()});
  };

  const routes = useMemo<JSX.Element[]>(() => {
    return appRoutes
      .concat(route404)
      .map((
        {
          title,
          menuId,
          routePath,
          signWithInvitation = false,
          exact = false,
          userHasAllRights,
          userHasAnyOfRights,
          render,
        }: IAppRoute,
        index: number = 0,
      ): JSX.Element => (
        <Route
          key={index}
          path={routePath}
          exact={exact}
          render={(route) => (
            <AccessPageCheck
              pageTitle={title}
              userHasAllRights={userHasAllRights}
              userHasAnyOfRights={userHasAnyOfRights}
              signWithInvitation={signWithInvitation}
            >
              <AppRoute
                appTitle={title}
                menuId={menuId}
              >
                {render({
                  pathParams:
                    route.match.params as any,
                  queryParams:
                    window.location.search
                      ? qs.parse(window.location.search.substring(1)) as any
                      : {} as any,
                })}
              </AppRoute>
            </AccessPageCheck>
          )}
        />
      ));
  }, []);

  const mapAppMenuItem = (appMenu: IAppMenu | 'DIVIDER'): IMenuItemDivider => {
    if (appMenu === 'DIVIDER') return 'DIVIDER';

    const visible =
      userHasAllRights(appMenu.userHasAllRights) &&
      userHasAnyOfRights(appMenu.userHasAnyOfRights);

    return {
      menuId: appMenu.menuId,
      icon: appMenu.icon,
      title: appMenu.title,
      description: appMenu.description,
      hidden: !visible,
      children: (appMenu.children || []).map(mapAppMenuItem),
      onClick: () => navigateTo({url: appMenu.navigateTo}),
    };
  };

  const getMenus = (appMenus: (IAppMenu | 'DIVIDER')[]): IMenuItemDivider[] => {
    return appMenus
      .filter(menu => menu === "DIVIDER" || !menu.hidden)
      .map(mapAppMenuItem);
  };

  const applyUserName = displayName || firstName || undefined;

  return (
    <AppContainerDashboard
      appTitle={store.app.state.title}
      logoSquareImage={logoSquare}
      logoLandscapeImage={logoLandscape}
      selectedMenuId={store.app.state.menuId}
      menuItems={getMenus(appMenusTop)}
      menuItemsBottom={getMenus(appMenusBottom)}
      profileIcon={getProfileIcon(store)}
      profileUserName={applyUserName}
      profileOptions={getProfileIconOptions(store)}
      notificationIcons={notificationIcons(store)}
      showMinimizedOption
      initialOpenMode="LAST"
      bottomAppInfo={<AppBottomInfo/>}
      onLogoClick={handleLogoClick}
    >
      <Box
        sx={{position: "relative"}}
        fullHeight
      >
        <Box
          sx={{
            position: "absolute",
            top: theme.spacing(1),
            bottom: theme.spacing(1),
            left: theme.spacing(1),
            right: theme.spacing(1),
          }}
        >
          <Switch>
            {routes}
          </Switch>
        </Box>
      </Box>
    </AppContainerDashboard>
  );
});
