// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leaflet-marker-div-icon {
  background: transparent;
  border: none;
}
`, "",{"version":3,"sources":["webpack://./src/GeoMapLeafletCore/markers/customIconMarker.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,YAAA;AACF","sourcesContent":[".leaflet-marker-div-icon {\n  background: transparent;\n  border: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
