export const buildUrl = (...urlParts) => {
    const helperAddress = urlParts.join(' ').trim()
        .split('?')[0].trim();
    const startsWithSlash = helperAddress.startsWith('/');
    const endsWithSlash = helperAddress.endsWith('/');
    let address = urlParts
        .filter(Boolean)
        .map(p => `/${p}`)
        .reduce((acc, p) => acc + p, '')
        .split('/')
        .filter(Boolean)
        .join('/');
    if (address.indexOf('http:/') === 0)
        address = address.replace('http:/', 'http://');
    if (address.indexOf('https:/') === 0)
        address = address.replace('https:/', 'https://');
    address = address.replace('/?', '?');
    if (startsWithSlash)
        address = `/${address}`;
    if (endsWithSlash) {
        address = (() => {
            const parts = address.split('?');
            return [
                parts[0],
                '/',
                parts.length > 1 ? '?' : '',
                ...(parts.slice(1)),
            ]
                .join('');
        })();
    }
    return address;
};
