import {IAppRoute} from "../../application/config/IAppRoute";

export const routeSignInWithInvitationPagePaths: IAppRoute = {
  menuId: '',
  title: 'Accept invitation',
  routePath: '/invitation-sign-in',
  getRoutePath: () => routeSignInWithInvitationPagePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  exact: true,
  render: () => null,
};
