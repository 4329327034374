import { jsx as _jsx } from "react/jsx-runtime";
import SortNoneIcon from '@mui/icons-material/ImportExport';
import SortAscIcon from '@mui/icons-material/ArrowUpward';
import ArrowDescIcon from '@mui/icons-material/ArrowDownward';
import { useTheme } from "../../ThemeProvider";
import { sxHover } from "../../sxHover";
export const SortButton = (props) => {
    const { fieldName, sort, onChange, } = props;
    const theme = useTheme();
    const handleClick = () => {
        const sortDirections = [0, 1, -1, 0, 1, -1];
        const next = sortDirections
            .concat(sortDirections, sortDirections)
            .indexOf(sort)
            + 1;
        onChange(fieldName, sortDirections[next]);
    };
    const Icon = (() => {
        switch (sort) {
            case 0: return SortNoneIcon;
            case 1: return SortAscIcon;
            case -1: return ArrowDescIcon;
        }
    })();
    return (_jsx("span", { style: {
            position: 'relative',
            top: 6,
            whiteSpace: "nowrap",
        }, children: _jsx(Icon, { sx: Object.assign({ color: sort !== 0 ? theme.palette.primary.main : theme.palette.text.secondary }, sxHover({ pointer: true })), onClick: handleClick }) }));
};
