export const textEllipsis = (text, maxLength) => {
    if (text.length <= maxLength) {
        return text;
    }
    // Check if the maxLength is less than 4, in which case we can't add ellipsis.
    if (maxLength < 4) {
        return Array(maxLength).fill('.')
            .join('');
    }
    // Calculate the maximum length for the text with ellipsis.
    const maxTextLengthWithEllipsis = maxLength - 3; // 3 is for the ellipsis ("...")
    // Find the last space within the maxTextLengthWithEllipsis.
    const lastSpaceIndex = text.lastIndexOf(' ', maxTextLengthWithEllipsis);
    // If no space is found, simply add ellipsis.
    if (lastSpaceIndex === -1) {
        return text.slice(0, maxTextLengthWithEllipsis) + '...';
    }
    // Otherwise, cut the text at the last space and add ellipsis.
    return text.slice(0, lastSpaceIndex) + '...';
};
