/**
 * Concatenates an array of strings, numbers, and boolean `false` values into a single string.
 * The boolean `false` values and empty or whitespace-only strings are ignored.
 * Each valid element is separated by a single space in the final string.
 *
 * @param {...(string | number | false)[]} parts - The parts to concatenate. These can be strings, numbers, or boolean `false` values.
 * @returns {string} - The concatenated string with valid elements separated by a space.
 *
 * @example
 * text("hello", false, "world", 123, " ") // "hello world 123"
 * text("foo", 0, "bar", false, "") // "foo 0 bar"
 */
export const text = (...parts) => {
    let result = '';
    for (const part of parts) {
        if (part === false)
            continue;
        if (part === null)
            continue;
        if (part === undefined)
            continue;
        const text = String(part).trim();
        if (!text)
            continue;
        if (result)
            result += ' ';
        result += text;
    }
    return result;
};
