import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {ITrafficStudyManageProps} from "./TrafficStudyManage";
export {
  ETrafficStudyManageTab,
} from "./interfaces";

const TrafficStudyManageLazy = lazy(() => import("./TrafficStudyManage"));

export const TrafficStudyManage = connect((props: ITrafficStudyManageProps) => (
  <LazyLoadComponent>
    <TrafficStudyManageLazy {...props}/>
  </LazyLoadComponent>
));
