import { convertDictionaryToViewLabelArray } from "utils-library/dist/utils";
export var EDeviceManagementRights;
(function (EDeviceManagementRights) {
    EDeviceManagementRights["ADMINISTRATOR"] = "DM_A";
    EDeviceManagementRights["VIEW"] = "DM_V";
    EDeviceManagementRights["CONFIG_DEVICES"] = "DM_C";
    EDeviceManagementRights["CONFIG_PRESETS"] = "DM_CP";
    EDeviceManagementRights["ALPHA_DRIVERS"] = "DM_AD";
    EDeviceManagementRights["BETA_DRIVERS"] = "DM_BD";
})(EDeviceManagementRights || (EDeviceManagementRights = {}));
const labelsDic = {
    [EDeviceManagementRights.ADMINISTRATOR]: "Device Management / Administrator",
    [EDeviceManagementRights.VIEW]: "Device Management / View",
    [EDeviceManagementRights.CONFIG_DEVICES]: "Device Management / Config Devices",
    [EDeviceManagementRights.CONFIG_PRESETS]: "Device Management / Config Presets",
    [EDeviceManagementRights.ALPHA_DRIVERS]: "Device Management / Add Alpha drivers",
    [EDeviceManagementRights.BETA_DRIVERS]: "Device Management / Add Beta drivers",
};
export const EDeviceManagementRightsArray = convertDictionaryToViewLabelArray(labelsDic);
