import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";
import {routeUserInvitationEntryPagePaths} from "./routeUserInvitationEntryPage.paths";

const SignInPage = lazy(() => import("../../user-authnentication/pages/SignInPage"));

export const routeUserInvitationEntryPage: IAppRoute = {
  ...routeUserInvitationEntryPagePaths,
  render: () => (
    <LazyLoadComponent>
      <SignInPage signInWithInvitation/>
    </LazyLoadComponent>
  ) ,
};
