import {BrowserDb} from "mhc-ui-components/dist/BrowserDb";

const browserDbs: { [dbName: string]: BrowserDb } = {};

const BROWSER_DB_VERSION = '2022-06-24'; // Change the version in breaking changes but note that existed unsaved offline CIMs will be lost!

export const getBrowserDb = (companyId: string, userId: string): BrowserDb => {
  const dbName =
    [
      companyId,
      userId,
      BROWSER_DB_VERSION,
    ]
      .join('---');
  if (browserDbs[dbName]) return browserDbs[dbName];
  return browserDbs[dbName] = new BrowserDb({
    dbName,
    collectChanges: true,
  });
};
