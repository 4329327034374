import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useTheme, useMediaQuery, } from "../ThemeProvider";
export var EBreakpoint;
(function (EBreakpoint) {
    // Source: https://mui.com/customization/breakpoints/
    // Breakpoints can be changed by https://material-ui.com/customization/breakpoints/#custom-breakpoints
    EBreakpoint["XSMALL"] = "xs";
    EBreakpoint["SMALL"] = "sm";
    EBreakpoint["MEDIUM"] = "md";
    EBreakpoint["LARGE"] = "lg";
    EBreakpoint["XLARGE"] = "xl";
})(EBreakpoint || (EBreakpoint = {}));
export const BreakpointContainer = (props) => {
    const { breakpoint, toBreakpoint, children, } = props;
    const theme = useTheme();
    const before = useMediaQuery(theme.breakpoints.between(breakpoint, 9999999));
    const after = useMediaQuery(theme.breakpoints.up(toBreakpoint));
    const middle = useMediaQuery(theme.breakpoints.between(breakpoint, toBreakpoint));
    const matches = (() => {
        if (breakpoint && !toBreakpoint) {
            return before;
        }
        if (!breakpoint && toBreakpoint) {
            return after;
        }
        return middle;
    })();
    return _jsx(_Fragment, { children: matches ? children : null });
};
