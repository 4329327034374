var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as L from "leaflet";
import 'leaflet.offline';
import { deleteDB } from "idb";
import { DbTiles, } from "./DbTiles";
import { findElements } from "./utils/findElements";
export const leafletOffline = ({ leafletMap, showOfflineSaveDeleteButtons, onSaveOfflineMaps, onDeleteOfflineMaps, }) => {
    const dbTiles = new DbTiles({ onSaveOfflineMaps });
    const offlineLayer = L
        .tileLayer
        .offline('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        subdomains: 'abc',
        minZoom: 13,
        maxZoom: 19,
        crossOrigin: true,
    });
    offlineLayer.addTo(leafletMap);
    // Delete indexedDB used by leaflet.offline
    // We use our own idb in the DbTiles class through localforage
    deleteDB('leaflet.offline');
    if (showOfflineSaveDeleteButtons) {
        const offlineControl = L
            .control
            .savetiles(offlineLayer, {
            zoomlevels: [13, 19],
            confirm: (nTilesToSave) => __awaiter(void 0, void 0, void 0, function* () {
                yield dbTiles.saveTiles(nTilesToSave._tilesforSave);
            }),
            confirmRemoval: () => __awaiter(void 0, void 0, void 0, function* () {
                const savedMapsCount = yield dbTiles.getSavedMapsCount();
                const savedMapsLabel = dbTiles.getMapsCountLabel(savedMapsCount);
                onDeleteOfflineMaps({
                    savedMapsCount,
                    savedMapsLabel,
                    deleteMaps: () => dbTiles.clear(),
                });
            }),
            saveText: `<div class="geo-map-offline-save-button" style="${buttonStyle}">
                <i class="fa fa-save"></i>
              </div>`,
            rmText: `<div style="${buttonStyle}">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </div>`,
        });
        offlineControl.addTo(leafletMap);
    }
    return {
        enableSaveButton: (enable) => {
            findElements('.geo-map-offline-save-button').forEach(element => {
                element.parentNode.style.transition = 'opacity 150ms ease-in-out, backgroundColor 150ms ease-in-out';
                element.parentNode.style.pointerEvents = enable ? '' : 'none';
                element.parentNode.style.opacity = enable ? 1 : 0.5;
            });
        },
    };
};
const buttonStyle = `
  font-size: 16px;
  text-align: center;
`;
