import { getDefaultDBEntityDocument, } from "utils-library/dist/db-entity-interfaces";
export const getDefaultPreset = ({ groupId, preset, }) => getDefaultDBEntityDocument({
    groupId,
    title: '',
    description: '',
    usedTimes: 0,
    preset,
    lastUsedAt: 0,
    deleteAfterDaysOfInactivity: 0,
});
export const convertPresetToPresetHeader = (preset) => {
    const output = Object.assign({}, preset);
    delete output.preset;
    return output;
};
