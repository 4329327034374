import { scanElement } from "./scanElement";
import { getElementStyleString } from "./getElementStyleString";
import { prettifyHTML } from "./prettifyHTML";
/**
 * Converts an HTML element into an HTML string, applying inline styles so it can be rendered elsewhere without needing classes or external styles.
 * It also removes (optionally) the classes from the processed elements.
 *
 * This works like DOM'S outerHTML or React's renderToString but applying inline styles
 */
export const convertHTMLElementToString = ({ element, excludeNodeNames: userExcludeNodeNames = [], injectInlineStyles = false, removeClasses = false, prettify = false, }) => {
    const excludeNodeNames = userExcludeNodeNames.map(nodeName => nodeName.toUpperCase());
    const processedElements = [];
    scanElement(element, ({ element, skip, }) => {
        if (excludeNodeNames.includes(element.nodeName)) {
            skip();
            return;
        }
        if (injectInlineStyles) {
            if (!element.getAttribute(dataProcessedAttrName)) {
                element.setAttribute('style', getElementStyleString(element));
                processedElements.push(element);
                element.setAttribute(dataProcessedAttrName, "true");
            }
        }
    });
    if (removeClasses) {
        for (const element of processedElements) {
            element.removeAttribute('class');
        }
    }
    return prettify
        ? prettifyHTML(element.outerHTML)
        : element.outerHTML;
};
const dataProcessedAttrName = "data-convertHTMLElementToHTMLScriptWithStyles-processed";
