export var EDeviceDriverStatus;
(function (EDeviceDriverStatus) {
    // The EDriverState enum determines whether a driver is eligible to be added to the system.
    // However, once a driver has been added, it can be used in the system regardless of its state.
    //    In other words, the driver state does not interfere with the functionality of added drivers in the system.
    //    Its purpose is to control whether a device should be added or not.
    //    Any non-RELEASE driver may cause problems and should be replaced if issues arise.
    EDeviceDriverStatus["ALPHA"] = "ALPHA";
    EDeviceDriverStatus["BETA"] = "BETA";
    EDeviceDriverStatus["RELEASE"] = "RELEASE";
    EDeviceDriverStatus["DEPRECATED_SOON"] = "DEPRECATED_SOON";
    EDeviceDriverStatus["DEPRECATED"] = "DEPRECATED";
})(EDeviceDriverStatus || (EDeviceDriverStatus = {}));
