import { useRef, useEffect, } from "react";
export const useSetTimeout = ({ cb }) => {
    const refTimeoutId = useRef(null);
    const start = (ms) => {
        clear();
        refTimeoutId.current = window.setTimeout(cb, ms);
    };
    const clear = () => {
        if (refTimeoutId.current === null)
            return;
        window.clearTimeout(refTimeoutId.current);
        refTimeoutId.current = null;
    };
    useEffect(() => {
        return clear;
    }, []);
    return {
        start,
        clear,
    };
};
