
      import API from "!../../../../../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.93.0/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.93.0/node_modules/style-loader/dist/runtime/styleDomAPI.js";
      import insertFn from "!../../../../../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.93.0/node_modules/style-loader/dist/runtime/insertBySelector.js";
      import setAttributes from "!../../../../../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.93.0/node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.93.0/node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../../node_modules/.pnpm/style-loader@4.0.0_webpack@5.93.0/node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../../node_modules/.pnpm/typed-css-modules-loader@0.0.18/node_modules/typed-css-modules-loader/index.js??ruleSet[1].rules[3].use[2]!../../../../../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.4.39_typescript@5.6.2_webpack@5.93.0/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[3]!../../../../../../node_modules/.pnpm/less-loader@12.2.0_less@4.2.0_webpack@5.93.0/node_modules/less-loader/dist/cjs.js!./CoordinateInput.module.less";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = insertFn.bind(null, "head");
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../../node_modules/.pnpm/css-loader@7.1.2_webpack@5.93.0/node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[3].use[1]!../../../../../../node_modules/.pnpm/typed-css-modules-loader@0.0.18/node_modules/typed-css-modules-loader/index.js??ruleSet[1].rules[3].use[2]!../../../../../../node_modules/.pnpm/postcss-loader@8.1.1_postcss@8.4.39_typescript@5.6.2_webpack@5.93.0/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[3].use[3]!../../../../../../node_modules/.pnpm/less-loader@12.2.0_less@4.2.0_webpack@5.93.0/node_modules/less-loader/dist/cjs.js!./CoordinateInput.module.less";
       export default content && content.locals ? content.locals : undefined;
