import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { GeoMapLeafletCore, } from "../GeoMapLeafletCore";
import { SaveOfflineMapsDialog } from "./components/SaveOfflineMapsDialog";
import { DeleteOfflineMapsDialog } from "./components/DeleteOfflineMapsDialog";
export const GeoMapLeafletOfflineManager = (props) => {
    const [saveMapsArgs, setSaveMapsArgs] = useState(null);
    const [deleteMapsArgs, setDeleteMapsArgs] = useState(null);
    const handleDialogClose = () => {
        setSaveMapsArgs(null);
        setDeleteMapsArgs(null);
    };
    const mapOverlay = (() => {
        if (saveMapsArgs) {
            return (_jsx(SaveOfflineMapsDialog, { saveMapsArgs: saveMapsArgs, onClose: handleDialogClose }));
        }
        if (deleteMapsArgs) {
            return (_jsx(DeleteOfflineMapsDialog, { deleteMapsArgs: deleteMapsArgs, onClose: handleDialogClose }));
        }
        return undefined;
    })();
    return (_jsx(GeoMapLeafletCore, Object.assign({}, props, { maxZoom: 19, showOfflineSaveDeleteButtons: true, mapOverlay: mapOverlay, onOfflineSaveMaps: setSaveMapsArgs, onOfflineDeleteMaps: setDeleteMapsArgs })));
};
