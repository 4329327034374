import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, } from "react";
import { dynaSwitchEnum } from "dyna-switch";
import { Box } from "../Box";
import { IconViewer } from "../IconViewer";
import { createIcon } from "../IconComponent";
import SuccessIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Report';
import InfoIcon from '@mui/icons-material/Info';
import DebugIcon from '@mui/icons-material/BugReport';
import HelpIcon from '@mui/icons-material/Help';
export var EIconStatus;
(function (EIconStatus) {
    EIconStatus["SUCCESS"] = "SUCCESS";
    EIconStatus["WARNING"] = "WARNING";
    EIconStatus["ERROR"] = "ERROR";
    EIconStatus["INFO"] = "INFO";
    EIconStatus["DEBUG"] = "DEBUG";
    EIconStatus["HELP"] = "HELP";
})(EIconStatus || (EIconStatus = {}));
export const IconStatus = ({ status, sizePx = 64, color = true, animation = true, delayAnimationStartMs = 0, inline = false, center = true, message, }) => {
    const [startAnimation, setStartAnimation] = useState(false);
    useEffect(() => {
        setTimeout(() => setStartAnimation(true), delayAnimationStartMs);
    }, []);
    return (_jsxs(Box, { dataComponentName: "IconStatus", inline: inline, sx: { textAlign: center ? 'center' : undefined }, children: [_jsx(IconViewer, { sx: {
                    color: theme => (() => {
                        if (!color)
                            return undefined;
                        return dynaSwitchEnum(status, {
                            [EIconStatus.SUCCESS]: theme.palette.success.main,
                            [EIconStatus.WARNING]: theme.palette.warning.main,
                            [EIconStatus.ERROR]: theme.palette.error.main,
                            [EIconStatus.INFO]: theme.palette.info.main,
                            [EIconStatus.DEBUG]: theme.palette.error.dark,
                            [EIconStatus.HELP]: theme.palette.info.light,
                        });
                    })(),
                    transform: animation
                        ? startAnimation
                            ? undefined
                            : "rotateY(90deg)"
                        : undefined,
                    transition: animation
                        ? "transform 250ms ease"
                        : undefined,
                }, width: sizePx, height: sizePx, Icon: createIcon.byMuiIcon(dynaSwitchEnum(status, {
                    [EIconStatus.SUCCESS]: SuccessIcon,
                    [EIconStatus.WARNING]: WarningIcon,
                    [EIconStatus.ERROR]: ErrorIcon,
                    [EIconStatus.INFO]: InfoIcon,
                    [EIconStatus.DEBUG]: DebugIcon,
                    [EIconStatus.HELP]: HelpIcon,
                })) }), _jsx(Box, { show: !!message, sx: {
                    marginTop: theme => theme.spacing(0.5),
                    fontSize: theme => theme.typography.fontSize * 0.9,
                    opacity: 0.8,
                }, children: message })] }));
};
