import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "../Box";
import { FlexContainerHorizontal, FlexItemMin, FlexItemMax, } from "../FlexContainer";
import { HelperText, EHelperTextType, } from "../HelperText";
import { sxTransition, ECSSDuration, } from "../sxTransition";
import { IconViewer } from "../IconViewer";
export var EInputCheckboxSize;
(function (EInputCheckboxSize) {
    EInputCheckboxSize["SMALL"] = "small";
    EInputCheckboxSize["MEDIUM"] = "medium";
})(EInputCheckboxSize || (EInputCheckboxSize = {}));
export var ECheckBoxPosition;
(function (ECheckBoxPosition) {
    ECheckBoxPosition["LEFT"] = "LEFT";
    ECheckBoxPosition["RIGHT"] = "RIGHT";
})(ECheckBoxPosition || (ECheckBoxPosition = {}));
export const InputCheckbox = (props) => {
    const { sx = {}, dataComponentName, id, name = "", Icon, label, disabled = false, readOnly = false, size = EInputCheckboxSize.SMALL, position = ECheckBoxPosition.LEFT, helperLabel, validationError, value, onChange, } = props;
    const handleChange = () => {
        if (disabled)
            return;
        if (readOnly)
            return;
        onChange === null || onChange === void 0 ? void 0 : onChange(!value, name);
    };
    return (_jsxs(Box, { sx: Object.assign(Object.assign({}, sx), { cursor: !disabled && !readOnly ? 'pointer' : undefined, opacity: disabled ? 0.7 : undefined, transition: theme => sxTransition(theme, 'opacity', ECSSDuration.SHORT) }), dataComponentName: [dataComponentName, 'InputCheckbox'], children: [_jsxs(FlexContainerHorizontal, { alignVertical: "middle", sx: { userSelect: 'none' }, reverseOrder: position === ECheckBoxPosition.RIGHT, onClick: handleChange, children: [_jsx(FlexItemMin, { children: _jsx(Checkbox, { sx: { 'svg': { color: theme => theme.palette.mode === 'dark' ? theme.palette.primary.light : undefined } }, id: id, name: name, size: size, disabled: disabled || readOnly, checked: (() => {
                                if (value === true || value === false)
                                    return value;
                                if (value === "mixed")
                                    return true;
                                return undefined;
                            })(), indeterminate: value === 'mixed' }) }), _jsx(FlexItemMin, { show: !!Icon, children: _jsx(IconViewer, { sx: { marginRight: 1 }, Icon: Icon, width: 16 }) }), _jsx(FlexItemMax, { show: !!label, sx: { textAlign: position === ECheckBoxPosition.RIGHT ? "right" : undefined }, children: _jsx("span", { style: { userSelect: "none" }, children: label }) })] }), _jsx(HelperText, { children: helperLabel }), _jsx(HelperText, { type: EHelperTextType.ERROR, children: validationError })] }));
};
