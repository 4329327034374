import {IAppRoute} from "../../application/config/IAppRoute";
import {routeDeviceManagementVirtualTreePaths} from "./routeDeviceManagementVirtualTree.paths";

import {DeviceManagementVirtualTree} from "../pages/DeviceManagementVirtualTree";

export const routeDeviceManagementVirtualTree: IAppRoute<{presetId: string}> = {
  ...routeDeviceManagementVirtualTreePaths,
  render: ({pathParams: {presetId}}) => (
    <DeviceManagementVirtualTree
      presetId={presetId}
    />
  ),
};
