import { convertDictionaryToViewLabelArray } from "utils-library/dist/utils";
export var EWorkZonesManagementRights;
(function (EWorkZonesManagementRights) {
    EWorkZonesManagementRights["WORKZONES_VIEW"] = "WZM_V";
    EWorkZonesManagementRights["WORKZONES_EDIT"] = "WZM_E";
})(EWorkZonesManagementRights || (EWorkZonesManagementRights = {}));
const labelsDic = {
    [EWorkZonesManagementRights.WORKZONES_VIEW]: 'CIMs / View',
    [EWorkZonesManagementRights.WORKZONES_EDIT]: 'CIMs / Edit',
};
export const EWorkZonesManagementRightsArray = convertDictionaryToViewLabelArray(labelsDic);
