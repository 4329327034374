import {Box} from "mhc-ui-components/dist/Box";
import {Link} from "mhc-ui-components/dist/Link";

import {useTheme} from "mhc-ui-components/dist/ThemeProvider";

export const AppBottomInfo = (): JSX.Element => {
  const theme = useTheme();

  return (
    <Box
      inline
      sx={{
        color: theme.palette.text.primary,
        whiteSpace: 'normal',
        fontSize: theme.typography.fontSize,
        '& > a': {color: theme.palette.text.secondary},
      }}
    >
      <strong>Metiri Application</strong> All rights reserved.
      <br/>
      MH Corbin 1986 - {(new Date()).getFullYear()} <Link href="https://mhcorbin.com/" target="_blank" rel="noreferrer">mhcorbin.com</Link>
    </Box>
  );
};
