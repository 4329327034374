import { jsx as _jsx } from "react/jsx-runtime";
import { Polyline as LeafletPolyline } from "react-leaflet";
import { MapPopUp, } from "../MapMarker/components/MapPopUp";
import { makeStyles } from "@mui/styles";
export const MapPolyline = (props) => {
    const { points, pathOptions = {}, popUp, 
    // Cursor, Todo: fix me! Currently, this is intentionally ignored as changes to the className of the LeafletPolyline are not taking effect. In other words, the LeafletPolyline applies the className only once and not on subsequent renders.
    onClick, } = props;
    const classes = useStyles();
    if (!points.length)
        return null;
    return (
    // @ts-ignore --> This is because the dep returns ReactElement and not JSX.Element
    _jsx(LeafletPolyline, { className: classes.cursorPointer, positions: points, pathOptions: pathOptions, eventHandlers: { click: onClick }, children: !!popUp && _jsx(MapPopUp, Object.assign({}, popUp)) }));
};
const useStyles = makeStyles({ cursorPointer: { cursor: "pointer !important" } });
