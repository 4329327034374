import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { dynaSwitchEnum } from "dyna-switch";
import { LabelOverlay } from "../LabelOverlay";
import { Ellipsis } from "../Ellipsis";
import { createIcon } from "../IconComponent";
import { ProgressIcon } from "../ProgressIcon";
import InitializeIcon from '@mui/icons-material/HourglassEmpty';
import IsLoadingStaticIcon from '@mui/icons-material/Cloud';
import NoSearchIcon from '@mui/icons-material/SearchOff';
import NoDataIcon from '@mui/icons-material/Storage';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
export var EProgressType;
(function (EProgressType) {
    EProgressType["HIDDEN"] = "HIDDEN";
    EProgressType["INITIALIZING"] = "INITIALIZING";
    EProgressType["IS_LOADING_ANIMATION"] = "IS_LOADING_ANIMATION";
    EProgressType["IS_LOADING_STATIC"] = "IS_LOADING_STATIC";
    EProgressType["NO_DATA_FOUND"] = "NO_DATA_FOUND";
    EProgressType["NO_DATA_AVAILABLE"] = "NO_DATA_AVAILABLE";
    EProgressType["SUCCESS"] = "SUCCESS";
    EProgressType["ERROR"] = "ERROR";
    EProgressType["WARNING"] = "WARNING";
})(EProgressType || (EProgressType = {}));
export const LabelOverlayDataProgress = ({ show = true, fullHeight = true, iconSize = 128, type, title, description, children, }) => {
    if (!show)
        return null;
    return dynaSwitchEnum(type, {
        [EProgressType.HIDDEN]: null,
        [EProgressType.INITIALIZING]: _jsx(LabelOverlay, { Icon: createIcon.byMuiIcon(InitializeIcon), fullHeight: fullHeight, title: title || "Initializing...", description: description || "Please wait.", children: children }),
        [EProgressType.IS_LOADING_ANIMATION]: _jsx(LabelOverlay, { iconElement: _jsx(ProgressIcon, { size: iconSize }), fullHeight: fullHeight, title: title || "Loading...", description: description || "Please wait.", children: children }),
        [EProgressType.IS_LOADING_STATIC]: _jsx(LabelOverlay, { Icon: createIcon.byMuiIcon(IsLoadingStaticIcon), size: iconSize, fullHeight: fullHeight, title: title || _jsxs(_Fragment, { children: ["Loading", _jsx(Ellipsis, {})] }), description: description || "Please wait.", children: children }),
        [EProgressType.NO_DATA_FOUND]: _jsx(LabelOverlay, { Icon: createIcon.byMuiIcon(NoSearchIcon), size: iconSize, fullHeight: fullHeight, title: title || "No results found", description: description || "Your search return no data", children: children }),
        [EProgressType.NO_DATA_AVAILABLE]: _jsx(LabelOverlay, { Icon: createIcon.byMuiIcon(NoDataIcon), size: iconSize, fullHeight: fullHeight, title: title || "Empty", description: description || "Data not available", children: children }),
        [EProgressType.SUCCESS]: _jsx(LabelOverlay, { Icon: createIcon.byMuiIcon(SuccessIcon), iconColor: theme => theme.palette.success.main, size: iconSize, fullHeight: fullHeight, title: title || "Success", description: description || "Operation successfully completed", children: children }),
        [EProgressType.ERROR]: _jsx(LabelOverlay, { Icon: createIcon.byMuiIcon(ErrorIcon), iconColor: theme => theme.palette.error.main, size: iconSize, fullHeight: fullHeight, title: title || "Error", description: description || "Operation failed", children: children }),
        [EProgressType.WARNING]: _jsx(LabelOverlay, { Icon: createIcon.byMuiIcon(WarningIcon), iconColor: theme => theme.palette.warning.main, size: iconSize, fullHeight: fullHeight, title: title || "Warning", description: description, children: children }),
    });
};
