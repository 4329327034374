import { useEffect, useRef, useState, } from "react";
import { shortGuid } from "dyna-guid";
import { areValuesEqual } from "utils-library/dist/utils";
/**
 * Generates different changeId when the passed is different from the the previous.
 *
 * # Hight perfomance
 *
 * This is **hight perfomance** hook.  It uses the isEqual of lodash resolving the result on 1st detected change.
 *
 * # Compatibility
 *
 * The value would be anything, object, array
 *
 * @param value
 */
export const useChangeId = (value) => {
    const refPrevDeps = useRef(value);
    const [changeId, setChangeId] = useState(shortGuid());
    useEffect(() => {
        if (!areValuesEqual(value, refPrevDeps.current))
            setChangeId(shortGuid());
        refPrevDeps.current = value;
    }, [value]);
    return changeId;
};
