import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { Box } from "../Box";
export const CenterContainer = forwardRef(function CenterContainer(props, ref) {
    const { sx = {}, show = true, dataComponentName, textAlign = "left", horizontally, vertically, fullHeight = true, children, } = props;
    if (!show)
        return null;
    return (_jsx(Box, { dataComponentName: [dataComponentName, "CenterContainer"], ref: ref, sx: Object.assign({ height: fullHeight ? '100%' : undefined, display: 'flex', flexDirection: 'column', textAlign, justifyContent: (() => {
                if (vertically === "top")
                    return "flex-start";
                if (vertically === "bottom")
                    return "flex-end";
                return "center";
            })(), alignContent: (() => {
                if (horizontally === "left")
                    return "flex-start";
                if (horizontally === "right")
                    return "flex-end";
                return "center";
            })(), flexWrap: 'wrap' }, sx), children: children }));
});
