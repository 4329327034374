import { useState, useRef, useCallback, } from "react";
import { areValuesEqual } from "utils-library/dist/utils";
/**
 * An optimized useState version that set the state if really needed.
 *
 * The set methods supports partial update of the state when it is used for objects.
 */
export const usePerformanceState = (
/**
 * The initial state value
 */
initialState, 
/**
 * Callback that it is called when really there is a change (and so a render trigger)
 */
onChange) => {
    const initial = typeof initialState === 'function'
        ? initialState()
        : initialState;
    const prevStateRef = useRef(initial);
    const [state, setState] = useState(initial);
    const handleSetState = (partialStateOrCb) => {
        if (typeof state === "object" && !Array.isArray(partialStateOrCb)) {
            // State is an object, support partial updates
            const prevState = prevStateRef.current;
            const partialState = typeof partialStateOrCb === 'function'
                ? partialStateOrCb(prevStateRef.current)
                : partialStateOrCb;
            const newState = Object.assign(Object.assign({}, prevStateRef.current), partialState);
            if (!areValuesEqual(prevState, newState)) {
                prevStateRef.current = newState;
                setState(newState);
                onChange === null || onChange === void 0 ? void 0 : onChange(newState);
            }
        }
        else {
            // State is not an object, just overwrite the state
            const newState = typeof partialStateOrCb === 'function'
                ? partialStateOrCb(prevStateRef.current)
                : partialStateOrCb;
            const prevState = prevStateRef.current;
            if (prevState !== newState) {
                prevStateRef.current = newState;
                setState(newState);
                onChange === null || onChange === void 0 ? void 0 : onChange(newState);
            }
        }
        return undefined;
    };
    const setStatePerformance = useCallback(handleSetState, [state]);
    return [
        state,
        setStatePerformance,
    ];
};
