import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "../Box";
import { getDataComponentName } from "../web-utils";
/**
 * Div Container with Ellipsis effect for text content
 * If this is in FlexItemMax, set FlexItemMax's overFlowX to true to be effective!
 * @param props
 * @constructor
 */
export const ContainerEllipsis = (props) => {
    const { sx, show, dataComponentName, component = "div", children, maxLines = 1, } = props; // Default maxLine to 1
    const applySx = Object.assign(Object.assign({}, sx), { display: '-webkit-box', WebkitBoxOrient: 'vertical', boxOrient: 'vertical', lineClamp: maxLines.toString(), WebkitLineClamp: maxLines.toString(), wordBreak: 'break-word', overflow: 'hidden', textOverflow: 'ellipsis' });
    return (_jsx(Box, { show: show, dataComponentName: getDataComponentName(dataComponentName, "ContainerEllipsis"), component: component, sx: applySx, children: children }));
};
