import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {ITrafficStudyReportsPageProps} from "./TrafficStudyReportsPage";

const TrafficStudyReportLazy = lazy(() => import("./TrafficStudyReportsPage"));

export const TrafficStudyReportsPage = connect((props: ITrafficStudyReportsPageProps) => (
  <LazyLoadComponent>
    <TrafficStudyReportLazy {...props}/>
  </LazyLoadComponent>
));
