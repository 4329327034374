import { useResizeDetector } from "react-resize-detector";
import { useIsMounted } from "../useIsMounted";
import { usePerformanceState } from "../usePerformanceState";
export const useResizeEvent = ({ refreshRate = 500, skipOnMount = true, leading = true, trailing = true, onResize = () => undefined, } = {}) => {
    const [dimension, setDimension] = usePerformanceState({
        width: 0,
        height: 0,
    });
    const getIsMounted = useIsMounted();
    const handleContainerResize = (width, height) => {
        if (!getIsMounted())
            return;
        // Ignore these useDebouncedCallback's call
        if (width === undefined || height === undefined)
            return;
        if (width === 0 && height === 0)
            return;
        const widthDiffPercentage = Math.abs(100 * (width - dimension.width) / dimension.width);
        const heightDiffPercentage = Math.abs(100 * (height - dimension.height) / dimension.height);
        const diffPercentage = (widthDiffPercentage + heightDiffPercentage) / 2;
        setDimension({
            width,
            height,
        });
        onResize({
            width,
            height,
            diffPercentage,
        });
    };
    const { ref } = useResizeDetector({
        skipOnMount,
        refreshRate,
        refreshMode: "debounce",
        refreshOptions: {
            leading,
            trailing,
        },
        onResize: handleContainerResize,
    });
    return Object.assign({ ref: ref }, dimension);
};
