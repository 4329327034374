export const JSONStringifyForHumans = ({ obj, spacing = 2, }) => {
    const cache = new WeakMap();
    const replacer = (_key, value) => {
        if (typeof global !== "undefined" && value === global)
            return "<global>";
        if (typeof window !== "undefined" && value === window)
            return "<window>";
        if (value instanceof Error)
            return `<error> ${value.message || "Unknown error"}`;
        if (value instanceof RegExp)
            return value.toString();
        if (typeof value === 'object' && value !== null) {
            if (cache.has(value))
                return '<circular-ref>';
            cache.set(value, true);
        }
        if (typeof value === 'function')
            return "<function> " + value.toString();
        return value;
    };
    return JSON.stringify(obj, replacer, spacing);
};
