const alphabet = Array(26)
    .fill(null)
    .map((_v, index) => String.fromCharCode(65 + index));
export const camelToHuman = (text) => {
    return text
        .split('')
        .reduce((acc, t, index) => {
        if (alphabet.includes(t) && index > 0)
            acc.push(' ');
        acc.push(index === 0
            ? t.toUpperCase()
            : t.toLowerCase());
        return acc;
    }, [])
        .join('');
};
