import md5 from "md5";
/**
 * Hashes the given string using the MD5 (Message Digest Algorithm 5) hash function.
 * Use it for strong encryption, but be aware that it produces quite long results.
 *
 * @param {string} value - The input string to be hashed.
 * @returns {string} The resulting hash value as a 128-bit hexadecimal string.
 *
 * @description Use this hash function for legacy compatibility or checksum verification purposes.
 * MD5 was widely used in the past, but it is now considered cryptographically broken and unsuitable for
 * security-sensitive applications. It produces a fixed-length 128-bit hash, which can be useful for tasks
 * such as generating checksums or ensuring data integrity in non-critical systems. However, due to its
 * vulnerabilities to collision attacks, it should not be used for security-critical tasks like password
 * hashing or cryptographic key generation.
 *
 * @example
 * const output = hashFnv("https://www.duckduckgo.com");
 * // The output is "c1fb03fb2623dcd29f307539c65f1390"
 */
export const hashMd5 = (value) => typeof value === "string"
    ? md5(value)
    : md5(JSON.stringify(value));
