import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import UsersInvitationsIcon from "@mui/icons-material/GroupAdd";

const UsersInvitationsListPage = lazy(() => import("../pages/UsersInvitationsListPage/UsersInvitationsListPage"));

export const routeUsersInvitationsList: IAppRoute = {
  menuId: 'usr-invitations',
  title: 'Users Invitations',
  icon: <UsersInvitationsIcon/>,
  routePath: '/users-invitations',
  getRoutePath: () => routeUsersInvitationsList.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  exact: true,
  render: () => (
    <LazyLoadComponent>
      <UsersInvitationsListPage/>
    </LazyLoadComponent>
  ),
};
