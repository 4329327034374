import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, } from "react";
import { dynaSwitchEnum } from "dyna-switch";
import { FlexContainerHorizontal, FlexItemMax, FlexItemMin, } from "../../../FlexContainer";
import { Box } from "../../../Box";
import { HelperText, EHelperTextType, } from "../../../HelperText";
import { useTheme } from "../../../ThemeProvider";
import { EGeoCoordinatesEditorSize } from "../../interfaces";
import * as styles from './CoordinateInput.module.less';
import { useStateWithPropValue } from "../../../useStateWithPropValue";
import { sxTransition, ECSSDuration, } from "../../../sxTransition";
export const CoordinateInput = ({ size, label, title, readOnly, validation: { min, max, }, helperLabel, disabled = false, value: propValue, validationError: userValidationError, onFocus, onBlur, }) => {
    const theme = useTheme();
    const [value, setValue] = useStateWithPropValue({
        readOnly: readOnly,
        defaultValue: 0,
        propValue,
    });
    const [validationError, setValidationError] = useState("");
    const validate = () => {
        if (value < min)
            setValidationError(`Should be bigger or equal than ${min}`);
        else if (value > max)
            setValidationError(`Should be less or equal than ${max}`);
        else
            setValidationError("");
    };
    useEffect(validate, []);
    const handleKeyDown = (event) => {
        if (event.keyCode === 38 || event.keyCode === 40) {
            event.preventDefault();
        }
    };
    const handleWheel = (event) => {
        event.target.blur();
    };
    const handleFocus = () => {
        onFocus && onFocus();
    };
    const handleBlur = (event) => {
        if (readOnly)
            return;
        validate();
        onBlur(Number(event.target.value));
    };
    const handleChange = (event) => {
        if (readOnly)
            return;
        setValue(Number(event.target.value));
    };
    return (_jsxs(FlexContainerHorizontal, { sx: { background: theme => theme.palette.background.default }, title: title, alignVertical: "middle", children: [_jsx(FlexItemMin, { sx: {
                    fontFamily: 'monospace',
                    fontSize: '12px',
                    marginLeft: '2px',
                    marginRight: '4px',
                    opacity: 0.7,
                }, children: label }), _jsxs(FlexItemMax, { sx: {
                    position: 'relative',
                    'input': {
                        opacity: disabled ? 0.5 : undefined,
                        transition: theme => sxTransition(theme, 'opacity', ECSSDuration.SHORT),
                    },
                }, children: [_jsx("input", { className: styles.hideSpinButtons, style: {
                            width: '100%',
                            outline: 'none',
                            borderColor: theme.palette.grayShades.gray3,
                            borderStyle: 'groove',
                            borderWidth: '1px',
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.paper,
                            fontSize: dynaSwitchEnum(size, {
                                [EGeoCoordinatesEditorSize.SMALL]: '11px',
                                [EGeoCoordinatesEditorSize.MEDIUM]: '13px',
                            }),
                        }, disabled: disabled, readOnly: readOnly, type: "number", value: value, onKeyDown: handleKeyDown, onWheel: handleWheel, onFocus: handleFocus, onBlur: handleBlur, onChange: handleChange }), _jsx(Box, { sx: {
                            position: 'absolute',
                            fontSize: '8px',
                            right: '2px',
                            bottom: '1px',
                            opacity: 0.7,
                        }, children: helperLabel }), _jsx(HelperText, { type: EHelperTextType.ERROR, children: [
                            userValidationError,
                            validationError,
                        ]
                            .filter(Boolean)
                            .join(' - ') })] })] }));
};
