var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Wait until something by condition
 * @returns If the condition became true
 */
export const waitUntil = (_a) => __awaiter(void 0, [_a], void 0, function* ({ checkCondition, timeout, intervalTimeout, }) {
    return new Promise((resolve, reject) => {
        let conditionMet = false;
        let elapsedTime = 0;
        // Start the timer for timeout
        const timeoutId = setTimeout(() => {
            conditionMet = false;
            resolve(false);
        }, timeout);
        // Check the condition at regular intervals
        const intervalId = setInterval(() => {
            checkCondition()
                .then((result) => {
                conditionMet = result;
            })
                .catch(error => {
                clearTimeout(timeoutId);
                clearInterval(intervalId);
                reject(error);
            })
                .finally(() => {
                elapsedTime += intervalTimeout;
                // Resolve with the result only if the condition is met or the timeout is reached
                if (conditionMet || elapsedTime >= timeout) {
                    clearTimeout(timeoutId);
                    clearInterval(intervalId);
                    if (conditionMet) {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                }
            });
        }, intervalTimeout);
    });
});
