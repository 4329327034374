var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useRef, } from "react";
import cloneDeep from 'lodash.clonedeep';
import { dynaObjectScan } from "dyna-object-scan";
import { dynaSwitch } from "dyna-switch";
import { dynaError, } from "dyna-error";
import { guid } from "dyna-guid";
import { validationMergeResults, validationEngine, validationEngineEmptyRules, validationEngineCleanResults, } from "utils-library/dist/validation-engine";
import { convertStringToNumber, getDeepValue, setDeepValue, } from "utils-library/dist/utils";
import { EAlertType, Alert, } from "../Alert";
import { useConfirm } from "../useConfirm";
export var EFormType;
(function (EFormType) {
    EFormType["VIEW"] = "VIEW";
    EFormType["VIEW_EDIT"] = "VIEW_EDIT";
    EFormType["EDIT"] = "EDIT";
})(EFormType || (EFormType = {}));
export var EFormMode;
(function (EFormMode) {
    EFormMode["CREATE"] = "CREATE";
    EFormMode["EDIT_MANAGE"] = "EDIT_MANAGE";
})(EFormMode || (EFormMode = {}));
export var EViewMode;
(function (EViewMode) {
    EViewMode["VIEW"] = "VIEW";
    EViewMode["EDIT"] = "EDIT";
})(EViewMode || (EViewMode = {}));
export var EUpdateMode;
(function (EUpdateMode) {
    EUpdateMode["ON_CHANGE"] = "ON_CHANGE";
    EUpdateMode["ON_BLUR"] = "ON_BLUR";
})(EUpdateMode || (EUpdateMode = {}));
export var EValidationStatus;
(function (EValidationStatus) {
    EValidationStatus["NONE"] = "NONE";
    EValidationStatus["SUCCESS"] = "SUCCESS";
    EValidationStatus["FAILED"] = "FAILED";
})(EValidationStatus || (EValidationStatus = {}));
const ALLOW_CHANGES_ON_BLUR = [
    {
        nodeName: 'INPUT',
        type: 'checkbox',
    },
    {
        nodeName: 'SELECT',
        type: 'select-one',
    },
];
/**
 * Determinate if the input control has only change and not blur event
 * @param event
 */
const isOnlyChangeControl = (event) => !!ALLOW_CHANGES_ON_BLUR.find(scan => scan.nodeName === event.target.nodeName &&
    scan.type === event.target.type);
export const useForm = ({ formType, updateMode = EUpdateMode.ON_BLUR, emptyFormData, trimStringValues = true, loadDataId, userCanCreate = true, userCanEdit = true, userCanArchive = true, userCanUnarchive = true, userCanDelete = true, userCanUnDelete = true, disabledEditOnArchived = false, disabledEditOnDeleted = false, hideCancelButtonOnCreateFormMore = false, disableAutoMapping = false, validationRules = validationEngineEmptyRules(emptyFormData), onApiPost, onApiGet, onApiPut, onApiArchive, onApiUnarchive, onApiDelete, onApiUndelete, onAlert, onChangeMapValue, onBeforeFormSave, onFormSave, onFormCancel, onFormArchive, onFormUnarchive, onFormDelete, onFormUndelete, _debug_consoleFormChange, }) => {
    const [formMode, setFormMode] = useState(loadDataId
        ? EFormMode.EDIT_MANAGE
        : EFormMode.CREATE);
    const [viewMode, setViewMode] = useState((() => {
        if (formType === EFormType.VIEW)
            return EViewMode.VIEW;
        if (formType === EFormType.EDIT)
            return EViewMode.EDIT;
        return loadDataId === null ? EViewMode.EDIT : EViewMode.VIEW;
    })());
    const [alert, setAlert] = useState({
        show: false,
        type: EAlertType.INFO,
        title: '',
        children: undefined,
    });
    const setAlertHide = () => {
        setAlert(Object.assign(Object.assign({}, alert), { show: false }));
    };
    useEffect(() => {
        if (alert.show)
            onAlert === null || onAlert === void 0 ? void 0 : onAlert();
    }, [alert.show]);
    const [data, _setData] = useState(emptyFormData);
    const setData = (partialData, infoSilentUpdate = false, changeOrigin = 'useForm user') => {
        _setData(data => {
            const newData = Object.assign(Object.assign({}, data), partialData);
            if (_debug_consoleFormChange) {
                console.log('useForm: formChange', _debug_consoleFormChange, {
                    currentData: Object.assign({}, data),
                    updateData: Object.assign({}, partialData),
                    newData: Object.assign({}, newData),
                    silentUpdate: infoSilentUpdate,
                    changeOrigin,
                });
            }
            return newData;
        });
    };
    const [dataId, setDataId] = useState(loadDataId);
    const [originalData, setOriginalData] = useState(emptyFormData);
    const [isNew, setIsNew] = useState(formMode === EFormMode.CREATE);
    const [isChanged, setIsChanged] = useState(false);
    const [changeId, setChangeId] = useState(guid());
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingLabel, setIsLoadingLabel] = useState('');
    const [isCanceled, setIsCanceled] = useState(false);
    const isArchived = data.archivedAt > 0;
    const isDeleted = data.deletedAt > 0;
    const [availablePropPaths, setAvailablePropPaths] = useState([]);
    const [validationStatus, _setValidationStatus] = useState(EValidationStatus.NONE);
    const [validationResult, _setValidationResult] = useState(validationEngineCleanResults(validationRules));
    const setValidationResult = (validationResult) => {
        if (validationResult === null) {
            _setValidationResult(validationEngineCleanResults(validationRules));
            _setValidationStatus(EValidationStatus.NONE);
            return;
        }
        _setValidationResult(validationResult);
        _setValidationStatus(validationResult.isValid
            ? EValidationStatus.SUCCESS
            : EValidationStatus.FAILED);
        if (!validationResult.isValid) {
            onAlert && onAlert();
        }
    };
    const [networkError, setNetworkError] = useState(null);
    const [loadFatalError, setLoadFatalError] = useState(null);
    const { confirm, confirmViewer, } = useConfirm();
    const eventListeners = useRef({
        load: [],
        save: [],
        cancel: [],
        archive: [],
        unarchive: [],
        delete: [],
        undelete: [],
    });
    const loadFormData = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (formMode === EFormMode.CREATE)
                return; // The form will create the data.
            if (loadDataId === null)
                return; // 4TS, It is the same as the above line
            setIsLoading(true);
            setIsLoadingLabel('Loading...');
            setChangeId(guid());
            if (!onApiGet) {
                throw dynaError({
                    code: 202102030732,
                    message: 'Dev error: onApiGet is not implemented',
                    userMessage: 'Dev error: onApiGet is not implemented',
                });
            }
            const loadedData_ = yield onApiGet(loadDataId, data);
            const loadedData = cloneDeep(loadedData_);
            setData(loadedData, false, 'useForm: loadFormData');
            setOriginalData(loadedData);
            eventListeners.current.load.forEach(handler => handler(loadedData));
        }
        catch (e) {
            const error = dynaError(e);
            setNetworkError(error);
            if (error.status === 401) {
                setLoadFatalError(dynaError({
                    message: '401 - Access denied',
                    userMessage: 'Access denied\nYou don\'t have access to this item.',
                }));
                return;
            }
            if (error.status === 404) {
                setLoadFatalError(dynaError({
                    message: error.message || '404 - Not found',
                    userMessage: error.userMessage || 'Not found\nCannot find this item.',
                }));
                return;
            }
            setLoadFatalError(error);
        }
        finally {
            setIsLoading(false);
            setChangeId(guid());
            setIsLoadingLabel('');
        }
    });
    // Find the available update paths
    useEffect(() => {
        const availablePaths = [];
        dynaObjectScan(emptyFormData, ({ path }) => {
            if (!path)
                return;
            availablePaths.push(path.slice(1));
        });
        setAvailablePropPaths(availablePaths);
    }, []);
    // Initial data form load
    useEffect(() => {
        loadFormData();
    }, []);
    const changeProp = ({ propPathName, value, silentUpdate = false, changeOrigin, forceRender, }) => {
        if (!silentUpdate && viewMode === EViewMode.VIEW) {
            console.error('useForm: `changeProp()` called, while the form is in view mode (and not in edit mode). Most likely, an input control is not read-only.', {
                fieldName: propPathName,
                value,
            });
            return;
        }
        if (!availablePropPaths.includes(propPathName)) {
            console.error(`Dev error: useForm.changeProp(): Property name/path [${propPathName}] doesn't not exist on the loaded data and it is not added. Checkout if this name/path is correct.`, { availablePropPaths });
            return;
        }
        const currentValue = getDeepValue(data, propPathName);
        if (!forceRender && value === currentValue)
            return; // Exit, the value is the same.
        setData(setDeepValue({
            data,
            propName: propPathName,
            value,
        })
            .data, silentUpdate, changeOrigin || 'useForm: changeProp()');
        if (!silentUpdate) {
            setIsChanged(true);
            setChangeId(guid());
        }
    };
    const getFieldNameFromInput = (event) => {
        return event.target.name;
    };
    const getValueFromInput = (event) => {
        return disableAutoMapping
            ? event.target.value
            : dynaSwitch(event.target.type, event.target.value, {
                checkbox: event.target.checked,
                // eslint-disable-next-line id-blacklist
                number: convertStringToNumber(event.target.value, -1),
            });
    };
    const getMappedValueFromInput = (event) => {
        const propPathName = getFieldNameFromInput(event);
        const inputValue = getValueFromInput(event);
        return (onChangeMapValue
            ? onChangeMapValue({
                input: event.target,
                propName: propPathName,
                oldValue: getDeepValue(data, propPathName),
                newValue: inputValue,
                formData: data,
            })
            : inputValue);
    };
    const trimValueIfNeededOnBlur = (event) => {
        if (!trimStringValues)
            return;
        const propPathName = getFieldNameFromInput(event);
        if (!propPathName)
            return;
        const value = getMappedValueFromInput(event);
        if (typeof value !== "string")
            return;
        const textValue = value.trim();
        const currentValue = getDeepValue(data, propPathName);
        if (currentValue === textValue)
            return;
        changeProp({
            propPathName,
            value: textValue,
            silentUpdate: true,
            changeOrigin: 'trimValueIfNeededOnBlur',
            forceRender: false,
        });
    };
    const handleFormChange = (event) => {
        const propPathName = getFieldNameFromInput(event);
        if (!propPathName)
            return;
        if (viewMode === EViewMode.VIEW) {
            console.error('useForm: Form\'s `change` triggered, while the form is in view mode (and not in edit mode). Most likely an input control is not in readonly.', {
                fieldName: propPathName,
                value: getValueFromInput(event),
            });
            return;
        }
        const value = getMappedValueFromInput(event);
        changeProp({
            propPathName,
            value: value,
            silentUpdate: false,
            changeOrigin: 'useForm: <form> change event',
        });
    };
    const useFormApi = {
        useFormApi: null, // Updated later with the actual value
        formMode,
        viewMode,
        alertViewer: _jsx(Alert, Object.assign({}, alert)),
        confirmViewer,
        data,
        isNew,
        isChanged,
        changeId,
        isLoading,
        isLoadingLabel,
        isCanceled,
        validationStatus,
        validationResult,
        networkError,
        loadFatalError,
        change: (updateData, silentUpdate = false, changeOrigin = 'useForm: change() called') => {
            if (!silentUpdate && viewMode === EViewMode.VIEW) {
                console.error('useForm: `change()` called, while the form is in view mode (and not in edit mode). Most likely, an input control is not read-only.', { formData: updateData });
                return;
            }
            if (Object.keys(updateData).length === 0)
                return; // Nothing to change
            setData(updateData, silentUpdate, changeOrigin);
            if (!silentUpdate) {
                setIsChanged(true);
                setChangeId(guid());
            }
        },
        changeByInput: (value, name, silentUpdate = false, changeOrigin = 'useForm: changeByInput() called') => {
            if (!name) {
                console.error('useForm: changeByInput() the `name` is not provided', {
                    data,
                    name,
                });
                return;
            }
            setData({ [name]: value }, silentUpdate, changeOrigin);
            if (!silentUpdate) {
                setIsChanged(true);
                setChangeId(guid());
            }
        },
        formProps: {
            // It is necessary to stop the propagation for all the below events because this form would be a nested form of another form.
            onReset: (event) => {
                event.preventDefault();
                event.stopPropagation();
                useFormApi.resetForm();
            },
            onChange: (event) => {
                event.stopPropagation();
                let changeApplied = false;
                if (updateMode === EUpdateMode.ON_CHANGE) {
                    handleFormChange(event);
                    changeApplied = true;
                }
                if (updateMode === EUpdateMode.ON_BLUR) {
                    if (isOnlyChangeControl(event)) {
                        // Change it immediately since this control has not a blur event
                        handleFormChange(event);
                        changeApplied = true;
                    }
                }
                if (!changeApplied && !isChanged) {
                    setIsChanged(true);
                    setChangeId(guid());
                }
            },
            onBlur: (event) => {
                var _a, _b;
                event.stopPropagation();
                if (viewMode === EViewMode.VIEW)
                    return;
                if (((_a = event.target) === null || _a === void 0 ? void 0 : _a.readOnly) === true)
                    return;
                if (((_b = event.target) === null || _b === void 0 ? void 0 : _b.disabled) === true)
                    return;
                if (updateMode === EUpdateMode.ON_BLUR) {
                    handleFormChange(event);
                    trimValueIfNeededOnBlur(event);
                }
            },
            onSubmit: (event) => {
                event.stopPropagation();
                event.preventDefault();
                useFormApi.buttons.save.onClick();
            },
        },
        validateForm: (_data) => {
            setValidationResult(null);
            const validateData = _data || data;
            const validationResult = validationEngine({
                data: validateData,
                validationRules: validationRules,
            });
            setValidationResult(validationResult);
            return validationResult.isValid;
        },
        setValidationResult: setValidationResult,
        save: () => {
            return useFormApi.buttons.save.onClick();
        },
        cancel: () => {
            return useFormApi.buttons.cancel.onClick();
        },
        archive: () => {
            return useFormApi.buttons.archive.onClick();
        },
        unarchive: () => {
            return useFormApi.buttons.unarchive.onClick();
        },
        delete: () => {
            return useFormApi.buttons.delete.onClick();
        },
        undelete: () => {
            return useFormApi.buttons.undelete.onClick();
        },
        reloadData: () => __awaiter(void 0, void 0, void 0, function* () {
            if (viewMode === EViewMode.EDIT) {
                console.error('useForm: reloadFormData() called while the form is in EDIT mode, only in VIEW can be called');
                return;
            }
            return loadFormData();
        }),
        resetForm: (emptyData = {}) => {
            setData(Object.assign(Object.assign({}, emptyFormData), emptyData), true, 'useForm: resetForm() called');
            setIsChanged(false);
            setIsCanceled(false);
            setValidationResult(null);
            setNetworkError(null);
            setLoadFatalError(null);
            setChangeId(guid());
        },
        addEventListener: (eventName, handler) => {
            eventListeners.current[eventName].push(handler);
        },
        removeEventListener: (eventName, handler) => {
            eventListeners.current[eventName] =
                eventListeners.current[eventName]
                    .filter(scanHandler => scanHandler !== handler);
        },
        buttons: {
            edit: {
                show: userCanEdit
                    && formType === EFormType.VIEW_EDIT,
                active: viewMode === EViewMode.EDIT,
                disabled: isLoading
                    || (disabledEditOnArchived && isArchived)
                    || (disabledEditOnDeleted && isDeleted),
                onClick: () => {
                    if (formType === EFormType.VIEW || formType === EFormType.EDIT) {
                        console.error('Edit button clicked while the formType === EFormType.EDIT, the button should be hidden!');
                        return;
                    }
                    const newViewMode = viewMode === EViewMode.VIEW ? EViewMode.EDIT : EViewMode.VIEW;
                    setViewMode(newViewMode);
                    setChangeId(guid());
                    if (newViewMode === EViewMode.EDIT && isCanceled)
                        setIsCanceled(false);
                    setAlertHide();
                },
            },
            save: {
                show: formType !== EFormType.VIEW
                    && ((userCanCreate && formMode === EFormMode.CREATE) ||
                        (userCanEdit && formMode === EFormMode.EDIT_MANAGE)),
                disabled: !isChanged
                    || isLoading
                    || (disabledEditOnArchived && isArchived)
                    || (disabledEditOnDeleted && isDeleted),
                onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    const dataToSave = (() => {
                        if (!onBeforeFormSave)
                            return data;
                        const changedBySaveBeforeData = onBeforeFormSave(data);
                        if (changedBySaveBeforeData !== undefined)
                            return changedBySaveBeforeData;
                        return data;
                    })();
                    let updatedData = undefined;
                    try {
                        setValidationResult(null);
                        const validationResult = validationEngine({
                            data: dataToSave,
                            checkForUnexpectedProperties: false, // Do not check, because DB might hold deprecated properties
                            validationRules: validationRules,
                        });
                        if (!validationResult.isValid) {
                            // Throw error with the client side validation error as validationResult.
                            // These errors will be consumed as server side errors, but this doesn't matter.
                            // We have to throw an error because the save() required returning the saved data.
                            throw dynaError({
                                message: 'Client validation errors',
                                validationErrors: { body: validationResult },
                            });
                        }
                        setIsLoading(true);
                        setIsLoadingLabel('Saving...');
                        setNetworkError(null);
                        setAlertHide();
                        setChangeId(guid());
                        if (formMode === EFormMode.CREATE) {
                            if (!onApiPost) {
                                throw dynaError({
                                    code: 202102030728,
                                    message: 'Dev error: onApiPost is not implemented',
                                    userMessage: 'Dev error: onApiPost is not implemented',
                                });
                            }
                            const response = yield onApiPost(dataToSave);
                            updatedData = response.data;
                            if (!updatedData)
                                setDataId(response.dataId);
                            setIsNew(false);
                            setFormMode(EFormMode.EDIT_MANAGE);
                        }
                        else if (formMode === EFormMode.EDIT_MANAGE) {
                            if (!onApiPut) {
                                throw dynaError({
                                    code: 202102030729,
                                    message: 'Dev error: onApiPut is not implemented',
                                    userMessage: 'Dev error: onApiPut is not implemented',
                                });
                            }
                            updatedData = yield onApiPut(dataToSave, originalData);
                        }
                        if (updatedData) {
                            setData(updatedData, false, 'useForm: update data from onApiPost()');
                            setOriginalData(cloneDeep(updatedData));
                        }
                        else {
                            setOriginalData(cloneDeep(dataToSave));
                        }
                        setIsChanged(false);
                        setChangeId(guid());
                        if (formType === EFormType.VIEW_EDIT)
                            setViewMode(EViewMode.VIEW);
                        setAlert({
                            show: true,
                            type: EAlertType.INFO,
                            title: 'Successfully saved',
                        });
                        onFormSave && onFormSave(updatedData || dataToSave);
                        eventListeners.current.save.forEach(handler => handler());
                    }
                    catch (e) {
                        const error = dynaError(e);
                        setNetworkError(error);
                        if (error.validationErrors) {
                            // Merge body / query validation errors
                            const validationResult = validationMergeResults(error.validationErrors.query
                                || {
                                    isValid: true,
                                    dataValidation: {},
                                    messages: [],
                                }, error.validationErrors.body
                                || {
                                    isValid: true,
                                    dataValidation: {},
                                    messages: [],
                                });
                            if (!validationResult.isValid) {
                                setValidationResult(validationResult);
                                setAlert({
                                    show: true,
                                    type: EAlertType.WARNING,
                                    title: 'Validation error',
                                    children: validationResult.messages.join('\n'),
                                });
                            }
                        }
                        else {
                            setAlert({
                                show: true,
                                type: EAlertType.ERROR,
                                title: '',
                                children: [
                                    (() => {
                                        if (error.status === 400)
                                            return 'Invalid request';
                                        if (error.status === 500)
                                            return 'Backend error';
                                        return 'General error';
                                    })(),
                                    error.userMessage || '',
                                    error.code ? `Error code: ${error.code}` : undefined,
                                ]
                                    .filter(Boolean).join('\n'),
                            });
                            console.error('useForm: Unexpected error on save', e);
                        }
                    }
                    finally {
                        setIsLoading(false);
                        setIsLoadingLabel('');
                        setChangeId(guid());
                    }
                    return updatedData || dataToSave;
                }),
            },
            cancel: {
                show: formType !== EFormType.VIEW
                    && ((userCanCreate && formMode === EFormMode.CREATE) ||
                        (userCanEdit && formMode === EFormMode.EDIT_MANAGE))
                    && !(formMode === EFormMode.CREATE
                        && hideCancelButtonOnCreateFormMore),
                disabled: isLoading
                    || viewMode === EViewMode.VIEW,
                onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (isChanged) {
                        const userDiscardChangesConfirmation = yield confirm({
                            title: 'Discard changes?',
                            message: 'You have unsaved changes, are you sure you want to discard them?',
                            labelConfirmButton: 'Continue editing',
                            labelCancelButton: 'Discard changes',
                        });
                        if (userDiscardChangesConfirmation)
                            return;
                    }
                    setIsCanceled(true);
                    setIsChanged(false);
                    setData(originalData, false, 'useForm: restore original data by cancel operation');
                    if (formType === EFormType.VIEW_EDIT)
                        setViewMode(EViewMode.VIEW);
                    setValidationResult(null);
                    setAlertHide();
                    setChangeId(guid());
                    onFormCancel && onFormCancel();
                    eventListeners.current.cancel.forEach(handler => handler());
                }),
            },
            archive: {
                show: !!onApiArchive
                    && userCanArchive
                    && !isArchived
                    && formMode === EFormMode.EDIT_MANAGE,
                disabled: isLoading
                    || isChanged
                    || (formType === EFormType.VIEW_EDIT && viewMode === EViewMode.EDIT),
                onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        if (dataId === null)
                            return; // 4TS
                        const userArchiveConfirmation = yield confirm({
                            title: 'Archive',
                            message: 'Are you sure you want to archive?',
                            labelConfirmButton: 'Archive',
                            labelCancelButton: 'Cancel',
                        });
                        if (!userArchiveConfirmation)
                            return;
                        setIsLoading(true);
                        setIsLoadingLabel('Archiving...');
                        setNetworkError(null);
                        setAlertHide();
                        setChangeId(guid());
                        if (!onApiArchive) {
                            throw dynaError({
                                code: 202102230730,
                                message: 'Dev error: onApiArchive is not implemented',
                                userMessage: 'Dev error: onApiArchive is not implemented',
                            });
                        }
                        const updateData = yield onApiArchive(dataId, data);
                        const newData = updateData
                            ? updateData
                            : { archivedAt: Date.now() };
                        setData(newData, false, updateData
                            ? 'useForm: update data from onApiArchive()'
                            : 'useForm: update archivedAt by archive operation');
                        setOriginalData(cloneDeep(newData));
                        if (formType === EFormType.VIEW_EDIT)
                            setViewMode(EViewMode.VIEW);
                        setChangeId(guid());
                        onFormArchive && onFormArchive(newData);
                        eventListeners.current.archive.forEach(handler => handler());
                    }
                    catch (e) {
                        const error = dynaError(e);
                        setNetworkError(error);
                        setAlert({
                            show: true,
                            type: EAlertType.ERROR,
                            title: getTitleError(error.status),
                            children: error.userMessage,
                        });
                    }
                    finally {
                        setIsLoading(false);
                        setIsLoadingLabel('');
                        setChangeId(guid());
                    }
                }),
            },
            unarchive: {
                show: !!onApiUnarchive
                    && userCanUnarchive
                    && data.archivedAt !== 0
                    && formMode === EFormMode.EDIT_MANAGE,
                disabled: isLoading
                    || isChanged
                    || (formType === EFormType.VIEW_EDIT && viewMode === EViewMode.EDIT),
                onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        if (dataId === null)
                            return; // 4TS
                        const userUnarchiveConfirmation = yield confirm({
                            title: 'Unarchive',
                            message: 'Are you sure you want to unarchive?',
                            labelConfirmButton: 'Unarchive',
                            labelCancelButton: 'Cancel',
                        });
                        if (!userUnarchiveConfirmation)
                            return;
                        setIsLoading(true);
                        setIsLoadingLabel('Undeleting...');
                        setNetworkError(null);
                        setAlertHide();
                        setChangeId(guid());
                        if (!onApiUnarchive) {
                            throw dynaError({
                                code: 202102230731,
                                message: 'Dev error: onApiUnarchive is not implemented',
                                userMessage: 'Dev error: onApiUnarchive is not implemented',
                            });
                        }
                        const updatedData = yield onApiUnarchive(dataId, data);
                        const newData = updatedData
                            ? updatedData
                            : { archivedAt: 0 };
                        setData(newData, false, updatedData
                            ? 'useForm: update data from onApiUnarchive()'
                            : 'useForm: update archivedAt by unarchive operation');
                        setOriginalData(cloneDeep(newData));
                        if (formType === EFormType.VIEW_EDIT)
                            setViewMode(EViewMode.VIEW);
                        setChangeId(guid());
                        onFormUnarchive && onFormUnarchive(newData);
                        eventListeners.current.unarchive.forEach(handler => handler());
                    }
                    catch (e) {
                        const error = dynaError(e);
                        setNetworkError(error);
                        setAlert({
                            show: true,
                            type: EAlertType.ERROR,
                            title: getTitleError(error.status),
                            children: error.userMessage,
                        });
                    }
                    finally {
                        setIsLoading(false);
                        setIsLoadingLabel('');
                        setChangeId(guid());
                    }
                }),
            },
            delete: {
                show: !!onApiDelete
                    && userCanDelete
                    && !isDeleted
                    && formMode === EFormMode.EDIT_MANAGE,
                disabled: isLoading
                    || isChanged
                    || (formType === EFormType.VIEW_EDIT && viewMode === EViewMode.EDIT),
                onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        if (dataId === null)
                            return; // 4TS
                        const userDeleteConfirmation = yield confirm({
                            title: 'Delete',
                            message: 'Are you sure you want to delete?',
                            labelConfirmButton: 'Delete',
                            labelCancelButton: 'Cancel',
                        });
                        if (!userDeleteConfirmation)
                            return;
                        setIsLoading(true);
                        setIsLoadingLabel('Deleting...');
                        setNetworkError(null);
                        setAlertHide();
                        setChangeId(guid());
                        if (!onApiDelete) {
                            throw dynaError({
                                code: 202102030730,
                                message: 'Dev error: onApiDelete is not implemented',
                                userMessage: 'Dev error: onApiDelete is not implemented',
                            });
                        }
                        const updateData = yield onApiDelete(dataId, data);
                        const newData = updateData
                            ? updateData
                            : { deletedAt: Date.now() };
                        setData(newData, false, updateData
                            ? 'useForm: update data from onApiDelete()'
                            : 'useForm: update deletedAt by delete operation');
                        setOriginalData(cloneDeep(newData));
                        if (formType === EFormType.VIEW_EDIT)
                            setViewMode(EViewMode.VIEW);
                        setChangeId(guid());
                        onFormDelete && onFormDelete(newData);
                        eventListeners.current.delete.forEach(handler => handler());
                    }
                    catch (e) {
                        const error = dynaError(e);
                        setNetworkError(error);
                        setAlert({
                            show: true,
                            type: EAlertType.ERROR,
                            title: getTitleError(error.status),
                            children: error.userMessage,
                        });
                    }
                    finally {
                        setIsLoading(false);
                        setIsLoadingLabel('');
                        setChangeId(guid());
                    }
                }),
            },
            undelete: {
                show: !!onApiUndelete
                    && userCanUnDelete
                    && data.deletedAt !== 0
                    && formMode === EFormMode.EDIT_MANAGE,
                disabled: isLoading
                    || isChanged
                    || (formType === EFormType.VIEW_EDIT && viewMode === EViewMode.EDIT),
                onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        if (dataId === null)
                            return; // 4TS
                        const userUndeleteConfirmation = yield confirm({
                            title: 'Undelete',
                            message: 'Are you sure you want to undelete?',
                            labelConfirmButton: 'Undelete',
                            labelCancelButton: 'Cancel',
                        });
                        if (!userUndeleteConfirmation)
                            return;
                        setIsLoading(true);
                        setIsLoadingLabel('Undeleting...');
                        setNetworkError(null);
                        setAlertHide();
                        setChangeId(guid());
                        if (!onApiUndelete) {
                            throw dynaError({
                                code: 202102030731,
                                message: 'Dev error: onApiUndelete is not implemented',
                                userMessage: 'Dev error: onApiUndelete is not implemented',
                            });
                        }
                        const updatedData = yield onApiUndelete(dataId, data);
                        const newData = updatedData
                            ? updatedData
                            : { deletedAt: 0 };
                        setData(newData, false, updatedData
                            ? 'useForm: update data from onApiUndelete()'
                            : 'useForm: update deletedAt by undelete operation');
                        setOriginalData(cloneDeep(newData));
                        if (formType === EFormType.VIEW_EDIT)
                            setViewMode(EViewMode.VIEW);
                        setChangeId(guid());
                        onFormUndelete && onFormUndelete(newData);
                        eventListeners.current.undelete.forEach(handler => handler());
                    }
                    catch (e) {
                        const error = dynaError(e);
                        setNetworkError(error);
                        setAlert({
                            show: true,
                            type: EAlertType.ERROR,
                            title: getTitleError(error.status),
                            children: error.userMessage,
                        });
                    }
                    finally {
                        setIsLoading(false);
                        setIsLoadingLabel('');
                        setChangeId(guid());
                    }
                }),
            },
        },
    };
    useFormApi.useFormApi = useFormApi;
    return useFormApi;
};
const getTitleError = (status) => {
    switch (status) {
        case undefined:
            return "Unknown error";
        case 400:
            return "Invalid request";
        case 404:
            return "Not found";
        case 503:
            return "Unavailable service";
        case 401:
            return "You are Unauthorized";
        case 403:
            return "Access denied";
        case 500:
            return "Backend error";
        default:
            return "Network error";
    }
};
