import axios, {AxiosError} from 'axios';
import * as qs from "qs";

import {dynaError} from 'dyna-error';
import {guid} from "dyna-guid";

import {getOnline} from "utils-library/dist/web";

interface IFetchConfig {
  baseUrl?: string;
  preflight?: boolean;          // To disable CORS
  on401AccessDenied?: (fetchRequest: IFetchRequest) => void;  // Aka 401 (not 403!). This occurred when the token got invalid or not signed-in at all.
}

// @ts-ignore
export interface IFetchRequest<TRequestQuery = any, TRequestBody = any> {
  path: string;
  method?: 'GET' | 'POST' | 'PUT' | 'HEAD' | 'DELETE' | 'PATCH' | 'OPTIONS';
  body?: TRequestBody;
  query?: TRequestQuery;
  disableCache?: boolean;
  preflight?: boolean;
  ignore401?: boolean;
  getCancelMethod?: (cancel: () => void) => void;
  onUploadProgress?: (percentage: number, current: number, ofTotal: number) => void;
  onDownloadProgress?: (percentage: number, current: number, ofTotal: number) => void;
}

class ApiFetch {
  constructor(private readonly config: IFetchConfig = {}) {
  }

  public set baseUrl(baseUrl: string) {
    this.config.baseUrl = baseUrl;
  }

  public set preflight(preflight: boolean) {
    this.config.preflight = preflight;
  }

  public set on401AccessDenied(on401AccessDenied: (fetchRequest: IFetchRequest) => void) {
    this.config.on401AccessDenied = on401AccessDenied;
  }

  public request = async <TRequestQuery, TRequestBody, TResponse>(fetchRequest: IFetchRequest<TRequestQuery, TRequestBody>): Promise<TResponse> => {
    const {
      path,
      method = 'GET',
      body,
      query,
      disableCache,
      preflight,
      ignore401 = false,
      getCancelMethod,
      onUploadProgress,
      onDownloadProgress,
    } = fetchRequest;
    if (!getOnline()) {
      throw dynaError({
        code: 202105281610,
        message: 'apiFetch: Your are offline, browser is not currently connected',
        userMessage: 'You are offline.\nThis operation requires internet connection.',
        data: {
          path,
          method,
        },
      });
    }

    let canceled = false;
    const controller = new AbortController();
    const handleCancel = () => controller.abort();
    if (getCancelMethod) getCancelMethod(handleCancel);

    try {

      if (this.config.preflight || preflight) {
        await axios.request({
          baseURL: this.config.baseUrl,
          url: path,
          method: "OPTIONS",
        });
      }

      if ((method === "GET" || method === "HEAD") && !!body) {
        throw dynaError({
          code: 202010091103,
          message: "Internal error: fetch: data (xhr's body) is not supported by GET or HEAD methods",
          data: {apiFetchInternalError: true},
        });
      }

      let applyQuery = query || {};
      if (disableCache) (applyQuery as any)._disableCache = guid();
      if (!Object.keys(applyQuery).length) applyQuery = {};

      const response = await axios.request<TResponse>({
        baseURL: this.config.baseUrl,
        url: [path, qs.stringify(applyQuery)].filter(Boolean).join('?'),
        method,
        data: body as any,
        // Params: qs.stringify(applyQuery), <-- Dev Note: It doesn't work! It adds a "0=" at the front.
        signal: controller.signal,
        withCredentials: true,
        headers: {'content-type': 'application/json; charset=utf-8'},
        onUploadProgress: (progressEvent) => {
          // Todo: known issue, Axios doesn't return the actual progress https://github.com/axios/axios/issues/1591
          if (!onUploadProgress) return;
          if (canceled) return;
          if (progressEvent.lengthComputable) {
            onUploadProgress(100 * progressEvent.loaded / (progressEvent.total || 1), progressEvent.loaded, progressEvent.total || 0);
          }
        },
        onDownloadProgress: (progressEvent) => {
          // Todo: known issue, Axios doesn't return the actual progress https://github.com/axios/axios/issues/1591
          if (!onDownloadProgress) return;
          if (canceled) return;
          if (progressEvent.lengthComputable) {
            onDownloadProgress(100 * progressEvent.loaded / (progressEvent.total || 1), progressEvent.loaded, progressEvent.total || 0);
          }
        },
      });

      return response.data;
    }
    catch (e: any) {
      const error: AxiosError = e as any;

      if (error.message === "canceled") {
        canceled = true;
        throw dynaError({
          code: 202206020838,
          message: 'Client request is canceled',
          userMessage: 'Canceled',
        });
      }

      if (error.response && error.response.status === 401) {
        if (this.config.on401AccessDenied && !ignore401) this.config.on401AccessDenied(fetchRequest);
        throw dynaError({
          status: error.response.status,
          message: 'Unauthorized / Access denied',
          userMessage: 'Unauthorized / Access denied',
          parentError: error,
        });
      }

      if (error.response && typeof error.response.status === 'number') {
        throw dynaError({
          message: error.message || 'Response is an unknown error',
          ...(error.response.data as any || {}),
          status: error.response.status,
        });
      }

      if (e.data?.apiFetchInternalError) {
        console.error(
          'apiFetch internal error',
          {
            error: e,
            fetchRequest,
          },
        );
      }
      throw e;
    }
  };
}

export const apiFetch = new ApiFetch();
