import {ELanePanningRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {LanePlanningIcon} from "../icons";

export const routeLanePlanningPagePaths: IAppRoute = {
  menuId: '##line-planning-main-page',
  icon: <LanePlanningIcon/>,
  title: 'Lane Planning',
  routePath: '/lane-planning',
  getRoutePath: () => routeLanePlanningPagePaths.routePath,
  userHasAnyOfRights: Object.values(ELanePanningRights),
  userHasAllRights: [],
  render: () => null,
};
