import {IAppRoute} from "../../application/config/IAppRoute";
import {routeWorkZonesEditPaths} from "./routeWorkzoneEdit.paths";
import {WorkzoneCRUDPage} from "../pages/WorkzoneCRUDPage/WorkzoneCRUDPage";
import {
  ECIMTabRoot,
  ECIMTabMap,
} from "../components/WorkzoneForm";

export const routeWorkZonesEdit: IAppRoute<
  {
    id: string;
    tabRoot?: ECIMTabRoot;
    tabMap?: ECIMTabMap;
  },
  {
    performAction?:
      | 'validate'
      | 'broadcast';
  }
> = {
  ...routeWorkZonesEditPaths,
  render: (
    {
      pathParams: {
        id,
        tabRoot,
        tabMap,
      },
      queryParams: {performAction},
    },
  ) =>
    <WorkzoneCRUDPage
      id={id}
      tabRoot={tabRoot}
      tabMap={tabMap}
      performAction={performAction}
    />,
};
