import { convertDictionaryToViewLabelArray } from "utils-library/dist/utils";
export var EUserAuthenticationRights;
(function (EUserAuthenticationRights) {
    EUserAuthenticationRights["ACTIVE"] = "UA_S";
    EUserAuthenticationRights["ADMINISTRATOR"] = "UA_A";
    EUserAuthenticationRights["SYSTEM_ADMINISTRATOR"] = "UA_SA";
})(EUserAuthenticationRights || (EUserAuthenticationRights = {}));
const labelsDic = {
    [EUserAuthenticationRights.ACTIVE]: 'User / Active',
    [EUserAuthenticationRights.ADMINISTRATOR]: 'Administrator',
    [EUserAuthenticationRights.SYSTEM_ADMINISTRATOR]: 'Admin / System Administrator',
};
export const EUserAuthenticationRightsArray = convertDictionaryToViewLabelArray(labelsDic);
