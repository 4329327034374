/**
 * Resolve the host name, from a url adderess
 * Example: http://my.example.com/schön --> my.example.com
 * Example: my.example.com/schön --> my.example.com
 * @param address
 */
export const getHost = (address) => {
    if (!address)
        return "";
    if (address.startsWith('http'))
        return address.split('/')[2];
    return address.split('/')[0];
};
