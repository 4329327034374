import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";
import {routeDeviceManagementMapPaths} from "./routeDeviceManagementMap.paths";
import {IDeviceManagerMapSearchObject} from "../pages/DeviceManagementMap/interfaces";

const DeviceManagementMap = lazy(() => import ("../pages/DeviceManagementMap"));

export const routeDeviceManagementMap: IAppRoute<{ searchObjectText: string }> = {
  ...routeDeviceManagementMapPaths,
  render: ({pathParams: {searchObjectText}}) => {
    const searchObject: IDeviceManagerMapSearchObject = (() => {
      if (!searchObjectText) return defaultSearchObject;

      const decoded = decodeURI(searchObjectText);

      try {
        return JSON.parse(decoded);
      }
      catch (e) {
        console.error(
          'Parsing searchObjectText error',
          {
            error: e,
            searchObjectText,
          },
        );
        return defaultSearchObject;
      }
    })();

    return (
      <LazyLoadComponent>
        <DeviceManagementMap
          searchObject={searchObject}
        />
      </LazyLoadComponent>
    );
  },
};

const defaultSearchObject: IDeviceManagerMapSearchObject = {
  regionId: '',
  searchText: '',
  searchPanelOpen: true,
};
