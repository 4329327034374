import * as ReactDOMServer from 'react-dom/server';
import { prettifyHTML } from "utils-library/dist/web";
// This is needed by renderToString when it is used in the browser environment.
import { Buffer } from "buffer";
if (!window.Buffer)
    window.Buffer = Buffer;
/**
 * This is technically the renderToString, but provides also some extra features
 *
 * # Warning, currently no support for MUI's SX classes
 *
 * Due to the nature of the React's renderToString, the MUI's sx classes are not added to the DOM.
 * To fix this problem, render the jsxElement like this <Box hidden children={jsxElement}/>
 *
 * When this is called on client side (aka browser) the application should assign the Buffer like this:
 * ```typescript
 * import { Buffer } from "buffer";
 * if (!window.Buffer) window.Buffer = Buffer;
 * ```
 */
export const convertJSXElementToHTML = ({ jsxElement, forHydration = true, prettify = false, }) => {
    const html = forHydration
        ? ReactDOMServer.renderToString(jsxElement)
        : ReactDOMServer.renderToStaticMarkup(jsxElement);
    return prettify
        ? prettifyHTML(html)
        : html;
};
