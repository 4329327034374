export const getOpenIdEndpoint = ({ host, strategy, endpoint, companyId, }) => [
    '/services/authentication/oAuth/open-id-v1/',
    strategy,
    '/',
    endpoint === "client"
        ? 'sign-in'
        : 'provider-callback',
    '/',
    host,
    '/',
    companyId,
].join('');
