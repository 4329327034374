import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IAlertMgtmSettingsPageProps} from "./AlertMgtmSettingsPage";

const AlertMgtmSettingsPageLazy = lazy(() => import("./AlertMgtmSettingsPage"));

export const AlertMgtmSettingsPage = connect((props: IAlertMgtmSettingsPageProps) => (
  <LazyLoadComponent>
    <AlertMgtmSettingsPageLazy {...props}/>
  </LazyLoadComponent>
));
