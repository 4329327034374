import {connect} from "react-dynadux";

import {
  EWorkZonesManagementRights,
} from "mhc-server/dist/interfaces";

import {IAppStore} from "../../../../state/IAppStore";
import {
  WorkzoneForm,
  ECIMTabRoot,
  ECIMTabMap,
} from "../../components/WorkzoneForm";

export interface IWorkzoneCRUDPageProps {
  store: IAppStore;
  id?: string;
  tabRoot?: ECIMTabRoot;
  tabMap?: ECIMTabMap;
  performAction?:
    | 'validate'
    | 'broadcast';
}

export const WorkzoneCRUDPage = connect((props: IWorkzoneCRUDPageProps): JSX.Element => {
  const {
    store: {userAuth: {utils: {userHasAllRights}}},
    id,
    tabRoot,
    tabMap,
    performAction,
  } = props;
  return (
    <WorkzoneForm
      id={id}
      allowBroadcast={userHasAllRights([
        EWorkZonesManagementRights.WORKZONES_EDIT,
      ])}
      tabRoot={tabRoot}
      tabMap={tabMap}
      performAction={performAction}
    />
  );
});
