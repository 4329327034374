export const validationEngineCleanResults = (validationRules) => {
    return {
        isValid: true,
        dataValidation: createValidationCleanResults(validationRules),
        messages: [],
    };
};
const createValidationCleanResults = (validationRules, _output = {}) => {
    Object.keys(validationRules)
        .forEach(property => {
        if (!property.includes('.'))
            _output[property] = "";
    });
    return _output;
};
