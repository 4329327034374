import {IAppRoute} from "../config/IAppRoute";
import {NotFound404Page} from "../pages/NotFound404Page";

export const route404: IAppRoute = {
  menuId: '',
  title: 'Not found',
  routePath: '*',
  getRoutePath: () => route404.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  exact: true,
  render: () => {
    return <NotFound404Page info="This page doesn't exist anymore or it is removed."/>;
  },
};
