export const arrayUnique = (array, keyGenerator) => (array
    .reduce((acc, item) => {
    if (keyGenerator) {
        const itemKey = keyGenerator(item);
        if (!acc.find(scanItem => keyGenerator(scanItem) === itemKey))
            acc.push(item);
    }
    else {
        if (!acc.includes(item))
            acc.push(item);
    }
    return acc;
}, []));
